import { TableColumns, TableHeaders, TableRowConfig } from '@/components/table/models';

export enum PLAYBOOKS_TABLE_COLUMN_IDS {
	BEST_PRACTICES = 'BEST_PRACTICES',
	BUSINESS_PROCESS = 'BUSINESS_PROCESS',
	MODULES = 'MODULES',
	PRIORITY = 'PRIORITY',
	FUNCTIONS = 'FUNCTIONS',
	CONFIGURATION_CONSIDERATIONS = 'CONFIGURATION_CONSIDERATIONS',
	LESSONS_LEARNED = 'LESSONS_LEARNED',
	PROCESS_FLOW_STEPS_INFLUENCED = 'PROCESS_FLOW_STEPS_INFLUENCED',
	CURRENT_ASSEESSMENT = 'CURRENT_ASSEESSMENT',
	BUSINESS_VALUE = 'BUSINESS_VALUE',
}

export const playbooksTableColumns: TableColumns<PLAYBOOKS_TABLE_COLUMN_IDS> = {
	[PLAYBOOKS_TABLE_COLUMN_IDS.BEST_PRACTICES]: {
		columnId: PLAYBOOKS_TABLE_COLUMN_IDS.BEST_PRACTICES,
		columnTitle: 'Best Practices',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.BUSINESS_PROCESS]: {
		columnId: PLAYBOOKS_TABLE_COLUMN_IDS.BUSINESS_PROCESS,
		columnTitle: 'Business Process',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.MODULES]: {
		columnId: PLAYBOOKS_TABLE_COLUMN_IDS.MODULES,
		columnTitle: 'Modules',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.PRIORITY]: {
		columnId: PLAYBOOKS_TABLE_COLUMN_IDS.PRIORITY,
		columnTitle: 'Priority',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.FUNCTIONS]: {
		columnId: PLAYBOOKS_TABLE_COLUMN_IDS.FUNCTIONS,
		columnTitle: 'Functions',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.CONFIGURATION_CONSIDERATIONS]: {
		columnId: PLAYBOOKS_TABLE_COLUMN_IDS.CONFIGURATION_CONSIDERATIONS,
		columnTitle: 'Configuration Considerations',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.LESSONS_LEARNED]: {
		columnId: PLAYBOOKS_TABLE_COLUMN_IDS.LESSONS_LEARNED,
		columnTitle: 'Lessons Learned',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.PROCESS_FLOW_STEPS_INFLUENCED]: {
		columnId: PLAYBOOKS_TABLE_COLUMN_IDS.PROCESS_FLOW_STEPS_INFLUENCED,
		columnTitle: 'Process Flow Steps Influenced',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.CURRENT_ASSEESSMENT]: {
		columnId: PLAYBOOKS_TABLE_COLUMN_IDS.CURRENT_ASSEESSMENT,
		columnTitle: 'Current Assessment',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.BUSINESS_VALUE]: {
		columnId: PLAYBOOKS_TABLE_COLUMN_IDS.BUSINESS_VALUE,
		columnTitle: 'Business Value',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
};

export const playbooksTableHeaders: TableHeaders<PLAYBOOKS_TABLE_COLUMN_IDS> = {
	[PLAYBOOKS_TABLE_COLUMN_IDS.BEST_PRACTICES]: {
		tableHeaderId: PLAYBOOKS_TABLE_COLUMN_IDS.BEST_PRACTICES,
		title: 'Best Practices',
		isSortable: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.BUSINESS_PROCESS]: {
		tableHeaderId: PLAYBOOKS_TABLE_COLUMN_IDS.BUSINESS_PROCESS,
		title: 'Business Process',
		isSortable: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.MODULES]: {
		tableHeaderId: PLAYBOOKS_TABLE_COLUMN_IDS.MODULES,
		title: 'Modules',
		isSortable: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.PRIORITY]: {
		tableHeaderId: PLAYBOOKS_TABLE_COLUMN_IDS.PRIORITY,
		title: 'Priority',
		isSortable: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.FUNCTIONS]: {
		tableHeaderId: PLAYBOOKS_TABLE_COLUMN_IDS.FUNCTIONS,
		title: 'Functions',
		isSortable: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.CONFIGURATION_CONSIDERATIONS]: {
		tableHeaderId: PLAYBOOKS_TABLE_COLUMN_IDS.CONFIGURATION_CONSIDERATIONS,
		title: 'Configuration Considerations',
		isSortable: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.LESSONS_LEARNED]: {
		tableHeaderId: PLAYBOOKS_TABLE_COLUMN_IDS.LESSONS_LEARNED,
		title: 'Lessons Learned',
		isSortable: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.PROCESS_FLOW_STEPS_INFLUENCED]: {
		tableHeaderId: PLAYBOOKS_TABLE_COLUMN_IDS.PROCESS_FLOW_STEPS_INFLUENCED,
		title: 'Process Flow Steps Influenced',
		isSortable: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.CURRENT_ASSEESSMENT]: {
		tableHeaderId: PLAYBOOKS_TABLE_COLUMN_IDS.CURRENT_ASSEESSMENT,
		title: 'Current Assessment',
		isSortable: true,
	},
	[PLAYBOOKS_TABLE_COLUMN_IDS.BUSINESS_VALUE]: {
		tableHeaderId: PLAYBOOKS_TABLE_COLUMN_IDS.BUSINESS_VALUE,
		title: 'Business Value',
		isSortable: true,
	},
};

export const playbooksTableRows: TableRowConfig<{
	bestPractices: string;
	businessProcess: string;
	modules: string;
	priority: string;
	functions: string;
	configurationConsiderations: string;
	lessonsLearned: string;
	processFlowStepsInfluenced: string;
	currentAssessment: string;
	businessValue: string;
}>[] = [
	{
		rowId: 'xxxx-xxxx-xxxx-xxxx-0',
		checked: false,
		expanded: false,
		columnData: {
			bestPractices:
				'Consolidate transaction processing within a shared services or leveraged environment (retained, outsourced or hybrid).',
			businessProcess: 'Accounts Payable',
			modules: 'Accounts Payable',
			priority: 'None',
			functions: 'Application Security, Policy & Procedures, Workflow',
			configurationConsiderations:
				"Leverage Oracle Cloud's shared services functionality by creating relationships among client and service provider business units to establish a consolidated accounts payable shared service center.",
			lessonsLearned: 'N/A',
			processFlowStepsInfluenced:
				'AP 1.0 - Process Invoice, AP 2.0 - Issue Payment, AP 3.0 - Retain Records, AP 4.0 - Perform Period End Activities, AP 5.0 - Respond to Inquiries',
			currentAssessment: '2 - Rarely Performed',
			businessValue: '3 - Moderate',
		},
	},
];
