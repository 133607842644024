import React, { useRef } from 'react';
import { Switch, Redirect, useRouteMatch, Route } from 'react-router-dom';

import { EngagementDashboard, EngagementPlan, NoMatch } from '@/pages';
import { NavigationSub } from '@/components';

export const Engagement = () => {
	const { path } = useRouteMatch();

	const routes = useRef([
		{
			path: path,
			exact: true,
			main: () => <Redirect to={`${path}/dashboard`} />,
		},
		{
			path: `${path}/dashboard`,
			exact: true,
			main: EngagementDashboard,
		},
		{
			path: `${path}/engagement-plan`,
			exact: true,
			main: EngagementPlan,
		},
		{
			path: `${path}/*`,
			exact: false,
			main: NoMatch,
		},
	]).current;

	return (
		<>
			<NavigationSub
				navigationItems={[
					{
						to: `${path}/dashboard`,
						title: 'Dashboard',
					},
					{
						to: `${path}/engagement-plan`,
						title: 'Engagement Plan',
					},
				]}
			/>

			<Switch>
				{routes.map((route, index) => {
					return <Route key={index} path={route.path} exact={route.exact} children={<route.main />} />;
				})}
			</Switch>
		</>
	);
};
