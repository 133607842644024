import { v4 as uuid } from 'uuid';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles((theme) => ({
	typewriter: {
		'& p': {
			marginBottom: 18,
		},
		'& ol': {
			padding: 0,
			marginBottom: 18,
			counterReset: 'item',
			'& > li': {
				paddingLeft: 24,
				position: 'relative',
				listStyleType: 'none',
				counterIncrement: 'item',
				'&:before': {
					top: 0,
					left: 0,
					position: 'absolute',
					...theme.fonts.openSansBold,
					content: 'counter(item) "."',
				},
				'&:last-child': {
					marginBottom: 0,
				},
			},
		},
		'& ul': {
			paddingLeft: 24,
			marginBottom: 18,
			listStyle: 'disc',
			'& > li': {
				'&:last-child': {
					marginBottom: 0,
				},
			},
		},
	},
}));

interface TypewriterProps {
	htmlString: string;
	delayBetweenCharacters?: number;
	onAnimationComplete?(): void;
}

interface HtmlStructure {
	uuid: string;
	parentUuid?: string;
	element: ChildNode;
	childStructures: HtmlStructure[];
	text?: string;
}

export const Typewriter: FC<TypewriterProps> = ({
	htmlString,
	delayBetweenCharacters = 10,
	onAnimationComplete,
	children,
}) => {
	const classes = useStyles();
	const containerRef = useRef<HTMLDivElement>(null);
	const characterTimeouts = useRef<NodeJS.Timeout[]>([]);
	const animationCompleteTimeout = useRef<NodeJS.Timeout>();
	const [animationComplete, setAnimationComplete] = useState(false);

	const convertChildNodesToData = useCallback((elements: NodeListOf<ChildNode>): HtmlStructure[] => {
		return Array.from(elements).map((element) => {
			const childStructures = convertChildNodesToData(element.childNodes);

			return {
				uuid: uuid(),
				element,
				childStructures,
				...(element.nodeType === Node.TEXT_NODE && { text: element.textContent ?? '' }),
			};
		});
	}, []);

	const flattenHtmlStructures = useCallback(
		(htmlStructure: HtmlStructure[], parentUuid?: string): HtmlStructure[] => {
			return htmlStructure.flatMap((hs) => [
				{ ...hs, parentUuid },
				...flattenHtmlStructures(hs.childStructures, hs.uuid),
			]);
		},
		[]
	);

	const convertFlattenedHtmlStructureToSpans = useCallback((htmlStructures: HtmlStructure[]) => {
		return htmlStructures.reduce((accumulator, currentValue) => {
			return [
				...accumulator,
				...(currentValue.element.nodeType === Node.TEXT_NODE
					? (currentValue.text ?? '').split('').map((character) => {
							const spanElement = document.createElement('span');
							spanElement.innerHTML = character;

							return {
								uuid: uuid(),
								parentUuid: currentValue.parentUuid,
								element: spanElement,
								childStructures: [],
								text: character,
							};
					  })
					: [currentValue]),
			];
		}, [] as HtmlStructure[]);
	}, []);

	const clearContainerHtml = () => {
		if (containerRef.current) {
			containerRef.current.innerHTML = '';
		}
	};

	const clearCharacterTimeouts = () => {
		characterTimeouts.current.forEach((timeout) => {
			clearTimeout(timeout);
		});

		characterTimeouts.current = [];
	};

	const clearAnimationCompleteTimeout = () => {
		if (animationCompleteTimeout.current) {
			clearTimeout(animationCompleteTimeout.current);
			animationCompleteTimeout.current = undefined;
		}
	};

	const appendHtmlStructureToDom = useCallback((htmlStructure: HtmlStructure) => {
		const elementClone = htmlStructure.element.cloneNode(true);
		(elementClone as HTMLElement).setAttribute('data-typewriter-id', htmlStructure.uuid);
		(elementClone as HTMLElement).innerHTML = htmlStructure.text ?? '';

		const parentElement = htmlStructure.parentUuid
			? document.querySelectorAll(`[data-typewriter-id="${htmlStructure.parentUuid}"]`)[0]
			: containerRef.current;

		parentElement?.append(elementClone);
	}, []);

	useEffect(() => {
		clearContainerHtml();
		clearCharacterTimeouts();
		clearAnimationCompleteTimeout();
		setAnimationComplete(false);

		if (!htmlString) {
			return;
		}

		const domParser = new DOMParser();
		const htmlDocument = domParser.parseFromString(htmlString, 'text/html');
		const htmlStructures = convertChildNodesToData(htmlDocument.body.childNodes);
		const htmlStructuresFlat = flattenHtmlStructures(htmlStructures);
		const htmlStructuresFlatWithSpans = convertFlattenedHtmlStructureToSpans(htmlStructuresFlat);
		const animationDuration = htmlStructuresFlatWithSpans.length * delayBetweenCharacters;

		htmlStructuresFlatWithSpans.forEach((hs, index) => {
			const characterTimeout = setTimeout(() => {
				appendHtmlStructureToDom(hs);
			}, delayBetweenCharacters * index);

			characterTimeouts.current.push(characterTimeout);
		});

		animationCompleteTimeout.current = setTimeout(() => {
			animationCompleteTimeout.current = undefined;
			setAnimationComplete(true);
		}, animationDuration);
	}, [
		appendHtmlStructureToDom,
		convertFlattenedHtmlStructureToSpans,
		convertChildNodesToData,
		delayBetweenCharacters,
		flattenHtmlStructures,
		htmlString,
		onAnimationComplete,
	]);

	useEffect(() => {
		if (!animationComplete) {
			return;
		}

		onAnimationComplete?.();
	}, [animationComplete, onAnimationComplete]);

	useEffect(() => {
		return () => {
			clearCharacterTimeouts();
			clearAnimationCompleteTimeout();
		};
	}, []);

	return (
		<>
			<div ref={containerRef} className={classes.typewriter}></div>
			{animationComplete && <div>{children}</div>}
		</>
	);
};
