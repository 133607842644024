import { v4 as uuid } from 'uuid';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Modal, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import classNames from 'classnames';

import { DOCUMENT_UPLOAD_STATUS_ID, FormOption, PresignedUploadModel, inquiryAttachment } from '@/lib/models';
import { documentUploader, inquiryService } from '@/lib/services';
import { useAccount, useAlert, useHandleError, useNavigationConfig } from '@/hooks';
import {
	AttachmentDropdown,
	FadeTransition,
	FileInputButton,
	LoadingButton,
	Meter,
	Select,
	Wysiwyg,
} from '@/components';

import { ReactComponent as PaperclipIcon } from '@/assets/icons/icon-paperclip.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/icon-plus.svg';

export const InquiryForm = React.memo(() => {
	const history = useHistory();
	const handleError = useHandleError();
	const { showAlert } = useAlert();
	const { account } = useAccount();
	const { navigationConfig } = useNavigationConfig();

	const [formOptionsLoading, setFormOptionsLoading] = useState(false);
	const [clientOptions, setClientOptions] = useState<FormOption[]>([]);
	const [requesterOptions, setRequesterOptions] = useState<FormOption[]>([]);
	const [programOptions, setProgramOptions] = useState<FormOption[]>([]);
	const [typeOptions, setTypeOptions] = useState<FormOption[]>([]);
	const [topicsOptions, setTopicsOptions] = useState<FormOption[]>([]);
	const [accountUrl, setAccountUrl] = useState('');
	const [shouldShowNotes, setShouldShowNotes] = useState(false);
	const [reachedInquiryLimit, setReachedInquiryLimit] = useState(false);
	const [showLimitInquiryModal, setShowLimitInquiryModal] = useState(false);

	const [clientValue, setClientValue] = useState('');
	const [requesterValue, setRequesterValue] = useState('');
	const [programValue, setProgramValue] = useState('');
	const [typeValue, setTypeValue] = useState('');
	const [topicsValue, setTopicsValue] = useState<FormOption[]>([]);
	const [titleValue, setTitleValue] = useState('');
	const [inquiryText, setInquiryText] = useState('');
	const [notes, setNotes] = useState('');
	const [inquiryDocuments, setInquiryDocuments] = useState<{ file: File; fileId: string }[]>([]);
	const [isUploading, setIsUpoading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const fetchInquiryFormOptions = useCallback(async () => {
		try {
			setFormOptionsLoading(true);

			const {
				clients,
				accounts,
				inquiryType,
				programs,
				topics,
				createAccountUrl,
				shouldShowNotes,
				reachedInquiryLimit,
			} = await inquiryService
				.getInquiryFormOptions({
					...(clientValue && { clientId: clientValue }),
					...(requesterValue && { accountId: requesterValue }),
					...(programValue && { programId: programValue }),
				})
				.fetch();

			setClientOptions(clients ?? []);
			setRequesterOptions(accounts ?? []);
			setProgramOptions(programs ?? []);
			setTypeOptions(inquiryType ?? []);
			setTopicsOptions(topics ?? []);
			setAccountUrl(createAccountUrl);
			setShouldShowNotes(shouldShowNotes);
			setReachedInquiryLimit(reachedInquiryLimit);
		} catch (error) {
			handleError(error);
		} finally {
			setFormOptionsLoading(false);
		}
	}, [clientValue, handleError, programValue, requesterValue]);

	useEffect(() => {
		fetchInquiryFormOptions();
	}, [fetchInquiryFormOptions]);

	const handleAttachButtonChange = useCallback(
		(file: File) => {
			if (file.size > inquiryAttachment.maxFileSize_bytes) {
				showAlert({
					variant: 'danger',
					children: () => {
						return (
							<p className="mb-0 text-white">
								<strong>Attachment size cannot exceed 100MB. Please try again.</strong>
							</p>
						);
					},
				});
				return;
			}

			let fileExtension = (/\.[^.]+$/.exec(file.name) ?? []).pop();
			if (fileExtension === undefined || !inquiryAttachment.validExtensions.includes(fileExtension)) {
				showAlert({
					variant: 'danger',
					children: () => {
						return (
							<p className="mb-0 text-white">
								<strong>
									Incorrect file type. Choose a file with valid format: any office type, pdf or zip.
								</strong>
							</p>
						);
					},
				});
				return;
			}

			setInquiryDocuments((previousValue) => [...previousValue, { file, fileId: uuid() }]);
		},
		[showAlert]
	);

	const handleDocumentRemove = useCallback((document: { file: File; fileId: string }) => {
		setInquiryDocuments((previousValue) => {
			const indexToRemove = previousValue.findIndex((v) => v.fileId === document.fileId);

			if (indexToRemove > -1) {
				previousValue.splice(indexToRemove, 1);
			}

			return [...previousValue];
		});
	}, []);

	const handleContactFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setShowLimitInquiryModal(reachedInquiryLimit);

		if (!reachedInquiryLimit) {
			handleContactSubmitRequest();
		}
	};

	const handleContactSubmitRequest = async () => {
		setIsSubmitting(true);

		try {
			let requestingAccountId = navigationConfig?.allowClientManagement ? requesterValue : account?.accountId;
			setShowLimitInquiryModal(reachedInquiryLimit);
			const { inquiry } = await inquiryService
				.createInquiry({
					...(clientValue && { clientId: clientValue }),
					requestingAccountId: requestingAccountId,
					programId: programValue,
					inquiryTypeId: typeValue,
					...(topicsValue.length > 0 && { topicIds: topicsValue.map((topic) => topic.id) }),
					inquiryTitle: titleValue,
					inquiryText,
					notes,
				})
				.fetch();

			const presignedUploadPromises = inquiryDocuments.map(({ file }) => {
				return new Promise((resolve: (presignedUpload: PresignedUploadModel) => void, reject) => {
					let newDocumentName = nameWithoutComma(file.name);
					documentUploader(
						file,
						inquiryService.getPresignedDocumentUpload({
							inquiryId: inquiry.inquiryId,
							contentType: file.type,
							title: newDocumentName,
						}).fetch
					)
						.onBeforeUpload(() => {
							setUploadProgress(0);
							setIsUpoading(true);
						})
						.onPresignedUploadObtained(() => {})
						.onProgress((percentage) => {
							setUploadProgress(percentage);
						})
						.onComplete(async (presignedUpload) => {
							setUploadProgress(100);
							setIsUpoading(false);

							try {
								await inquiryService
									.updateInquiryDocumentStatus({
										documentUploadStatusId: DOCUMENT_UPLOAD_STATUS_ID.COMPLETE,
										inquiryId: inquiry.inquiryId,
										inquiryDocumentId: presignedUpload.documentId,
									})
									.fetch();

								return resolve(presignedUpload);
							} catch (error) {
								return reject(error);
							}
						})
						.onError((error) => {
							setIsUpoading(false);
							handleError(error);
						})
						.start();
				});
			});

			const presignedUploadResponse = await Promise.all(presignedUploadPromises);

			await inquiryService
				.updateInquiry({
					inquiryId: inquiry.inquiryId,
					uploadedDocumentIds: presignedUploadResponse.map((u) => u.documentId),
					topicIds: inquiry.topicsList.map((t) => t.id),
					notes: inquiry.notes,
					inquiryAccountIds: requestingAccountId ? [requestingAccountId] : [],
				})
				.fetch();

			if (navigationConfig?.allowClientManagement) {
				history.push(`/client-management/inquiries/${inquiry.inquiryId}/edit`);
			} else {
				history.push(`/inquiries/${inquiry.inquiryId}`);
			}

			showAlert({
				variant: 'success',
				children: () => {
					return (
						<p className="mb-0 text-white">
							<strong>Thank you for your Inquiry!</strong>
						</p>
					);
				},
			});
		} catch (error) {
			handleError(error);
			setIsSubmitting(false);
		}
	};

	const nameWithoutComma = (filename: string) => {
		return filename.split(',').join('');
	};

	const resetForm = () => {
		setClientValue('');
		setRequesterValue('');
		setProgramValue('');
		setTypeValue('');
		setTopicsValue([]);
		setTitleValue('');
		setInquiryText('');
		setNotes('');
		setInquiryDocuments([]);
		setIsSubmitting(false);
	};

	return (
		<>
			<Form onSubmit={handleContactFormSubmit}>
				{navigationConfig?.allowClientManagement && (
					<>
						<Form.Group className="mb-6">
							<Form.Label>Who is your client?</Form.Label>
							<Select
								value={clientValue}
								onChange={({ currentTarget }) => {
									setClientValue(currentTarget.value);
									setRequesterValue('');
								}}
								disabled={formOptionsLoading || isSubmitting}
								required
							>
								<option value="" disabled>
									Select client...
								</option>
								{clientOptions.map((option) => {
									return (
										<option key={option.id} value={option.id}>
											{option.display}
										</option>
									);
								})}
							</Select>
						</Form.Group>
						<Form.Group className="mb-6">
							<Form.Label>Who is requesting this?</Form.Label>
							<Select
								className={classNames({ 'mb-1': navigationConfig?.allowAdminAccess })}
								value={requesterValue}
								onChange={({ currentTarget }) => {
									setRequesterValue(currentTarget.value);
									setProgramValue('');
								}}
								disabled={
									formOptionsLoading || isSubmitting || !clientValue || requesterOptions.length <= 0
								}
								required
							>
								<option value="" disabled>
									Select requester...
								</option>
								{requesterOptions.map((option) => {
									return (
										<option key={option.id} value={option.id}>
											{option.display}
										</option>
									);
								})}
							</Select>
							{navigationConfig?.allowAdminAccess && (
								<p>
									Can't find the user?{' '}
									<a href={accountUrl} target="_blank" rel="noreferrer">
										Click here to add them
									</a>
									.
								</p>
							)}
						</Form.Group>
					</>
				)}
				<Form.Group className="mb-6">
					<Form.Label>What area does your question fall under?</Form.Label>
					<Select
						value={programValue}
						onChange={({ currentTarget }) => {
							setProgramValue(currentTarget.value);
						}}
						disabled={
							formOptionsLoading || navigationConfig?.allowClientManagement
								? isSubmitting || !clientValue || programOptions.length <= 0
								: isSubmitting || programOptions.length <= 0
						}
						required
					>
						<option value="" disabled>
							Select program...
						</option>
						{programOptions.map((programOption) => {
							return (
								<option key={programOption.id} value={programOption.id}>
									{programOption.display}
								</option>
							);
						})}
					</Select>
				</Form.Group>

				<Form.Group className="mb-6">
					<Form.Label>What type of question do you have?</Form.Label>
					<Select
						value={typeValue}
						onChange={({ currentTarget }) => {
							setTypeValue(currentTarget.value);
						}}
						disabled={formOptionsLoading || isSubmitting}
						required
					>
						<option value="" disabled>
							Select type...
						</option>
						{typeOptions.map((typeOption) => {
							return (
								<option key={typeOption.id} value={typeOption.id}>
									{typeOption.display}
								</option>
							);
						})}
					</Select>
				</Form.Group>
				{navigationConfig?.allowClientManagement && (
					<Form.Group className="mb-6">
						<Form.Label>
							What are the topics related to this issue?{' '}
							<span className="text-muted font-italic font-weight-regular">- Optional</span>
						</Form.Label>
						<Typeahead
							id="typeahead--topics"
							labelKey="display"
							multiple
							selected={topicsValue}
							onChange={(selected) => {
								setTopicsValue(selected as FormOption[]);
							}}
							options={topicsOptions}
							placeholder="Select topics..."
							disabled={formOptionsLoading || isSubmitting || !programValue}
						/>
					</Form.Group>
				)}
				<Form.Group className="mb-6">
					<Form.Label>Inquiry Subject</Form.Label>
					<Form.Control
						type="text"
						value={titleValue}
						onChange={({ currentTarget }) => {
							setTitleValue(currentTarget.value);
						}}
						disabled={formOptionsLoading || isSubmitting}
						required
					></Form.Control>
				</Form.Group>
				<Form.Group className="mb-8">
					<Form.Label>Inquiry Details</Form.Label>
					<Wysiwyg
						value={inquiryText}
						onChange={setInquiryText}
						readOnly={formOptionsLoading || isSubmitting}
					/>
				</Form.Group>

				{shouldShowNotes && (
					<Form.Group className="mb-8">
						<Form.Label>Notes (For Internal Use)</Form.Label>
						<Wysiwyg value={notes} onChange={setNotes} readOnly={formOptionsLoading || isSubmitting} />
					</Form.Group>
				)}

				{inquiryDocuments.length > 0 || isUploading ? (
					<Form.Group className="mb-8">
						<div className="mb-4 d-flex align-items-center justify-content-between">
							<Form.Label className="mb-0">Attachment</Form.Label>
							<FileInputButton
								accept={inquiryAttachment.validExtensions.join()}
								onChange={handleAttachButtonChange}
								className="text-primary"
							>
								<PlusIcon width={24} height={24} />
							</FileInputButton>
						</div>

						<FadeTransition in={isUploading}>
							<div className="pb-4">
								<Meter value={uploadProgress} max={100} size="sm" pill showBackground />
							</div>
						</FadeTransition>

						{inquiryDocuments.map((document) => {
							return (
								<AttachmentDropdown
									key={document.fileId}
									className="mb-2"
									title={nameWithoutComma(document.file.name)}
									onRemove={() => {
										handleDocumentRemove(document);
									}}
								/>
							);
						})}
					</Form.Group>
				) : (
					<FileInputButton
						className="mb-7"
						accept={inquiryAttachment.validExtensions.join()}
						onChange={handleAttachButtonChange}
					>
						<div className="d-flex align-items-center text-primary">
							<PaperclipIcon className="mr-1" />
							<p className="mb-0">
								<strong>Attach</strong>
							</p>
						</div>
					</FileInputButton>
				)}
				<div className="text-center">
					<LoadingButton variant="success" type="submit" isLoading={formOptionsLoading || isSubmitting}>
						Submit
					</LoadingButton>
				</div>
			</Form>

			<Modal
				centered
				show={showLimitInquiryModal}
				onHide={() => {
					setShowLimitInquiryModal(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Inquiry Limit Reached</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						This client has reached the inquiry limit for this program. Would you like to bypass this limit?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="mr-1"
						variant="success"
						onClick={() => {
							setShowLimitInquiryModal(false);
							handleContactSubmitRequest();
						}}
					>
						Yes
					</Button>
					<Button
						onClick={() => {
							setShowLimitInquiryModal(false);
							resetForm();
						}}
					>
						No
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
});
