import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { DocumentModel } from '@/lib/models';
import { documentService } from '@/lib/services';
import {
	createUseThemedStyles,
	useAlert,
	useCopyTextToClipboard,
	useHandleError,
	useAnalytics,
	useNavigationConfig,
} from '@/hooks';

import { ReactComponent as ViewIcon } from '@/assets/icons/file.svg';
import { ReactComponent as DownloadIcon } from '@/assets/icons/download.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';
import { ReactComponent as BookmarkIcon } from '@/assets/icons/bookmark.svg';
import { ReactComponent as BookmarkFilledIcon } from '@/assets/icons/bookmark-filled.svg';
import { downloadFileAtUrl } from '@/lib/utils';

const useStyles = createUseThemedStyles((theme) => ({
	imageOuter: {
		width: '100%',
		paddingBottom: '66.66%',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.colors.gray300,
	},
}));

interface ResearchListItemProps {
	documentId: string;
	category: string;
	fileType?: string;
	fileName?: string;
	viewUrl: string;
	canDownload: boolean;
	isBookmarked: boolean;
	to: string;
	title: string;
	description: string;
	imageSrc: string;
	date: string;
	tags: string[];
	onChange?(documents: DocumentModel): void;
	isPodcast?: boolean;
}

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const ResearchListItem = ({
	documentId,
	category,
	fileName,
	viewUrl,
	canDownload,
	isBookmarked,
	to,
	title,
	description,
	imageSrc,
	date,
	tags,
	onChange,
	isPodcast,
}: ResearchListItemProps) => {
	const classes = useStyles();
	const { copyTextToClipboard } = useCopyTextToClipboard();
	const { navigationConfig } = useNavigationConfig();
	const handleError = useHandleError();
	const { showAlert } = useAlert();
	const [isBookmarking, setIsBookmarking] = useState(false);
	const analytics = useAnalytics();
	const handleShareButtonClick = () => {
		copyTextToClipboard(`${window.location.origin}/research/${documentId}`, 'Link copied to clipboard.');
	};

	const handleSaveBookmarkButtonClick = useCallback(async () => {
		try {
			setIsBookmarking(true);

			const response = await documentService.addBookmark(documentId).fetch();
			if (onChange) onChange(response);

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Bookmark saved.</p>;
				},
			});
		} catch (error) {
			handleError(error);
		} finally {
			setIsBookmarking(false);
		}
	}, [documentId, handleError, onChange, showAlert]);

	const handleRemoveBookmarkButtonClick = useCallback(async () => {
		try {
			setIsBookmarking(true);

			const response = await documentService.removeBookmark(documentId).fetch();
			if (onChange) onChange(response);

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Bookmark removed.</p>;
				},
			});
		} catch (error) {
			handleError(error);
		} finally {
			setIsBookmarking(false);
		}
	}, [documentId, handleError, onChange, showAlert]);

	const handleDownloadClick = useCallback(async () => {
		try {
			await downloadFileAtUrl(viewUrl, fileName ?? title);

			documentService.trackDocumentDownload(documentId).fetch();
			analytics.handleClickEvent(viewUrl);
		} catch (error) {
			handleError(error);
		}
	}, [viewUrl, fileName, title, analytics, handleError, documentId]);

	return (
		<div className="mb-16">
			<Row className="mb-5">
				<Col md={7}>
					<div className="mb-2 d-flex align-items-center justify-content-between">
						<h4 className="mb-0 d-flex align-items-center text-muted">{category}</h4>
						<div className="d-flex align-items-center text-primary">
							<Button
								variant="link"
								className="mr-1"
								href={isPodcast ? to : viewUrl}
								target={isPodcast ? '_self' : '_blank'}
								rel={isPodcast ? '' : 'noopener'}
								onClick={() => {
									if (!isPodcast) {
										documentService.trackDocumentView(documentId).fetch();
									}
									analytics.handleClickEvent(viewUrl);
								}}
							>
								<ViewIcon />
							</Button>

							{navigationConfig?.allowDownloads && canDownload && viewUrl && (
								<Button className="mr-1" variant="link" onClick={handleDownloadClick}>
									<DownloadIcon />
								</Button>
							)}
							<Button variant="link" className="mx-1" onClick={handleShareButtonClick}>
								<CopyIcon />
							</Button>
							{isBookmarked ? (
								<Button
									variant="link"
									className="ml-1"
									onClick={handleRemoveBookmarkButtonClick}
									disabled={isBookmarking}
								>
									<BookmarkFilledIcon />
								</Button>
							) : (
								<Button
									variant="link"
									className="ml-1"
									onClick={handleSaveBookmarkButtonClick}
									disabled={isBookmarking}
								>
									<BookmarkIcon />
								</Button>
							)}
						</div>
					</div>
					<h3 className="mb-5">
						<Link to={to}>{title}</Link>
					</h3>
					<ResponsiveEllipsis text={description ?? ''} maxLine="4" component="p" />
				</Col>
				<Col md={5}>
					<div className={classes.imageOuter} style={{ backgroundImage: `url(${imageSrc})` }}></div>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="d-flex flex-wrap">
						<h4 className="mb-0 d-flex align-items-center text-muted">
							{date} {tags && tags.length > 0 && <span className="mx-1">&bull;</span>}
						</h4>
						{tags &&
							tags.map((tag, index) => {
								const isLastTag = index === tags.length - 1;

								return (
									<span key={index} className="small font-weight-semi-bold text-muted">
										{tag}
										{isLastTag ? '' : ', '}
									</span>
								);
							})}
					</div>
				</Col>
			</Row>
		</div>
	);
};
