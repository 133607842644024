export interface AiConversation {
	aiConversationId: string;
	title: string;
	conversationQueries: ConversationQuery[];
}

export interface AiConversationSections {
	[x: string]: AiConversation[];
}

export interface ConversationQuery {
	conversationQueryId: string;
	aiConversationId: string;
	query: string;
	queryResponse: string;
	displayOrder: number;
	hasRating: boolean;
}

export interface TempResponse {
	answer: string;
	citations: TempCitation[];
	followUpQuestionsSuggestions?: string[];
}

export interface TempCitation {
	documentTitle: string;
	documentUrl: string;
}

export enum FEEDBACK_RATING_ID {
	NEGATIVE = 'NEGATIVE',
	NEUTRAL = 'NEUTRAL',
	POSITIVE = 'POSITIVE',
}
