import React, { Fragment, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { activityService } from '@/lib/services';
import { createUseThemedStyles } from '@/hooks';
import { boxShadow } from '@/jss';
import { AsyncPage } from './async-page';
import { ACTIVITY_TYPE_ID, RecentActivityModel } from '@/lib/models';

const useStyles = createUseThemedStyles((theme) => ({
	linkBox: {
		...boxShadow,
		backgroundColor: theme.colors.white,
	},
	sectionsOuter: {
		padding: '2.4rem 3.2rem',
	},
	link: {
		display: 'block',
		...theme.fonts.openSansSemiBold,
	},
}));

interface RecentlyViewedProps {
	className?: string;
}

export const RecentlyViewed = ({ className }: RecentlyViewedProps) => {
	const classes = useStyles();
	const [recentlyViewed, setRecentlyViewed] = useState<RecentActivityModel[]>([]);

	const fetchData = useCallback(async () => {
		const response = await activityService.getRecentlyViewed().fetch();

		setRecentlyViewed(response.recentActivities);
	}, []);

	return (
		<AsyncPage fetchData={fetchData} errorDisplayClassName="m-0 p-0 w-100">
			{recentlyViewed.length > 0 && (
				<div className={classNames(classes.linkBox, className)}>
					<div className={classNames(classes.sectionsOuter)}>
						<h3 className="mb-4">Recently Viewed</h3>

						{recentlyViewed.map((link, linkIndex) => {
							const isLastLink = linkIndex === recentlyViewed.length - 1;

							return (
								<Fragment key={linkIndex}>
									<h4 className="mb-2 text-muted">{link.ipTypeDescription}</h4>
									<Link
										className={classNames(classes.link, {
											'mb-5': !isLastLink,
										})}
										to={
											link.activityTypeId === ACTIVITY_TYPE_ID.BP
												? `/best-practices/${link.ipId}`
												: `/research/${link.ipId}`
										}
									>
										{link.ipTitle}
									</Link>
								</Fragment>
							);
						})}
					</div>
				</div>
			)}
		</AsyncPage>
	);
};
