import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Nav, Dropdown as ReactBootstrapDropdown, Tab } from 'react-bootstrap';
import classNames from 'classnames';

import { constructUrl } from '@/lib/utils';
import { createUseThemedStyles, useNavigationConfig } from '@/hooks';

const useStyles = createUseThemedStyles((theme) => ({
	navOuter: {
		padding: '10px 50px',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.info,
		'& nav ul': {
			margin: 0,
			padding: 0,
			height: '100%',
			display: 'flex',
			listStyle: 'none',
			borderLeft: `1px solid rgba(255,255,255,0.4)`,
			'& li': {
				borderRight: `1px solid rgba(255,255,255,0.4)`,
				'& a': {
					height: '100%',
					display: 'flex',
					padding: '0 30px',
					textAlign: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					justifyContent: 'center',
					color: theme.colors.white,
					...theme.fonts.openSansSemiBold,
				},
			},
		},
	},
	dropdownToggle: {
		borderRadius: `0 !important`,
		color: `${theme.colors.white} !important`,
		borderRight: `1px solid rgba(255,255,255,0.4) !important`,
	},
	dropdownMenuOuter: {
		padding: 0,
	},
	dropdownMenu: {
		height: 470,
		display: 'flex',
		'& .dropdown-item': {
			padding: '24px 50px !important',
		},
	},
	dropdownTabs: {
		width: 300,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		borderRight: `1px solid ${theme.colors.gray300}`,
	},
	contactCallout: {
		padding: '32px 50px',
		borderTop: `1px solid ${theme.colors.gray300}`,
	},
	dropdownContent: {
		width: 570,
		overflowY: 'auto',
		padding: '24px 50px',
	},
	filterLink: {
		display: 'block',
		...theme.fonts.s,
		...theme.fonts.openSansSemiBold,
	},
}));

enum EVENT_KEYS {
	TOPICS = 'TOPICS',
	BUSNIESS_PROCESSES = 'BUSNIESS_PROCESSES',
	CONTENT_TYPES = 'CONTENT_TYPES',
	ALL_RESEARCH = 'ALL_RESEARCH',
}

interface NavigationResearchDropdownMenuProps {
	onCloseDropdown(): void;
}

export const NavigationResearchDropdownMenu = ({ onCloseDropdown }: NavigationResearchDropdownMenuProps) => {
	const classes = useStyles();
	const history = useHistory();
	const { navigationConfig } = useNavigationConfig();
	const [tabKey, setTabKey] = useState(EVENT_KEYS.TOPICS);

	const closeDropdown = () => {
		onCloseDropdown();
	};

	const handleTabSelect = (eventKey: string | null) => {
		if (!eventKey) {
			return;
		}

		if (eventKey === EVENT_KEYS.ALL_RESEARCH) {
			history.push('/research');
			onCloseDropdown();
		}

		setTabKey(eventKey as EVENT_KEYS);
	};

	return (
		<div className={classes.dropdownMenu}>
			<Tab.Container activeKey={tabKey} onSelect={handleTabSelect}>
				<div className={classes.dropdownTabs}>
					<Nav>
						<ReactBootstrapDropdown.Item eventKey={EVENT_KEYS.TOPICS}>Topics</ReactBootstrapDropdown.Item>
						<ReactBootstrapDropdown.Item eventKey={EVENT_KEYS.BUSNIESS_PROCESSES}>
							Business Processes
						</ReactBootstrapDropdown.Item>
						<ReactBootstrapDropdown.Item eventKey={EVENT_KEYS.CONTENT_TYPES}>
							Content Types
						</ReactBootstrapDropdown.Item>
						<ReactBootstrapDropdown.Item eventKey={EVENT_KEYS.ALL_RESEARCH}>
							All Research
						</ReactBootstrapDropdown.Item>
					</Nav>
					<div className={classes.contactCallout}>
						<p>Can't find what you're looking for?</p>
						<p className="mb-0">
							<Link to="/contact" onClick={closeDropdown}>
								Contact Hackett
							</Link>
						</p>
					</div>
				</div>
				<div className={classes.dropdownContent}>
					<Tab.Content>
						<Tab.Pane eventKey={EVENT_KEYS.TOPICS}>
							{navigationConfig?.topics && navigationConfig.topics.length > 0 ? (
								navigationConfig.topics.map((section, sectionIndex) => {
									return (
										<React.Fragment key={sectionIndex}>
											{section.navigationHeader && (
												<h4 className="mb-2">
													<Link
														to={constructUrl('/research', {
															parentTopics: section.navigationHeader.navigationItemId,
														})}
														onClick={closeDropdown}
													>
														{section.navigationHeader.name}
													</Link>
												</h4>
											)}
											{section.navigationItems.map((item, itemIndex) => {
												const isLast = itemIndex === section.navigationItems.length - 1;

												return (
													<Link
														key={itemIndex}
														to={constructUrl('/research', {
															topics: item.navigationItemId,
														})}
														className={classNames(classes.filterLink, {
															'mb-4': isLast,
														})}
														onClick={closeDropdown}
													>
														{item.name}
													</Link>
												);
											})}
										</React.Fragment>
									);
								})
							) : (
								<p className="mb-0">Sorry, no Topics found.</p>
							)}
						</Tab.Pane>
						<Tab.Pane eventKey={EVENT_KEYS.BUSNIESS_PROCESSES}>
							{navigationConfig?.taxonomy && navigationConfig.taxonomy.length > 0 ? (
								navigationConfig.taxonomy.map((section, sectionIndex) => {
									return (
										<React.Fragment key={sectionIndex}>
											{section.navigationHeader && (
												<h4 className="mb-2">
													<Link
														to={constructUrl('/research', {
															parentBusinessProcesses:
																section.navigationHeader.navigationItemId,
														})}
														onClick={closeDropdown}
													>
														{section.navigationHeader.name}
													</Link>
												</h4>
											)}
											{section.navigationItems.map((item, itemIndex) => {
												const isLast = itemIndex === section.navigationItems.length - 1;

												return (
													<Link
														key={itemIndex}
														to={constructUrl('/research', {
															businessProcesses: item.navigationItemId,
														})}
														className={classNames(classes.filterLink, {
															'mb-4': isLast,
														})}
														onClick={closeDropdown}
													>
														{item.name}
													</Link>
												);
											})}
										</React.Fragment>
									);
								})
							) : (
								<p className="mb-0">Sorry, no Business Processes found.</p>
							)}
						</Tab.Pane>
						<Tab.Pane eventKey={EVENT_KEYS.CONTENT_TYPES}>
							{navigationConfig?.contentTypes && navigationConfig.contentTypes.length > 0 ? (
								navigationConfig.contentTypes.map((section, sectionIndex) => {
									return (
										<React.Fragment key={sectionIndex}>
											{section.navigationHeader && (
												<h4 className="mb-2">
													<Link
														to={constructUrl('/research', {
															parentContentTypes:
																section.navigationHeader.navigationItemId,
														})}
														onClick={closeDropdown}
													>
														{section.navigationHeader.name}
													</Link>
												</h4>
											)}
											{section.navigationItems.map((item, itemIndex) => {
												const isLast = itemIndex === section.navigationItems.length - 1;

												return (
													<Link
														key={itemIndex}
														to={constructUrl('/research', {
															contentTypes: item.navigationItemId,
														})}
														className={classNames(classes.filterLink, {
															'mb-4': isLast,
														})}
														onClick={closeDropdown}
													>
														{item.name}
													</Link>
												);
											})}
										</React.Fragment>
									);
								})
							) : (
								<p className="mb-0">Sorry, no Content Types found.</p>
							)}
						</Tab.Pane>
					</Tab.Content>
				</div>
			</Tab.Container>
		</div>
	);
};
