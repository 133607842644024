import { httpSingleton } from '@/lib/http-client';
import { PerformanceMetricModel, PaginatedResponseModel, FormOption, ProgramFormOption } from '@/lib/models';
import { constructUrl } from '@/lib/utils';

export const performanceMetricService = {
	getPerformanceMetrics(queryParameters: {
		parentBusinessProcessIds?: string;
		businessProcessIds?: string;
		driverComponentIds?: string;
		programId: string;
		page?: string;
		size?: string;
		sortDirection?: string;
		sortField?: string;
		sourceComponentIds?: string;
	}) {
		return httpSingleton.orchestrateRequest<
			PaginatedResponseModel & { performanceMetrics: PerformanceMetricModel[] }
		>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/performance-metric', queryParameters),
		});
	},
	getFilters(queryParameters?: {
		programId?: string;
		parentBusinessProcessIds?: string;
		businessProcessIds?: string;
		driverComponentIds?: string;
		sourceComponentIds?: string;
	}) {
		return httpSingleton.orchestrateRequest<{
			businessProcess: FormOption[];
			driver: FormOption[];
			sortFields: FormOption[];
			source: FormOption[];
			topics: FormOption[];
			programs: ProgramFormOption[];
		}>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/performance-metric/filters', queryParameters),
		});
	},
	getPerformanceMetricDetailsFilters(performanceMetricId: string) {
		return httpSingleton.orchestrateRequest<{
			programs: ProgramFormOption[];
		}>({
			method: 'GET',
			url: `/advisory-service/api/v1/performance-metric/${performanceMetricId}/filters`,
		});
	},
	getPerformanceMetricById(performanceMetricId: string, queryParameters: { programId: string }) {
		return httpSingleton.orchestrateRequest<PerformanceMetricModel>({
			method: 'GET',
			url: constructUrl(`/advisory-service/api/v1/performance-metric/${performanceMetricId}`, queryParameters),
		});
	},
};
