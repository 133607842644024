import React, { FC } from 'react';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles((theme) => ({
	tableRow: ({ canClick }: { canClick: boolean }) => ({
		backgroundColor: theme.colors.white,
		cursor: canClick ? 'pointer' : 'default',
		'&:hover': {
			backgroundColor: canClick ? theme.colors.gray200 : theme.colors.white,
		},
		'&:nth-child(odd)': {
			backgroundColor: theme.colors.gray100,
			'&:hover': {
				backgroundColor: canClick ? theme.colors.gray200 : theme.colors.gray100,
			},
		},
		'&:active': {
			backgroundColor: canClick ? theme.colors.gray300 : theme.colors.white,
		},
	}),
}));

interface TableRowProps {
	className?: string;
	onClick?(event: React.MouseEvent<HTMLTableRowElement>): void;
}

export const TableRow: FC<TableRowProps> = ({ className, onClick, children }) => {
	const classes = useStyles({
		canClick: onClick ? true : false,
	});

	return (
		<tr
			className={classNames(classes.tableRow, className)}
			tabIndex={onClick ? 0 : undefined}
			role={onClick ? 'button' : undefined}
			onClick={onClick}
		>
			{children}
		</tr>
	);
};
