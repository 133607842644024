import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { createUseThemedStyles } from '@/hooks';

import { ReactComponent as DwcMatrixIcon } from '@/assets/icons/hackett-dwc-matrix-icon.svg';
import { ReactComponent as ResearchReportsIcon } from '@/assets/icons/hackett-research-reports-icon.svg';
import { ReactComponent as CustomContentIcon } from '@/assets/icons/hackett-custom-content-icon.svg';

const useStyles = createUseThemedStyles((theme) => ({
	iconOuter: {
		width: 160,
		height: 'auto',
		display: 'block',
		margin: '0 auto 8px',
	},
}));

export const MarketIntelligence = () => {
	const classes = useStyles();

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Market Intelligence</title>
			</Helmet>

			<Container className="bg-white" fluid>
				<Container className="py-11">
					<Row>
						<Col>
							<h1 className="mb-4 text-primary">Market Intelligence for Buyers</h1>
							<p>
								In a digital world, it isn't enough for vendors of software and business process
								outsourcing services to offer a robust feature set, broad market penetration and, in the
								case of service providers, relevant skill sets and experience. Buyers like you want
								evidence of tangible performance improvement and measurable business impact - before you
								invest.
							</p>
							<p className="mb-0">
								The Hackett Group's Market Intelligence service supports your software or service vendor
								selection process and enables Advisory members to self-assess their
								pre-implementation planning and post-implementation optimization. Our market
								intelligence property and expert analysts can help you navigate today's complex
								technology landscape.
							</p>
						</Col>
					</Row>
				</Container>
			</Container>

			<Container className="bg-gray-100" fluid>
				<Container className="py-11">
					<Row className="mb-4">
						<Col>
							<h2 className="mb-4 text-primary">Resources Available in Hackett Connect</h2>
							<p className="mb-0">
								Featured Market Intelligence for Buyers' insights are available to you in your member
								programs, including:
							</p>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col lg={4} className="mb-4">
							<DwcMatrixIcon className={classNames(classes.iconOuter, 'text-danger')} />
							<h4 className="mb-2 text-uppercase text-danger text-center">
								Digital World Class Matrix™ Reports
							</h4>
							<p className="mb-0 text-center">
								With in-depth process knowledge and understanding of what it takes to achieve Digital
								World Class™ performance, we measure the degree to which vendors and their software or
								service solutions help companies generate return on investment and achieve better
								business outcomes
							</p>
						</Col>
						<Col lg={4} className="mb-4">
							<ResearchReportsIcon className={classNames(classes.iconOuter, 'text-secondary')} />
							<h4 className="mb-2 text-uppercase text-secondary text-center">Vendor Profiles</h4>
							<p className="mb-0 text-center">
								Profiles of software and service vendors, including an overview of the company, their
								vision and product portfolio, key software features, representative clients, and their
								major partners
							</p>
						</Col>
						<Col lg={4} className="mb-4">
							<CustomContentIcon className={classNames(classes.iconOuter, 'text-warning')} />
							<h4 className="mb-2 text-uppercase text-warning text-center">
								Market Intelligence Research
							</h4>
							<p className="mb-0 text-center">
								Access to Advisory research including spotlight reports of vendor specific software and
								service capabilities, market perspectives offering insights on the evolving technology
								market, topical podcasts, webcasts and key takeaways from vendor conferences and events
							</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="text-center">
								<a
									className="hackett-button hackett-button-secondary"
									href="https://www.thehackettgroup.com/market-intelligence/"
									target="_blank"
									rel="noreferrer"
								>
									Learn More
								</a>
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
		</>
	);
};
