import React from 'react';
import { Link } from 'react-router-dom';

import { createUseThemedStyles } from '@/hooks';
import { TagList, TagListItem } from '@/components';
import { boxShadow } from '@/jss';

const useStyles = createUseThemedStyles((theme) => ({
	webcastCard: {
		...boxShadow,
		borderRadius: 2,
		overflow: 'hidden',
		backgroundColor: theme.colors.white,
	},
	videoThumbnail: {
		height: 200,
		backgroundColor: theme.colors.gray600,
	},
	informationOuter: {
		padding: '2.0rem',
	},
}));

interface WebcastCardProps {
	category: string;
	title: string;
	to: string;
	tags: TagListItem[];
}

export const WebcastCard = ({ category, title, to, tags }: WebcastCardProps) => {
	const classes = useStyles();

	return (
		<div className={classes.webcastCard}>
			<div className={classes.videoThumbnail}></div>
			<div className={classes.informationOuter}>
				<h4 className="mb-0 text-muted">{category}</h4>
				<h3 className="mb-6">
					<Link to={to}>{title}</Link>
				</h3>
				<TagList tags={tags} />
			</div>
		</div>
	);
};
