import React, { useMemo } from 'react';
import classNames from 'classnames';
import { createUseThemedStyles } from '@/hooks';

interface useStylesProps {
	width: number;
	height: number;
}

const useStyles = createUseThemedStyles((theme) => ({
	imageOuter: {
		width: '100%',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.colors.gray300,
		paddingBottom: ({ width, height }: useStylesProps) => `${(height / width) * 100}%`,
	},
}));

interface AspectRatioLockedImageProps {
	width: number;
	height: number;
	src?: string;
	className?: string;
}

export const AspectRatioLockedImage = ({ width, height, src, className }: AspectRatioLockedImageProps) => {
	const classes = useStyles({ width, height });
	const placeHolder = useMemo(() => {
		return `https://placehold.co/${width * 2}x${height * 2}`;
	}, [height, width]);

	return (
		<div
			className={classNames(classes.imageOuter, className)}
			style={{ backgroundImage: `url(${src ?? placeHolder})` }}
		/>
	);
};
