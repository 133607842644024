import React, { FC, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorConfig, ERROR_CODES } from '@/lib/http-client';
import { isErrorConfig } from '@/lib/utils';
import { ErrorDisplay, Loader } from '@/components';
import { useSignOut } from '@/hooks';

enum DISPLAY_STATES {
	LOADING = 'LOADING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

interface AsyncPageProps {
	fetchData(): Promise<void>;
	abortFetch?(): void;
	errorDisplayClassName?: string;
}

export const AsyncPage: FC<AsyncPageProps> = ({ children, fetchData, abortFetch, errorDisplayClassName }) => {
	const history = useHistory();
	const { signOut, signOutNoServiceCall } = useSignOut();
	const [fetchPageDataError, setFetchPageDataError] = useState<ErrorConfig | unknown>(undefined);
	const [displayState, setDisplayState] = useState(DISPLAY_STATES.LOADING);

	const fetchPageData = useCallback(async () => {
		setDisplayState(DISPLAY_STATES.LOADING);
		setFetchPageDataError(undefined);

		try {
			await fetchData();
			setDisplayState(DISPLAY_STATES.SUCCESS);
		} catch (error) {
			if (isErrorConfig(error)) {
				if (error.code === ERROR_CODES.REQUEST_ABORTED) {
					return;
				}

				if (error.status === 503) {
					history.replace('/maintenance');
					return;
				}

				if (error.code === 'AUTHENTICATION_REQUIRED' || error.code === 'NotAuthorizedException') {
					signOutNoServiceCall();
					return;
				}

				if (error.status === 401) {
					signOutNoServiceCall();
					return;
				}

				if (error.status === 403) {
					signOut();
					return;
				}
			}

			setFetchPageDataError(error);
			setDisplayState(DISPLAY_STATES.ERROR);
		}
	}, [fetchData, history, signOut, signOutNoServiceCall]);

	useEffect(() => {
		fetchPageData();

		return () => {
			typeof abortFetch === 'function' && abortFetch();
		};
	}, [fetchPageData, abortFetch]);

	function getDisplayState() {
		switch (displayState) {
			case DISPLAY_STATES.LOADING:
				return (
					<div
						data-testid="async-page__loader"
						className="pt-20 d-flex justify-content-center align-items-center"
					>
						<Loader />
					</div>
				);
			case DISPLAY_STATES.SUCCESS:
				return children;
			case DISPLAY_STATES.ERROR:
				return (
					<ErrorDisplay
						className={errorDisplayClassName}
						error={fetchPageDataError}
						showBackButton={true}
						showRetryButton={true}
						onRetryButtonClick={fetchPageData}
					/>
				);
			default:
				return <></>;
		}
	}

	return <>{getDisplayState()}</>;
};
