import { httpSingleton } from '@/lib/http-client';
import { InquiryNotificationPreference } from '../models';

export const inquirySettingService = {
	getPreferences(accountId: string) {
		return httpSingleton.orchestrateRequest<{ inquiryNotificationPreferences: InquiryNotificationPreference[] }>({
			method: 'GET',
			url: `/advisory-service/api/v1/account/${accountId}/inquiry-notification-preferences`,
		});
	},
	setPreferences(accountId: string, data: { inquiryNotificationPreferences: InquiryNotificationPreference[] }) {
		return httpSingleton.orchestrateRequest<{ inquiryNotificationPreferences: InquiryNotificationPreference[] }>({
			method: 'PUT',
			url: `/advisory-service/api/v1/account/${accountId}/inquiry-notification-preferences`,
			data: data,
		});
	}
};
