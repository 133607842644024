import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';
import { mediaQueries } from '@/jss';

import { ReactComponent as LeftChevron } from '@/assets/icons/icon-chevron-left.svg';
import { ReactComponent as RightChevron } from '@/assets/icons/icon-chevron-right.svg';

const animationDurationInMs = 300;

const useStyles = createUseThemedStyles((theme) => ({
	carousel: {
		display: 'block',
		[mediaQueries.lg]: {
			display: 'flex',
			flexDirection: 'row-reverse',
		},
	},
	informationOuter: {
		overflow: 'hidden',
		position: 'relative',
		backgroundColor: theme.colors.gray200,
		borderLeft: `1px solid ${theme.colors.gray300}`,
		borderRight: `1px solid ${theme.colors.gray300}`,
		borderBottom: `1px solid ${theme.colors.gray300}`,
		transition: `height ${animationDurationInMs}ms`,
		[mediaQueries.lg]: {
			width: '56%',
			borderRight: 0,
			borderTop: `1px solid ${theme.colors.gray300}`,
		},
	},
	informationSlide: {
		top: 0,
		left: 0,
		right: 0,
		position: 'absolute',
	},
	informationSlideLink: {
		display: 'block',
		padding: '30px 30px 25px',
		color: theme.colors.dark,
		'& .title-content': {
			minHeight: 70,
		},
		'& .html-content': {
			minHeight: 75,
			color: theme.colors.gray900,
			'& p:last-of-type': {
				marginBottom: 0,
			},
		},
		'&:hover': {
			color: theme.colors.dark,
			textDecoration: 'none',
			'& h2': {
				textDecoration: 'underline',
			},
		},
	},
	carouselControls: {
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		position: 'absolute',
		alignItems: 'center',
		padding: '0 30px 25px',
		justifyContent: 'center',
		'& ul': {
			padding: 0,
			display: 'flex',
			margin: '0 10px',
			listStyle: 'none',
			'& li': {
				width: 10,
				height: 10,
				margin: '0 5px',
				borderRadius: 5,
				position: 'relative',
				backgroundColor: theme.colors.gray400,
				'&:first-child': {
					marginLeft: 0,
				},
				'&:last-child': {
					marginRight: 0,
				},
				'&.active': {
					border: `2px solid ${theme.colors.info}`,
				},
			},
		},
	},
	arrowButton: {
		width: 32,
		height: 24,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'&:disabled': {
			backgroundColor: 'transparent !important',
		},
	},
	imageOuter: {
		height: 250,
		overflow: 'hidden',
		position: 'relative',
		[mediaQueries.lg]: {
			height: 'auto',
			width: '44%',
		},
	},
	imageSlide: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		padding: 30,
		position: 'absolute',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundColor: theme.colors.gray300,
	},
	'@global': {
		'.carousel-previous-enter': {
			transform: 'translateX(-100%)',
		},
		'.carousel-previous-enter-active': {
			transition: `transform ${animationDurationInMs}ms`,
			transform: 'translateX(0%)',
		},
		'.carousel-previous-enter-done': {
			transform: 'translateX(0%)',
		},
		'.carousel-previous-exit': {
			transform: 'translateX(0%)',
		},
		'.carousel-previous-exit-active': {
			transition: `transform ${animationDurationInMs}ms`,
			transform: 'translateX(100%)',
		},
		'.carousel-previous-exit-done': {
			transform: 'translateX(100%)',
		},
		'.carousel-next-enter': {
			transform: 'translateX(100%)',
		},
		'.carousel-next-enter-active': {
			transition: `transform ${animationDurationInMs}ms`,
			transform: 'translateX(0%)',
		},
		'.carousel-next-enter-done': {
			transform: 'translateX(0%)',
		},
		'.carousel-next-exit': {
			transform: 'translateX(0%)',
		},
		'.carousel-next-exit-active': {
			transition: `transform ${animationDurationInMs}ms`,
			transform: 'translateX(-100%)',
		},
		'.carousel-next-exit-done': {
			transform: 'translateX(-100%)',
		},
	},
}));

interface CarouselSlide {
	to: string;
	title: string;
	description: string;
	imageUrl: string;
}

interface Props {
	slides: CarouselSlide[];
}

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

export const CarouselSingle = ({ slides }: Props) => {
	const classes = useStyles();

	const currentSlideRef = useRef<HTMLDivElement>(null);
	const controlsRef = useRef<HTMLDivElement>(null);

	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	const [animationClassNames, setAnimationClassNames] = useState('carousel-next');
	const [carouselHeight, setCarouselHeight] = useState(0);
	const currentSlide = useMemo(() => slides[currentSlideIndex], [currentSlideIndex, slides]);

	const measureAndSetCarouselHeight = useCallback(() => {
		if (!currentSlideRef.current || !controlsRef.current) {
			return;
		}

		const currentSlideHeight = currentSlideRef.current.getBoundingClientRect().height;
		const controlsHeight = controlsRef.current.getBoundingClientRect().height;

		setCarouselHeight(currentSlideHeight + controlsHeight);
	}, []);

	const handlePreviousButtonClick = useCallback(() => {
		setCurrentSlideIndex((previousValue) => {
			if (previousValue === 0) {
				return slides.length - 1;
			}

			return previousValue - 1;
		});

		setAnimationClassNames('carousel-previous');
	}, [slides]);

	const handleNextButtonClick = useCallback(() => {
		setCurrentSlideIndex((previousValue) => {
			if (previousValue === slides.length - 1) {
				return 0;
			}

			return previousValue + 1;
		});

		setAnimationClassNames('carousel-next');
	}, [slides]);

	const handlePageButtonClick = useCallback((index: number) => {
		let previousIndex = 0;

		setCurrentSlideIndex((previousValue) => {
			previousIndex = previousValue;
			return index;
		});

		if (previousIndex > index) {
			setAnimationClassNames('carousel-previous');
		} else {
			setAnimationClassNames('carousel-next');
		}
	}, []);

	const handleDescriptionReflow = useCallback(() => {
		measureAndSetCarouselHeight();
	}, [measureAndSetCarouselHeight]);

	useEffect(() => {
		measureAndSetCarouselHeight();
	}, [currentSlideIndex, measureAndSetCarouselHeight]);

	// TODO return something better here when there is no content for the carousel?
	if (!currentSlide) return <div></div>;

	return (
		<div className={classes.carousel}>
			<div className={classes.imageOuter}>
				<TransitionGroup
					childFactory={(child) => React.cloneElement(child, { classNames: animationClassNames })}
				>
					<CSSTransition
						key={currentSlideIndex}
						classNames={animationClassNames}
						timeout={animationDurationInMs}
						mountOnEnter
						unmountOnExit
					>
						<div
							className={classes.imageSlide}
							style={{ backgroundImage: `url(${currentSlide.imageUrl})` }}
						/>
					</CSSTransition>
				</TransitionGroup>
			</div>
			<div className={classes.informationOuter} style={{ height: `${carouselHeight}px` }}>
				<TransitionGroup
					childFactory={(child) => React.cloneElement(child, { classNames: animationClassNames })}
				>
					<CSSTransition
						key={currentSlideIndex}
						classNames={animationClassNames}
						timeout={animationDurationInMs}
						mountOnEnter
						unmountOnExit
					>
						<div ref={currentSlideRef} className={classes.informationSlide}>
							<Link to={currentSlide.to} className={classes.informationSlideLink}>
								<ResponsiveEllipsis
									unsafeHTML={currentSlide.title ?? ''}
									maxLine="2"
									component="h2"
									className="title-content"
									onReflow={handleDescriptionReflow}
								/>

								{currentSlide.description && (
									<ResponsiveEllipsis
										className="html-content"
										unsafeHTML={currentSlide.description ?? ''}
										maxLine="3"
										component="div"
										onReflow={handleDescriptionReflow}
									/>
								)}
							</Link>
						</div>
					</CSSTransition>
				</TransitionGroup>
				<div ref={controlsRef} className={classes.carouselControls}>
					<Button className={classes.arrowButton} variant="link" onClick={handlePreviousButtonClick}>
						<LeftChevron className="d-flex" />
					</Button>
					<ul>
						{slides.map((_slide, slideIndex) => (
							<li
								key={slideIndex}
								className={classNames({
									active: currentSlideIndex === slideIndex,
								})}
								onClick={() => handlePageButtonClick(slideIndex)}
							/>
						))}
					</ul>
					<Button className={classes.arrowButton} variant="link" onClick={handleNextButtonClick}>
						<RightChevron className="d-flex" />
					</Button>
				</div>
			</div>
		</div>
	);
};
