import React, { FC } from 'react';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

interface UseStylesProps {
	isLoading?: boolean;
}

const useStyles = createUseThemedStyles((theme) => ({
	tableOuter: ({ isLoading }: UseStylesProps) => ({
		width: '100%',
		borderRadius: 2,
		overflowX: 'auto',
		opacity: isLoading ? 0.32 : 1,
		boxShadow: '0 1px 2px 0 rgba(91,103,112,0.1), 0 2px 5px 0 rgba(91,103,112,0.1)',
	}),
	table: {
		width: '100%',
	},
}));

interface TableProps {
	isLoading?: boolean;
	className?: string;
}

export const Table: FC<TableProps> = ({ isLoading, className, children }) => {
	const classes = useStyles({ isLoading });

	return (
		<div className={classNames(classes.tableOuter, className)}>
			<table className={classes.table}>{children}</table>
		</div>
	);
};
