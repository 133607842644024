import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';
import { AspectRatioLockedImage } from './aspect-ratio-locked-image';

interface UseStylesProps {
	border?: boolean;
}

const useStyles = createUseThemedStyles((theme) => ({
	featuredContentTile: {
		height: '100%',
		display: 'block',
		border: ({ border }: UseStylesProps) => (border ? `1px solid ${theme.colors.gray300}` : ''),
		padding: ({ border }: UseStylesProps) => (border ? 20 : 0),
		'& .html-content, & .bottom-text': {
			color: theme.colors.gray900,
		},
		'&:hover': {
			textDecoration: 'none',
			'& h4': {
				textDecoration: 'underline',
			},
		},
	},
}));

interface FeaturedContentTileProps {
	to: string;
	imageUrl?: string;
	contentType?: string;
	title: string;
	description?: string;
	bottomText?: string;
	border?: boolean;
}

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

export const FeaturedContentTile = ({
	to,
	imageUrl,
	contentType,
	title,
	description,
	bottomText,
	border,
}: FeaturedContentTileProps) => {
	const classes = useStyles({ border });

	return (
		<Link className={classes.featuredContentTile} to={to}>
			<div className="d-flex justify-content-between flex-column h-100">
				<div>
					{imageUrl && <AspectRatioLockedImage width={279} height={157} src={imageUrl} className="mb-4" />}
					{contentType && (
						<Badge bg="light" className="mb-3">
							{contentType}
						</Badge>
					)}
					<h4
						className={classNames({
							'mb-0': !description,
							'mb-2': description,
						})}
					>
						{title}
					</h4>
					{description && (
						<ResponsiveEllipsis
							className="html-content"
							unsafeHTML={description ?? ''}
							maxLine="2"
							component="div"
						/>
					)}
				</div>
				{bottomText && <p className="mb-0 pt-2 bottom-text small">{bottomText}</p>}
			</div>
		</Link>
	);
};
