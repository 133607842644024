import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Container, Form, Row, Tab, Tabs } from 'react-bootstrap';

import { constructUrl } from '@/lib/utils';
import { BarModel, BestPracticeItemDetailModel, MetricModel, ProgramFormOption } from '@/lib/models';
import { bestPracticeService } from '@/lib/services';
import { useCopyTextToClipboard, useQuery } from '@/hooks';
import { AsyncPage, Meter, Select } from '@/components';
import { Helmet } from 'react-helmet';

interface MeterLevel {
	value: number;
	color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'info' | 'danger';
}

export const BestPracticeDetail = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const { bestPracticeId } = useParams<{ bestPracticeId: string }>();
	const query = useQuery();
	const programId = useMemo(() => query.get('programId'), [query]);
	const { copyTextToClipboard } = useCopyTextToClipboard();

	const [programOptions, setProgramOptions] = useState<ProgramFormOption[]>([]);
	const [bestPractice, setBestPractice] = useState<BestPracticeItemDetailModel>();
	const [levelOfEffort, setLevelOfEffort] = useState<MeterLevel>({ value: 0, color: 'success' });
	const [technicalComplexity, setTechnicalComplexity] = useState<MeterLevel>({ value: 0, color: 'success' });
	const [organizationalComplexity, setOrganizationalComplexity] = useState<MeterLevel>({
		value: 0,
		color: 'success',
	});
	const [technicalSkills, setTechnicalSkills] = useState<MeterLevel>({ value: 0, color: 'success' });
	const [businessSkills, setBusinessSkills] = useState<MeterLevel>({ value: 0, color: 'success' });
	const [bestPracticeMetrics, setBestPracticeMetrics] = useState<MetricModel[]>([]);

	/* ------------------------------------- */
	/* Must default a programId */
	/* ------------------------------------- */
	useEffect(() => {
		if (!programOptions || programOptions.length <= 0) {
			return;
		}

		if (!programId) {
			history.replace(constructUrl(pathname, { page: '0', programId: programOptions[0].id }, search));
		}
	}, [history, pathname, programId, programOptions, search]);

	const fetchFilters = useCallback(async () => {
		const response = await bestPracticeService.getBestPracticeDetailsFilters(bestPracticeId).fetch();
		setProgramOptions(response.programs);
	}, [bestPracticeId]);

	const fetchBestPractice = useCallback(async () => {
		const response = await bestPracticeService.getBestPractice(bestPracticeId).fetch();

		setBestPractice(response.bestPractice);
		setImplementationConsiderations(response.bestPractice.levelOfEffort, setLevelOfEffort);
		setImplementationConsiderations(response.bestPractice.technicalComplexity, setTechnicalComplexity);
		setImplementationConsiderations(response.bestPractice.organizationalComplexity, setOrganizationalComplexity);
		setImplementationConsiderations(response.bestPractice.technicalSkillsRequired, setTechnicalSkills);
		setImplementationConsiderations(response.bestPractice.businessSkillsRequired, setBusinessSkills);
	}, [bestPracticeId]);

	const fetchMetrics = useCallback(async () => {
		const response = await bestPracticeService
			.getBestPractice(bestPracticeId, {
				...(programId && { programId }),
			})
			.fetch();

		setBestPracticeMetrics(response.bestPractice.metrics ?? []);
	}, [bestPracticeId, programId]);

	function setImplementationConsiderations(implementationValue: string, setFn: Function) {
		switch (implementationValue) {
			case 'LOW':
				setFn({ value: 33, color: 'success' });
				break;
			case 'MEDIUM':
				setFn({ value: 66, color: 'warning' });
				break;
			case 'HIGH':
				setFn({ value: 99, color: 'danger' });
				break;
			default:
				setFn({ value: 0, color: 'success' });
				break;
		}
	}

	function handleShareButtonClick() {
		copyTextToClipboard(`${window.location.origin}/best-practices/${bestPracticeId}`, 'Link copied to clipboard.');
	}

	const handleProgramSelectChange = useCallback(
		({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
			history.replace(constructUrl(pathname, { programId: currentTarget.value }, search));
		},
		[history, pathname, search]
	);

	const getMeterColor = useCallback((bar: BarModel) => {
		switch (bar.barColor) {
			case 'PURPLE':
				return 'tertiary';
			case 'GREEN':
				return 'success';
			case 'DARK_GREEN':
				return 'info';
			case 'YELLOW':
				return 'warning';
			case 'RED':
				return 'danger';
			case 'BLUE':
			default:
				return 'primary';
		}
	}, []);

	return (
		<AsyncPage fetchData={fetchFilters}>
			<Helmet>
				<title>Hackett Connect - Best Practices - Best Practice Detail</title>
			</Helmet>
			<Container className="py-6">
				<AsyncPage fetchData={fetchBestPractice}>
					<Row className="mb-6">
						<Col>
							<h1 className="mb-0">{bestPractice?.title}</h1>
						</Col>
					</Row>
					<Row>
						<Col xs={12} xl={8}>
							<Row className="mb-8">
								<Col>
									<Button variant="outline-primary" onClick={handleShareButtonClick}>
										Share
									</Button>
								</Col>
							</Row>

							{bestPractice?.background && (
								<Row className="mb-6">
									<Col>
										<h2 className="mb-3">Background</h2>
										<div
											dangerouslySetInnerHTML={{
												__html: bestPractice?.background,
											}}
										/>
									</Col>
								</Row>
							)}

							{bestPractice?.serviceDeliveries && (
								<Row className="mb-6">
									<Col>
										<h2 className="mb-3">Service Delivery Component</h2>
										<ul>
											{bestPractice?.serviceDeliveries.map((item, index) => (
												<li key={`${index}-${item}`}>{item}</li>
											))}
										</ul>
									</Col>
								</Row>
							)}

							{bestPractice?.additionalInformation && (
								<Row className="mb-6">
									<Col>
										<h2 className="mb-3">Additional Talking Points</h2>
										<div
											dangerouslySetInnerHTML={{
												__html: bestPractice?.additionalInformation,
											}}
										/>
									</Col>
								</Row>
							)}

							{bestPractice?.decisionCriteria && (
								<Row className="mb-6">
									<Col>
										<h2 className="mb-3">Decision Criteria</h2>
										<div
											dangerouslySetInnerHTML={{
												__html: bestPractice?.decisionCriteria,
											}}
										/>
									</Col>
								</Row>
							)}

							{bestPractice?.prerequisitesAndEnablers && (
								<Row className="mb-11">
									<Col>
										<h2 className="mb-4">Prerequisites &amp; Enablers</h2>
										<Tabs
											defaultActiveKey={bestPractice?.prerequisitesAndEnablers[0].title}
											id="tabs--prerequisits-and-enablers"
										>
											{bestPractice?.prerequisitesAndEnablers.map((item, index) => {
												return (
													<Tab
														eventKey={item.title}
														title={item.title}
														key={`${index}-${item.title}`}
													>
														<div
															dangerouslySetInnerHTML={{
																__html: item.value,
															}}
														/>
													</Tab>
												);
											})}
										</Tabs>
									</Col>
								</Row>
							)}

							{bestPractice?.benefitsAndRisks && (
								<Row className="mb-11">
									<Col>
										<h2 className="mb-4">Benefits &amp; Risks</h2>
										<Tabs
											defaultActiveKey={bestPractice?.benefitsAndRisks[0].title}
											id="tabs--benefits-and-risks"
										>
											{bestPractice?.benefitsAndRisks.map((item, index) => {
												return (
													<Tab
														eventKey={item.title}
														title={item.title}
														key={`${index}-${item.title}`}
													>
														<div
															dangerouslySetInnerHTML={{
																__html: item.value,
															}}
														/>
													</Tab>
												);
											})}
										</Tabs>
									</Col>
								</Row>
							)}
						</Col>

						<Col xs={12} xl={4}>
							<div className="border p-5">
								{bestPractice?.themes && (
									<>
										<h4 className="mb-3 text-muted">Best Practice Themes</h4>
										<ul className="mb-4">
											{bestPractice?.themes.map((item, index) => {
												return <li key={`${index}-${item}`}>{item}</li>;
											})}
										</ul>
									</>
								)}

								{bestPractice?.enablers && (
									<>
										<h4 className="mb-2 text-muted">Primary Enablers</h4>
										<ul className="mb-4">
											{bestPractice?.enablers.map((item, index) => {
												return <li key={`${index}-${item}`}>{item}</li>;
											})}
										</ul>
									</>
								)}

								{bestPractice?.status && (
									<>
										<h4 className="mb-2 text-muted">Status</h4>
										<ul className="mb-4">
											<li>{bestPractice?.status}</li>
										</ul>
									</>
								)}

								<Meter
									className="mb-4"
									variant={levelOfEffort?.color}
									size="sm"
									value={levelOfEffort!.value}
									max={100}
									pill
									title="Overall level of effort"
									showBackground={true}
								/>
								<Meter
									className="mb-4"
									variant={technicalComplexity?.color}
									size="sm"
									value={technicalComplexity!.value || 0}
									max={100}
									pill
									title="Technical Complexity"
									showBackground={true}
								/>
								<Meter
									className="mb-4"
									variant={organizationalComplexity?.color}
									size="sm"
									value={organizationalComplexity!.value || 0}
									max={100}
									pill
									title="Organizational Complexity"
									showBackground={true}
								/>
								<Meter
									className="mb-4"
									variant={technicalSkills?.color}
									size="sm"
									value={technicalSkills!.value || 0}
									max={100}
									pill
									title="Technical Skills Required"
									showBackground={true}
								/>
								<Meter
									className="mb-4"
									variant={businessSkills?.color}
									size="sm"
									value={businessSkills!.value || 0}
									max={100}
									pill
									title="Business Skills Required"
									showBackground={true}
								/>

								{bestPractice?.timeframeToImplement && (
									<>
										<h4 className="mb-2 text-muted">Time to Implement</h4>
										<ul className="mb-4">
											<li>{bestPractice?.timeframeToImplement}</li>
										</ul>
									</>
								)}

								{bestPractice?.approach && (
									<>
										<h4 className="mb-2 text-muted">Approach</h4>
										<div
											className="mb-4"
											dangerouslySetInnerHTML={{
												__html: bestPractice?.approach[0],
											}}
										/>
									</>
								)}

								{bestPractice?.otherImplementationIssues && (
									<>
										<h4 className="mb-2 text-muted">Other Implementation Issues</h4>
										<div
											className="mb-4"
											dangerouslySetInnerHTML={{
												__html: bestPractice?.otherImplementationIssues,
											}}
										/>
									</>
								)}
							</div>
						</Col>
					</Row>
				</AsyncPage>

				{bestPractice?.metrics && bestPractice?.metrics.length > 0 && (
					<Row>
						<Col>
							<div>
								<h2 className="mb-4">Performance Metrics ({bestPracticeMetrics.length})</h2>
								<Form.Group className="mb-10">
									<Form.Label className="d-block">Program</Form.Label>
									<Select
										className="d-inline-flex"
										value={programId ?? ''}
										onChange={handleProgramSelectChange}
									>
										{programOptions.map((po) => (
											<option key={po.id} value={po.id}>
												{po.display}
											</option>
										))}
									</Select>
								</Form.Group>
							</div>
							<AsyncPage fetchData={fetchMetrics}>
								{bestPracticeMetrics.map((metric, metricIndex) => {
									return (
										<React.Fragment key={`${metric.title}--${metricIndex}`}>
											<Row className="mb-4">
												<Col>
													<h3 className="mb-0">{metric.title}</h3>
												</Col>
											</Row>
											<Row>
												<Col xl={7}>
													<div className="pr-4">
														{metric.metricBars.map((barItem, barIndex) => {
															return (
																<Meter
																	key={barIndex}
																	className="mb-4"
																	variant={getMeterColor(barItem)}
																	size="sm"
																	value={barItem.barPercent}
																	max={100}
																	pill
																	title={barItem.title}
																/>
															);
														})}
													</div>
												</Col>
												<Col xl={5}>
													<Row>
														{metric.businessProcess && (
															<Col className="mb-4" xs={6}>
																<h4 className="mb-2 text-muted">BUSINESS PROCESS</h4>
																<h5>{metric.businessProcess}</h5>
															</Col>
														)}
														{metric.driver && (
															<Col className="mb-4" xs={6}>
																<h4 className="mb-2 text-muted">DRIVER</h4>
																<h5>{metric.driver}</h5>
															</Col>
														)}
														{metric.category && (
															<Col className="mb-4" xs={6}>
																<h4 className="mb-2 text-muted">CATEGORY</h4>
																<h5>{metric.category}</h5>
															</Col>
														)}
														{metric.source && (
															<Col className="mb-4" xs={6}>
																<h4 className="mb-2 text-muted">Source</h4>
																<h5>{metric.source}</h5>
															</Col>
														)}
													</Row>
												</Col>
											</Row>
											<Row className="mb-4">
												<Col>
													<hr />
												</Col>
											</Row>
										</React.Fragment>
									);
								})}
							</AsyncPage>
						</Col>
					</Row>
				)}
			</Container>
		</AsyncPage>
	);
};
