import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { DOCUMENT_FILE_TYPES } from '@/lib/models';
import { useAnalytics, useCopyTextToClipboard, useHandleError, useNavigationConfig } from '@/hooks';

import { ReactComponent as ViewIcon } from '@/assets/icons/file.svg';
import { ReactComponent as DownloadIcon } from '@/assets/icons/download.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';
import { documentService } from '@/lib/services';
import { downloadFileAtUrl } from '@/lib/utils';

interface EventDocumentListItemProps {
	documentId: string;
	fileType?: DOCUMENT_FILE_TYPES;
	fileName?: string;
	viewUrl: string;
	downloadUrl?: string;
	to: string;
	title: string;
}

export const EventDocumentListItem = ({
	documentId,
	fileType,
	fileName,
	viewUrl,
	to,
	title,
}: EventDocumentListItemProps) => {
	const { copyTextToClipboard } = useCopyTextToClipboard();
	const analytics = useAnalytics();
	const handleError = useHandleError();
	const { navigationConfig } = useNavigationConfig();

	const handleShareButtonClick = () => {
		copyTextToClipboard(`${window.location.origin}/research/${documentId}`, 'Link copied to clipboard.');
	};

	const handleDownloadClick = useCallback(async () => {
		if (viewUrl) {
			try {
				await downloadFileAtUrl(viewUrl, fileName ?? title);
				documentService.trackDocumentDownload(documentId).fetch();
				analytics.handleClickEvent(viewUrl);
			} catch (error) {
				handleError(error);
			}
		}
	}, [viewUrl, fileName, title, documentId, analytics, handleError]);

	return (
		<div className="py-4 d-flex align-items-center justify-content-between">
			<h3 className="mb-0">
				<Link to={to}>{title}</Link>
			</h3>
			<div className="d-flex align-items-center">
				<Button
					variant="link"
					className="mr-1"
					href={viewUrl}
					target="_blank"
					rel="noopener"
					onClick={() => {
						analytics.handleClickEvent(viewUrl);
					}}
				>
					<ViewIcon />
				</Button>
				{navigationConfig?.allowDownloads && viewUrl && (
					<Button className="mr-1" variant="link" onClick={handleDownloadClick}>
						<DownloadIcon />
					</Button>
				)}
				<Button variant="link" className="mx-1" onClick={handleShareButtonClick}>
					<CopyIcon />
				</Button>
			</div>
		</div>
	);
};
