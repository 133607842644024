import React, { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Color from 'color';

import { createUseThemedStyles } from '@/hooks';
import classNames from 'classnames';

interface UseNavigationLinkStylesProps {
	isActive: boolean;
}

const useNavigationLinkStyles = createUseThemedStyles((theme) => ({
	navigationLink: ({ isActive }: UseNavigationLinkStylesProps) => ({
		borderRadius: 2,
		backgroundColor: isActive
			? Color(theme.colors.primary)
					.alpha(0.16)
					.string()
			: 'transparent',
		'& a': {
			...theme.fonts.s,
			display: 'block',
			padding: '12px 16px',
			color: isActive ? theme.colors.primary : theme.colors.black,
			...(isActive ? theme.fonts.openSansSemiBold : theme.fonts.openSansRegular),
		},
	}),
}));

interface NavigationLinkProps {
	to: string;
	match?: string;
	className?: string;
	onClick?(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

export const NavigationLink: FC<NavigationLinkProps> = ({ to, match, className, onClick, children }) => {
	const classes = useNavigationLinkStyles({
		isActive: !!useRouteMatch(match ? match : to),
	});

	return (
		<div className={classNames(classes.navigationLink, className)}>
			<Link to={to} onClick={onClick}>
				{children}
			</Link>
		</div>
	);
};
