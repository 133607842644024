import Cookies from 'js-cookie';
import config from '@/lib/config';
import { accessTokenCookieAttributes, COOKIES } from '@/lib/models';
import { useCallback, useState } from 'react';
import { accountService } from '@/lib/services';
import { constructUrl } from '@/lib/utils';

export const useSignOut = () => {
	const [isSigningOut, setIsSigningOut] = useState(false);

	const signOut = useCallback(async () => {
		try {
			setIsSigningOut(true);

			if (config.HACKETT_ADVISORY_USE_REAL_AUTH === 'true') {
				await accountService.signOut().fetch();
				Cookies.remove(COOKIES.ACCESS_TOKEN, accessTokenCookieAttributes);
				Cookies.remove(COOKIES.TARGET_URL);

				window.location.href = constructUrl(config.HACKETT_ADVISORY_AUTH_UI_URL, {
					redirectUrl: encodeURIComponent(window.location.origin),
					platform: 'hackett-connect',
				});
			} else {
				Cookies.remove(COOKIES.ACCESS_TOKEN, accessTokenCookieAttributes);
				Cookies.remove(COOKIES.TARGET_URL);
				window.location.href = '/sign-in';
			}
		} catch (error) {
			Cookies.remove(COOKIES.ACCESS_TOKEN, accessTokenCookieAttributes);
			Cookies.remove(COOKIES.TARGET_URL);
			throw error;
		} finally {
			setIsSigningOut(false);
		}
	}, []);

	const signOutNoServiceCall = useCallback(async () => {
		Cookies.remove(COOKIES.ACCESS_TOKEN, accessTokenCookieAttributes);
		Cookies.remove(COOKIES.TARGET_URL);

		if (config.HACKETT_ADVISORY_USE_REAL_AUTH === 'true') {
			window.location.href = constructUrl(config.HACKETT_ADVISORY_AUTH_UI_URL, {
				redirectUrl: encodeURIComponent(window.location.href),
				platform: 'hackett-connect',
			});
		} else {
			window.location.href = '/sign-in';
		}
	}, []);

	return {
		signOut,
		signOutNoServiceCall,
		isSigningOut,
	};
};
