import React, { useCallback, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Badge, Card, Col, Container, Row, Form } from 'react-bootstrap';

import { InquiryModel, INQUIRY_STATUS_ID } from '@/lib/models';
import { inquiryService } from '@/lib/services';
import { useAlert, useHandleError, useNavigationConfig } from '@/hooks';
import { AsyncPage, AttachmentDropdown, LoadingButton, Wysiwyg } from '@/components';

import { ReactComponent as TrashIcon } from '@/assets/icons/icon-trash.svg';
import { Helmet } from 'react-helmet';

export const InquiryDetailReadOnly = () => {
	const history = useHistory();
	const { showAlert } = useAlert();
	const handleError = useHandleError();
	const { navigationConfig } = useNavigationConfig();

	const { inquiryId } = useParams<{ inquiryId: string }>();
	const [inquiry, setInquiry] = useState<InquiryModel>();
	const [isSaving, setIsSaving] = useState(false);

	const fetchData = useCallback(async () => {
		const response = await inquiryService.getInquiryDetails(inquiryId).fetch();
		setInquiry(response.inquiry);
	}, [inquiryId]);

	const handleResendEmailButtonClick = useCallback(async () => {
		try {
			setIsSaving(true);

			if (!inquiry) {
				throw new Error('inquiry is undefined.');
			}

			await inquiryService.resendEmail(inquiry?.inquiryId).fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Email resent.</p>;
				},
			});
		} catch (error) {
			handleError(error);
		} finally {
			setIsSaving(false);
		}
	}, [handleError, inquiry, showAlert]);

	const handleReopenInquiryButtonClick = useCallback(async () => {
		try {
			if (!inquiry) {
				throw new Error('Inquiry not found.');
			}

			setIsSaving(true);

			const response = await inquiryService
				.updateInquiry({
					inquiryId: inquiry.inquiryId,
					inquiryAccountIds: inquiry.inquiryAccounts.map((a) => a.accountId),
					inquiryStatus: INQUIRY_STATUS_ID.IN_PROGRESS,
					uploadedDocumentIds: inquiry.documents.map((d) => d.documentId),
					topicIds: inquiry.topicsList.map((t) => t.id),
					notes: inquiry.notes
				})
				.fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Inquiry reopened.</p>;
				},
			});

			history.replace(`/client-management/inquiries/${response.inquiry.inquiryId}/edit`);
		} catch (error) {
			setIsSaving(false);
			handleError(error);
		}
	}, [handleError, history, inquiry, showAlert]);

	const handleDeleteButtonClick = useCallback(async () => {
		if (!window.confirm('All the information in this inquiry will be deleted. Are you sure you want to proceed?')) {
			return;
		}

		try {
			setIsSaving(true);

			if (!inquiry) {
				throw new Error('inquiry is undefined.');
			}

			await inquiryService.deleteInquiry(inquiry.inquiryId).fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Inquiry deleted.</p>;
				},
			});
			history.push('/client-management/inquiries');
		} catch (error) {
			setIsSaving(false);
			handleError(error);
		}
	}, [handleError, history, inquiry, showAlert]);

	const statusBadge = () => {
		return (
			<Badge bg='light' text='dark' pill>Inactive</Badge>
		)
	}

	const handlePostComment = useCallback(async ()=> {
		try {
			if (!inquiry) {
				throw new Error('Inquiry not found.');
			}
			setIsSaving(true);
			const response = await inquiryService
				.postNotesRequester({
					inquiryId: inquiry.inquiryId,
					inquiryAccountIds: inquiry.inquiryAccounts.map((a) => a.accountId),
					inquiryStatus: inquiry.inquiryStatus,
					uploadedDocumentIds: inquiry.documents.map((d) => d.documentId),
					topicIds: inquiry.topicsList.map((t) => t.id),
					notes: inquiry.notes,
					requesterNotes: inquiry.requesterNotes
				})
				.fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Notes posted.</p>;
				},
			});

			history.replace(`/client-management/inquiries/${response.inquiry.inquiryId}`);
		} catch (error) {
			setIsSaving(false);
			handleError(error);
		}
	},[handleError, history, inquiry, showAlert]);

	return (
		<AsyncPage fetchData={fetchData}>
			<Helmet><title>Hackett Connect - Client Management - Inquiries - Inquiry Details</title></Helmet>
			<Container className="py-13">
				<Row className="mb-2">
					<Col>
						{navigationConfig?.allowClientManagement ? (
							<Link to={'/client-management/inquiries'}>Inquiries /</Link>
						) : (
							<Link to={'/inquiries'}>Inquiries /</Link>
						)}
					</Col>
				</Row>
				<Row>
					<Col xs={12} xl={8} className="mb-3">
						<h2 className="mb-2">{inquiry?.inquiryTitle}</h2>
						<div className="mb-4 d-flex align-items-center justify-content-between">
							<p className="mb-0 text-muted font-italic">{inquiry?.inquiryDescription}</p>
							<p className="mb-0 text-muted font-italic">{inquiry?.inquiryStatusDescription}</p>
						</div>

						<hr className="mb-4" />

						<div className="mb-4">
							<div className="d-flex justify-content-between">
								<p>
									{navigationConfig?.allowClientManagement && <strong>{inquiry?.client.name}</strong>}
								</p>
								<p className="text-muted">Submitted {inquiry?.createdDescription}</p>
							</div>
							<div dangerouslySetInnerHTML={{ __html: inquiry?.inquiryText ?? '' }} />
						</div>

						{inquiry?.shouldShowNotes && (
						<div>
							<hr className="mb-4" />

							<div className="mb-4">
								<div className="d-flex justify-content-between">
									<p>
										{inquiry?.shouldShowNotes && <strong>Notes</strong>}
									</p>
								</div>
								<div dangerouslySetInnerHTML={{ __html: inquiry?.notes ?? '' }} />
							</div>
						</div>
						)}

						<hr className="mb-4" />

						<div className="mb-4">
							<p>
								<strong>Recipients</strong>
							</p>
							<p className="mb-0">
								{inquiry?.inquiryAccounts
									.map((recipient) => {
										return `${recipient.name} (${recipient.emailAddress})`;
									})
									.join(', ')}
							</p>
						</div>

						<hr className={inquiry?.inquiryStatus === INQUIRY_STATUS_ID.CLOSED ? 'mb-4' : 'mb-6'} />

						{inquiry?.inquiryStatus === INQUIRY_STATUS_ID.CLOSED && (
							<>
								<div className="mb-5">
									<div className="d-flex justify-content-between">
										<p>
											<strong>{inquiry?.advisorAccount.name}, Inquiry Advisor</strong>
										</p>
										<p className="text-muted">{inquiry?.responseSubmittedDescription}</p>
									</div>
									<div dangerouslySetInnerHTML={{ __html: inquiry?.responseText ?? '' }} />
								</div>
								{inquiry.documents.map((document) => {
									return (
										<div className="d-flex">
											<AttachmentDropdown
												className="mb-6"
												key={document.documentId}
												title={document.name}
												downloadUrl={document.downloadUrl}
												showBorder={false}
												showRemoveOption={false}
											/>
										</div>
									);
								})}
								<hr className="mb-6" />
								<Form.Label className="mb-4">Notes (from Requester) </Form.Label>
									<Form.Group className="mr-3 mb-6" >
										{!inquiry?.requesterNotes && !navigationConfig?.allowClientManagement &&(
											<>
												<Wysiwyg
												className="mr-3 mb-6"
												value={inquiry.requesterNotes}
												onChange={(content) => {
													inquiry.requesterNotes = content;
												}}
												readOnly={isSaving }
												/>
												<LoadingButton
													className="mr-3 mb-6"
													variant="outline-primary"
													onClick={handlePostComment}
													isLoading={isSaving}
												>
													Post
												</LoadingButton>
											</>
										)}
										{inquiry?.requesterNotes && (
											<div className="mr-3 mb-6" dangerouslySetInnerHTML={{ __html: inquiry?.requesterNotes ?? '' }} />
										)}

										{inquiry?.advisorBookingsUrl &&  !navigationConfig?.allowClientManagement &&(
											<LoadingButton
												variant="outline-primary"
												className="mr-3 mb-6"
												href={inquiry.advisorBookingsUrl}
												target="_blank"
												rel="noopener"
											>
												Schedule a call
											</LoadingButton>
										)}
									</Form.Group>
								<hr className="mb-6" />
							</>
						)}

						{navigationConfig?.allowClientManagement && (
							<div className="d-flex align-items-center justify-content-between">
								<div>
									<LoadingButton
										className="mr-3"
										variant="outline-primary"
										onClick={handleResendEmailButtonClick}
										isLoading={isSaving}
									>
										Resend Email
									</LoadingButton>
									<LoadingButton onClick={handleReopenInquiryButtonClick} isLoading={isSaving}>
										Reopen Inquiry
									</LoadingButton>
								</div>
								<LoadingButton
									variant="outline-primary"
									onClick={handleDeleteButtonClick}
									isLoading={isSaving}
								>
									<TrashIcon className="mr-3" />
									Delete
								</LoadingButton>
							</div>
						)}
					</Col>
					<Col xs={12} xl={4}>
						<Card>
							<Card.Body>
								<Card.Title className="mb-4">In this conversation</Card.Title>
								{inquiry?.client && (
									<div className="mb-5">
										<p className="mb-0">
											<strong>Client</strong>
										</p>
										<p className="mb-0">{inquiry.client.name}</p>
										<p className="mb-0 text-muted">Submitted {inquiry.createdDescription}</p>
									</div>
								)}
								{inquiry?.requestingAccount && (
									<div className="mb-5">
										<p className="mb-0">
											<strong>Requester</strong>
										</p>
										<div className="mb-4 d-flex align-items-center">
											<p className="mb-0">{inquiry?.requestingAccount.name}</p>
											<p className="mb-0" style={{paddingLeft: '5px'}}>
												{inquiry?.requestingAccount.deleted ? statusBadge() : null}
											</p>
										</div>
									</div>
								)}
								{inquiry?.advisorAccount && (
									<div className="mb-5 d-flex align-items-center">
										<div>
											<p className="mb-0">
												<strong>Advisor</strong>
											</p>
											<p className="mb-0">{inquiry.advisorAccount.name}</p>
										</div>
									</div>
								)}
								{inquiry?.accountManagerAccount && (
									<div className="d-flex align-items-center">
										<div>
											<p className="mb-0">
												<strong>Account Manager</strong>
											</p>
											<p className="mb-0">{inquiry.accountManagerAccount.name}</p>
										</div>
									</div>
								)}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
