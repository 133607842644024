import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { constructUrl } from '@/lib/utils';

import { TableColumns, TableHeaders, TableRowConfig } from '@/components/table/models';
import { TableCell, TableHeader, TableRenderer, TableRow } from '@/components/table';

import { AsyncPage, Select } from '@/components';
import { FormOption, InquiryStat, INQUIRY_STATUS_ID } from '@/lib/models';
import { useAccount, useHandleError, useQuery } from '@/hooks';
import { inquiryService } from '@/lib/services';

enum INQUIRY_TABLE_COLUMN_IDS {
	ADVISOR = 'ADVISOR',
	INQUIRY_RECEIVED = 'INQUIRY_RECEIVED',
	INQUIRY_CONFIRMED = 'INQUIRY_CONFIRMED',
	IN_PROGRESS = 'IN_PROGRESS',
	QC_REVIEW = 'QC_REVIEW',
	CLOSED = 'CLOSED',
	TOTAL = 'TOTAL',
}

enum ADVISOR_TYPE {
	ADVISOR = 'ADVISOR',
	INQUIRY_ADVISOR = 'INQUIRY_ADVISOR',
	ADVISOR_INQUIRY_ADVISOR = 'ADVISOR_INQUIRY_ADVISOR',
}

const inquiryTableColumns: TableColumns<INQUIRY_TABLE_COLUMN_IDS> = {
	[INQUIRY_TABLE_COLUMN_IDS.ADVISOR]: {
		columnId: INQUIRY_TABLE_COLUMN_IDS.ADVISOR,
		columnTitle: 'Advisor',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[INQUIRY_TABLE_COLUMN_IDS.INQUIRY_RECEIVED]: {
		columnId: INQUIRY_TABLE_COLUMN_IDS.INQUIRY_RECEIVED,
		columnTitle: 'Inquiry Received',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[INQUIRY_TABLE_COLUMN_IDS.INQUIRY_CONFIRMED]: {
		columnId: INQUIRY_TABLE_COLUMN_IDS.INQUIRY_CONFIRMED,
		columnTitle: 'Inquiry Confirmed',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[INQUIRY_TABLE_COLUMN_IDS.IN_PROGRESS]: {
		columnId: INQUIRY_TABLE_COLUMN_IDS.IN_PROGRESS,
		columnTitle: 'In Progress',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[INQUIRY_TABLE_COLUMN_IDS.QC_REVIEW]: {
		columnId: INQUIRY_TABLE_COLUMN_IDS.QC_REVIEW,
		columnTitle: 'QC Review',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[INQUIRY_TABLE_COLUMN_IDS.CLOSED]: {
		columnId: INQUIRY_TABLE_COLUMN_IDS.CLOSED,
		columnTitle: 'Closed',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[INQUIRY_TABLE_COLUMN_IDS.TOTAL]: {
		columnId: INQUIRY_TABLE_COLUMN_IDS.TOTAL,
		columnTitle: 'Total',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
};

const inquiryTableHeaders: TableHeaders<INQUIRY_TABLE_COLUMN_IDS> = {
	[INQUIRY_TABLE_COLUMN_IDS.ADVISOR]: {
		tableHeaderId: INQUIRY_TABLE_COLUMN_IDS.ADVISOR,
		title: 'Advisor',
		isSortable: false,
	},
	[INQUIRY_TABLE_COLUMN_IDS.INQUIRY_RECEIVED]: {
		tableHeaderId: INQUIRY_TABLE_COLUMN_IDS.INQUIRY_RECEIVED,
		title: 'Inquiry Received',
		isSortable: false,
	},
	[INQUIRY_TABLE_COLUMN_IDS.INQUIRY_CONFIRMED]: {
		tableHeaderId: INQUIRY_TABLE_COLUMN_IDS.INQUIRY_CONFIRMED,
		title: 'Inquiry Confirmed',
		isSortable: false,
	},
	[INQUIRY_TABLE_COLUMN_IDS.IN_PROGRESS]: {
		tableHeaderId: INQUIRY_TABLE_COLUMN_IDS.IN_PROGRESS,
		title: 'In Progress',
		isSortable: false,
	},
	[INQUIRY_TABLE_COLUMN_IDS.QC_REVIEW]: {
		tableHeaderId: INQUIRY_TABLE_COLUMN_IDS.QC_REVIEW,
		title: 'QC Review',
		isSortable: false,
	},
	[INQUIRY_TABLE_COLUMN_IDS.CLOSED]: {
		tableHeaderId: INQUIRY_TABLE_COLUMN_IDS.CLOSED,
		title: 'Closed',
		isSortable: false,
	},
	[INQUIRY_TABLE_COLUMN_IDS.TOTAL]: {
		tableHeaderId: INQUIRY_TABLE_COLUMN_IDS.TOTAL,
		title: 'Total',
		isSortable: false,
	},
};

export const AdvisorInquiriesDashboard = () => {
	const history = useHistory();
	const handleError = useHandleError();
	const { pathname, search } = useLocation();
	const { account } = useAccount();
	const query = useQuery();
	const advisorType = useMemo(() => query.get('advisorType') ?? ADVISOR_TYPE.ADVISOR, [query]);

	const [myStats, setMyStats] = useState<InquiryStat>();
	const [flagShowMyStats, setFlagShowMyStats] = useState(false);
	const [filterOptions, setFilterOptions] = useState<FormOption[]>([]);
	const [tableColumnsData, setTableColumnsData] = useState(inquiryTableColumns);
	const [tableHeadersData] = useState(inquiryTableHeaders);
	const [tableRowData, setTableRowData] = useState<TableRowConfig<InquiryStat>[]>([]);

	const fetchFilters = useCallback(async () => {
		const response = await inquiryService.getInquiryStatFilters().fetch();
		setFilterOptions(response.advisorTypes);
	}, []);

	const fetchData = useCallback(async () => {
		try {
			const response = await inquiryService
				.getInquiryStats({
					...(advisorType && { advisorType }),
				})
				.fetch();
			setMyStats(response.myStats);
			setFlagShowMyStats(response.flagShowMyStats);
			setTableRowData(
				response.teamStats.map((teamStat) => {
					return {
						rowId: teamStat.accountId,
						checked: false,
						expanded: false,
						columnData: teamStat,
					};
				})
			);
		} catch (error) {
			handleError(error);
		}
	}, [advisorType, handleError]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<AsyncPage fetchData={fetchFilters}>
			<Helmet>
				<title>Hackett Connect - Client Management - Inquiries Dashboard</title>
			</Helmet>
			<Container className="py-13">
				<Row className="mb-10">
					<Col>
						<div className="d-flex align-item-center justify-content-between">
							<h1 className="mb-0">Inquiry Dashboard</h1>
							<Button
								onClick={() => {
									history.push('/contact/create-inquiry');
								}}
							>
								Create a new inquiry
							</Button>
						</div>
					</Col>
				</Row>

				<Row className="mb-6">
					<Col>
						<div className="d-flex align-item-center justify-content-between">
							<div className="d-flex align-item-center align-items-center">
								<h3 className="mb-0 mr-1">Filter Inquiries by</h3>
								<Select
									variant="secondary"
									value={advisorType}
									onChange={({ currentTarget }) => {
										history.push(
											constructUrl(pathname, { advisorType: currentTarget.value }, search)
										);
									}}
								>
									{filterOptions.map((fo) => {
										return (
											<option key={fo.id} value={fo.id}>
												{fo.display}
											</option>
										);
									})}
								</Select>
							</div>
						</div>
					</Col>
				</Row>

				<Row className="mb-6">
					<Col>
						<div className="d-flex align-item-center justify-content-between">
							<h3 className="mb-0">My open Inquiries</h3>
							{flagShowMyStats && (
								<p className="mb-0">
									<strong>
										<Link
											to={constructUrl('/client-management/inquiries', {
												...(advisorType === ADVISOR_TYPE.ADVISOR && {
													advisorId: account?.accountId,
												}),
												...(advisorType === ADVISOR_TYPE.INQUIRY_ADVISOR && {
													grcAdvisorId: account?.accountId,
												}),
												...(advisorType === ADVISOR_TYPE.ADVISOR_INQUIRY_ADVISOR && {
													advisorId: account?.accountId,
													grcAdvisorId: account?.accountId,
													isOrConditionAdvisor: true,
												}),
										})}
										>
											See all my inquiries
										</Link>
									</strong>
								</p>
							)}
						</div>
					</Col>
				</Row>
				<Row className="mb-8">
					{myStats?.inquiryStatuses.map((stat) => {
						return (
							<Col xs={12} md={6} lg={3} key={stat.statusId}>
								<Card className="mb-3">
									<Card.Body className="p-3">
										<h1 className="mb-0 text-success font-weight-regular">{stat.count}</h1>
										<p className="mb-0">
											<Link
												to={constructUrl('/client-management/inquiries', {
													status: stat.statusId,
													...(advisorType === ADVISOR_TYPE.ADVISOR && {
														advisorId: account?.accountId,
													}),
													...(advisorType === ADVISOR_TYPE.INQUIRY_ADVISOR && {
														grcAdvisorId: account?.accountId,
													}),
													...(advisorType === ADVISOR_TYPE.ADVISOR_INQUIRY_ADVISOR && {
														advisorId: account?.accountId,
														grcAdvisorId: account?.accountId,
														isOrConditionAdvisor: true,
													}),
												})}
											>
												{stat.statusDescription}
											</Link>
										</p>
									</Card.Body>
								</Card>
							</Col>
						);
					})}
					{(!myStats?.inquiryStatuses || myStats.inquiryStatuses.length <= 0) && (
						<Col>
							<p className="mt-6 text-muted text-center">
								<strong>You have no inquiries</strong>
							</p>
						</Col>
					)}
				</Row>
				<Row className="mb-6">
					<Col>
						<div className="d-flex align-item-center justify-content-between">
							<div className="d-flex align-item-center align-items-center">
								<h3 className="mb-0 mr-1">Team's Inquiries</h3>
							</div>
							<p className="mb-0">
								<strong>
									<Link to="/client-management/inquiries">See all team's inquiries</Link>
								</strong>
							</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<TableRenderer
							tableColumnData={tableColumnsData}
							onTableColumnDataChange={setTableColumnsData}
							tableRowData={tableRowData}
							onTableRowDataChange={setTableRowData}
							tableRowDataTotal={1000}
							pageSize={10}
							pageIndex={0}
							hidePagination
							onPaginationChange={() => {
								return;
							}}
							tableHeaderRowRenderer={() => {
								return (
									<TableRow>
										{Object.values(tableColumnsData).map((columnConfig) => {
											const tableHeader = tableHeadersData[columnConfig.columnId];

											return (
												<TableHeader
													key={tableHeader.tableHeaderId}
													className={classNames({
														'justify-content-end':
															tableHeader.tableHeaderId !==
															INQUIRY_TABLE_COLUMN_IDS.ADVISOR,
													})}
												>
													{tableHeader.title}
												</TableHeader>
											);
										})}
									</TableRow>
								);
							}}
							tableBodyRowRenderer={(data) => {
								return (
									<TableRow key={data.rowId}>
										{Object.values(tableColumnsData).map((columnConfig) => {
											const { columnData } = data;

											switch (columnConfig.columnId) {
												case INQUIRY_TABLE_COLUMN_IDS.ADVISOR:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.name}
														</TableCell>
													);
												case INQUIRY_TABLE_COLUMN_IDS.INQUIRY_RECEIVED:
													return (
														<TableCell key={columnConfig.columnId} className="text-right">
															<Link
																to={constructUrl('/client-management/inquiries', {
																	status: INQUIRY_STATUS_ID.INQUIRY_RECEIVED,
																	...(advisorType ===
																		ADVISOR_TYPE.INQUIRY_ADVISOR && {
																		grcAdvisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR && {
																		advisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR_INQUIRY_ADVISOR && {
																		advisorId: columnData.accountId,
																		grcAdvisorId: columnData.accountId,
																		isOrConditionAdvisor: true,
																	}),
																})}
															>
																{columnData.inquiryStatuses.find(
																	(is) =>
																		is.statusId ===
																		INQUIRY_STATUS_ID.INQUIRY_RECEIVED
																)?.count ?? 0}
															</Link>
														</TableCell>
													);
												case INQUIRY_TABLE_COLUMN_IDS.INQUIRY_CONFIRMED:
													return (
														<TableCell key={columnConfig.columnId} className="text-right">
															<Link
																to={constructUrl('/client-management/inquiries', {
																	status: INQUIRY_STATUS_ID.INQUIRY_CONFIRMED,
																	...(advisorType ===
																		ADVISOR_TYPE.INQUIRY_ADVISOR && {
																		grcAdvisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR && {
																		advisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR_INQUIRY_ADVISOR && {
																		advisorId: columnData.accountId,
																		grcAdvisorId: columnData.accountId,
																		isOrConditionAdvisor: true,
																	}),
																})}
															>
																{columnData.inquiryStatuses.find(
																	(is) =>
																		is.statusId ===
																		INQUIRY_STATUS_ID.INQUIRY_CONFIRMED
																)?.count ?? 0}
															</Link>
														</TableCell>
													);
												case INQUIRY_TABLE_COLUMN_IDS.IN_PROGRESS:
													return (
														<TableCell key={columnConfig.columnId} className="text-right">
															<Link
																to={constructUrl('/client-management/inquiries', {
																	status: INQUIRY_STATUS_ID.IN_PROGRESS,
																	...(advisorType ===
																		ADVISOR_TYPE.INQUIRY_ADVISOR && {
																		grcAdvisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR && {
																		advisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR_INQUIRY_ADVISOR && {
																		advisorId: columnData.accountId,
																		grcAdvisorId: columnData.accountId,
																		isOrConditionAdvisor: true,
																	}),
																})}
															>
																{columnData.inquiryStatuses.find(
																	(is) =>
																		is.statusId === INQUIRY_STATUS_ID.IN_PROGRESS
																)?.count ?? 0}
															</Link>
														</TableCell>
													);
												case INQUIRY_TABLE_COLUMN_IDS.QC_REVIEW:
													return (
														<TableCell key={columnConfig.columnId} className="text-right">
															<Link
																to={constructUrl('/client-management/inquiries', {
																	status: INQUIRY_STATUS_ID.QC_REVIEW,
																	...(advisorType ===
																		ADVISOR_TYPE.INQUIRY_ADVISOR && {
																		grcAdvisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR && {
																		advisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR_INQUIRY_ADVISOR && {
																		advisorId: columnData.accountId,
																		grcAdvisorId: columnData.accountId,
																		isOrConditionAdvisor: true,
																	}),
																})}
															>
																{columnData.inquiryStatuses.find(
																	(is) => is.statusId === INQUIRY_STATUS_ID.QC_REVIEW
																)?.count ?? 0}
															</Link>
														</TableCell>
													);
												case INQUIRY_TABLE_COLUMN_IDS.CLOSED:
													return (
														<TableCell key={columnConfig.columnId} className="text-right">
															<Link
																to={constructUrl('/client-management/inquiries', {
																	status: INQUIRY_STATUS_ID.CLOSED,
																	...(advisorType ===
																		ADVISOR_TYPE.INQUIRY_ADVISOR && {
																		grcAdvisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR && {
																		advisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR_INQUIRY_ADVISOR && {
																		advisorId: columnData.accountId,
																		grcAdvisorId: columnData.accountId,
																		isOrConditionAdvisor: true,
																	}),
																})}
															>
																{columnData.inquiryStatuses.find(
																	(is) => is.statusId === INQUIRY_STATUS_ID.CLOSED
																)?.count ?? 0}
															</Link>
														</TableCell>
													);
												case INQUIRY_TABLE_COLUMN_IDS.TOTAL:
													return (
														<TableCell key={columnConfig.columnId} className="text-right">
															<Link
																to={constructUrl('/client-management/inquiries', {
																	...(advisorType ===
																		ADVISOR_TYPE.INQUIRY_ADVISOR && {
																		grcAdvisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR && {
																		advisorId: columnData.accountId,
																	}),
																	...(advisorType === ADVISOR_TYPE.ADVISOR_INQUIRY_ADVISOR && {
																		advisorId: columnData.accountId,
																		grcAdvisorId: columnData.accountId,
																		isOrConditionAdvisor: true,
																	}),
																})}
															>
																<strong>
																	{columnData.inquiryStatuses.find(
																		(is) => is.statusId === INQUIRY_STATUS_ID.TOTAL
																	)?.count ?? 0}
																</strong>
															</Link>
														</TableCell>
													);
												default:
													return null;
											}
										})}
									</TableRow>
								);
							}}
						/>
						{tableRowData.length <= 0 && (
							<p className="mt-6 text-muted text-center">
								<strong>Team has no open inquiries</strong>
							</p>
						)}
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
