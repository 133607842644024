import { httpSingleton } from '@/lib/http-client';
import { AccountInviteModel } from '@/lib/models';

interface AuthenticateResponseBody {
	token: string;
}

interface CreateInviteResponseBody {
	inviteToken: string;
}

interface AccountInviteResponseBody {
	accountId: string;
	accountStatusId: string;
	clientId: string;
	emailAddress: string;
	location: string | null;
	mobileNumber: string | null;
	name: string;
	timeZone: string;
	title: string | null;
}

interface GetInviteResponseBody extends AccountInviteModel {}

export const publicService = {
	authenticate(data: { emailAddress: string }) {
		return httpSingleton.orchestrateRequest<AuthenticateResponseBody>({
			method: 'POST',
			url: `/advisory-service/api/v1/public/authenticate`,
			data,
		});
	},
	createInvite(data: { name: string; emailAddress: string }) {
		return httpSingleton.orchestrateRequest<CreateInviteResponseBody>({
			method: 'POST',
			url: `/advisory-service/api/v1/public/create-invite`,
			data,
		});
	},
	getInvite(inviteId: string) {
		return httpSingleton.orchestrateRequest<GetInviteResponseBody>({
			method: 'GET',
			url: `/advisory-service/api/v1/public/account-invite/${inviteId}`,
		});
	},
	accountInvite(inviteId: string) {
		return httpSingleton.orchestrateRequest<AccountInviteResponseBody>({
			method: 'POST',
			url: `/advisory-service/api/v1/public/account-invite/${inviteId}`,
		});
	},
	getSystemStatus() {
		return httpSingleton.orchestrateRequest<{
			version: string;
			status: string;
			date: string;
		}>({
			method: 'GET',
			url: '/advisory-service/api/v1/public/system/status',
		});
	},
	getError() {
		return httpSingleton.orchestrateRequest<any>({
			method: 'GET',
			url: '/advisory-service/api/v1/public/system/error',
		});
	},
};
