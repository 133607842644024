import Color from 'color';

export const boxShadow = {
	boxShadow: '0 1px 2px 0 rgba(91,103,112,0.1), 0 2px 5px 0 rgba(91,103,112,0.1)',
};

export const focus = (color: string) => {
	return {
		outline: 'none',
		boxShadow: `0 0 0 0.2rem ${Color(color)
			.alpha(0.32)
			.rgb()}`,
	};
};
