import { httpSingleton } from '@/lib/http-client';
import {
	AccountModel,
	DeliverableModel,
	FormOption,
	PaginatedResponseModel,
	UnmatchedAccountModel,
	PresignedUploadModel,
	DOCUMENT_UPLOAD_STATUS_ID,
} from '@/lib/models';
import { constructUrl } from '@/lib/utils';

interface GetDeliverableFiltersResponse {
	clients: FormOption[];
	deliverableType: FormOption[];
	programs: FormOption[];
	sortFields: FormOption[];
}

interface GetDeliverablesFormResponse {
	createClientUrl: string;
	createDocumentUrl: string;
	deliverableType: FormOption[];
	programs: FormOption[];
	topics: FormOption[];
}

interface PostDeliverableRequest {
	contacts: {
		contactId: string;
		deliveryDate: string;
	}[];
	deliverableTypeId: string;
	deliveryDate: string;
	description: string;
	documentIds: string[];
	deliverableDocumentUploadedIds: string[];
	eventIds: string[];
	programIds: string[];
	title: string;
	topicId: string;
}

interface ImportContactsFromFileResponse {
	matchedAccounts: AccountModel[];
	unmatchedContacts: UnmatchedAccountModel[];
}

interface GetPresignedUploadRequestBody {
	deliverableId: string;
	contentType: string;
	title: string;
}

interface UpdateDeliverableDocumentStatusRequestBody {
	documentUploadStatusId: DOCUMENT_UPLOAD_STATUS_ID;
	deliverableId: string;
	deliverableDocumentUploadedId: string;
}

export const deliverablesService = {
	getDeliverables(queryParameters?: {
		clientId?: string;
		contactLastName?: string;
		deliverableTypeId?: string;
		emailAddress?: string;
		fromDate?: string;
		page?: string;
		programIds?: string;
		query?: string;
		size?: string;
		sortDirection?: string;
		sortField?: string;
		toDate?: string;
	}) {
		return httpSingleton.orchestrateRequest<
			PaginatedResponseModel & {
				deliverables: DeliverableModel[];
			}
		>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/deliverable', queryParameters),
		});
	},
	getDeliverableFilters() {
		return httpSingleton.orchestrateRequest<GetDeliverableFiltersResponse>({
			method: 'GET',
			url: '/advisory-service/api/v1/deliverable-filter',
		});
	},
	getDeliverablesForm(queryParameters?: {
		deliverableId?: string;
	}) {
		return httpSingleton.orchestrateRequest<GetDeliverablesFormResponse>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/deliverable-form', queryParameters)
		});
	},
	importContactsFromFile(file: File) {
		const data = new FormData();
		data.append('file', file, file.name);
		return httpSingleton.orchestrateRequest<ImportContactsFromFileResponse>({
			method: 'POST',
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			url: '/advisory-service/api/v1/deliverable/contacts',
			data,
		});
	},
	getAccounts(data: { query?: string; page?: string; size?: string; accountsToIgnore?: string[] }) {
		return httpSingleton.orchestrateRequest<
			PaginatedResponseModel & {
				accounts: AccountModel[];
			}
		>({
			method: 'POST',
			url: '/advisory-service/api/v1/deliverable/accounts',
			data
		});
	},
	postDeliverable(data: PostDeliverableRequest) {
		return httpSingleton.orchestrateRequest<DeliverableModel>({
			method: 'POST',
			url: '/advisory-service/api/v1/deliverable',
			data,
		});
	},
	getDeliverableById(deliverableId: string) {
		return httpSingleton.orchestrateRequest<DeliverableModel>({
			method: 'GET',
			url: `/advisory-service/api/v1/deliverable/${deliverableId}`,
		});
	},
	updateDeliverable(deliverableId: string, data: PostDeliverableRequest) {
		return httpSingleton.orchestrateRequest<DeliverableModel>({
			method: 'PUT',
			url: `/advisory-service/api/v1/deliverable/${deliverableId}`,
			data,
		});
	},
	deleteDeliverable(deliverableId: string) {
		return httpSingleton.orchestrateRequest<DeliverableModel>({
			method: 'DELETE',
			url: `/advisory-service/api/v1/deliverable/${deliverableId}`,
		});
	},
	getPresignedDocumentUpload(data: GetPresignedUploadRequestBody) {
		return httpSingleton.orchestrateRequest<{ upload: PresignedUploadModel }>({
			method: 'POST',
			url: `/advisory-service/api/v1/deliverable-document`,
			data,
		});
	},
	updateDeliverableDocumentUploadedStatus(data: UpdateDeliverableDocumentStatusRequestBody) {
		return httpSingleton.orchestrateRequest<any>({
			method: 'PUT',
			url: '/advisory-service/api/v1/deliverable-document/status',
			data,
		});
	},
};
