import { httpSingleton } from '@/lib/http-client';
import { RecentActivityModel } from '@/lib/models';
import { constructUrl } from '@/lib/utils';

export const activityService = {
	getRecentlyViewed(queryParameters?: { limit?: number }) {
		return httpSingleton.orchestrateRequest<{ recentActivities: RecentActivityModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/recent-activity/view', queryParameters),
		});
	},
};
