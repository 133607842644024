import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';
import { Avatar } from '@/components';

import { ReactComponent as LinkIcon } from '@/assets/icons/icon-link.svg';
import { ReactComponent as PaperclipIcon } from '@/assets/icons/icon-paperclip.svg';

const useStyles = createUseThemedStyles({
	commentPost: {
		display: 'flex',
	},
	textareaOuter: {
		flex: 1,
	},
});

interface CommentPostProps {
	className?: string;
}

export const CommentPost = ({ className }: CommentPostProps) => {
	const classes = useStyles();
	const [commentValue, setCommentValue] = useState('');

	function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
	}

	return (
		<div className={classNames(classes.commentPost, className)}>
			<Avatar className="mr-3" size={34} url="https://via.placeholder.com/34" />
			<Form onSubmit={handleFormSubmit} className={classes.textareaOuter}>
				<Form.Label>Add a Comment</Form.Label>
				<Form.Control
					className="mb-2"
					as="textarea"
					value={commentValue}
					onChange={(event) => {
						setCommentValue(event.currentTarget.value);
					}}
				/>
				<div className="d-flex align-items-center justify-content-between">
					<Button type="submit" disabled={!commentValue}>
						Post Comment
					</Button>
					<div className="d-flex align-items-center">
						<Button className="mr-3 d-flex align-items-center small" variant="link">
							<LinkIcon className="mr-1" />
							Attach a link
						</Button>
						<Button className="d-flex align-items-center small" variant="link">
							<PaperclipIcon className="mr-1" />
							Attach a file
						</Button>
					</div>
				</div>
			</Form>
		</div>
	);
};
