import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ThemeProvider as BootstrapThemeProvider } from 'react-bootstrap';
import { createBrowserHistory } from 'history';

import {
	AccountProvider,
	AlertProvider,
	AnalyticsProvider,
	NavigationConfigProvider,
	ThemeProvider as MasterClientThemeProvider,
} from '@/contexts';

import config from '@/lib/config';
import { constructUrl } from '@/lib/utils';
import { accessTokenCookieAttributes, COOKIES } from '@/lib/models';
import { accountService, navigationService } from '@/lib/services';
import { Alert, AsyncPage, Footer, PrivateRoute } from '@/components';

import { prefixes } from '@/jss/bootstrap';
import { useGlobalStyles } from '@/jss/hooks/use-global-styles';
import { useThemedBootstrapStyles } from '@/jss/hooks/use-themed-bootstrap-styles';

import { routes } from '@/routes';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-datepicker/dist/react-datepicker.css';

import { useAccount, useAnalytics, useNavigationConfig } from '@/hooks';
import { ErrorConfig } from '@/lib/http-client';

import * as Sentry from '@sentry/react';

// Wrapped by <Router>, allows use of 'react-router-dom' hooks
const RoutedApp = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const isSignInRoute = !!useRouteMatch({ path: '/sign-in' });
	const { setAccount } = useAccount();
	const { setNavigationConfig } = useNavigationConfig();
	const analytics = useAnalytics();
	const appIsInitialized = useRef(false);

	const fetchData = useCallback(async () => {
		try {
			if (appIsInitialized.current) {
				return;
			}

			const path = window.location.pathname;
			const searchParams = new URLSearchParams(window.location.search);
			const accessTokenFromQuery = searchParams.get('X-Hackett-Access-Token');

			if (accessTokenFromQuery) {
				Cookies.set(COOKIES.ACCESS_TOKEN, accessTokenFromQuery, accessTokenCookieAttributes);
				searchParams.delete('X-Hackett-Access-Token');

				try {
					await accountService.trackSignIn().fetch();
				} catch (_trackingError) {
					// Fail silently, allow app to load even if tracking call is unsuccessful.
				}
			}

			const accessTokenFromCookie = Cookies.get(COOKIES.ACCESS_TOKEN);
			const targetUrl = constructUrl(path, {}, searchParams.toString());

			if (!accessTokenFromCookie && !isSignInRoute) {
				Cookies.set(COOKIES.TARGET_URL, targetUrl, {
					sameSite: 'strict',
				});

				if (config.HACKETT_ADVISORY_USE_REAL_AUTH === 'true') {
					window.location.href = constructUrl(config.HACKETT_ADVISORY_AUTH_UI_URL, {
						redirectUrl: encodeURIComponent(window.location.href),
						platform: 'hackett-connect',
					});
				} else {
					history.replace('/sign-in');
				}
				return;
			}

			if (accessTokenFromCookie) {
				const [accountResponse, navigationResponse] = await Promise.all([
					accountService.getCurrentAccount().fetch(),
					navigationService.getNavigationItems().fetch(),
				]);
				Sentry.setUser({ email: accountResponse.account.emailAddress });
				setAccount(accountResponse.account);
				setNavigationConfig(navigationResponse);

				if (!accountResponse.account.hasAdvisoryProfile) {
					history.replace('/complete-profile');

					// Force user to complete their profile
					appIsInitialized.current = true;
					return;
				}
			}

			if (accessTokenFromQuery) {
				history.replace(targetUrl);
			}

			appIsInitialized.current = true;
		} catch (error) {
			// Do not throw if the error status is 503.
			// If an error is thrown here, The <AsyncPage/> below would prevent this component's children from rendering.
			// That means the <Maintenance/> component could never show if a 503 occurs during initialization.
			// The <Maintenance/> component is tied to the "/maintenance" route, and all routes are technically a chilren of this component
			if ((error as ErrorConfig)?.status === 503) {
				return;
			}
			throw error;
		}
	}, [history, isSignInRoute, setAccount, setNavigationConfig]);

	const setTrackingUrl = useCallback(() => {
		// Remove any sensative information from the url
		// (Just the possible accessToken for now).
		const searchParams = new URLSearchParams(search);
		searchParams.delete('X-Hackett-Access-Token');

		// Reassemble the url before sending pageView event.
		const searchParamsLength = Array.from(searchParams).length;
		return searchParamsLength > 0 ? constructUrl(pathname, {}, searchParams.toString()) : pathname;
	}, [search, pathname]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		if (typeof Cookies.get('cookieyes-analytics') === 'undefined') {
			analytics.cookieListener(setTrackingUrl());
		}
		if (Cookies.get('cookieyes-analytics') === 'yes') {
			analytics.initialize();
			// analytics.pageView(setTrackingUrl());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<AsyncPage fetchData={fetchData}>
			<Alert />

			<Switch>
				{routes.map((route, index) => {
					return <Route key={index} path={route.path} exact={route.exact} children={<route.header />} />;
				})}
			</Switch>

			<Switch>
				{routes.map((route, index) => {
					return React.createElement(route.private ? PrivateRoute : Route, {
						key: index,
						path: route.path,
						exact: route.exact,
						children: <route.main />,
					});
				})}
			</Switch>
			<Footer />
		</AsyncPage>
	);
};

// Wrapped by various <ThemeProviders>, allows use of 'jss' hooks
const ThemedApp = () => {
	useGlobalStyles();
	useThemedBootstrapStyles();

	return (
		<Router>
			<RoutedApp />
		</Router>
	);
};

// Exported App, not wrapped by anything, can add various context providers here
const App = () => {
	return (
		<MasterClientThemeProvider>
			<BootstrapThemeProvider prefixes={prefixes}>
				<AccountProvider>
					<NavigationConfigProvider>
						<AlertProvider>
							<AnalyticsProvider>
								<ThemedApp />
							</AnalyticsProvider>
						</AlertProvider>
					</NavigationConfigProvider>
				</AccountProvider>
			</BootstrapThemeProvider>
		</MasterClientThemeProvider>
	);
};

Sentry.init({
	dsn: config.SENTRY_DSN,
	release: __VERSION__,
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV5Instrumentation(createBrowserHistory()),
		}),
		// DO NOT USE, causes performance slow down
		// new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default App;
