import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { constructUrl } from '@/lib/utils';
import { AdvisorModel, ProgramModel } from '@/lib/models';
import { accountService, advisoryTeamService } from '@/lib/services';
import { useAccount, useNavigationConfig, useQuery } from '@/hooks';
import { Advisor, AsyncPage, LinkBox, Select } from '@/components';

export const AdvisoryTeam = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const { account } = useAccount();
	const query = useQuery();
	const programId = useMemo(() => query.get('programId'), [query]);
	const { navigationConfig } = useNavigationConfig();

	const [programOptions, setProgramOptions] = useState<ProgramModel[]>([]);
	const [advisors, setAdvisors] = useState<AdvisorModel[]>([]);

	const fetchPrograms = useCallback(async () => {
		if (!account) {
			throw new Error('account is undefined');
		}

		const response = await accountService.getPrograms(account.accountId, { activeOnly: true }).fetch();
		setProgramOptions(response.programs);
	}, [account]);

	const fetchData = useCallback(async () => {
		const response = await advisoryTeamService
			.getAdvisoryTeam({
				...(programId && { programId }),
			})
			.fetch();

		setAdvisors(response.advisors);
	}, [programId]);

	const handleProgramSelectChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		history.push(constructUrl(pathname, { programId: currentTarget.value }, search));
	};

	return (
		<Container className="py-13">
			<Helmet>
				<title>Hackett Connect - Advisory Team</title>
			</Helmet>
			<Row className="mb-6">
				<Col>
					<h1 className="mb-0 text-primary">Meet Your Team</h1>
				</Col>
			</Row>
			<Row>
				<Col xs={8}>
					<AsyncPage fetchData={fetchData}>
						{advisors.map((advisor, index) => {
							const isLast = advisors.length - 1 === index;

							return (
								<React.Fragment key={advisor.advisorId}>
									<Advisor
										avatarUrl={advisor.imageUrl}
										jobTitle={advisor.role}
										name={advisor.name}
										specialties={advisor.position}
										bio={advisor.description}
										bookingsUrl={
											navigationConfig?.allowAccessToInquiries ? advisor.bookingsUrl : undefined
										}
									/>
									{!isLast && <hr className="my-10" />}
								</React.Fragment>
							);
						})}
					</AsyncPage>
				</Col>
				<Col xs={4}>
					<Card className="mb-2">
						<Card.Body>
							<div className="mb-4 d-flex align-items-center justify-content-between">
								<Card.Title className="mb-0">Filters</Card.Title>
							</div>
							<AsyncPage fetchData={fetchPrograms}>
								<Form.Group>
									<Form.Label>Program</Form.Label>
									<Select value={programId ?? ''} onChange={handleProgramSelectChange}>
										<option value="">All Programs</option>;
										{programOptions.map((po) => {
											return (
												<option key={po.programId} value={po.programId}>
													{po.name}
												</option>
											);
										})}
									</Select>
								</Form.Group>
							</AsyncPage>
						</Card.Body>
					</Card>
					<LinkBox
						sections={[
							{
								title: "Can't find what you're looking for?",
								links: [{ title: 'Contact Hackett', to: '/contact', showIcon: true }],
							},
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
};
