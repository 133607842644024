import React from 'react';
import { Link } from 'react-router-dom';

import { createUseThemedStyles } from '@/hooks';
import { ReactComponent as ChevronIcon } from '@/assets/icons/icon-chevron-right.svg';

const useStyles = createUseThemedStyles((theme) => ({
	recentlyViewed: {
		width: '100%',
		display: 'flex',
	},
	numberOuter: {
		width: 40,
		flexShrink: 0,
		marginRight: 16,
		'& h2': {
			top: -9,
			position: 'relative',
		},
	},
	infoOuter: {
		flex: 1,
	},
	link: {
		color: theme.colors.gray800,
		...theme.fonts.openSansBold,
	},
	chevronIcon: {
		marginTop: -1,
		color: theme.colors.primary,
	},
}));

interface DashboardRecentlyViewedProps {
	number?: string;
	type: string;
	title: string;
	to: string;
}

export const DashboardRecentlyViewed = ({ number, type, title, to }: DashboardRecentlyViewedProps) => {
	const classes = useStyles();

	return (
		<div className={classes.recentlyViewed}>
			{number && (
				<div className={classes.numberOuter}>
					<h2 className="mb-0 text-gray-400">{number}</h2>
				</div>
			)}
			<div className={classes.infoOuter}>
				<h4 className="mb-2 text-gray-700">{type}</h4>
				<p className="mb-0">
					<Link className={classes.link} to={to}>
						{title} <ChevronIcon className={classes.chevronIcon} />
					</Link>
				</p>
			</div>
		</div>
	);
};
