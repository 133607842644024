import { useContext } from 'react';
import { AnalyticsContext } from '@/contexts';

export const useAnalytics = () => {
	const analyticsContext = useContext(AnalyticsContext);

	if (!analyticsContext) {
		throw new Error(`useAnalytics used outside its provider`);
	}

	return analyticsContext;
};
