import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';
import { boxShadow } from '@/jss';
import { ReactComponent as FileIcon } from '@/assets/icons/icon-file.svg';
import { ReactComponent as ClockIcon } from '@/assets/icons/icon-clock.svg';
import { ReactComponent as StarIcon } from '@/assets/icons/icon-star.svg';

const useStyles = createUseThemedStyles((theme) => ({
	cardOuter: {
		height: '100%',
		display: 'flex',
		borderRadius: 2,
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.white,
		...boxShadow,
	},
	card: ({ smaller }: { smaller?: boolean }) => ({
		padding: smaller ? '2.4rem 4.0rem' : '4.0rem',
	}),
	fileIcon: {
		width: 28,
		height: 28,
		opacity: 0.5,
		marginRight: 10,
	},
	callToActionOuter: ({ smaller }: { smaller?: boolean }) => ({
		display: 'flex',
		alignItems: 'center',
		padding: smaller ? '0.0rem 4.0rem 4.0rem' : '2.0rem 4.0rem',
		borderBottomLeftRadius: 2,
		borderBottomRightRadius: 2,
		color: smaller ? theme.colors.primary : theme.colors.white,
		backgroundColor: smaller ? 'transparent' : theme.colors.primary,
		'&-primary': {
			padding: '2.0rem 4.0rem',
			color: smaller ? theme.colors.primary : theme.colors.white,
			backgroundColor: smaller ? 'transparent' : theme.colors.primary,
		},
		'&-secondary': {
			color: smaller ? theme.colors.secondary : theme.colors.white,
			backgroundColor: smaller ? 'transparent' : theme.colors.secondary,
		},
		'&-success': {
			color: smaller ? theme.colors.success : theme.colors.white,
			backgroundColor: smaller ? 'transparent' : theme.colors.success,
		},
		'&-warning': {
			color: smaller ? theme.colors.warning : theme.colors.white,
			backgroundColor: smaller ? 'transparent' : theme.colors.warning,
		},
		'&-danger': {
			color: smaller ? theme.colors.danger : theme.colors.white,
			backgroundColor: smaller ? 'transparent' : theme.colors.danger,
		},
		'&-info': {
			color: smaller ? theme.colors.info : theme.colors.white,
			backgroundColor: smaller ? 'transparent' : theme.colors.info,
		},
	}),
}));

interface FeatureCardProps {
	smaller?: boolean;
	titleSize?: 'sm' | 'lg';
	hideFileIcon?: boolean;
	category: string;
	type?: string;
	to: string;
	title: string;
	description?: string;
	buttonTitle?: string;
	callToActionVariant?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info';
	callToActionIcon?: 'clock' | 'star';
	callToActionText?: string;
}

export const FeatureCard = ({
	smaller,
	titleSize = 'lg',
	hideFileIcon,
	category,
	type,
	to,
	title,
	description,
	buttonTitle,
	callToActionVariant,
	callToActionIcon,
	callToActionText,
}: FeatureCardProps) => {
	const history = useHistory();
	const classes = useStyles({ smaller });

	return (
		<div className={classes.cardOuter}>
			<div className={classes.card}>
				<div className={`${smaller ? 'mb-3' : 'mb-6'} d-flex align-items-center text-primary`}>
					{!hideFileIcon && <FileIcon className={classes.fileIcon} />}

					<h4 className="mb-0">
						{category} {type && <>&bull; {type}</>}
					</h4>
				</div>

				{titleSize === 'lg' && (
					<h3 className={description ? 'mb-3' : 'mb-0'}>
						<Link className="text-black" to={to}>
							{title}
						</Link>
					</h3>
				)}
				{titleSize === 'sm' && (
					<p className={description ? 'mb-3' : 'mb-0'}>
						<strong>
							<Link className="text-black" to={to}>
								{title}
							</Link>
						</strong>
					</p>
				)}

				{description && <p className={classNames('small', buttonTitle ? 'mb-6' : 'mb-0')}>{description}</p>}

				{buttonTitle && (
					<div className="text-left">
						<Button
							size="sm"
							variant="outline-primary"
							onClick={() => {
								history.push(to);
							}}
						>
							{buttonTitle}
						</Button>
					</div>
				)}
			</div>
			{callToActionText && (
				<div
					className={classNames(
						classes.callToActionOuter,
						callToActionVariant ? `${[classes.callToActionOuter]}-${callToActionVariant}` : ''
					)}
				>
					{callToActionIcon === 'clock' && <ClockIcon className="mr-3 flex-shrink-0" />}
					{callToActionIcon === 'star' && <StarIcon className="mr-3 flex-shrink-0" />}
					<h4 className="mb-0">{callToActionText}</h4>
				</div>
			)}
		</div>
	);
};
