import { v4 as uuid } from 'uuid';
import Cookies from 'js-cookie';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import * as Sentry from '@sentry/react';

import { COOKIES } from '@/lib/models';
import { ERROR_CODES, errors, ErrorConfig, ApiError } from '@/lib/http-client/errors';

export type HttpConfig = {
	baseUrl?: string;
	defaultHeaders?: Record<string, string | number | boolean>;
	tokenHeaderKey?: string;
	tokenHeaderType?: string;
	getToken?(): Promise<string | undefined>;
};

export type OrchestratedRequest<T = undefined> = {
	requestId: string;
	requestComplete: boolean;
	source: CancelTokenSource;
	config: AxiosRequestConfig;
	fetch(): Promise<T>;
	abort(): void;
};

export class HttpClient {
	_baseUrl: string;
	_headers: any;
	_requests: Record<string, OrchestratedRequest<any>>;
	_axiosInstance: AxiosInstance;

	constructor(httpConfig: HttpConfig) {
		this._baseUrl = httpConfig.baseUrl || '';
		this._headers = httpConfig.defaultHeaders || {};
		this._requests = {};

		this._axiosInstance = axios.create();
		this._axiosInstance.interceptors.request.use(
			async (options) => {
				if (httpConfig.tokenHeaderKey && httpConfig.getToken) {
					const token = await httpConfig.getToken();

					if (token) {
						options.headers[httpConfig.tokenHeaderKey] = `${
							httpConfig.tokenHeaderType ? `${httpConfig.tokenHeaderType} ` : ''
						}${token}`;
					}
				}
				return options;
			},
			(error) => {
				console.error('Error obtaining accessToken:', error);
			}
		);
	}

	abortRequest(requestId: string) {
		const request = this._requests[requestId];

		if (request) {
			request.abort();
		}
	}

	async _fetch(config: AxiosRequestConfig) {
		try {
			const response: AxiosResponse = await this._axiosInstance(config);
			return response;
		} catch (error) {
			const maintenanceTargetUrl = Cookies.get(COOKIES.MAINTENANCE_TARGET_URL);

			Sentry.addBreadcrumb({ message: (error as AxiosError).message });
			Sentry.addBreadcrumb({ message: 'maintenanceTargetUrl set to ' + maintenanceTargetUrl });

			if (axios.isCancel(error)) {
				throw errors[ERROR_CODES.REQUEST_ABORTED](error as AxiosError);
			} else if ((error as AxiosError).response === undefined) {
				const systemStatus = await this.getSystemStatus();

				if (systemStatus === 503) {
					Object.values(this._requests).forEach((request) => {
						request.abort();
					});

					if (!maintenanceTargetUrl && window.location.pathname !== '/maintenance') {
						Cookies.set(COOKIES.MAINTENANCE_TARGET_URL, window.location.pathname);
					}

					Sentry.addBreadcrumb({ message: 'System status was ' + systemStatus });
					Sentry.captureMessage('Maintenance page redirect');

					throw errors[ERROR_CODES.SERVICE_UNAVAILABLE](error as AxiosError);
				} else {
					throw this._getFormattedError(error as AxiosError);
				}
			} else if ((error as AxiosError).response?.status === 503) {
				Object.values(this._requests).forEach((request) => {
					request.abort();
				});

				if (!maintenanceTargetUrl && window.location.pathname !== '/maintenance') {
					Cookies.set(COOKIES.MAINTENANCE_TARGET_URL, window.location.pathname);
				}

				Sentry.addBreadcrumb({ message: 'System status not checked' });
				Sentry.captureMessage('Maintenance page redirect');

				throw errors[ERROR_CODES.SERVICE_UNAVAILABLE](error as AxiosError);
			} else {
				const formattedError = this._getFormattedError(error as AxiosError);

				if (
					formattedError.code === 'AUTHENTICATION_REQUIRED' ||
					formattedError.code === 'NotAuthorizedException' ||
					formattedError.status === 401 ||
					formattedError.status === 403
				) {
					Object.values(this._requests).forEach((request) => {
						request.abort();
					});
				}

				throw formattedError;
			}
		}
	}

	async getSystemStatus() {
		try {
			const response = await fetch(`${this._baseUrl}advisory-service/api/v1/public/system/status`);

			if (!response || !response.status || response.status === 503 || response.status === 0) {
				if (!response) {
					Sentry.addBreadcrumb({ message: 'Responwse was empty', type: 'getSystemStatus' });
					return 400;
				} else if (!response.status) {
					Sentry.addBreadcrumb({ message: 'Response status was empty', type: 'getSystemStatus' });
					return 400;
				} else if (response.status === 503) {
					Sentry.addBreadcrumb({ message: 'Response was 503', type: 'getSystemStatus' });
					return 503;
				} else if (response.status === 0) {
					Sentry.addBreadcrumb({ message: 'Response was 0', type: 'getSystemStatus' });
					return 400;
				}
			}

			return response.status;
		} catch (error) {
			Sentry.addBreadcrumb({ message: 'getSystemStatus fetch error caught', type: 'getSystemStatus' });
			Sentry.addBreadcrumb({ message: JSON.stringify(error), type: 'getSystemStatus' });
			return 400;
		}
	}

	_getFormattedError(error: AxiosError): ErrorConfig {
		const errorFromApi: ApiError = error?.response?.data;

		if (errorFromApi) {
			const formattedApiError: ErrorConfig = {
				code: errorFromApi.code,
				status: error.response?.status,
				message: errorFromApi.message,
				apiError: errorFromApi,
				axiosError: error,
			};

			return formattedApiError;
		}

		return errors[ERROR_CODES.GENERIC](error);
	}

	orchestrateRequest<T>(requestConfig: AxiosRequestConfig) {
		const orchestratedRequest: OrchestratedRequest<T> = {
			requestId: uuid(),
			requestComplete: false,
		} as any;

		orchestratedRequest.fetch = async () => {
			const source = axios.CancelToken.source();

			orchestratedRequest.requestComplete = false;
			orchestratedRequest.source = source;
			orchestratedRequest.config = {
				...requestConfig,
				baseURL: this._baseUrl,
				headers: {
					...this._headers,
					...requestConfig.headers,
				},
				cancelToken: source.token,
				data: requestConfig.data ?? {},
			};

			this._requests[orchestratedRequest.requestId] = orchestratedRequest;

			const { data } = await this._fetch(orchestratedRequest.config);

			orchestratedRequest.requestComplete = true;
			delete this._requests[orchestratedRequest.requestId];

			return data as T;
		};

		orchestratedRequest.abort = () => {
			if (orchestratedRequest.requestComplete) return;
			if (!orchestratedRequest.source) return;

			orchestratedRequest.source.cancel();

			orchestratedRequest.requestComplete = true;
			delete this._requests[orchestratedRequest.requestId];
		};

		return orchestratedRequest;
	}
}
