import { format, parseISO } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';

import { publicService } from '@/lib/services';
import { useHandleError } from '@/hooks';
import { AsyncPage } from '@/components';
import { Helmet } from 'react-helmet';

export const Version = () => {
	const handleError = useHandleError();

	const [date, setDate] = useState('');
	const [status, setStatus] = useState('');
	const [version, setVersion] = useState('');

	const fetchData = useCallback(async () => {
		const response = await publicService.getSystemStatus().fetch();

		setDate(format(new Date(response.date), 'MM/dd/yyyy h:mm:ss a'));
		setStatus(response.status);
		setVersion(response.version);
	}, []);

	return (
		<AsyncPage fetchData={fetchData}>
			<Helmet>
				<title>Hackett Connect - Version</title>
			</Helmet>
			<Container className="py-15">
				<Row className="mb-4">
					<Col>
						<Card>
							<Card.Header>
								<Card.Title>UI Status</Card.Title>
							</Card.Header>
							<Card.Body>
								<Form.Group className="mb-5">
									<Form.Label>Date</Form.Label>
									<p>{format(parseISO(__DATE_TIME__), 'MM/dd/yyyy h:mm:ss a')}</p>
								</Form.Group>
								<Form.Group className="mb-5">
									<Form.Label>Version</Form.Label>
									<p>{__VERSION__}</p>
								</Form.Group>
								<Form.Group>
									<Button
										onClick={() => {
											throw new Error('Test Error');
										}}
									>
										Throw Test Error
									</Button>
								</Form.Group>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card>
							<Card.Header>
								<Card.Title>System Status</Card.Title>
							</Card.Header>
							<Card.Body>
								<Form.Group className="mb-5">
									<Form.Label>Date</Form.Label>
									<p>{date}</p>
								</Form.Group>
								<Form.Group className="mb-5">
									<Form.Label>Status</Form.Label>
									<p>{status}</p>
								</Form.Group>
								<Form.Group className="mb-5">
									<Form.Label>Version</Form.Label>
									<p>{version}</p>
								</Form.Group>
								<Form.Group>
									<Button
										onClick={async () => {
											try {
												await publicService.getError().fetch();
											} catch (error) {
												handleError(error);
											}
										}}
									>
										Throw Test Error
									</Button>
								</Form.Group>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
