import { httpSingleton } from '@/lib/http-client';
import { constructUrl } from '@/lib/utils';
import { AdvisorModel } from '@/lib/models';

export const advisoryTeamService = {
	getAdvisoryTeam(queryParameters?: { count?: number; programId?: string }) {
		return httpSingleton.orchestrateRequest<{ advisors: AdvisorModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/advisory-team', queryParameters),
		});
	},
};
