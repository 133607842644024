function fallbackCopyTextToClipboard(text: string, successCallback?: () => void, errorCallback?: (error: any) => void) {
	const tempInput = document.createElement('input');

	tempInput.value = text;

	document.body.appendChild(tempInput);
	tempInput.select();

	try {
		// document.execCommand is depricated,
		// but not all browsers support navigator.clipboard,
		// so we need this fallback
		document.execCommand('copy');

		if (successCallback) {
			successCallback();
		}
	} catch (error) {
		if (errorCallback) {
			errorCallback(error);
		}
	}

	document.body.removeChild(tempInput);
}

export function copyTextToClipboard(text: string, successCallback?: () => void, errorCallback?: (error: any) => void) {
	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(text, successCallback, errorCallback);
		return;
	}

	navigator.clipboard.writeText(text).then(successCallback, errorCallback);
}

export async function copyHtmlTextToClipboard(
	htmlText: string,
	successCallback?: () => void,
	errorCallback?: (error: any) => void
) {
	const domParser = new DOMParser();
	const htmlDocument = domParser.parseFromString(htmlText, 'text/html');
	const plainText = htmlDocument.body.textContent ?? '';

	if (typeof ClipboardItem !== 'undefined') {
		try {
			const html = new Blob([htmlText], { type: 'text/html' });
			const text = new Blob([plainText], { type: 'text/plain' });
			const data = new ClipboardItem({ 'text/html': html, 'text/plain': text });

			await navigator.clipboard.write([data]);
			successCallback?.();
		} catch (error) {
			errorCallback?.(error);
		}
	} else {
		try {
			const cb = (e: ClipboardEvent) => {
				e.clipboardData?.setData('text/html', htmlText);
				e.clipboardData?.setData('text/plain', plainText);
				e.preventDefault();
			};

			document.addEventListener('copy', cb);
			document.execCommand('copy');
			document.removeEventListener('copy', cb);

			successCallback?.();
		} catch (error) {
			errorCallback?.(error);
		}
	}
}
