import { AccountModel, FormOption, ProgramModel } from '@/lib/models';

export const inquiryAttachment = {
	validExtensions: ['.pdf', '.zip', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'],
	maxFileSize_bytes: 104857600, // 100MB
	accept: {
		'application/pdf': ['.pdf'],
		'application/zip': ['.zip'],
		'application/msword': ['.doc'],
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		'application/vnd.ms-excel': ['.xls'],
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
		'application/vnd.ms-powerpoint': ['.ppt'],
		'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
	},
};

export enum INQUIRY_STATUS_ID {
	OPEN = 'OPEN',
	INQUIRY_RECEIVED = 'INQUIRY_RECEIVED',
	INQUIRY_CONFIRMED = 'INQUIRY_CONFIRMED',
	IN_PROGRESS = 'IN_PROGRESS',
	QC_REVIEW = 'QC_REVIEW',
	CLOSED = 'CLOSED',
	TOTAL = 'TOTAL',
}

export enum INQUIRY_TYPE_ID {
	PERFORMANCE_METRICS = 'PERFORMANCE_METRICS',
	PEER_TO_PEER = 'PEER_TO_PEER',
	CUSTOM_RESPONSE = 'CUSTOM_RESPONSE',
	RESEARCH = 'RESEARCH',
}

export interface InquiryModel {
	accountManagerAccount: AccountModel;
	advisorAccount: AccountModel;
	client: { clientId: string; name: string };
	createdDescription: string;
	documents: InquiryDocument[];
	grcAdvisorAccount: AccountModel;
	inquiryAccounts: AccountModel[];
	inquiryCcAccounts?: AccountModel[];
	inquiryDescription: string;
	inquiryListDescription: string;
	inquiryId: string;
	inquiryStatus: INQUIRY_STATUS_ID;
	inquiryStatusDescription: string;
	inquiryText: string;
	listInquiryText: string;
	inquiryTitle: string;
	notes?: string;
	shouldShowNotes: boolean;
	inquiryType: INQUIRY_TYPE_ID;
	inquiryTypeDescription: string;
	program: ProgramModel;
	requestingAccount: AccountModel;
	requiresImmediateResponse: boolean;
	responseSubmittedDescription: string;
	responseText: string;
	topics: string;
	topicsList: FormOption[];
	isRecipientNotificationRequired?: boolean;
	requesterNotes?: string;
	showAdvisorEmail: boolean;
	showAdvisorBookingsUrl: boolean;
	advisorBookingsUrl: string;

}

export interface InquiryDocument {
	documentId: string;
	downloadUrl: string;
	name: string;
}

export interface InquiryStat {
	accountId: string;
	inquiryStatuses: InquiryStatStatus[];
	name: string;
}

export interface InquiryStatStatus {
	statusId: INQUIRY_STATUS_ID;
	statusDescription: string;
	count: string;
}

export interface InquiryNotificationPreference {
	inquiryStatusId: INQUIRY_STATUS_ID;
	description: string;
	shouldNotify: boolean;
	type: string;
}
