import { cloneDeep } from 'lodash';
import React, { useState, useCallback } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

import { ProgramModel } from '@/lib/models';
import { navigationService, programSettingService } from '@/lib/services';
import { useHandleError, useAccount, useNavigationConfig } from '@/hooks';
import { AsyncPage, ProgramToggleItem } from '@/components';
import { Helmet } from 'react-helmet';

export const Programs = () => {
	const handleError = useHandleError();
	const { account } = useAccount();
	const { setNavigationConfig } = useNavigationConfig();

	const [programs, setPrograms] = useState<ProgramModel[]>([]);
	const [suppressProgramEmail, setSuppressProgramEmail] = useState<boolean>(false);

	const fetchData = useCallback(async () => {
		if (!account) {
			throw new Error('Account not found.');
		}

		const response = await programSettingService.getPreferences(account.accountId).fetch();
		setPrograms(response.programs);
		setSuppressProgramEmail(response.suppressProgramEmail);
	}, [account]);

	async function handleChange(
		programIndex: number,
		preferenceKey: 'allowAdvisoryDisplay' | 'allowEmail',
		isChecked: boolean
	) {
		const programsClone = cloneDeep(programs);
		programsClone[programIndex][preferenceKey] = isChecked;

		const accountCommPreferences = programsClone
			.filter((p) => p.allowUserPreferences)
			.map(({ programId, allowEmail, allowAdvisoryDisplay }) => {
				return {
					programId,
					allowEmails: allowEmail,
					allowAdvisoryDisplay,
				};
			});

		try {
			if (!account) {
				throw new Error('Account not found.');
			}

			const response = await programSettingService
				.setPreferences(account.accountId, { accountCommPreferences })
				.fetch();
			setPrograms(response.programs);

			const navigationResponse = await navigationService.getNavigationItems().fetch();
			setNavigationConfig(navigationResponse);
		} catch (error) {
			handleError(error);
		}
	}

	return (
		<AsyncPage fetchData={fetchData}>
			<Helmet>
				<title>Hackett Connect - Account - Programs</title>
			</Helmet>
			<Container className="pt-7 pb-16">
				<Row className="mb-10">
					<Col>
						<h1 className="mb-0 text-primary">Your Programs</h1>
					</Col>
				</Row>
				{programs.length > 0 ? (
					programs.map(({ allowUserPreferences, allowAdvisoryDisplay, allowEmail, name }, index) => {
						return allowUserPreferences ? (
							<section key={index} className="mb-11">
								<Row className="mb-3">
									<Col>
										<h3 className="mb-0">{name}</h3>
									</Col>
								</Row>
								<ProgramToggleItem
									title="Show this program's content in the Advisory Portal?"
									isChecked={allowAdvisoryDisplay}
									onChange={(event) => {
										handleChange(index, 'allowAdvisoryDisplay', event.currentTarget.checked);
									}}
								/>
								{!suppressProgramEmail ? (
									<ProgramToggleItem
										title="Receive email communication about this program?"
										isChecked={allowEmail}
										onChange={(event) => {
											handleChange(index, 'allowEmail', event.currentTarget.checked);
										}}
									/>
								) : (
									<></>
								)}
							</section>
						) : (
							<></>
						);
					})
				) : (
					<Row>
						<Col>
							<Card>
								<Card.Body>
									<p className="mb-0 bold w-100 text-center">No Programs</p>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				)}
			</Container>
		</AsyncPage>
	);
};
