import { TableColumns, TableHeaders } from '@/components/table/models';

export enum CONTACTS_TABLE_COLUMN_IDS {
	COMPANY = 'COMPANY',
	NAME = 'NAME',
	TITLE = 'TITLE',
	EMAIL = 'EMAIL',
	PHONE = 'PHONE',
	DELIVERED = 'DELIVERED',
	ACTIONS = 'ACTIONS',
}

export const contactsTableColumns: TableColumns<CONTACTS_TABLE_COLUMN_IDS> = {
	[CONTACTS_TABLE_COLUMN_IDS.COMPANY]: {
		columnId: CONTACTS_TABLE_COLUMN_IDS.COMPANY,
		columnTitle: 'Company',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[CONTACTS_TABLE_COLUMN_IDS.NAME]: {
		columnId: CONTACTS_TABLE_COLUMN_IDS.NAME,
		columnTitle: 'User Name',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[CONTACTS_TABLE_COLUMN_IDS.TITLE]: {
		columnId: CONTACTS_TABLE_COLUMN_IDS.TITLE,
		columnTitle: 'Title',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[CONTACTS_TABLE_COLUMN_IDS.EMAIL]: {
		columnId: CONTACTS_TABLE_COLUMN_IDS.EMAIL,
		columnTitle: 'Email',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[CONTACTS_TABLE_COLUMN_IDS.PHONE]: {
		columnId: CONTACTS_TABLE_COLUMN_IDS.PHONE,
		columnTitle: 'Phone',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[CONTACTS_TABLE_COLUMN_IDS.DELIVERED]: {
		columnId: CONTACTS_TABLE_COLUMN_IDS.DELIVERED,
		columnTitle: 'Delivered',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[CONTACTS_TABLE_COLUMN_IDS.ACTIONS]: {
		columnId: CONTACTS_TABLE_COLUMN_IDS.ACTIONS,
		columnTitle: 'Actions',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
};

export const contactsTableHeaders: TableHeaders<CONTACTS_TABLE_COLUMN_IDS> = {
	[CONTACTS_TABLE_COLUMN_IDS.COMPANY]: {
		tableHeaderId: CONTACTS_TABLE_COLUMN_IDS.COMPANY,
		title: 'Company',
		isSortable: true,
	},
	[CONTACTS_TABLE_COLUMN_IDS.NAME]: {
		tableHeaderId: CONTACTS_TABLE_COLUMN_IDS.NAME,
		title: 'User Name',
		isSortable: true,
	},
	[CONTACTS_TABLE_COLUMN_IDS.TITLE]: {
		tableHeaderId: CONTACTS_TABLE_COLUMN_IDS.TITLE,
		title: 'Title',
		isSortable: true,
	},
	[CONTACTS_TABLE_COLUMN_IDS.EMAIL]: {
		tableHeaderId: CONTACTS_TABLE_COLUMN_IDS.EMAIL,
		title: 'Email',
		isSortable: true,
	},
	[CONTACTS_TABLE_COLUMN_IDS.PHONE]: {
		tableHeaderId: CONTACTS_TABLE_COLUMN_IDS.PHONE,
		title: 'Phone',
		isSortable: false,
	},
	[CONTACTS_TABLE_COLUMN_IDS.DELIVERED]: {
		tableHeaderId: CONTACTS_TABLE_COLUMN_IDS.DELIVERED,
		title: 'Delivered',
		isSortable: false,
	},
	[CONTACTS_TABLE_COLUMN_IDS.ACTIONS]: {
		tableHeaderId: CONTACTS_TABLE_COLUMN_IDS.ACTIONS,
		title: 'Actions',
		isSortable: false,
	},
};
