import { cloneDeep } from 'lodash';
import React, { useState, useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { InquiryNotificationPreference } from '@/lib/models';
import { navigationService } from '@/lib/services';
import { useHandleError, useAccount, useNavigationConfig } from '@/hooks';
import { AsyncPage, ProgramToggleItem } from '@/components';
import { inquirySettingService } from '@/lib/services/inquiry-settings-service';
import { Helmet } from 'react-helmet';

export const Inquiries = () => {
	const handleError = useHandleError();
	const { account } = useAccount();
	const { setNavigationConfig } = useNavigationConfig();

	const [inquiryNotificationPreferences, setInquiryNotificationPreferences] = useState<
		InquiryNotificationPreference[]
	>([]);

	const fetchData = useCallback(async () => {
		if (!account) {
			throw new Error('Account not found.');
		}

		const response = await inquirySettingService.getPreferences(account.accountId).fetch();
		setInquiryNotificationPreferences(response.inquiryNotificationPreferences);
	}, [account]);

	async function handleChange(preferenceIndex: number, isChecked: boolean) {
		const preferencesClone = cloneDeep(inquiryNotificationPreferences);
		preferencesClone[preferenceIndex]['shouldNotify'] = isChecked;

		try {
			if (!account) {
				throw new Error('Account not found.');
			}

			const response = await inquirySettingService
				.setPreferences(account.accountId, { inquiryNotificationPreferences: preferencesClone })
				.fetch();
			setInquiryNotificationPreferences(response.inquiryNotificationPreferences);

			const navigationResponse = await navigationService.getNavigationItems().fetch();
			setNavigationConfig(navigationResponse);
		} catch (error) {
			handleError(error);
		}
	}

	function getSettingText(settingType: string, description: string): string {
		if (settingType === 'STATUS') {
			return `Receive email communications regarding ${description} status changes?`;
		} else if (settingType === 'EVENT') {
			return `Receive email notifications regarding ${description}?`;
		} else {
			return description;
		}
	}

	return (
		<AsyncPage fetchData={fetchData}>
			<Helmet>
				<title>Hackett Connect - Account - Inquiry Settings</title>
			</Helmet>
			<Container className="py-15">
				<Row className="mb-11">
					<Col>
						<h1 className="mb-0">Your Inquiry Notification Preferences</h1>
					</Col>
				</Row>
				<section className="mb-11">
					{inquiryNotificationPreferences.map((pref: InquiryNotificationPreference, index) => {
						return (
							<ProgramToggleItem
								title={getSettingText(pref.type, pref.description)}
								isChecked={pref.shouldNotify}
								onChange={(event) => {
									handleChange(index, event.currentTarget.checked);
								}}
							/>
						);
					})}
				</section>
			</Container>
		</AsyncPage>
	);
};
