import { AccountModel, AccountOptionModel, BannerMessage, FormOption, ProgramModel } from '@/lib/models';
import { authHttpSingleton, httpSingleton } from '@/lib/http-client';
import { constructUrl } from '@/lib/utils';

interface UpdateCurrentAccountRequestBody {
	name: string;
	title: string;
	userLevelTagId: string;
	userFunctionTagIds: string[];
	emailAddress?: string;
	bookingsUrl?: string;
	mobileNumber?: string;
	location?: string;
}

export const accountService = {
	getCurrentAccount() {
		return httpSingleton.orchestrateRequest<{ account: AccountModel }>({
			method: 'GET',
			url: `/advisory-service/api/v1/account`,
		});
	},
	updateCurrentAccount(id: string, data: UpdateCurrentAccountRequestBody) {
		return httpSingleton.orchestrateRequest<{ account: AccountModel }>({
			method: 'PUT',
			url: `/advisory-service/api/v1/account/${id}`,
			data,
		});
	},
	getAccountOptions(option: 'USER_LEVEL' | 'USER_FUNCTION') {
		return httpSingleton.orchestrateRequest<{ options: AccountOptionModel[] }>({
			method: 'GET',
			url: `/advisory-service/api/v1/account/options/${option}`,
		});
	},
	getPrograms(accountId: string, queryParameters?: { activeOnly?: boolean }) {
		return httpSingleton.orchestrateRequest<{ programs: ProgramModel[] }>({
			method: 'GET',
			url: constructUrl(`/advisory-service/api/v1/account/${accountId}/programs`, queryParameters),
		});
	},
	getAccountFilters() {
		return httpSingleton.orchestrateRequest<{ sortFields: FormOption[] }>({
			method: 'GET',
			url: '/advisory-service/api/v1/account-filter',
		});
	},
	getFreshdeskSupportUrl() {
		return httpSingleton.orchestrateRequest<{ freshdeskSupportUrl: string }>({
			method: 'GET',
			url: '/advisory-service/api/v1/account/freshdesk-support',
		});
	},
	getFreshdeskTicketsUrl() {
		return httpSingleton.orchestrateRequest<{ freshdeskSupportUrl: string }>({
			method: 'GET',
			url: '/advisory-service/api/v1/account/freshdesk-tickets',
		});
	},
	getBannerMessages() {
		return httpSingleton.orchestrateRequest<{ bannerMessages: BannerMessage[] }>({
			method: 'GET',
			url: '/advisory-service/api/v1/account/banner-messages',
		});
	},
	markBannerMessageRead(bannerMessageId: string) {
		return httpSingleton.orchestrateRequest<{ bannerMessages: BannerMessage[] }>({
			method: 'PUT',
			url: `/advisory-service/api/v1/account/banner-message/${bannerMessageId}`,
		});
	},
	signOut() {
		return authHttpSingleton.orchestrateRequest<{ bannerMessages: BannerMessage[] }>({
			method: 'POST',
			url: '/auth-service/api/v1/account/sign-out',
		});
	},
	trackSignIn() {
		return httpSingleton.orchestrateRequest<any>({
			method: 'PUT',
			url: '/advisory-service/api/v1/account/track-login',
		});
	},
};
