import { TableColumns, TableHeaders, TableRowConfig } from '@/components/table/models';

export enum USE_CASES_TABLE_COLUMN_IDS {
	USE_CASES = 'USE_CASES',
	NUMBER = 'NUMBER',
	FUNCTION = 'FUNCTION',
	BUSINESS_PROCESS = 'BUSINESS_PROCESS',
	AI_TECHNOLOGY = 'AI_TECHNOLOGY',
	VALUE_RATING = 'VALUE_RATING',
}

export const useCasesTableColumns: TableColumns<USE_CASES_TABLE_COLUMN_IDS> = {
	[USE_CASES_TABLE_COLUMN_IDS.USE_CASES]: {
		columnId: USE_CASES_TABLE_COLUMN_IDS.USE_CASES,
		columnTitle: 'Use Cases',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[USE_CASES_TABLE_COLUMN_IDS.NUMBER]: {
		columnId: USE_CASES_TABLE_COLUMN_IDS.NUMBER,
		columnTitle: 'Number',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
	[USE_CASES_TABLE_COLUMN_IDS.FUNCTION]: {
		columnId: USE_CASES_TABLE_COLUMN_IDS.FUNCTION,
		columnTitle: 'Function',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[USE_CASES_TABLE_COLUMN_IDS.BUSINESS_PROCESS]: {
		columnId: USE_CASES_TABLE_COLUMN_IDS.BUSINESS_PROCESS,
		columnTitle: 'Business Process',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[USE_CASES_TABLE_COLUMN_IDS.AI_TECHNOLOGY]: {
		columnId: USE_CASES_TABLE_COLUMN_IDS.AI_TECHNOLOGY,
		columnTitle: 'AI Technology',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[USE_CASES_TABLE_COLUMN_IDS.VALUE_RATING]: {
		columnId: USE_CASES_TABLE_COLUMN_IDS.VALUE_RATING,
		columnTitle: 'Value Rating',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
};

export const useCasesTableHeaders: TableHeaders<USE_CASES_TABLE_COLUMN_IDS> = {
	[USE_CASES_TABLE_COLUMN_IDS.USE_CASES]: {
		tableHeaderId: USE_CASES_TABLE_COLUMN_IDS.USE_CASES,
		title: 'AI Use Cases',
		isSortable: false,
	},
	[USE_CASES_TABLE_COLUMN_IDS.NUMBER]: {
		tableHeaderId: USE_CASES_TABLE_COLUMN_IDS.NUMBER,
		title: 'Number',
		isSortable: true,
	},
	[USE_CASES_TABLE_COLUMN_IDS.FUNCTION]: {
		tableHeaderId: USE_CASES_TABLE_COLUMN_IDS.FUNCTION,
		title: 'Function',
		isSortable: false,
	},
	[USE_CASES_TABLE_COLUMN_IDS.BUSINESS_PROCESS]: {
		tableHeaderId: USE_CASES_TABLE_COLUMN_IDS.BUSINESS_PROCESS,
		title: 'Business Process',
		isSortable: false,
	},
	[USE_CASES_TABLE_COLUMN_IDS.AI_TECHNOLOGY]: {
		tableHeaderId: USE_CASES_TABLE_COLUMN_IDS.AI_TECHNOLOGY,
		title: 'AI Technology',
		isSortable: false,
	},
	[USE_CASES_TABLE_COLUMN_IDS.VALUE_RATING]: {
		tableHeaderId: USE_CASES_TABLE_COLUMN_IDS.VALUE_RATING,
		title: 'Value Rating',
		isSortable: false,
	},
};

export const useCasesTableRows: TableRowConfig<{
	title: string;
	number: string;
	function: string;
	businessProcess: string;
	aiTechnology: string;
	valueRating: string;
}>[] = [
	{
		rowId: 'xxxx-xxxx-xxxx-xxxx-0',
		checked: false,
		expanded: false,
		columnData: {
			title: 'Title',
			number: 'Number',
			function: 'Function',
			businessProcess: 'BusinessProcess',
			aiTechnology: 'AiTechnology',
			valueRating: 'ValueRating',
		},
	},
];
