import React from 'react';
import { Link } from 'react-router-dom';

interface DeliverableListItemProps {
	type: string;
	to: string;
	title: string;
	programs: string;
	date: string;
}

export const DeliverableListItem = ({ type, to, title, programs, date }: DeliverableListItemProps) => {
	return (
		<div className="py-4 border-bottom">
			<h4 className="mb-2 d-flex align-items-center text-muted">{type}</h4>
			<h3 className="mb-2">
				<Link to={to}>{title}</Link>
			</h3>
			<p className="mb-4 text-muted">{programs}</p>
			<p className="mb-0 text-muted font-weight-semi-bold">{date}</p>
		</div>
	);
};
