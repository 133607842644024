import { v4 as uuid } from 'uuid';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { AiConversation, AiConversationSections, TempCitation, TempResponse } from '@/lib/models';
import { aiConversationService } from '@/lib/services';
import { createUseThemedStyles, useCopyTextToClipboard, useHandleError } from '@/hooks';
import { AiAnswerSources, AiRateModal, Loader, LoadingButton, Typewriter } from '@/components';
import { ReactComponent as RobotIcon } from '@/assets/icons/robot.svg';
import { ReactComponent as CommentIcon } from '@/assets/icons/icon-comment.svg';
import config from '@/lib/config';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';

interface UseStylesProps {
	isContained: boolean;
}

const useStyles = createUseThemedStyles((theme) => ({
	chatOuter: {
		height: 600,
		display: 'flex',
		borderRadius: 10,
		overflow: 'hidden',
		border: `1px solid ${theme.colors.gray300}`,
	},
	chatList: {
		width: '25%',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.colors.gray200,
		borderRight: `1px solid ${theme.colors.gray300}`,
	},
	chatListButtonOuter: {
		padding: '25px 25px 0',
	},
	chatListScroller: {
		flex: 1,
		overflowY: 'auto',
		padding: '25px 15px 10px',
		'& h6': {
			padding: '0 10px',
		},
	},
	chatListButton: {
		border: 0,
		width: '100%',
		borderRadius: 4,
		display: 'block',
		textAlign: 'left',
		...theme.fonts.xs,
		appearance: 'none',
		padding: '8px 10px',
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: theme.colors.gray100,
		},
		'&:active': {
			backgroundColor: theme.colors.gray400,
		},
		'&.active': {
			backgroundColor: theme.colors.gray300,
		},
	},
	chat: {
		width: '75%',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.colors.white,
	},
	chatScroller: {
		flex: 1,
		overflowY: 'scroll',
	},
	chatScrollerInner: {
		padding: 30,
		margin: '0 auto',
		maxWidth: ({ isContained }: UseStylesProps) => (isContained ? 720 : '100%'),
	},
	chatInputOuter: {
		width: '100%',
		maxWidth: 720,
		margin: '0 auto',
		padding: '0 30px 30px',
	},
	textareaOuter: {
		padding: 5,
		display: 'flex',
		borderRadius: 2,
		marginBottom: 10,
		alignItems: 'flex-end',
		border: `1px solid ${theme.colors.gray500}`,
		'&:focus-within': {
			border: `1px solid ${theme.colors.primary}`,
		},
		'& textarea.hackett-form__control': {
			flex: 1,
			border: 0,
			height: 39,
			boxShadow: 'none',
		},
	},
	sendButtonOuter: {
		flexShrink: 0,
	},
	chatContent: {
		marginBottom: 30,
		'&:last-child': {
			marginBottom: 0,
		},
	},
	chatQuestion: {
		padding: 15,
		borderRadius: 5,
		backgroundColor: theme.colors.gray200,
	},
	aiIconOuter: {
		width: 40,
		flexShrink: 0,
		marginRight: 20,
	},
	aiIcon: {
		width: '100%',
		borderRadius: '50%',
		position: 'relative',
		paddingBottom: '100%',
		backgroundColor: theme.colors.primary,
		'& > svg': {
			top: '50%',
			left: '50%',
			position: 'absolute',
			transform: 'translate(-50%, -50%)',
		},
	},
	typewriterOuter: {
		flex: 1,
	},
}));

enum CHAT_CONTENT_TYPE_ID {
	QUESTION = 'QUESTION',
	ANSWER = 'ANSWER',
	PAST_ANSWER = 'PAST_ANSWER',
}

interface ChatContent {
	conversationQueryId: string;
	chatContentId: string;
	chatContentTypeId: CHAT_CONTENT_TYPE_ID;
	content: string;
	sources: TempCitation[];
	followUpQuestions?: string[];
	rated: boolean;
}

export const AskHackettAi = () => {
	const [chatConfig, setChatConfig] = useState({
		isContained: true,
		shouldAnimate: true,
	});

	const classes = useStyles({
		isContained: chatConfig.isContained,
	});
	const handleError = useHandleError();
	const { copyHtmlToClipboard } = useCopyTextToClipboard();

	const chatScrollerOuterRef = useRef<HTMLDivElement>(null);
	const chatScrollerInnerRef = useRef<HTMLDivElement>(null);
	const chatResizeObserverRef = useRef<ResizeObserver>();
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const [conversationsLoading, setConversationsLoading] = useState(false);
	const [conversationSections, setConversationSections] = useState<AiConversationSections>();
	const [conversation, setConversation] = useState<AiConversation>();
	const [questionInputValue, setQuestionInputValue] = useState('');
	const [chatContent, setChatContent] = useState<ChatContent[]>([]);
	const [answerIsLoading, setAnswerIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [showRateModel, setShowRateModal] = useState(false);
	const [queryIdToRate, setQueryIdToRate] = useState<string>();

	const fetchPastConversations = useCallback(async () => {
		try {
			setConversationsLoading(true);
			const response = await aiConversationService.getPastConversations().fetch();
			setConversationSections(response.aiConversations);
		} catch (error) {
			handleError(error);
		} finally {
			setConversationsLoading(false);
		}
	}, [handleError]);

	useEffect(() => {
		fetchPastConversations();
	}, [fetchPastConversations]);

	const handleNewConversationButtonClick = async () => {
		await fetchPastConversations();
		setConversation(undefined);
		setQuestionInputValue('');
		setChatContent([]);
		setAnswerIsLoading(false);
		setIsDisabled(false);
		destroyResizeObserver();
	};

	const handlePastConversationButtonClick = async (conversation: AiConversation) => {
		await fetchPastConversations();
		setConversation(conversation);
		setQuestionInputValue('');
		setChatContent(
			conversation.conversationQueries.reduce((accumulator, currentValue) => {
				const parsedAiResponse: TempResponse = JSON.parse(currentValue.queryResponse);

				return [
					...accumulator,
					{
						conversationQueryId: currentValue.conversationQueryId,
						chatContentId: `${currentValue.conversationQueryId}-question`,
						chatContentTypeId: CHAT_CONTENT_TYPE_ID.QUESTION,
						content: currentValue.query,
						sources: [],
						followUpQuestions: [],
						rated: false,
					},
					{
						conversationQueryId: currentValue.conversationQueryId,
						chatContentId: `${currentValue.conversationQueryId}-answer`,
						chatContentTypeId: CHAT_CONTENT_TYPE_ID.PAST_ANSWER,
						content: parsedAiResponse.answer,
						sources: parsedAiResponse.citations,
						followUpQuestions: parsedAiResponse.followUpQuestionsSuggestions,
						rated: currentValue.hasRating,
					},
				];
			}, [] as ChatContent[])
		);
		setAnswerIsLoading(false);
		setIsDisabled(false);
		destroyResizeObserver();
	};

	useEffect(() => {
		snapToBottomOfChat();
	}, [chatContent]);

	const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setQuestionInputValue('');
		setChatContent((previousValue) => [
			...previousValue,
			{
				conversationQueryId: '',
				chatContentId: `${uuid()}-question`,
				chatContentTypeId: CHAT_CONTENT_TYPE_ID.QUESTION,
				content: questionInputValue,
				sources: [],
				followUpQuestions: [],
				rated: false,
			},
		]);
		setAnswerIsLoading(true);
		destroyResizeObserver();
		createNewResizeObserver();

		try {
			const { query } = await aiConversationService
				.sendQuery({
					query: questionInputValue,
					...(conversation && { aiConversationId: conversation.aiConversationId }),
				})
				.fetch();
			const { aiConversation } = await aiConversationService.getConversation(query.aiConversationId).fetch();
			const parsedAiResponse: TempResponse = JSON.parse(query.queryResponse);

			setConversation(aiConversation);
			setIsDisabled(true);
			setChatContent((previousValue) => [
				...previousValue,
				{
					conversationQueryId: query.conversationQueryId,
					chatContentId: `${query.conversationQueryId}-answer`,
					chatContentTypeId: CHAT_CONTENT_TYPE_ID.ANSWER,
					content: parsedAiResponse.answer,
					sources: parsedAiResponse.citations,
					followUpQuestions: parsedAiResponse.followUpQuestionsSuggestions,
					rated: query.hasRating,
				},
			]);
		} catch (error) {
			handleError(error);
		} finally {
			setAnswerIsLoading(false);
		}
	};

	const scrollToBottomOfChat = () => {
		if (!chatScrollerOuterRef.current || !chatScrollerInnerRef.current) {
			return;
		}

		chatScrollerOuterRef.current.scrollTo({
			top: chatScrollerInnerRef.current.scrollHeight,
			behavior: 'smooth',
		});
	};

	const snapToBottomOfChat = () => {
		if (!chatScrollerOuterRef.current || !chatScrollerInnerRef.current) {
			return;
		}

		chatScrollerOuterRef.current.scrollTo({
			top: chatScrollerInnerRef.current.scrollHeight,
			behavior: 'auto',
		});
	};

	const destroyResizeObserver = () => {
		if (!chatResizeObserverRef.current) {
			return;
		}

		chatResizeObserverRef.current.disconnect();
		chatResizeObserverRef.current = undefined;
	};

	const createNewResizeObserver = () => {
		chatResizeObserverRef.current = new ResizeObserver(scrollToBottomOfChat);
		if (chatScrollerInnerRef.current) {
			chatResizeObserverRef.current.observe(chatScrollerInnerRef.current);
		}
	};

	const handleTypewriterAnimationComplete = useCallback(() => {
		setIsDisabled(false);
		scrollToBottomOfChat();
		destroyResizeObserver();
	}, []);

	const handleCopyButtonClick = useCallback(
		(chatContent: ChatContent) => {
			copyHtmlToClipboard(chatContent.content);
		},
		[copyHtmlToClipboard]
	);

	const handleTextareaInput = () => {
		if (!textareaRef.current) {
			return;
		}

		const heightLimit = 88;

		textareaRef.current.style.height = '';
		textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, heightLimit)}px`;
	};

	const chatContentComponent = useMemo(
		() => ({
			[CHAT_CONTENT_TYPE_ID.QUESTION]: (context: ChatContent) => {
				return <div className={classes.chatQuestion}>{context.content}</div>;
			},
			[CHAT_CONTENT_TYPE_ID.ANSWER]: (context: ChatContent) => {
				return (
					<div className="d-flex">
						<div className={classes.aiIconOuter}>
							<div className={classes.aiIcon}>
								<RobotIcon className="text-white" />
							</div>
						</div>
						<div className={classes.typewriterOuter}>
							<Typewriter
								delayBetweenCharacters={chatConfig.shouldAnimate ? 10 : 0}
								htmlString={context.content}
								onAnimationComplete={handleTypewriterAnimationComplete}
							>
								{context.sources.length > 0 && (
									<AiAnswerSources className="mb-4" sources={context.sources} />
								)}
								<div
									className={classNames('d-flex justify-content-end', {
										'mb-2': (context.followUpQuestions ?? []).length > 0,
									})}
								>
									<Button
										size="sm"
										variant="outline-primary"
										className="d-flex align-items-center mr-2"
										onClick={() => handleCopyButtonClick(context)}
									>
										<CopyIcon />
									</Button>
									<Button
										size="sm"
										variant={context.rated ? 'primary' : 'outline-primary'}
										onClick={() => handleRateButtonClick(context)}
										disabled={context.rated}
									>
										{context.rated ? 'Rating Recieved' : 'Rate Answer'}
									</Button>
								</div>
								{(context.followUpQuestions ?? []).length > 0 && (
									<>
										<p className="mb-4">
											<strong>Recommended follow up questions:</strong>
										</p>
										{(context.followUpQuestions ?? []).map((q, qIndex) => (
											<div
												key={qIndex}
												className="d-flex align-items-center mb-2 py-2 px-3 bg-gray-100 border rounded"
												onClick={() => {
													setQuestionInputValue(q);
												}}
											>
												<p className="mb-0 small">{q}</p>
											</div>
										))}
									</>
								)}
							</Typewriter>
						</div>
					</div>
				);
			},
			[CHAT_CONTENT_TYPE_ID.PAST_ANSWER]: (context: ChatContent) => {
				return (
					<div className="d-flex">
						<div className={classes.aiIconOuter}>
							<div className={classes.aiIcon}>
								<RobotIcon className="text-white" />
							</div>
						</div>
						<div className={classes.typewriterOuter}>
							<Typewriter
								delayBetweenCharacters={0}
								htmlString={context.content}
								onAnimationComplete={handleTypewriterAnimationComplete}
							>
								{context.sources.length > 0 && (
									<AiAnswerSources className="mb-4" sources={context.sources} />
								)}
								<div
									className={classNames('d-flex justify-content-end', {
										'mb-2': (context.followUpQuestions ?? []).length > 0,
									})}
								>
									<Button
										size="sm"
										variant="outline-primary"
										className="d-flex align-items-center mr-2"
										onClick={() => handleCopyButtonClick(context)}
									>
										<CopyIcon />
									</Button>
									<Button
										size="sm"
										variant={context.rated ? 'primary' : 'outline-primary'}
										onClick={() => handleRateButtonClick(context)}
										disabled={context.rated}
									>
										{context.rated ? 'Rating Recieved' : 'Rate Answer'}
									</Button>
								</div>
								{(context.followUpQuestions ?? []).length > 0 && (
									<>
										<p className="mb-4">
											<strong>Recommended follow up questions:</strong>
										</p>
										{(context.followUpQuestions ?? []).map((q, qIndex) => (
											<div
												key={qIndex}
												className="d-flex align-items-center mb-2 py-2 px-3 bg-gray-100 border rounded"
												onClick={() => {
													setQuestionInputValue(q);
												}}
											>
												<p className="mb-0 small">{q}</p>
											</div>
										))}
									</>
								)}
							</Typewriter>
						</div>
					</div>
				);
			},
		}),
		[
			chatConfig.shouldAnimate,
			classes.aiIcon,
			classes.aiIconOuter,
			classes.chatQuestion,
			classes.typewriterOuter,
			handleCopyButtonClick,
			handleTypewriterAnimationComplete,
		]
	);

	const handleRateButtonClick = (chatContent: ChatContent) => {
		setQueryIdToRate(chatContent.conversationQueryId);
		setShowRateModal(true);
	};

	const handleRateModalSave = () => {
		setShowRateModal(false);

		setChatContent((previousValue) => {
			return previousValue.map((c) => {
				if (c.conversationQueryId === queryIdToRate) {
					return {
						...c,
						rated: true,
					};
				}

				return c;
			});
		});

		setQueryIdToRate(undefined);
	};

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Ask Hackett.AI</title>
			</Helmet>

			{queryIdToRate && (
				<AiRateModal
					conversationQueryId={queryIdToRate}
					show={showRateModel}
					onHide={() => {
						setShowRateModal(false);
						setQueryIdToRate(undefined);
					}}
					onSave={handleRateModalSave}
				/>
			)}

			<Container className="pt-7">
				<Row className="mb-7">
					<Col>
						<div className="d-flex justify-content-between">
							<h1 className="mb-0 text-primary">Ask Hackett.AI</h1>
							{config.HACKETT_ADVISORY_SHOW_DEBUG === 'true' && (
								<Form>
									<Form.Group>
										<Form.Check
											inline
											name="should-animate"
											id="should-animate"
											label="Should Animate"
											value="true"
											checked={chatConfig.shouldAnimate}
											onChange={({ currentTarget }) => {
												setChatConfig((previousValue) => ({
													...previousValue,
													shouldAnimate: currentTarget.checked,
												}));
											}}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Check
											inline
											name="is-contained"
											id="is-contained"
											label="Is Contained"
											value="true"
											checked={chatConfig.isContained}
											onChange={({ currentTarget }) => {
												setChatConfig((previousValue) => ({
													...previousValue,
													isContained: currentTarget.checked,
												}));
											}}
										/>
									</Form.Group>
								</Form>
							)}
						</div>
					</Col>
				</Row>
			</Container>
			<Container className="pb-10" fluid={!chatConfig.isContained}>
				<Row>
					<Col>
						<div className={classes.chatOuter}>
							<div className={classes.chatList}>
								<div className={classes.chatListButtonOuter}>
									<LoadingButton
										className="w-100 d-flex justify-content-center flex-shrink-0"
										onClick={handleNewConversationButtonClick}
										disabled={chatContent.length < 1}
									>
										+ New Chat
									</LoadingButton>
								</div>
								{conversationsLoading ? (
									<Loader />
								) : (
									<div className={classes.chatListScroller}>
										{Object.entries(conversationSections ?? {}).map(([key, value]) => (
											<section key={key}>
												<h6 className="text-primary">{key}</h6>
												{value.map((c) => (
													<button
														key={c.aiConversationId}
														className={classNames(classes.chatListButton, {
															active:
																c.aiConversationId === conversation?.aiConversationId,
														})}
														onClick={() => handlePastConversationButtonClick(c)}
													>
														{c.title}
													</button>
												))}
											</section>
										))}
									</div>
								)}
							</div>
							<div className={classes.chat}>
								<div ref={chatScrollerOuterRef} className={classes.chatScroller}>
									<div ref={chatScrollerInnerRef} className={classes.chatScrollerInner}>
										{chatContent.length < 1 ? (
											<>
												<h3 className="mb-4 text-primary">Welcome!</h3>
												<p className="mb-8">
													Ask a question or send a prompt to get fast answers from the
													HackettAi database. This text should not be too long. 30-40 words
													max guidance for Hackett.
												</p>
												<p className="mb-4">
													<strong>Example Prompts</strong>
												</p>
												<div className="mb-3 d-flex align-items-center justify-content-start p-3 bg-gray-100 border rounded">
													<CommentIcon className="mr-2" />
													<p className="mb-0">Example prompt text.</p>
												</div>
												<div className="mb-3 d-flex align-items-center justify-content-start p-3 bg-gray-100 border rounded">
													<CommentIcon className="mr-2" />
													<p className="mb-0">Example prompt text.</p>
												</div>
												<div className="d-flex align-items-center justify-content-start p-3 bg-gray-100 border rounded">
													<CommentIcon className="mr-2" />
													<p className="mb-0">Example prompt text.</p>
												</div>
											</>
										) : (
											<>
												{chatContent.map((c) => (
													<div key={c.chatContentId} className={classes.chatContent}>
														{chatContentComponent[c.chatContentTypeId](c)}
													</div>
												))}
											</>
										)}
									</div>
								</div>
								<div className={classes.chatInputOuter}>
									<Form onSubmit={handleFormSubmit}>
										<div className={classes.textareaOuter}>
											<Form.Control
												ref={textareaRef}
												as="textarea"
												placeholder="Message Hackett.AI"
												value={questionInputValue}
												onInput={handleTextareaInput}
												onChange={({ currentTarget }) => {
													setQuestionInputValue(currentTarget.value);
												}}
												disabled={isDisabled}
											/>
											<div className={classes.sendButtonOuter}>
												<LoadingButton
													size="sm"
													type="submit"
													isLoading={answerIsLoading}
													disabled={isDisabled || !questionInputValue}
												>
													Send
												</LoadingButton>
											</div>
										</div>
									</Form>
									<p className="mb-0 text-muted text-center small">
										Short disclaimer text. Ex. ChatGPT can make mistakes. Please check important
										info.
									</p>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
};
