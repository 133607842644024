import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { AiTool, UseCase } from '@/lib/models';
import { useCaseService } from '@/lib/services';
import { AsyncPage } from '@/components';
import classNames from 'classnames';
import { ReactComponent as StarIcon } from '@/assets/icons/icon-star-large.svg';

export const UseCaseDetail = () => {
	const { useCaseId } = useParams<{ useCaseId: string }>();
	const [aiTools, setAiTools] = useState<AiTool[]>([]);
	const [useCase, setUseCase] = useState<UseCase>();

	const fetchUseCase = useCallback(async () => {
		const response = await useCaseService.getUseCase(useCaseId).fetch();

		setAiTools(response.aiTools);
		setUseCase(response.useCase);
	}, [useCaseId]);

	return (
		<>
			<Helmet>
				<title>Hackett Connect - AI Use Cases - AI Use Case Detail</title>
			</Helmet>
			<Container className="py-6">
				<AsyncPage fetchData={fetchUseCase}>
					<Row className="mb-6">
						<Col>
							<h1 className="mb-2">{useCase?.name}</h1>
						</Col>
					</Row>
					<Row>
						<Col xs={12} xl={8}>
							<Row className="mb-6">
								<Col xs={6}>
									<h4 className="mb-3 text-gray-700 font-weight-bold">Category Observed</h4>
									<p className="mb-0">{useCase?.categoryObserved.name}</p>
								</Col>
								{useCase?.useCaseClient.permissionToViewClientName && (
									<Col xs={3}>
										<h4 className="mb-3 text-gray-700 font-weight-bold">Client</h4>
										<p className="mb-0">{useCase?.useCaseClient.name}</p>
									</Col>
								)}
								<Col xs={3} className="text-right">
									<h4 className="mb-3 text-gray-700 font-weight-bold">Value Rating</h4>
									<p className="mb-0">{useCase?.valueRating.name}</p>
								</Col>
							</Row>
							<Row className="mb-6">
								<Col>
									<hr />
								</Col>
							</Row>
							<Row className="mb-6">
								<Col>
									<h2 className="mb-4">AI Technologies</h2>
									<ul className="m-0 p-0 list-unstyled d-flex">
										{aiTools.map((aiTool) => {
											const useCaseAiToolIds = (useCase?.aiTools ?? []).map((it) => it.aiToolId);
											const isIncluded = useCaseAiToolIds.includes(aiTool.aiToolId);

											return (
												<li key={aiTool.aiToolId} className="flex-1 text-center">
													<StarIcon
														className={classNames('mb-4', {
															'text-gray-300': !isIncluded,
															'text-primary': isIncluded,
														})}
													/>
													<h6
														className={classNames('text-uppercase px-2', {
															'text-muted': !isIncluded,
															'text-dark': isIncluded,
														})}
													>
														{aiTool.name}
													</h6>
												</li>
											);
										})}
									</ul>
								</Col>
							</Row>
							<Row className="mb-6">
								<Col>
									<hr />
								</Col>
							</Row>
							<Row>
								<Col>
									<h2 className="mb-5">Use Case Description</h2>
									<Tabs defaultActiveKey="SITUATION" id="tabs--use-case-description">
										<Tab eventKey="SITUATION" title="Situation">
											<div
												className="html-editor p-0"
												dangerouslySetInnerHTML={{
													__html: useCase?.situation ?? '',
												}}
											/>
										</Tab>
										<Tab eventKey="SOLUTION" title="Solution">
											<div
												className="html-editor p-0"
												dangerouslySetInnerHTML={{
													__html: useCase?.solution ?? '',
												}}
											/>
										</Tab>
										<Tab eventKey="BENEFITS_AND_RESPONSE" title="Benefits & Response">
											<div
												className="html-editor p-0"
												dangerouslySetInnerHTML={{
													__html: useCase?.benefitsAndResults ?? '',
												}}
											/>
										</Tab>
									</Tabs>
								</Col>
							</Row>
						</Col>
						<Col xs={12} xl={4}>
							<h4 className="mb-0 text-gray-700 font-weight-bold text-right">
								AI Use Case #{useCase?.useCaseSequence}
							</h4>
							<div className="border p-5">
								<h4 className="mb-3 text-gray-700 font-weight-bold">Function &amp; Process</h4>
								{useCase?.useCaseBusinessTaxonomies?.map((pair, index) => (
									<React.Fragment key={index}>
										<h5 className="text-uppercase text-gray-600">{pair.parent.name}</h5>
										<ul className="mb-5">
											{pair.children.map((child) => (
												<li key={child.businessTaxonomyId}>{child.name}</li>
											))}
										</ul>
									</React.Fragment>
								))}
								<h4 className="mb-3 text-gray-700 font-weight-bold">Industry</h4>
								<ul className="mb-0">
									{(useCase?.industries ?? []).map((i) => (
										<li key={i.industryId}>{i.description}</li>
									))}
								</ul>
							</div>
						</Col>
					</Row>
				</AsyncPage>
			</Container>
		</>
	);
};
