import React from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import Color from 'color';

import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles((theme) => ({
	featureHero: {
		padding: 60,
		display: 'flex',
		borderRadius: 4,
		alignItems: 'flex-end',
		backgroundColor: Color(theme.colors.primary)
			.saturate(0.8)
			.darken(0.32)
			.hex(),
		boxShadow: '0 2px 4px 0 rgba(0,0,0,0.15), 0 10px 20px 0 rgba(0,0,0,0.19);',
	},
	featureInformation: {
		width: 600,
		maxWidth: '100%',
	},
}));

interface FeatureHeroProps {
	category: string;
	title: string;
	description?: string;
	buttonTitle?: string;
	onButtonClick?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
}

export const FeatureHero = ({ category, title, description, buttonTitle, onButtonClick }: FeatureHeroProps) => {
	const classes = useStyles();

	return (
		<div className={classes.featureHero}>
			<div className={classes.featureInformation}>
				<h4 className="mb-1 text-white">{category}</h4>
				<h2 className={classNames('text-white', description ? 'mb-3' : 'mb-0')}>{title}</h2>
				{description && (
					<p className={classNames('text-white', buttonTitle ? 'mb-4' : 'mb-0')}>{description}</p>
				)}
				{buttonTitle && (
					<Button variant="secondary" onClick={onButtonClick}>
						{buttonTitle}
					</Button>
				)}
			</div>
		</div>
	);
};
