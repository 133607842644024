import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-bootstrap';

import { useNavigationConfig } from '@/hooks';
import { FreshDeskLink } from '@/components';

export const Contact = () => {
	const { navigationConfig } = useNavigationConfig();
	return (
		<Container className="py-13">
			<Helmet><title>Hackett Connect - Contact</title></Helmet>
			<Row>
				<Col xs={8}>
					<Row className="mb-10">
						<Col>
							<h1 className="mb-0">Contact Hackett</h1>
						</Col>
					</Row>
					<Row>
						<Col>
							<p className="mb-6">
								<strong>How can we help you?</strong>
							</p>
							<FreshDeskLink className="d-block mb-4">
								I have a question about my program or the site
							</FreshDeskLink>
							{navigationConfig?.allowAccessToInquiries && (
								<>
									<p className="mb-4">
										<Link to="/contact/create-inquiry">I have an inquiry for an advisor</Link>
									</p>
									<p className="mb-4">
										<Link to="/advisory-team">I want to schedule a meeting with an advisor</Link>
									</p>
								</>
							)}
							<FreshDeskLink forTickets={true} className="d-block mb-4">
								Create or view my support tickets
							</FreshDeskLink>
						</Col>
					</Row>
				</Col>
				<Col xs={4} />
			</Row>
		</Container>
	);
};
