import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { useNavigationConfig } from '@/hooks';
import { GetHelp, InquiryForm } from '@/components';

export const CreateInquiry = () => {
	const { navigationConfig } = useNavigationConfig();

	return (
		<Container className="py-13">
			<Helmet>
				<title>Hackett Connect - Contact - Create Inquiry</title>
			</Helmet>
			<Row className="mb-3">
				<Col>
					<p className="mb-0">
						{navigationConfig?.allowClientManagement ? (
							<Link to="/client-management/inquiries-dashboard">Inquiry Dashboard /</Link>
						) : (
							<Link to="/contact">Contact Hackett /</Link>
						)}
					</p>
				</Col>
			</Row>
			<Row>
				<Col xs={8}>
					<h2 className="mb-6">Create a New Inquiry</h2>
					<InquiryForm />
				</Col>
				<Col xs={4}>{!navigationConfig?.allowClientManagement && <GetHelp />}</Col>
			</Row>
		</Container>
	);
};
