import React, { useCallback, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Badge, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ErrorCode, FileRejection } from 'react-dropzone';

import {
	AccountModel,
	DOCUMENT_UPLOAD_STATUS_ID,
	FormOption,
	InquiryDocument,
	InquiryModel,
	INQUIRY_STATUS_ID,
	inquiryAttachment,
	PresignedUploadModel,
} from '@/lib/models';
import { inquiryService, documentUploader } from '@/lib/services';
import { useAlert, useCopyTextToClipboard, useHandleError } from '@/hooks';
import {
	AsyncPage,
	AttachmentDropdown,
	FadeTransition,
	FileDropzone,
	LoadingButton,
	Meter,
	Select,
	Wysiwyg,
} from '@/components';

import { ReactComponent as LinkIcon } from '@/assets/icons/icon-link.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/icon-trash.svg';
import { Helmet } from 'react-helmet';

export const AdvisorInquiriesDetailEdit = () => {
	const history = useHistory();
	const handleError = useHandleError();
	const { showAlert } = useAlert();
	const { copyTextToClipboard } = useCopyTextToClipboard();

	const { inquiryId } = useParams<{ inquiryId: string }>();
	const [inquiry, setInquiry] = useState<InquiryModel>();

	const [recipientOptions, setRecipientOptions] = useState<AccountModel[]>([]);
	const [advisorAccountManagerCcOptions, setAdvisorAccountManagerCcOptions] = useState<FormOption[]>([]);
	const [managerOptions, setManagerOptions] = useState<FormOption[]>([]);
	const [advisorOptions, setAdvisorOptions] = useState<FormOption[]>([]);
	const [grcAdvisorOptions, setGrcAdvisorOptions] = useState<FormOption[]>([]);
	const [statusOptions, setStatusOptions] = useState<FormOption[]>([]);
	const [typeOptions, setTypeOptions] = useState<FormOption[]>([]);
	const [topicsOptions, setTopicsOptions] = useState<FormOption[]>([]);
	const [programOptions, setProgramOptions] = useState<FormOption[]>([]);

	const [inquiryFormValues, setInquiryFormValues] = useState({
		titleValue: '',
		textValue: '',
		notesValue: '',
		recipientsValue: [] as AccountModel[],
		ccValue: [] as FormOption[],
		answerValue: '',
		requesterNotes: '',
		isShowAdvisorEmail: false,
		isShowAdvisorBookingsUrl: false,
	});

	const [inquiryDetailsValues, setInquiryDetailsValues] = useState({
		accountManagerValue: '',
		advisorValue: '',
		grcAdvisorValue: '',
		statusValue: '',
		typeValue: '',
		topicsValue: [] as FormOption[],
		programValue: '',
	});

	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgressPercentages, setUploadProgressPercentages] = useState<Record<string, number>>({});
	const [isSaving, setIsSaving] = useState(false);

	const [isRecipientNotificationRequired, setIsRecipientNotificationRequired] = useState(true);

	const fetchData = useCallback(async () => {
		const [inquiryResponse, optionsResponse] = await Promise.all([
			inquiryService.getInquiryDetails(inquiryId).fetch(),
			inquiryService.getInquiryFormOptions({ inquiryId }).fetch(),
		]);

		const recipientResponse = await inquiryService
			.getAccounts({ clientId: inquiryResponse.inquiry.client.clientId })
			.fetch();

		setInquiry(inquiryResponse.inquiry);

		setInquiryFormValues({
			titleValue: inquiryResponse.inquiry.inquiryTitle ?? '',
			textValue: inquiryResponse.inquiry.inquiryText ?? '',
			notesValue: inquiryResponse.inquiry.notes ?? '',
			recipientsValue: inquiryResponse.inquiry.inquiryAccounts ?? [],
			ccValue: (inquiryResponse.inquiry.inquiryCcAccounts ?? []).map((a) => ({
				id: a.accountId,
				display: a.name,
			})),
			answerValue: inquiryResponse.inquiry.responseText ?? '',
			requesterNotes: inquiryResponse.inquiry.requesterNotes ?? '',
			isShowAdvisorEmail: inquiryResponse.inquiry.showAdvisorEmail ?? false,
			isShowAdvisorBookingsUrl: inquiryResponse.inquiry.showAdvisorBookingsUrl ?? false,
		});

		setInquiryDetailsValues({
			accountManagerValue: inquiryResponse.inquiry.accountManagerAccount.accountId,
			advisorValue: inquiryResponse.inquiry.advisorAccount.accountId,
			grcAdvisorValue: inquiryResponse.inquiry.grcAdvisorAccount.accountId,
			statusValue: inquiryResponse.inquiry.inquiryStatus,
			typeValue: inquiryResponse.inquiry.inquiryType,
			topicsValue: inquiryResponse.inquiry.topicsList,
			programValue: inquiryResponse.inquiry.program.programId,
		});

		setRecipientOptions(recipientResponse.accounts);
		setAdvisorAccountManagerCcOptions(
			[...optionsResponse.advisors, ...optionsResponse.accountManagers]
				.filter((a) => a.id !== inquiryResponse.inquiry.accountManagerAccount.accountId)
				.filter((a) => a.id !== inquiryResponse.inquiry.advisorAccount.accountId)
		);
		setManagerOptions(optionsResponse.accountManagers);
		setAdvisorOptions(optionsResponse.advisors);
		setGrcAdvisorOptions(optionsResponse.grcAdvisors);
		setStatusOptions(optionsResponse.inquiryStatus);
		setTypeOptions(optionsResponse.inquiryType);
		setTopicsOptions(optionsResponse.topics);
		setProgramOptions(optionsResponse.programs);
	}, [inquiryId]);

	const handleCopyLinkButtonClick = useCallback(() => {
		copyTextToClipboard(window.location.href, 'Link was copied to clipboard.');
	}, [copyTextToClipboard]);

	const handleFileDropzoneChange = useCallback(
		async (files: File[], fileRejections: FileRejection[]) => {
			if (!inquiry) {
				return;
			}

			for (const fileRejection of fileRejections) {
				for (const rejectionError of fileRejection.errors) {
					if (rejectionError.code === ErrorCode.FileInvalidType) {
						showAlert({
							variant: 'danger',
							children: () => {
								return (
									<p className="mb-0 text-white">
										One or more files is an incorrect file type. Choose files with valid type: any
										office type, pdf or zip.
									</p>
								);
							},
						});

						return;
					}
					if (rejectionError.code === ErrorCode.FileTooLarge) {
						showAlert({
							variant: 'danger',
							children: () => {
								return (
									<p className="mb-0 text-white">File size cannot exceed 100MB. Please try again.</p>
								);
							},
						});

						return;
					}
				}
			}

			const presignedUploadPromises = files.map((file) => {
				return new Promise((resolve: (presignedUpload: PresignedUploadModel) => void, reject) => {
					let newDocumentName = file.name.split(',').join('');
					documentUploader(
						file,
						inquiryService.getPresignedDocumentUpload({
							inquiryId: inquiry.inquiryId,
							contentType: file.type,
							title: newDocumentName,
						}).fetch
					)
						.onBeforeUpload(() => {
							setUploadProgressPercentages((previousValue) => ({
								...previousValue,
								[newDocumentName]: 0,
							}));
						})
						.onPresignedUploadObtained(() => {})
						.onProgress((percentage) => {
							setUploadProgressPercentages((previousValue) => ({
								...previousValue,
								[newDocumentName]: percentage,
							}));
						})
						.onComplete(async (presignedUpload) => {
							setUploadProgressPercentages((previousValue) => ({
								...previousValue,
								[newDocumentName]: 100,
							}));
							try {
								await inquiryService
									.updateInquiryDocumentStatus({
										documentUploadStatusId: DOCUMENT_UPLOAD_STATUS_ID.COMPLETE,
										inquiryId: inquiry.inquiryId,
										inquiryDocumentId: presignedUpload.documentId,
									})
									.fetch();

								return resolve(presignedUpload);
							} catch (error) {
								return reject(error);
							}
						})
						.onError((error) => {
							return reject(error);
						})
						.start();
				});
			});

			try {
				setIsUploading(true);

				const presignedUploadResponse = await Promise.all(presignedUploadPromises);

				setIsUploading(false);
				setUploadProgressPercentages({});

				showAlert({
					variant: 'success',
					children: () => {
						return <p className="mb-0 text-white">Documents successfully uploaded, adding to inquiry...</p>;
					},
				});

				setIsSaving(true);

				const response = await inquiryService
					.updateInquiry({
						inquiryId: inquiry.inquiryId,
						uploadedDocumentIds: [
							...(inquiry.documents ?? []).map((d) => d.documentId),
							...presignedUploadResponse.map((u) => u.documentId),
						],
						notes: inquiry.notes ?? '',
						inquiryAccountIds: (inquiry.inquiryAccounts ?? []).map((a) => a.accountId),
						inquiryCcIds: (inquiry.inquiryCcAccounts ?? []).map((a) => a.accountId),
					})
					.fetch();

				setInquiry(response.inquiry);
				showAlert({
					variant: 'success',
					children: () => {
						return <p className="mb-0 text-white">Documents added to inquiry.</p>;
					},
				});
			} catch (error) {
				handleError(error);
			} finally {
				setIsSaving(false);
			}
		},
		[handleError, inquiry, showAlert]
	);

	const handleDocumentRemove = useCallback(
		async (document: InquiryDocument) => {
			try {
				if (!inquiry) {
					throw new Error('Inquiry not found.');
				}

				const documentsClone = [...inquiry.documents];
				const documentIndexToRemove = inquiry.documents.findIndex((d) => d.documentId === document.documentId);
				documentsClone.splice(documentIndexToRemove, 1);

				setIsSaving(true);

				const response = await inquiryService
					.updateInquiry({
						inquiryId: inquiry.inquiryId,
						uploadedDocumentIds: documentsClone.map((d) => d.documentId),
						notes: inquiry.notes ?? '',
						inquiryAccountIds: (inquiry.inquiryAccounts ?? []).map((a) => a.accountId),
						inquiryCcIds: (inquiry.inquiryCcAccounts ?? []).map((a) => a.accountId),
					})
					.fetch();

				setInquiry(response.inquiry);
				setIsSaving(false);
				showAlert({
					variant: 'success',
					children: () => {
						return <p className="mb-0 text-white">Document removed.</p>;
					},
				});
			} catch (error) {
				setIsSaving(false);
				handleError(error);
			}
		},
		[handleError, inquiry, showAlert]
	);

	const handleFormSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			try {
				if (!inquiry) {
					throw new Error('Inquiry not found.');
				}

				setIsSaving(true);

				const response = await inquiryService
					.updateInquiry({
						inquiryId: inquiry.inquiryId,
						accountManagerAccountId: inquiryDetailsValues.accountManagerValue,
						advisorAccountId: inquiryDetailsValues.advisorValue,
						grcAdvisorAccountId: inquiryDetailsValues.grcAdvisorValue,
						inquiryStatus: inquiryDetailsValues.statusValue,
						inquiryTypeId: inquiryDetailsValues.typeValue,
						...(inquiryDetailsValues.topicsValue.length > 0 && {
							topicIds: inquiryDetailsValues.topicsValue.map((topic) => topic.id),
						}),
						inquiryTitle: inquiryFormValues.titleValue,
						inquiryText: inquiryFormValues.textValue,
						notes: inquiryFormValues.notesValue,
						inquiryAccountIds: inquiryFormValues.recipientsValue.map((a) => a.accountId),
						inquiryCcIds: inquiryFormValues.ccValue.map((a) => a.id),
						responseText: inquiryFormValues.answerValue,
						uploadedDocumentIds: inquiry.documents.map((d) => d.documentId),
						isRecipientNotificationRequired: isRecipientNotificationRequired,
						programId: inquiryDetailsValues.programValue,
						requesterNotes: inquiryFormValues.requesterNotes,
						isShowAdvisorEmail: inquiryFormValues.isShowAdvisorEmail,
						isShowAdvisorBookingsUrl: inquiryFormValues.isShowAdvisorBookingsUrl,
					})
					.fetch();

				showAlert({
					variant: 'success',
					children: () => {
						return (
							<p className="mb-0 text-white">
								{response.inquiry.inquiryStatus === INQUIRY_STATUS_ID.CLOSED
									? 'Inquiry saved and sent.'
									: 'Inquiry saved.'}
							</p>
						);
					},
				});

				if (response.inquiry.inquiryStatus === INQUIRY_STATUS_ID.CLOSED) {
					history.replace(`/client-management/inquiries/${response.inquiry.inquiryId}`);
					return;
				}

				setInquiry(response.inquiry);

				setInquiryFormValues({
					titleValue: response.inquiry.inquiryTitle ?? '',
					textValue: response.inquiry.inquiryText ?? '',
					notesValue: response.inquiry.notes ?? '',
					recipientsValue: response.inquiry.inquiryAccounts ?? [],
					ccValue: (response.inquiry.inquiryCcAccounts ?? []).map((a) => ({
						id: a.accountId,
						display: a.name,
					})),
					answerValue: response.inquiry.responseText ?? '',
					requesterNotes: response.inquiry.requesterNotes ?? '',
					isShowAdvisorEmail: response.inquiry.showAdvisorEmail ?? false,
					isShowAdvisorBookingsUrl: response.inquiry.showAdvisorBookingsUrl ?? false,
				});

				setInquiryDetailsValues({
					accountManagerValue: response.inquiry.accountManagerAccount.accountId,
					advisorValue: response.inquiry.advisorAccount.accountId,
					grcAdvisorValue: response.inquiry.grcAdvisorAccount.accountId,
					statusValue: response.inquiry.inquiryStatus,
					typeValue: response.inquiry.inquiryType,
					topicsValue: response.inquiry.topicsList,
					programValue: response.inquiry.program.programId,
				});
				setIsSaving(false);
			} catch (error) {
				setIsSaving(false);
				handleError(error);
			}
		},
		[
			handleError,
			history,
			inquiry,
			inquiryDetailsValues,
			inquiryFormValues,
			showAlert,
			isRecipientNotificationRequired,
		]
	);

	const handleDeleteButtonClick = useCallback(async () => {
		if (!window.confirm('All the information in this inquiry will be deleted. Are you sure you want to proceed?')) {
			return;
		}

		try {
			setIsSaving(true);

			if (!inquiry) {
				throw new Error('inquiry is undefined.');
			}

			await inquiryService.deleteInquiry(inquiry.inquiryId).fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Inquiry deleted.</p>;
				},
			});

			history.push('/client-management/inquiries');
		} catch (error) {
			setIsSaving(false);
			handleError(error);
		}
	}, [handleError, history, inquiry, showAlert]);

	const statusBadge = () => {
		return (
			<Badge bg="light" text="dark" pill>
				Inactive
			</Badge>
		);
	};

	function showSaveAndNotifyButton() {
		if (inquiryDetailsValues.statusValue === INQUIRY_STATUS_ID.CLOSED) {
			return (
				<div>
					<LoadingButton
						variant="outline-primary"
						className="mr-3"
						type="submit"
						disabled={isUploading}
						isLoading={isSaving}
						onClick={() => {
							setIsRecipientNotificationRequired(false);
						}}
					>
						Save Without Client Notification
					</LoadingButton>
				</div>
			);
		}
	}

	const handleIncludeAdvisorEmail = useCallback(
		({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
			setInquiryFormValues((previousValue) => ({
				...previousValue,
				isShowAdvisorEmail: currentTarget.checked,
			}));
		},
		[inquiry]
	);
	const handleIncludeAdvisorBookingsUrl = useCallback(
		({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
			setInquiryFormValues((previousValue) => ({
				...previousValue,
				isShowAdvisorBookingsUrl: currentTarget.checked,
			}));
		},
		[inquiry]
	);

	return (
		<AsyncPage fetchData={fetchData}>
			<Helmet>
				<title>Hackett Connect - Client Management - Inquiries - Inquiry Details Edit</title>
			</Helmet>
			<Container className="py-13">
				<Row className="mb-2">
					<Col>
						<Link to="/client-management/inquiries">Inquiries /</Link>
					</Col>
				</Row>
				<Form onSubmit={handleFormSubmit}>
					<Row>
						<Col xs={12} xl={8} className="mb-3">
							<div className="mb-2 d-flex align-items-center">
								<h2 className="mb-0 mr-2">{inquiry?.inquiryTitle}</h2>
								<Button
									variant="link"
									className="mr-3 d-flex align-items-center small"
									onClick={handleCopyLinkButtonClick}
								>
									<LinkIcon width={24} height={24} />
								</Button>
							</div>
							<div className="mb-4 d-flex align-items-center justify-content-between">
								<p className="mb-0 text-muted font-italic">{inquiry?.inquiryDescription}</p>
								<p className="mb-0 text-muted font-italic">{inquiry?.inquiryStatusDescription}</p>
							</div>

							<hr className="mb-4" />

							<Form.Group className="mb-6">
								<Form.Label>Inquiry Subject</Form.Label>
								<Form.Control
									type="text"
									value={inquiryFormValues.titleValue}
									onChange={({ currentTarget }) => {
										setInquiryFormValues((previousValue) => ({
											...previousValue,
											titleValue: currentTarget.value,
										}));
									}}
									disabled={isSaving}
								/>
							</Form.Group>

							<Form.Group className="mb-6">
								<Form.Label>Inquiry Details</Form.Label>
								<Wysiwyg
									value={inquiryFormValues.textValue}
									onChange={(content) => {
										setInquiryFormValues((previousValue) => ({
											...previousValue,
											textValue: content,
										}));
									}}
									readOnly={isSaving}
								/>
							</Form.Group>

							<Form.Group className="mb-6">
								<Form.Label>Notes (For Internal Use) </Form.Label>
								<Wysiwyg
									value={inquiryFormValues.notesValue}
									onChange={(content) => {
										setInquiryFormValues((previousValue) => ({
											...previousValue,
											notesValue: content,
										}));
									}}
									readOnly={isSaving}
								/>
							</Form.Group>

							<Form.Group className="mb-6">
								<Form.Label>Recipients</Form.Label>
								<Typeahead
									filterBy={(option, props) => {
										const selectedAccountIds = inquiryFormValues.recipientsValue.map(
											(a) => a.accountId
										);

										return (
											!selectedAccountIds.includes((option as AccountModel).accountId) &&
											((option as AccountModel).name
												.toLowerCase()
												.indexOf(props.text.toLowerCase()) !== -1 ||
												(option as AccountModel).emailAddress
													.toLowerCase()
													.indexOf(props.text.toLowerCase()) !== -1)
										);
									}}
									id="typeahead--recipients"
									labelKey={(option) =>
										`${(option as AccountModel).name} (${(option as AccountModel).emailAddress})`
									}
									multiple
									selected={inquiryFormValues.recipientsValue}
									onChange={(selected) => {
										setInquiryFormValues((previousValue) => ({
											...previousValue,
											recipientsValue: selected as AccountModel[],
										}));
									}}
									options={recipientOptions}
									placeholder="Select recipients..."
									disabled={isSaving}
								/>
							</Form.Group>

							<Form.Group className="mb-6">
								<Form.Label>Advisor/Account Manager CC</Form.Label>
								<Typeahead
									id="typeahead--advisor-and-account-manager-cc"
									labelKey={(option) => `${(option as FormOption).display}`}
									multiple
									selected={inquiryFormValues.ccValue}
									onChange={(selected) => {
										setInquiryFormValues((previousValue) => ({
											...previousValue,
											ccValue: selected as FormOption[],
										}));
									}}
									options={advisorAccountManagerCcOptions}
									placeholder="Select recipients..."
									disabled={isSaving}
								/>
							</Form.Group>

							<Form.Group className="mb-8">
								<Form.Label>Answer</Form.Label>
								<Wysiwyg
									value={inquiryFormValues.answerValue}
									onChange={(content) => {
										setInquiryFormValues((previousValue) => ({
											...previousValue,
											answerValue: content,
										}));
									}}
									readOnly={isSaving}
								/>
							</Form.Group>
							<Form.Group className="mb-6">
								<Form.Label>Notes (Requester Response) </Form.Label>
								<div>
									<div dangerouslySetInnerHTML={{ __html: inquiry?.requesterNotes ?? '' }} />
								</div>
							</Form.Group>

							<Form.Group className="mb-8">
								<Form.Label>Attachments</Form.Label>
								{inquiry?.documents.map((document) => {
									return (
										<AttachmentDropdown
											key={document.documentId}
											className="mb-2"
											title={document.name}
											downloadUrl={document.downloadUrl}
											onRemove={() => {
												handleDocumentRemove(document);
											}}
										/>
									);
								})}
								<FadeTransition in={isUploading}>
									{Object.entries(uploadProgressPercentages).map(([key, value]) => (
										<div className="pb-2" key={key}>
											<Meter value={value} max={100} size="sm" pill showBackground title={key} />
										</div>
									))}
								</FadeTransition>
								<FileDropzone
									accept={inquiryAttachment.accept}
									maxSize={inquiryAttachment.maxFileSize_bytes}
									onChange={handleFileDropzoneChange}
									disabled={isUploading || isSaving}
								/>
							</Form.Group>

							<hr className="mb-6" />
							<div>
								{inquiryDetailsValues.statusValue === INQUIRY_STATUS_ID.CLOSED && (
									<>
										<Form.Group>
											<Row>
												<Col className="d-flex align-items-start">
													<Form.Label>Show Advisor Signature</Form.Label>
												</Col>
												<Col className="d-flex justify-content-end">
													<Form.Switch
														id="switch-advisor-email"
														label={
															inquiryFormValues.isShowAdvisorEmail
																? 'Enabled'
																: 'Disabled'
														}
														checked={inquiryFormValues.isShowAdvisorEmail}
														onChange={(currentTarget) => {
															handleIncludeAdvisorEmail(currentTarget);
														}}
													/>
												</Col>
											</Row>
										</Form.Group>
										<Form.Group className="mb-6">
											<Row>
												<Col xl={8} className="d-flex align-items-start">
													<Form.Label>
														Show MS Booking URL{' '}
														<span className="text-muted font-italic">
															{inquiry?.advisorAccount.bookingsUrl == ''
																? 'You need to add your Microsoft Booking URL'
																: ''}{' '}
														</span>
													</Form.Label>
												</Col>
												<Col className="d-flex justify-content-end">
													<Form.Switch
														id="switch-advisor-booking"
														label={
															inquiryFormValues.isShowAdvisorBookingsUrl
																? 'Enabled'
																: 'Disabled'
														}
														checked={
															inquiry?.advisorAccount.bookingsUrl == ''
																? false
																: inquiryFormValues.isShowAdvisorBookingsUrl
														}
														disabled={
															inquiry?.advisorAccount.bookingsUrl == '' ? true : false
														}
														onChange={(currentTarget) => {
															handleIncludeAdvisorBookingsUrl(currentTarget);
														}}
													/>
												</Col>
											</Row>
										</Form.Group>
									</>
								)}
							</div>
							<div className="d-flex align-items-center justify-content-between">
								<div>
									<LoadingButton
										variant="outline-primary"
										className="mr-3"
										type="submit"
										disabled={isUploading}
										isLoading={isSaving}
										onClick={() => {
											setIsRecipientNotificationRequired(true);
										}}
									>
										{inquiryDetailsValues.statusValue === INQUIRY_STATUS_ID.CLOSED
											? 'Save and Send Client Notification'
											: 'Save'}
									</LoadingButton>
								</div>
								{showSaveAndNotifyButton()}
								<div>
									<Button
										className="mr-4"
										variant="link"
										onClick={() => {
											history.goBack();
										}}
										disabled={isUploading || isSaving}
									>
										Cancel
									</Button>
									<LoadingButton
										variant="outline-primary"
										onClick={handleDeleteButtonClick}
										disabled={isUploading || isSaving}
									>
										<TrashIcon className="mr-3" />
										Delete
									</LoadingButton>
								</div>
							</div>
						</Col>
						<Col xs={12} xl={4}>
							<Card>
								<Card.Body>
									<div className="mb-4 d-flex align-items-center justify-content-between">
										<Card.Title className="mb-0">Inquiry Details</Card.Title>
									</div>
									{inquiry?.client && (
										<div className="mb-4">
											<p className="mb-0">
												<strong>Client</strong>
											</p>
											<p className="mb-0">{inquiry.client.name}</p>
											<p className="mb-0 text-muted">Submitted {inquiry.createdDescription}</p>
										</div>
									)}
									{inquiry?.requestingAccount && (
										<div className="mb-4">
											<p className="mb-0">
												<strong>Requester</strong>
											</p>
											<div className="mb-4 d-flex align-items-center">
												<p className="mb-0">{inquiry?.requestingAccount.name}</p>
												<p className="mb-0" style={{ paddingLeft: '5px' }}>
													{inquiry?.requestingAccount.deleted ? statusBadge() : null}
												</p>
											</div>
										</div>
									)}
									<Form.Group className="mb-6">
										<Form.Label>Account Manager</Form.Label>
										<Select
											value={inquiryDetailsValues.accountManagerValue}
											onChange={({ currentTarget }) => {
												setInquiryDetailsValues({
													...inquiryDetailsValues,
													accountManagerValue: currentTarget.value,
												});
											}}
											disabled={isSaving}
										>
											{managerOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Advisor</Form.Label>
										<Select
											value={inquiryDetailsValues.advisorValue}
											onChange={({ currentTarget }) => {
												setInquiryDetailsValues({
													...inquiryDetailsValues,
													advisorValue: currentTarget.value,
												});
											}}
											disabled={isSaving}
										>
											{advisorOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Inquiry Advisor</Form.Label>
										<Select
											value={inquiryDetailsValues.grcAdvisorValue}
											onChange={({ currentTarget }) => {
												setInquiryDetailsValues({
													...inquiryDetailsValues,
													grcAdvisorValue: currentTarget.value,
												});
											}}
											disabled={isSaving}
										>
											{grcAdvisorOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Program</Form.Label>
										<Select
											value={inquiryDetailsValues.programValue}
											onChange={({ currentTarget }) => {
												setInquiryDetailsValues({
													...inquiryDetailsValues,
													programValue: currentTarget.value,
												});
											}}
											disabled={isSaving}
										>
											{programOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<hr className="mb-4" />
									<Form.Group className="mb-6">
										<Form.Label>Status</Form.Label>
										<Select
											value={inquiryDetailsValues.statusValue}
											onChange={({ currentTarget }) => {
												setInquiryDetailsValues({
													...inquiryDetailsValues,
													statusValue: currentTarget.value,
												});
											}}
											disabled={isSaving}
										>
											{statusOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Type</Form.Label>
										<Select
											value={inquiryDetailsValues.typeValue}
											onChange={({ currentTarget }) => {
												setInquiryDetailsValues({
													...inquiryDetailsValues,
													typeValue: currentTarget.value,
												});
											}}
											disabled={isSaving}
										>
											{typeOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<Form.Group>
										<Form.Label>Topics</Form.Label>
										<Typeahead
											id="typeahead--topics"
											labelKey="display"
											multiple
											selected={inquiryDetailsValues.topicsValue}
											onChange={(selected) => {
												setInquiryDetailsValues({
													...inquiryDetailsValues,
													topicsValue: selected as FormOption[],
												});
											}}
											options={topicsOptions}
											placeholder="Select topics..."
											disabled={isSaving}
										/>
									</Form.Group>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Form>
			</Container>
		</AsyncPage>
	);
};
