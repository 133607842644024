import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { createUseThemedStyles, useAlert } from '@/hooks';

const transitionDuration = 200;

const useAlertStyles = createUseThemedStyles((theme) => ({
	alertOuter: {
		top: 20,
		left: '50%',
		zIndex: 1056, // Must be placed above Bootstrap's modal shadow
		maxWidth: '90%',
		position: 'fixed',
		transition: `all ${transitionDuration}ms`,
	},
	alert: {
		display: 'flex',
		alignItems: 'center',
		transition: `background-color ${transitionDuration}ms`,
		padding: `15px 40px 15px 25px`,
		'&.success': {
			backgroundColor: theme.colors.success,
		},
		'&.warning': {
			backgroundColor: theme.colors.warning,
		},
		'&.danger': {
			backgroundColor: theme.colors.danger,
		},
	},
	hideAlertButton: {
		right: 2,
		border: 0,
		width: 44,
		height: 44,
		top: '50%',
		appearance: 'none',
		position: 'absolute',
		transform: 'translateY(-50%)',
		backgroundColor: 'transparent',
		'&:before, &:after': {
			top: '50%',
			width: 12,
			height: 2,
			left: '50%',
			content: '""',
			position: 'absolute',
			backgroundColor: theme.colors.white,
		},
		'&:before': {
			transform: 'translate(-50%, -50%) rotate(-45deg)',
		},
		'&:after': {
			transform: 'translate(-50%, -50%) rotate(45deg)',
		},
	},
	'@global': {
		'.alert-enter': {
			opacity: 0,
			transform: 'translate(-50%, -100%)',
		},
		'.alert-enter-active': {
			opacity: 1,
			transform: 'translate(-50%, 0)',
		},
		'.alert-enter-done': {
			opacity: 1,
			transform: 'translate(-50%, 0)',
		},
		'.alert-exit': {
			opacity: 1,
			transform: 'translate(-50%, 0)',
		},
		'.alert-exit-active': {
			opacity: 0,
			transform: 'translate(-50%, -100%)',
		},
	},
}));

export const Alert: FC = () => {
	const { alertIsShowing, alertChildren, alertVariant, hideAlert } = useAlert();
	const classes = useAlertStyles();

	useEffect(() => {}, [alertChildren]);

	return (
		<CSSTransition
			in={alertIsShowing}
			timeout={transitionDuration}
			classNames="alert"
			mountOnEnter={true}
			unmountOnExit={true}
		>
			<div className={classes.alertOuter}>
				<div className={classNames([classes.alert, alertVariant])}>
					{alertChildren}
					<button className={classes.hideAlertButton} onClick={hideAlert} />
				</div>
			</div>
		</CSSTransition>
	);
};
