export enum ACTIVITY_TYPE_ID {
	BP = 'BP',
	ACC = 'ACC',
	INQ = 'INQ',
	PM = 'PM',
	PF = 'PF',
	ACCOUNT = 'ACCOUNT',
	PROGRAM = 'PROGRAM',
	DOCUMENT = 'DOCUMENT',
}

export interface RecentActivityModel {
	accountId: string;
	activityActionId: string; // todo
	activityDate: string;
	activityKey: string;
	activityTypeId: ACTIVITY_TYPE_ID;
	ipId?: string;
	ipTitle?: string;
	ipType?: string;
	ipTypeDescription?: string;
	recentActivityId: string;
	secondaryActivityKey: string;
}
