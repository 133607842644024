import { httpSingleton } from '@/lib/http-client';
import { BundleModel } from '@/lib/models';

export const bundleService = {
	getBundleByBundleId(bundleId: string) {
		return httpSingleton.orchestrateRequest<BundleModel>({
			method: 'GET',
			url: `/advisory-service/api/v1/bundle/${bundleId}`,
		});
	},
};
