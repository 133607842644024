export function constructUrl(pathname: string, queryParameters?: Record<string, any>, search?: string): string {
	const searchParams = search ? new URLSearchParams(search) : new URLSearchParams();

	if (queryParameters) {
		Object.entries(queryParameters).forEach(([key, value]) => {
			searchParams.delete(key);

			if (Array.isArray(value)) {
				searchParams.append(key, value.join('|'));
			} else {
				searchParams.append(key, value);
			}
		});
	}

	const searchParamsLength = Array.from(searchParams).length;

	if (searchParamsLength > 0) {
		return `${pathname}?${searchParams.toString()}`;
	}

	return pathname;
}

export function getParsedQuery(query: URLSearchParams, arrayFormat: string = ',') {
	const parsedQuery: Record<string, any> = {};

	for (const keyValuePair of query.entries()) {
		const key = keyValuePair[0];
		const value = keyValuePair[1].includes(arrayFormat)
			? keyValuePair[1].split(arrayFormat).filter((s) => s)
			: keyValuePair[1];

		if (parsedQuery[key]) {
			if (Array.isArray(parsedQuery[key])) {
				parsedQuery[key].push(value);
			} else {
				const previousValue = parsedQuery[key];
				parsedQuery[key] = [previousValue, value];
			}
		} else {
			parsedQuery[key] = value;
		}
	}

	return parsedQuery;
}

export function getValidPaginatorSize(query: URLSearchParams) {
	let size = query
		.get('size')
		?.split('|')
		.filter((s) => s)[0];
	const validPaginatorSizes = ['10','20','50'];
	if (!size || !validPaginatorSizes.includes(size)){
		size = '10'
	}
	
	return size;
}
