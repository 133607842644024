import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { Select } from '@/components';
import { FormOption } from '@/lib/models';
import { TableSelectedFilterModel } from '@/components/table/models';

interface dropdownFilterProps {
	filterLabel: string;
	filterKey: string;
	tableFilters: FormOption[];
	selectedFilters: TableSelectedFilterModel;
	updateFilterSelection(key: string, value: string): void;
	allowAny?: boolean;
	disabled?: boolean;
}

export const TableFilterDropdown: FC<dropdownFilterProps> = ({
	filterLabel,
	filterKey,
	tableFilters,
	selectedFilters,
	updateFilterSelection,
	allowAny = true,
	disabled,
}) => {
	return (
		<Form.Group>
			<Form.Label>{filterLabel}</Form.Label>
			<Select
				value={selectedFilters[filterKey as keyof TableSelectedFilterModel] ?? ''}
				onChange={({ currentTarget }) => {
					updateFilterSelection(filterKey, currentTarget.value);
				}}
				disabled={disabled}
			>
				{allowAny && <option value="">Any</option>}
				{tableFilters.map((filter) => (
					<option key={filter.id} value={filter.id} disabled={filter.disabled}>
						{filter.display}
					</option>
				))}
			</Select>
		</Form.Group>
	);
};
