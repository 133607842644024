import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import {
	performanceMetricsTableHeaders,
	performanceMetricsTableColumns,
	PERFORMANCE_METRICS_TABLE_COLUMN_IDS,
} from '@/lib/__mocks__';
import { PerformanceMetricModel, PerformanceMetricFilterModel } from '@/lib/models';
import { TableRowConfig, PerformanceMetricDownloadParametersModel } from '@/components/table/models';
import { constructUrl, formOptionToMultiSelectOption } from '@/lib/utils';
import { performanceMetricService } from '@/lib/services';
import { useHandleError, useNavigationConfig, useQuery } from '@/hooks';
import {
	flattenParentAndChildIdsForSelection,
	getTopLevelParentAndChildrenIds,
	MultiSelect,
	MultiSelectOption,
} from '@/components';
import {
	SORT_DIRECTION,
	TableCell,
	TableHeader,
	TableRenderer,
	TableRow,
	TableFilterDropdown,
} from '@/components/table';
import config from '@/lib/config';
import { cloneDeep } from 'lodash';

export const PerformanceMetrics = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const handleError = useHandleError();
	const { navigationConfig } = useNavigationConfig();

	const query = useQuery();
	const parentBusinessProcessIds = useMemo(() => query.get('parentBusinessProcessIds'), [query]);
	const businessProcessIds = useMemo(() => query.get('businessProcessIds'), [query]);
	const driverComponentIds = useMemo(() => query.get('driverComponentIds'), [query]);
	const programId = useMemo(() => query.get('programId'), [query]);
	const page = useMemo(() => query.get('page') ?? '0', [query]);
	const size = useMemo(() => query.get('size') ?? '10', [query]);
	const sortDirection = useMemo(() => query.get('sortDirection'), [query]);
	const sortField = useMemo(() => query.get('sortField'), [query]);
	const sourceComponentIds = useMemo(() => query.get('sourceComponentIds'), [query]);

	const clearSelectionsRef = useRef<() => void>();
	const firstLoadComplete = useRef(false);
	const [filtersAreLoading, setFiltersAreLoading] = useState(false);
	const [tableIsLoading, setTableIsLoading] = useState(false);
	const [tableSelectAll, setTableSelectAll] = useState(false);
	const [tableColumnsData, setTableColumnsData] = useState(performanceMetricsTableColumns);
	const [tableHeadersData] = useState(performanceMetricsTableHeaders);
	const [totalCount, setTotalCount] = useState(0);
	const [totalCountDescription, setTotalCountDescription] = useState('0');
	const initialTableFilters = useRef<PerformanceMetricFilterModel>();
	const [tableFilters, setTableFilters] = useState<PerformanceMetricFilterModel>();

	const [businessProcessFilters, setBusinessProcessFilters] = useState<MultiSelectOption[]>([]);
	const [tableRowData, setTableRowData] = useState<TableRowConfig<PerformanceMetricModel>[]>([]);
	const [downloadData, setDownloadData] = useState<PerformanceMetricDownloadParametersModel>();
	const [uncheckedRowsIds, setUncheckedRowsIds] = useState<string[]>([]);
	const [checkedRowsIds, setCheckedRowsIds] = useState<string[]>([]);

	const downloadUrl = useMemo(() => {
		return constructUrl(`${config.HACKETT_ADVISORY_BASE_URL}advisory-service/api/v1/performance-metric/download`, {
			programId,
		});
	}, [programId]);

	/* ------------------------------------- */
	/* Must default a programId */
	/* ------------------------------------- */
	useEffect(() => {
		if (!tableFilters || tableFilters.programs.length <= 0) {
			return;
		}

		if (!programId) {
			history.replace(constructUrl(pathname, { page: '0', programId: tableFilters.programs[0].id }, search));
		}
	}, [history, pathname, programId, search, tableFilters]);

	const fetchTableFilters = useCallback(async () => {
		try {
			setFiltersAreLoading(true);

			const response = await performanceMetricService
				.getFilters({
					...(programId && { programId: programId }),
					...(parentBusinessProcessIds && { parentBusinessProcessIds }),
					...(businessProcessIds && { businessProcessIds }),
					...(driverComponentIds && { driverComponentIds }),
					...(sourceComponentIds && { sourceComponentIds }),
				})
				.fetch();

			setBusinessProcessFilters(formOptionToMultiSelectOption(response.businessProcess));

			if (firstLoadComplete.current === false) {
				initialTableFilters.current = {
					driver: response.driver,
					source: response.source,
					programs: response.programs,
				};
				setTableFilters({
					driver: response.driver,
					source: response.source,
					programs: response.programs,
				});
			} else {
				setTableFilters({
					driver: (initialTableFilters.current?.driver ?? []).map((d) => {
						const match = response.driver.find((d2) => d2.id === d.id);
						if (match) {
							return d;
						} else {
							return {
								...d,
								disabled: true,
							};
						}
					}),
					source: (initialTableFilters.current?.source ?? []).map((s) => {
						const match = response.source.find((s2) => s2.id === s.id);
						if (match) {
							return s;
						} else {
							return {
								...s,
								disabled: true,
							};
						}
					}),
					programs: response.programs,
				});
			}

			firstLoadComplete.current = true;
		} catch (error) {
			handleError(error);
		} finally {
			setFiltersAreLoading(false);
		}
	}, [handleError, programId, parentBusinessProcessIds, businessProcessIds, driverComponentIds, sourceComponentIds]);

	useEffect(() => {
		fetchTableFilters();
	}, [fetchTableFilters]);

	const fetchData = useCallback(async () => {
		try {
			setTableIsLoading(true);

			if (!programId) {
				throw new Error('programId is undefined');
			}

			const response = await performanceMetricService
				.getPerformanceMetrics({
					...(parentBusinessProcessIds && { parentBusinessProcessIds }),
					...(businessProcessIds && { businessProcessIds }),
					...(driverComponentIds && { driverComponentIds }),
					...(page && { page }),
					...(size && { size }),
					...(sortDirection && { sortDirection }),
					...(sortField && { sortField }),
					...(sourceComponentIds && { sourceComponentIds }),
					programId,
				})
				.fetch();

			const formattedPerformanceMetrics = response.performanceMetrics.map((pm) => {
				let selectAll = tableSelectAll;
				if (uncheckedRowsIds.length && uncheckedRowsIds?.includes(pm.performanceMetricId)) {
					selectAll = false;
				} else if (checkedRowsIds.length && checkedRowsIds?.includes(pm.performanceMetricId)) {
					selectAll = true;
				}

				return {
					rowId: pm.performanceMetricId,
					checked: selectAll,
					expanded: false,
					columnData: pm,
				};
			});

			setTotalCount(response.totalCount);
			setTotalCountDescription(response.totalCountDescription);
			setTableRowData(formattedPerformanceMetrics);
		} catch (error) {
			handleError(error);
		} finally {
			setTableIsLoading(false);
		}
	}, [
		businessProcessIds,
		driverComponentIds,
		handleError,
		page,
		parentBusinessProcessIds,
		programId,
		size,
		sortDirection,
		sortField,
		sourceComponentIds,
		tableSelectAll,
		uncheckedRowsIds,
		checkedRowsIds,
	]);

	useEffect(() => {
		if (!programId) {
			return;
		}

		fetchData();
	}, [fetchData, programId]);

	const allBpSelections = useMemo(() => {
		return flattenParentAndChildIdsForSelection(
			businessProcessFilters,
			parentBusinessProcessIds?.split('|').filter((s) => s),
			businessProcessIds?.split('|').filter((s) => s)
		);
	}, [businessProcessFilters, businessProcessIds, parentBusinessProcessIds]);

	const updateFilterSelection = useCallback(
		(filterKey: string, filterValue: string) => {
			if (clearSelectionsRef.current) {
				clearSelectionsRef.current();
			}

			if (filterKey === 'programId') {
				firstLoadComplete.current = false;
			}

			history.replace(
				constructUrl(pathname, { page: '0', [filterKey]: filterValue }, filterKey === 'programId' ? '' : search)
			);
		},
		[history, pathname, search]
	);

	const handleBusinessProcessesChange = useCallback(
		(selectedBusinessProcessIds: string[]) => {
			if (clearSelectionsRef.current) {
				clearSelectionsRef.current();
			}

			const { parentIds, remainingChildrenIds } = getTopLevelParentAndChildrenIds(
				businessProcessFilters,
				selectedBusinessProcessIds
			);

			history.replace(
				constructUrl(
					pathname,
					{ page: '0', parentBusinessProcessIds: parentIds, businessProcessIds: remainingChildrenIds },
					search
				)
			);
		},
		[businessProcessFilters, history, pathname, search]
	);

	const handleTableSort = useCallback(
		(sortKey: string, sortDirection: SORT_DIRECTION) => {
			if (clearSelectionsRef.current) {
				clearSelectionsRef.current();
			}

			history.replace(constructUrl(pathname, { page: '0', sortField: sortKey, sortDirection }, search));
		},
		[history, pathname, search]
	);

	const handlePaginationChange = useCallback(
		({ pageSize, pageIndex }) => {
			history.replace(constructUrl(pathname, { page: String(pageIndex), size: String(pageSize) }, search));
		},
		[history, pathname, search]
	);

	useEffect(() => {
		let checkedRowIds = checkedRowsIds.join('|');
		let uncheckedRowIds = '';
		if (tableSelectAll) {
			uncheckedRowIds = uncheckedRowsIds.join('|');
		}
		setDownloadData({
			selectAll: tableSelectAll,
			...(checkedRowsIds.length > 0 && tableSelectAll ? { uncheckedRowIds } : { checkedRowIds }),
			selectedColumns: Object.values(tableColumnsData)
				.filter((item) => item.isShowing)
				.map((item) => item.columnId)
				.join('|'),
			...(programId && { programId }),
			...(businessProcessIds && { businessProcessIds }),
			...(parentBusinessProcessIds && { parentBusinessProcessIds }),
			...(sourceComponentIds && { sourceComponentIds }),
			...(driverComponentIds && { driverComponentIds }),
		});
	}, [
		businessProcessIds,
		driverComponentIds,
		parentBusinessProcessIds,
		programId,
		sourceComponentIds,
		tableColumnsData,
		tableRowData,
		tableSelectAll,
		uncheckedRowsIds,
		checkedRowsIds,
	]);

	/* ---------------------------------- */
	/* Optional Column logic */
	/* ---------------------------------- */
	useEffect(() => {
		if (!tableFilters) return;
		const selectedProgramId = tableFilters.programs.find((p) => p.id === programId);
		if (!selectedProgramId) return;

		setTableColumnsData(() => {
			const { performanceMetricTypes } = selectedProgramId;
			const tableColumnsDataClone = cloneDeep(performanceMetricsTableColumns);

			performanceMetricTypes.forEach((type) => {
				const column = tableColumnsDataClone[type as PERFORMANCE_METRICS_TABLE_COLUMN_IDS];
				if (!column) return;

				column.isHidable = true;
				column.isShownByDefault = true;
				column.isShowing = true;
			});

			return tableColumnsDataClone;
		});
	}, [programId, tableFilters]);

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Benchmark Metrics</title>
			</Helmet>

			<Container className="pt-7">
				<Row>
					<Col>
						<div className="d-flex align-items-center justify-content-between">
							<h1 className="mb-0 text-primary">Benchmark Metrics</h1>
						</div>
					</Col>
				</Row>
			</Container>

			<Container className="py-7">
				<Row className="mb-4">
					<Col lg={6}>
						<TableFilterDropdown
							filterLabel="Program"
							filterKey="programId"
							tableFilters={tableFilters?.programs ?? []}
							selectedFilters={{
								businessProcessIds: businessProcessIds ?? '',
								driverComponentIds: driverComponentIds ?? undefined,
								sourceComponentIds: sourceComponentIds ?? undefined,
								programId: programId ?? undefined,
							}}
							updateFilterSelection={updateFilterSelection}
							allowAny={false}
							disabled={filtersAreLoading}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={4} className="mb-5">
						<Form.Label>Business Processes</Form.Label>
						<MultiSelect
							options={businessProcessFilters}
							selected={allBpSelections}
							onChange={handleBusinessProcessesChange}
							disabled={filtersAreLoading}
						>
							{businessProcessIds ? 'Select' : 'Any'}
						</MultiSelect>
					</Col>
					<Col lg={4} className="mb-5">
						<TableFilterDropdown
							filterLabel="Driver"
							filterKey="driverComponentIds"
							tableFilters={tableFilters?.driver ?? []}
							selectedFilters={{
								businessProcessIds: businessProcessIds ?? '',
								driverComponentIds: driverComponentIds ?? undefined,
								sourceComponentIds: sourceComponentIds ?? undefined,
								programId: programId ?? undefined,
							}}
							updateFilterSelection={updateFilterSelection}
							disabled={filtersAreLoading}
						/>
					</Col>
					<Col lg={4} className="mb-5">
						<TableFilterDropdown
							filterLabel="Source"
							filterKey="sourceComponentIds"
							tableFilters={tableFilters?.source ?? []}
							selectedFilters={{
								businessProcessIds: businessProcessIds ?? '',
								driverComponentIds: driverComponentIds ?? undefined,
								sourceComponentIds: sourceComponentIds ?? undefined,
								programId: programId ?? undefined,
							}}
							updateFilterSelection={updateFilterSelection}
							disabled={filtersAreLoading}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<TableRenderer
							isLoading={tableIsLoading}
							tableSelectAll={tableSelectAll}
							onTableSelectAllChange={setTableSelectAll}
							tableColumnData={tableColumnsData}
							onTableColumnDataChange={setTableColumnsData}
							tableRowData={tableRowData}
							setUncheckedRowsIds={setUncheckedRowsIds}
							uncheckedRowsIds={uncheckedRowsIds}
							tableRowDataTotal={totalCount}
							onTableRowDataChange={setTableRowData}
							downloadUrl={navigationConfig?.allowDownloads ? downloadUrl : undefined}
							downloadData={navigationConfig?.allowDownloads ? downloadData : undefined}
							pageSize={parseInt(size, 10)}
							pageIndex={parseInt(page, 10)}
							setCheckedRowsIds={setCheckedRowsIds}
							checkedRowsIds={checkedRowsIds}
							onPaginationChange={handlePaginationChange}
							tableHeaderRowRenderer={(checkboxProps, clearSelections) => {
								clearSelectionsRef.current = clearSelections;

								return (
									<TableRow>
										<TableHeader fixed width={55} className="pr-0">
											<Form.Check {...checkboxProps} />
										</TableHeader>
										{Object.values(tableColumnsData).map((columnConfig) => {
											if (!columnConfig.isShowing) {
												return null;
											}

											const tableHeader = tableHeadersData[columnConfig.columnId];
											const tableHeaderProps = {
												key: tableHeader.tableHeaderId,
												sortable: tableHeader.isSortable,
												sortKey: tableHeader.tableHeaderId,
												onSort: handleTableSort,
												sortDirection:
													sortField && sortField === tableHeader.tableHeaderId
														? (sortDirection as SORT_DIRECTION)
														: undefined,
											};

											switch (columnConfig.columnId) {
												case PERFORMANCE_METRICS_TABLE_COLUMN_IDS.PERFORMANCE_METRICS:
													return (
														<TableHeader
															className="pl-0"
															fixed={true}
															fixedOffset={55}
															width={290}
															{...tableHeaderProps}
														>
															{totalCountDescription} {tableHeader.title}
														</TableHeader>
													);
												default:
													return (
														<TableHeader {...tableHeaderProps}>
															{tableHeader.title}
														</TableHeader>
													);
											}
										})}
									</TableRow>
								);
							}}
							tableBodyRowRenderer={(
								data,
								{ id: checkboxId, label: checkboxLabel, ...checkboxProps }
							) => {
								return (
									<TableRow
										key={data.rowId}
										onClick={() => {
											history.push(`/performance-metrics/${data.rowId}?programId=${programId}`);
										}}
									>
										<TableCell fixed width={55} className="pr-0">
											<Form.Check id={checkboxId}>
												<Form.Check.Input {...checkboxProps} />
												<Form.Check.Label onClick={checkboxProps.onClick} />
											</Form.Check>
										</TableCell>

										{Object.values(tableColumnsData).map((columnConfig) => {
											if (!columnConfig.isShowing) {
												return null;
											}

											const { columnData } = data;

											switch (columnConfig.columnId) {
												case PERFORMANCE_METRICS_TABLE_COLUMN_IDS.PERFORMANCE_METRICS:
													return (
														<TableCell
															key={columnConfig.columnId}
															className="pl-0"
															fixed={true}
															fixedOffset={55}
															width={290}
														>
															{columnData.description}
														</TableCell>
													);
												case PERFORMANCE_METRICS_TABLE_COLUMN_IDS.BUSINESS_PROCESS:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.businessProcess}
														</TableCell>
													);
												case PERFORMANCE_METRICS_TABLE_COLUMN_IDS.PEER_GROUP:
													return (
														<TableCell
															key={columnConfig.columnId}
															className="d-flex align-items-center"
														>
															<span>{columnData.peerGroup}</span>
														</TableCell>
													);
												case PERFORMANCE_METRICS_TABLE_COLUMN_IDS.DIGITAL_WORLD_CLASS:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.worldClass}
														</TableCell>
													);
												case PERFORMANCE_METRICS_TABLE_COLUMN_IDS.TOP_PERFORMER:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.topPerformer}
														</TableCell>
													);
												case PERFORMANCE_METRICS_TABLE_COLUMN_IDS.INDUSTRY_PEER:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.industryPeer}
														</TableCell>
													);
												case PERFORMANCE_METRICS_TABLE_COLUMN_IDS.FIRST_QUARTILE:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.firstQuartile}
														</TableCell>
													);
												case PERFORMANCE_METRICS_TABLE_COLUMN_IDS.DRIVER:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.driver}
														</TableCell>
													);
												case PERFORMANCE_METRICS_TABLE_COLUMN_IDS.CATEGORY:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.category}
														</TableCell>
													);
												case PERFORMANCE_METRICS_TABLE_COLUMN_IDS.SOURCE:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.source}
														</TableCell>
													);
												default:
													return <TableCell key={columnConfig.columnId}>N/A</TableCell>;
											}
										})}
									</TableRow>
								);
							}}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};
