import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';

import { constructUrl } from '@/lib/utils';
import { AdvisorModel, InquiryModel, INQUIRY_STATUS_ID, FormOption } from '@/lib/models';
import { advisoryTeamService, inquiryService } from '@/lib/services';
import { useQuery } from '@/hooks';
import { TablePagination } from '@/components/table';
import { AsyncPage, Avatar, ContactRequest, DateInput, FilterTabs } from '@/components';
import { format } from 'date-fns';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Helmet } from 'react-helmet';

export const ClientInquiries = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();

	const searchParams = useQuery();
	const status = searchParams.get('status') ?? INQUIRY_STATUS_ID.OPEN;
	const page = searchParams.get('page') ?? '0';
	const size = 10;

	const [inquiries, setInquiries] = useState<InquiryModel[]>([]);
	const [totalInquiryCount, setTotalInquiryCount] = useState(0);
	const [yourTeam, setYourTeam] = useState<AdvisorModel[]>([]);

	const topicIds = useMemo(() => searchParams.get('topicIds'), [searchParams]);
	const requestNumber = useMemo(() => searchParams.get('requestNumber'), [searchParams]);
	const fromDate = useMemo(() => searchParams.get('fromDate'), [searchParams]);
	const toDate = useMemo(() => searchParams.get('toDate'), [searchParams]);
	const [topicsOptions, setTopicsOptions] = useState<FormOption[]>([]);
	const [filterValues, setFilterValues] = useState({
		topicsValue: [] as FormOption[],
		requestNumber: '',
		fromDate: fromDate ? new Date(fromDate) : null,
		toDate: toDate ? new Date(toDate) : null,
	});
	const [oldFilterValues, setOldFilterValues] = useState({
		topicsValue: [] as FormOption[],
		requestNumber: '',
		fromDate: fromDate ? new Date(fromDate) : null,
		toDate: toDate ? new Date(toDate) : null,
	});
	const [originalFilter, setOriginalFilter] = useState(true);

	const getStatusToSubmit = () => {
		let statusToSubmit = [];

		// OPEN is not a real status, submit all that are not CLOSED instead
		if (status === INQUIRY_STATUS_ID.OPEN) {
			statusToSubmit = [
				INQUIRY_STATUS_ID.INQUIRY_RECEIVED,
				INQUIRY_STATUS_ID.INQUIRY_CONFIRMED,
				INQUIRY_STATUS_ID.IN_PROGRESS,
				INQUIRY_STATUS_ID.QC_REVIEW,
			];
		} else {
			statusToSubmit = [INQUIRY_STATUS_ID.CLOSED];
		}

		return statusToSubmit;
	};

	const fetchInquiryData = useCallback(async () => {
		let statusToSubmit = getStatusToSubmit();

		const response = await inquiryService
			.getInquiries({
				...(statusToSubmit.length > 0 && { status: statusToSubmit }),
				...(page && { page }),
				...(size && { size: String(size) }),
				...(topicIds && { topicIds: topicIds.split('|').filter((s) => s) }),
				...(requestNumber && { requestNumber }),
				...(fromDate && { fromDate }),
				...(toDate && { toDate }),
			})
			.fetch();

		setInquiries(response.inquiries);
		setTotalInquiryCount(response.totalCount);
	}, [page, status]);

	const fetchFilterOptions = useCallback(async () => {
		const response = await inquiryService.getInquiryFilters().fetch();

		setTopicsOptions(response.topics);
	}, []);

	useEffect(() => {
		const topicIdsFromQuery = (topicIds ?? '').split('|').filter((s) => s);
		let selectedTopics: FormOption[] = [];
		topicIdsFromQuery.forEach((tid) => {
			topicsOptions.forEach((t) => {
				if (tid === t.id) {
					selectedTopics.push(t);
				}
			});
		});

		setFilterValues((previousValue) => ({
			...previousValue,
			topicsValue: selectedTopics,
		}));
	}, [topicIds, topicsOptions]);

	const handleTabChange = useCallback(
		(status: string) => {
			let topics =
				oldFilterValues.topicsValue.length > 0
					? oldFilterValues.topicsValue.map((formOption) => formOption.id)
					: ([] as String[]);
			let params = {
				status,
				page: '0',
				size: String(size),
				requestNumber: oldFilterValues.requestNumber,
				fromDate: oldFilterValues.fromDate ? format(oldFilterValues.fromDate, 'yyyy-MM-dd') : '',
				toDate: oldFilterValues.toDate ? format(oldFilterValues.toDate, 'yyyy-MM-dd') : '',
				topicIds: topics,
			};

			if (originalFilter && oldFilterValues.topicsValue.length >= 1) {
				params.topicIds = oldFilterValues.topicsValue.map((formOption) => formOption.id);
			}

			history.push(constructUrl(pathname, params, search));
		},
		[history, pathname, search]
	);

	const handlePaginationChange = ({ pageIndex }: { pageIndex: number }) => {
		history.push(constructUrl(pathname, { page: String(pageIndex) }, search));
	};

	const fetchYourTeam = useCallback(async () => {
		const response = await advisoryTeamService.getAdvisoryTeam().fetch();
		const firstThree = response.advisors.splice(0, 3);
		setYourTeam(firstThree);
	}, []);

	const handleFilterFormSubmit = useCallback(async () => {
		let statusToSubmit = getStatusToSubmit();
		let topicIds = filterValues.topicsValue.map((formOption) => formOption.id);
		let requestNumber = filterValues.requestNumber;
		let fromDate = filterValues.fromDate ? format(filterValues.fromDate, 'yyyy-MM-dd') : '';
		let toDate = filterValues.toDate ? format(filterValues.toDate, 'yyyy-MM-dd') : '';

		const response = await inquiryService
			.getInquiries({
				...(statusToSubmit.length > 0 && { status: statusToSubmit }),
				...(page && { page }),
				...(size && { size: String(size) }),
				...(topicIds && { topicIds }),
				...(requestNumber && { requestNumber }),
				...(fromDate && { fromDate }),
				...(toDate && { toDate }),
			})
			.fetch();

		setInquiries(response.inquiries);
		setTotalInquiryCount(response.totalCount);
		setOriginalFilter(true);
		setOldFilterValues(filterValues);

		history.push(
			constructUrl(
				pathname,
				{ status: status, page: '0', size: String(size), topicIds, requestNumber, fromDate, toDate },
				search
			)
		);
	}, [requestNumber, status, filterValues]);

	const handleClearFiltersButtonClick = useCallback(async () => {
		setFilterValues({
			topicsValue: [],
			requestNumber: '',
			fromDate: null,
			toDate: null,
		});
		setOldFilterValues({
			topicsValue: [],
			requestNumber: '',
			fromDate: null,
			toDate: null,
		});
		setOriginalFilter(true);

		let statusToSubmit = getStatusToSubmit();

		const response = await inquiryService
			.getInquiries({
				...(statusToSubmit.length > 0 && { status: statusToSubmit }),
				...(page && { page }),
				...(size && { size: String(size) }),
			})
			.fetch();

		setInquiries(response.inquiries);
		setTotalInquiryCount(response.totalCount);

		history.push(constructUrl(pathname, { status: status, page: '0', size: String(size) }, search));
	}, [status]);

	const getActiveFilterLength = () => {
		return Object.values(filterValues).filter((fv) => {
			if (Array.isArray(fv)) {
				return fv.length;
			}

			return fv;
		}).length;
	};

	const showFilterChangedMessage = () => {
		let message = 'Please click Apply button to show results.';
		if (originalFilter) {
			message = '';
		}
		return message;
	};

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Expert Inquiries</title>
			</Helmet>
			<Container className="pt-7">
				<Row>
					<Col>
						<div className="d-flex align-items-center justify-content-between">
							<h1 className="mb-0 text-primary">Inquiries</h1>

							<Button
								variant="outline-primary"
								onClick={() => {
									history.push('/contact/create-inquiry');
								}}
							>
								Create a new inquiry
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
			<Container className="py-7">
				<Row>
					<Col xs={12} xl={8} className="mb-3">
						<FilterTabs
							value={status}
							tabs={[
								{ title: 'Open', value: INQUIRY_STATUS_ID.OPEN },
								{ title: 'Closed', value: INQUIRY_STATUS_ID.CLOSED },
							]}
							onChange={handleTabChange}
						/>

						<AsyncPage fetchData={fetchInquiryData}>
							{inquiries.length === 0 ? (
								<div className="py-8">
									<p className="mb-2 text-muted text-center">
										<strong>Your Advisory Team is here to help.</strong>
									</p>
									<p className="text-muted text-center">
										Reach out to us for knowledge, insight, or assistance.
									</p>
								</div>
							) : (
								<>
									{inquiries.map((inquiry) => {
										return (
											<ContactRequest
												key={inquiry.inquiryId}
												name={inquiry.inquiryTitle}
												time={`Submitted ${inquiry.createdDescription}`}
												lastMessage={inquiry.listInquiryText}
												href={`/inquiries/${inquiry.inquiryId}`}
											>
												<div className="mt-1 d-flex justify-content-between">
													<p className="text-muted">{inquiry.inquiryListDescription}</p>
													<p className="text-muted">{inquiry.advisorAccount.name}</p>
												</div>
											</ContactRequest>
										);
									})}
									<TablePagination
										showPageSize={false}
										pageSize={size}
										pageIndex={parseInt(page ?? '0', 10)}
										total={totalInquiryCount}
										onChange={handlePaginationChange}
									/>
								</>
							)}
						</AsyncPage>
					</Col>
					<Col xs={12} xl={4}>
						<Card>
							<Card.Body>
								<div className="mb-4 d-flex align-items-center justify-content-between">
									<Card.Title className="mb-0">Filters</Card.Title>
									<Button variant="link" onClick={handleClearFiltersButtonClick}>
										Clear {getActiveFilterLength() > 0 && `(${getActiveFilterLength()})`}
									</Button>
								</div>
								<div className="mb-4 d-flex bold text-danger">{showFilterChangedMessage()}</div>
								<AsyncPage fetchData={fetchFilterOptions}>
									<Form>
										<Form.Group className="mb-6">
											<Form.Label>Topics</Form.Label>
											<Typeahead
												id="typeahead--topics"
												labelKey="display"
												multiple
												selected={filterValues.topicsValue}
												onChange={(selected) => {
													setFilterValues({
														...filterValues,
														topicsValue: selected as FormOption[],
													});
													setOriginalFilter(false);
												}}
												options={topicsOptions}
												placeholder="Select topics..."
											/>
										</Form.Group>
										<Form.Group className="mb-6">
											<Form.Label>Inquiry #</Form.Label>
											<Form.Control
												type="text"
												maxLength={9}
												value={filterValues.requestNumber}
												onChange={({ currentTarget }) => {
													currentTarget.value = currentTarget.value.replace(/[^0-9]+/gi, '');
													setFilterValues({
														...filterValues,
														requestNumber: currentTarget.value,
													});
													if (currentTarget.value.length) {
														setOriginalFilter(false);
													}
												}}
												placeholder="Enter Inquiry #..."
											/>
										</Form.Group>
										<Form.Group className="mb-6">
											<Form.Label>From Date</Form.Label>
											<DateInput
												isClearable
												maxDate={filterValues.toDate}
												selected={filterValues.fromDate}
												onChange={(date) => {
													setFilterValues((previousValue) => ({
														...previousValue,
														fromDate: date,
													}));
													setOriginalFilter(false);
												}}
											/>
										</Form.Group>
										<Form.Group className="mb-6">
											<Form.Label>To Date</Form.Label>
											<DateInput
												isClearable
												minDate={filterValues.fromDate}
												selected={filterValues.toDate}
												onChange={(date) => {
													setFilterValues((previousValue) => ({
														...previousValue,
														toDate: date,
													}));
													setOriginalFilter(false);
												}}
											/>
										</Form.Group>
										<div className="mb-6 text-center">
											<Button
												variant="outline-primary"
												type="button"
												onClick={handleFilterFormSubmit}
											>
												Apply Filters
											</Button>
										</div>
									</Form>
								</AsyncPage>
								<hr className="mb-6" />
								<Card.Title className="mb-5">Your Team</Card.Title>
								<AsyncPage fetchData={fetchYourTeam}>
									{yourTeam.map((advisor) => {
										return (
											<div key={advisor.advisorId} className="mb-5 d-flex align-items-center">
												<Avatar className="mr-5" size={60} url={advisor.imageUrl} />
												<div>
													<p className="mb-0">
														<strong>{advisor.role}</strong>
													</p>
													<p className="mb-0">{advisor.name}</p>
												</div>
											</div>
										);
									})}
									<div className="text-center">
										<Link to="/advisory-team">See All</Link>
									</div>
								</AsyncPage>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};
