import React, { FC, createContext, useCallback, useState } from 'react';
import ReactGA from 'react-ga4';
import config from '@/lib/config';
import { CookieyesEventModel } from '@/lib/models';
import { accountService } from '@/lib/services';

export const AnalyticsContext = createContext<
	| {
		cookieListener(url: string): void;
		initialize(): void;
		handleAnalyticOutbound(link: string): void;
		handleClickEvent(url: string): void;
	}
	| undefined
>(undefined);


export const AnalyticsProvider: FC = ({ children }) => {
	const analyticsStr = 'analytics'; //set by cookieyes
	const [isInitialized, setIsInitialized] = useState<boolean>(false);

	const handleAnalyticOutbound = (link: string) => {
		const a = document.createElement('a');
		a.href = link;
		a.target = "_blank";
		a.rel = "noopener";
		document.body.appendChild(a);
		a.click();
		a.remove();
	};

	const cookieListener = (url: string) => {
		// https://www.cookieyes.com/documentation/events-on-cookie-banner-interactions/
		document.addEventListener('cookieyes_consent_update', function (eventData: CookieyesEventModel) {
			if (typeof eventData.detail !== 'undefined' && eventData.detail.accepted.includes(analyticsStr)) {
				initialize();
			}
		});
	};

	const initialize = useCallback(async () => {

		if (isInitialized) {
			return;
		}
		try {
			const accountData = await accountService.getCurrentAccount().fetch();
			ReactGA.initialize(config.HACKETT_ADVISORY_GOOGLE_ANALYTICS_ID, {
				testMode: __DEV__,
				gaOptions: accountData.account.analytics
			});
			ReactGA.set({ userId: accountData.account.accountId });
		} catch (error) {
			ReactGA.initialize(config.HACKETT_ADVISORY_GOOGLE_ANALYTICS_ID, {
				testMode: __DEV__
			});
		}
		setIsInitialized(true);
	}, [isInitialized]);

	const handleClickEvent = (url: string) => {
		ReactGA.gtag('event', 'click', {
			'event_category': 'outbound',
			'link_url': url,
			'transport_type': 'beacon',
			'outbound': 'true'
		});
	};
	const value = {
		cookieListener,
		initialize,
		handleAnalyticOutbound,
		handleClickEvent
	};

	return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};
