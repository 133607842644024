import React from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

import { ReactComponent as PaperclipIcon } from '@/assets/icons/icon-paperclip.svg';
import { Avatar } from '@/components';

const useStyles = createUseThemedStyles({
	commentOuter: {
		display: 'flex',
		marginBottom: 20,
	},
	comment: {
		flex: 1,
	},
});

interface CommentProps {
	avatarUrl: string;
	name: string;
	title: string;
	comment: string;
	attachments?: {
		title: string;
		url: string;
	}[];
	className?: string;
}

export const Comment = ({ avatarUrl, name, title, comment, attachments, className }: CommentProps) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.commentOuter, className)}>
			<Avatar className="mr-3" size={34} url={avatarUrl} />
			<div className={classes.comment}>
				<h6>
					{name}, {title}
				</h6>
				<p className="mb-1 small" dangerouslySetInnerHTML={{ __html: comment }} />
				{!!attachments?.length &&
					attachments.map((attachment, index) => {
						return (
							<Button key={index} className="d-flex small align-items-center" variant="link">
								<PaperclipIcon className="d-flex mr-1" />
								{attachment.title}
							</Button>
						);
					})}
			</div>
		</div>
	);
};
