import React from 'react';
import { Button, Form, FormCheckProps } from 'react-bootstrap';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import Color from 'color';

import { createUseThemedStyles } from '@/hooks';
import { ReactComponent as TrashIcon } from '@/assets/icons/icon-trash.svg';

interface useStylesProps {
	checked: boolean;
}

const useStyles = createUseThemedStyles((theme) => ({
	deliverablesEntryListItem: ({ checked }: useStylesProps) => ({
		display: 'flex',
		padding: '20px 16px',
		alignItems: 'stretch',
		borderBottom: `1px solid ${theme.colors.gray300}`,
		backgroundColor: checked
			? Color(theme.colors.primary)
					.alpha(0.08)
					.string()
			: 'transparent',
	}),
	documentInformation: {
		flex: 1,
	},
}));

interface DeliverablesEntryListItemProps {
	showCheck?: boolean;
	checkboxProps?: FormCheckProps;
	id: string;
	title: string;
	description: string;
	date: string;
	topic: string;
	showRemove?: boolean;
	onRemove?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const DeliverablesEntryListItem = ({
	id,
	showCheck,
	checkboxProps,
	title,
	description,
	date,
	topic,
	showRemove,
	onRemove,
}: DeliverablesEntryListItemProps) => {
	const classes = useStyles({
		checked: checkboxProps?.checked ?? false,
	});

	return (
		<div className={classes.deliverablesEntryListItem}>
			{showCheck && (
				<div className="d-flex align-items-start">
					<Form.Check className="mr-4" label="" {...checkboxProps} />
				</div>
			)}
			<div className={classes.documentInformation}>
				<p className="mb-0 font-weight-semi-bold">{title}</p>
				<ResponsiveEllipsis text={description ?? ''} maxLine="2" component="p" />
				<p className="mb-0 text-muted">
					{date} &bull; {topic}
				</p>
			</div>
			{showRemove && (
				<div className="d-flex align-items-start">
					<Button className="ml-4" variant="link" onClick={onRemove}>
						Remove <TrashIcon />
					</Button>
				</div>
			)}
		</div>
	);
};
