import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { ErrorCode, FileRejection } from 'react-dropzone';

import { contactsTableColumns, contactsTableHeaders, CONTACTS_TABLE_COLUMN_IDS } from '@/lib/__mocks__';
import {
	AccountModel,
	DocumentModel,
	EventModel,
	FormOption,
	UnmatchedAccountModel,
	DOCUMENT_UPLOAD_STATUS_ID,
	PresignedUploadModel,
	deliverableUploadedAttachment,
	DeliverableDocumentUploaded,
} from '@/lib/models';
import { TableRowConfig } from '@/components/table/models';
import { documentUploader, deliverablesService } from '@/lib/services';
import { createUseThemedStyles, useAlert, useHandleError, useNavigationConfig } from '@/hooks';

import {
	AsyncPage,
	DateInput,
	DeliverablesContactsModal,
	DeliverablesDocumentListItem,
	DeliverablesDocumentModal,
	DeliverablesEntryListItem,
	DeliverablesEntryModal,
	DeliverablesExistingModal,
	ExpandingTextarea,
	FadeTransition,
	FileInputButton,
	LoadingButton,
	Select,
	AttachmentDropdown,
	FileDropzone,
} from '@/components';
import { TableCell, TableHeader, TableRenderer, TableRow } from '@/components/table';
import { mediaQueries } from '@/jss';

import { ReactComponent as TrashIcon } from '@/assets/icons/icon-trash.svg';
import { Helmet } from 'react-helmet';

const useStyles = createUseThemedStyles((theme) => ({
	formRow: {
		boxShadow: `${theme.colors.gray300} 0px 2px 8px`,
		margin: '0',
	},
	formCol: {
		padding: '38px 50px 50px',
		background: theme.colors.white,
		'&:not(:last-child)': {
			borderBottom: `1px solid ${theme.colors.gray200}`,
			[mediaQueries.lg]: {
				borderRight: `1px solid ${theme.colors.gray200}`,
				borderBottom: '0',
			},
		},
	},
}));

export const DeliverablesCreate = () => {
	const history = useHistory();
	const classes = useStyles();
	const handleError = useHandleError();
	const { showAlert } = useAlert();
	const { navigationConfig } = useNavigationConfig();
	const { deliverableId } = useParams<{ deliverableId: string }>();

	/* Form state */
	const [createDocumentUrl, setCreateDocumentUrl] = useState('');
	const [createClientUrl, setCreateClientUrl] = useState('');
	const [deliverableTypeOptions, setDeliverableTypeOptions] = useState<FormOption[]>([]);
	const [topicOptions, setTopicOptions] = useState<FormOption[]>([]);
	const [programOptions, setProgramOptions] = useState<FormOption[]>([]);
	const [deliverableForm, setDeliverableForm] = useState({
		title: '',
		type: '',
		hours: 0,
		deliveryDate: new Date(),
		topic: '',
		programs: [] as FormOption[],
		description: '',
		entries: [] as EventModel[],
		documents: [] as DocumentModel[],
		contacts: [] as Array<AccountModel & { deliveredDate: Date | null }>,
		documentsUploaded: [] as DeliverableDocumentUploaded[],
	});
	const [isSaving, setIsSaving] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [documentUploaded, setDocumentUploaded] = useState<
		{ file: File; fileId: string; deliverableId: string; downloadUrl: string }[]
	>([]);

	/* Modal state */
	const [deliverablesEntryModalIsOpen, setDeliverablesEntryModalIsOpen] = useState(false);
	const [deliverablesDocumentModalIsOpen, setDeliverablesDocumentModalIsOpen] = useState(false);
	const [deliverablesExistingModalIsOpen, setDeliverablesExistingModalIsOpen] = useState(false);
	const [deliverablesContactsModalIsOpen, setDeliverablesContactsModalIsOpen] = useState(false);
	const [contactsFileName, setContactsFileName] = useState('');
	const [unmatchedContacts, setUnmatchedContacts] = useState<UnmatchedAccountModel[]>([]);
	const [matchedContacts, setMatchedContacts] = useState<AccountModel[]>([]);

	/* Contacts table state */
	const [tableIsLoading] = useState(false);
	const [tablePageSize] = useState(1000);
	const [tablePageIndex] = useState(0);
	const [tableColumnsData, setTableColumnsData] = useState(contactsTableColumns);
	const [tableHeadersData] = useState(contactsTableHeaders);
	const [contactsRowData, setContactsRowData] = useState<
		TableRowConfig<AccountModel & { deliveredDate: Date | null }>[]
	>([]);

	const handleFileDropzoneChange = useCallback(
		async (files: File[], fileRejections: FileRejection[]) => {
			for (const fileRejection of fileRejections) {
				for (const rejectionError of fileRejection.errors) {
					if (rejectionError.code === ErrorCode.FileInvalidType) {
						showAlert({
							variant: 'danger',
							children: () => {
								return (
									<p className="mb-0 text-white">
										One or more files is an incorrect file type. Choose files with valid type: any
										office type, pdf or zip.
									</p>
								);
							},
						});

						return;
					}
					if (rejectionError.code === ErrorCode.FileTooLarge) {
						showAlert({
							variant: 'danger',
							children: () => {
								return (
									<p className="mb-0 text-white">File size cannot exceed 100MB. Please try again.</p>
								);
							},
						});

						return;
					}
				}
			}

			files.map((file) => {
				setDocumentUploaded((previousValue) => [
					...previousValue,
					{ file: file, fileId: uuid(), deliverableId: '', downloadUrl: '' },
				]);
			});
		},
		[showAlert]
	);

	const fetchData = useCallback(async () => {
		const response = await deliverablesService.getDeliverablesForm({ deliverableId: deliverableId? deliverableId : '' }).fetch();

		if (deliverableId) {
			const deliverableResponse = await deliverablesService.getDeliverableById(deliverableId).fetch();

			setDeliverableForm({
				title: deliverableResponse.title,
				type: deliverableResponse.deliverableTypeId,
				hours: deliverableResponse.hours,
				deliveryDate: new Date(deliverableResponse.deliveryDate),
				topic: deliverableResponse.topicId ?? '',
				programs: deliverableResponse.programIds.map((programId) => {
					const programOption = response.programs.find((program) => program.id === programId);

					if (programOption) {
						return programOption;
					}

					return {
						id: programId,
						display: 'Program not found',
					};
				}),
				description: deliverableResponse.description,
				entries: deliverableResponse.events,
				documents: deliverableResponse.documents,
				contacts: deliverableResponse.contacts.map((contact) => ({
					...contact,
					deliveredDate: contact.deliveryDate ? new Date(contact.deliveryDate) : null,
				})),
				documentsUploaded: deliverableResponse.documentsUploaded,
			});
			setDocumentUploaded(
				deliverableResponse.documentsUploaded.map((document) => {
					var file = new File([document.title], document.title, {
						type: 'text/plain',
					});
					return {
						file: file,
						fileId: document.deliverableDocumentUploadedId,
						deliverableId: document.deliverableId,
						downloadUrl: document.documentViewUrl,
					};
				})
			);
		}

		setCreateDocumentUrl(response.createDocumentUrl);
		setCreateClientUrl(response.createClientUrl);
		setDeliverableTypeOptions(response.deliverableType);
		setTopicOptions(response.topics);
		setProgramOptions(response.programs);
	}, [deliverableId]);

	const handleContactsFileChange = async (file: File) => {
		try {
			const response = await deliverablesService.importContactsFromFile(file).fetch();

			setContactsFileName(file.name);
			setUnmatchedContacts(response.unmatchedContacts);
			setMatchedContacts(response.matchedAccounts);
			setDeliverablesContactsModalIsOpen(true);
		} catch (error) {
			handleError(error);
		}
	};

	useEffect(() => {
		const formattedContacts = deliverableForm.contacts.map((mc) => {
			return {
				rowId: mc.accountId,
				checked: false,
				expanded: false,
				columnData: mc,
			};
		});

		setContactsRowData(formattedContacts);
	}, [deliverableForm.contacts]);

	const handleEntryRemove = (entryId: string) => {
		if (!window.confirm('Are you sure you want to remove this entry?')) {
			return;
		}

		const deliverableFormClone = cloneDeep(deliverableForm);
		const indexToRemove = deliverableFormClone.entries.findIndex((e) => e.eventId === entryId);

		if (indexToRemove > -1) {
			deliverableFormClone.entries.splice(indexToRemove, 1);
		}

		setDeliverableForm(deliverableFormClone);
	};

	const handleDocumentUploadedRemove = useCallback((document: { file: File; fileId: string }) => {
		if (!window.confirm('Are you sure you want to remove this document?')) {
			return;
		}

		setDocumentUploaded((previousValue) => {
			const indexToRemove = previousValue.findIndex((v) => v.fileId === document.fileId);

			if (indexToRemove > -1) {
				previousValue.splice(indexToRemove, 1);
			}

			return [...previousValue];
		});
	}, []);

	const handleDocumentRemove = (documentId: string) => {
		if (!window.confirm('Are you sure you want to remove this document?')) {
			return;
		}

		const deliverableFormClone = cloneDeep(deliverableForm);
		const indexToRemove = deliverableFormClone.documents.findIndex((d) => d.documentId === documentId);

		if (indexToRemove > -1) {
			deliverableFormClone.documents.splice(indexToRemove, 1);
		}

		setDeliverableForm(deliverableFormClone);
	};

	const handleContactDateChange = (rowData: TableRowConfig<AccountModel>, date: Date | null) => {
		if (!date) {
			return;
		}

		const deliverableFormClone = cloneDeep(deliverableForm);
		const indexToUpdate = deliverableFormClone.contacts.findIndex(
			(c) => c.accountId === rowData.columnData.accountId
		);

		if (indexToUpdate > -1) {
			deliverableFormClone.contacts[indexToUpdate].deliveredDate = date;
		}

		setDeliverableForm(deliverableFormClone);
	};

	const handleContactDeleteButtonClick = (rowData: TableRowConfig<AccountModel>) => {
		if (!window.confirm('Are you sure you want to remove this contact?')) {
			return;
		}

		const deliverableFormClone = cloneDeep(deliverableForm);
		const indexToRemove = deliverableFormClone.contacts.findIndex(
			(c) => c.accountId === rowData.columnData.accountId
		);

		if (indexToRemove > -1) {
			deliverableFormClone.contacts.splice(indexToRemove, 1);
		}

		setDeliverableForm(deliverableFormClone);
	};

	const handleDeliverableDeleteButtonClick = useCallback(async () => {
		if (!window.confirm('Are you sure you want to delete this entry?')) {
			setIsDeleting(false);
			return;
		}
		setIsDeleting(true);

		try {
			await deliverablesService.deleteDeliverable(deliverableId).fetch();
			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Deliverable deleted.</p>;
				},
			});
			history.push('/client-management/deliverables');
		} catch (error) {
			handleError(error);
			setIsDeleting(false);
		}
	}, [deliverableId, handleError, history, showAlert]);

	const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		try {
			setIsSaving(true);

			const requestBody = {
				contacts: deliverableForm.contacts.map((c) => ({
					contactId: c.accountId,
					deliveryDate: format(c.deliveredDate ?? deliverableForm.deliveryDate, 'yyyy-MM-dd'),
				})),
				deliverableTypeId: deliverableForm.type,
				hours: deliverableForm.hours,
				deliveryDate: format(deliverableForm.deliveryDate, 'yyyy-MM-dd'),
				description: deliverableForm.description,
				documentIds: deliverableForm.documents.map((d) => d.documentId),
				deliverableDocumentUploadedIds: documentUploaded.filter((d) => d.fileId !== '').map((d) => d.fileId),
				eventIds: deliverableForm.entries.map((e) => e.eventId),
				programIds: deliverableForm.programs.map((p) => p.id),
				title: deliverableForm.title,
				topicId: deliverableForm.topic,
			};

			let deliverableIdResponse = deliverableId;
			if (deliverableId) {
				await deliverablesService.updateDeliverable(deliverableId, requestBody).fetch();

				showAlert({
					variant: 'success',
					children: () => {
						return <p className="mb-0 text-white">Deliverable updated.</p>;
					},
				});
			} else {
				const deliverableResponse = await deliverablesService.postDeliverable(requestBody).fetch();
				deliverableIdResponse = deliverableResponse.deliverableId;

				showAlert({
					variant: 'success',
					children: () => {
						return <p className="mb-0 text-white">Deliverable created.</p>;
					},
				});
			}

			const presignedUploadPromises = documentUploaded
				.filter((d) => d.deliverableId === '')
				.map(({ file }) => {
					return new Promise((resolve: (presignedUpload: PresignedUploadModel) => void, reject) => {
						documentUploader(
							file,
							deliverablesService.getPresignedDocumentUpload({
								deliverableId: deliverableIdResponse,
								contentType: file.type,
								title: file.name,
							}).fetch
						)
							.onPresignedUploadObtained(() => {})
							.onComplete(async (presignedUpload) => {
								try {
									await deliverablesService
										.updateDeliverableDocumentUploadedStatus({
											documentUploadStatusId: DOCUMENT_UPLOAD_STATUS_ID.COMPLETE,
											deliverableId: deliverableIdResponse,
											deliverableDocumentUploadedId: presignedUpload.documentId,
										})
										.fetch();

									return resolve(presignedUpload);
								} catch (error) {
									return reject(error);
								}
							})
							.onError((error) => {
								handleError(error);
							})
							.start();
					});
				});

			await Promise.all(presignedUploadPromises);

			history.push('/client-management/deliverables');
		} catch (error) {
			handleError(error);
			setIsSaving(false);
		}
	};

	return (
		<>
			<DeliverablesEntryModal
				value={deliverableForm.entries}
				onSave={(selectedEntries) => {
					setDeliverableForm((previousValue) => ({
						...previousValue,
						entries: selectedEntries,
					}));
					setDeliverablesEntryModalIsOpen(false);
				}}
				show={deliverablesEntryModalIsOpen}
				onHide={() => {
					setDeliverablesEntryModalIsOpen(false);
				}}
			/>

			<DeliverablesDocumentModal
				value={deliverableForm.documents}
				onSave={(selectedDocuments) => {
					setDeliverableForm((previousValue) => ({
						...previousValue,
						documents: selectedDocuments,
					}));
					setDeliverablesDocumentModalIsOpen(false);
				}}
				show={deliverablesDocumentModalIsOpen}
				onHide={() => {
					setDeliverablesDocumentModalIsOpen(false);
				}}
			/>

			<DeliverablesExistingModal
				createClientUrl={createClientUrl}
				value={deliverableForm.contacts}
				onSave={(selectedContacts) => {
					const formatedSelectedContacts = selectedContacts.map((cont) => {
						return {
							...cont,
							deliveredDate: null,
						};
					});

					setDeliverableForm((previousValue) => ({
						...previousValue,
						contacts: formatedSelectedContacts,
					}));
					setDeliverablesExistingModalIsOpen(false);
				}}
				show={deliverablesExistingModalIsOpen}
				onHide={() => {
					setDeliverablesExistingModalIsOpen(false);
				}}
			/>

			<DeliverablesContactsModal
				fileName={contactsFileName}
				unmatchedContacts={unmatchedContacts}
				matchedContacts={matchedContacts}
				onSave={(selectedContacts) => {
					const formatedSelectedContacts = selectedContacts.map((cont) => {
						return {
							...cont,
							deliveredDate: null,
						};
					});

					setDeliverableForm((previousValue) => ({
						...previousValue,
						contacts: previousValue.contacts
							.concat(formatedSelectedContacts)
							.filter(
								(account, index, self) =>
									index === self.findIndex((a) => a.accountId === account.accountId)
							),
					}));

					setDeliverablesContactsModalIsOpen(false);
				}}
				show={deliverablesContactsModalIsOpen}
				onHide={() => {
					setDeliverablesContactsModalIsOpen(false);
				}}
			/>

			<Container className="py-13">
				<Helmet>
					<title>
						Hackett Connect - Client Management - Deliverables -{' '}
						{deliverableId ? 'Edit Deliverable' : 'New Deliverable'}
					</title>
				</Helmet>
				<Row className="mb-2">
					<Col>
						<p className="mb-0 text-muted">
							<Link to="/client-management/deliverables">Deliverables /</Link>{' '}
							{deliverableId ? 'Edit Deliverable' : 'New Deliverable'}
						</p>
					</Col>
				</Row>
				<AsyncPage fetchData={fetchData}>
					<Form onSubmit={handleFormSubmit}>
						<Row className="mb-6">
							<Col>
								<div className="d-flex justify-content-between align-items-end">
									<div>
										<h1 className="mb-0">
											{deliverableId
												? `Edit "${deliverableForm.title}"`
												: 'Create a new Deliverable'}
										</h1>
									</div>
									<div className="d-flex align-items-center text-nowrap">
										<Button
											className="mr-4"
											variant="link"
											onClick={() => {
												history.goBack();
											}}
										>
											Cancel
										</Button>
										{deliverableId && (
											<LoadingButton
												className="mr-4 d-flex align-items-center"
												variant="outline-primary"
												onClick={handleDeliverableDeleteButtonClick}
												isLoading={isDeleting}
											>
												<TrashIcon className="mr-3" />
												Delete
											</LoadingButton>
										)}
										<LoadingButton type="submit" isLoading={isSaving}>
											Save
										</LoadingButton>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col>
								<h3 className="mb-0">Details</h3>
							</Col>
						</Row>
						<Row className={classNames(classes.formRow, 'mb-12')}>
							<Col lg={6} className={classes.formCol}>
								<Form.Group className="mb-5">
									<Form.Label>Title</Form.Label>
									<Form.Control
										type="text"
										value={deliverableForm.title}
										onChange={({ currentTarget }) => {
											setDeliverableForm((previousValue) => ({
												...previousValue,
												title: currentTarget.value,
											}));
										}}
										disabled={isSaving}
									/>
								</Form.Group>
								<Form.Group
									className={classNames({
										'mb-5': deliverableForm.type === 'BRIEFINGS',
									})}
								>
									<Form.Label>Type</Form.Label>
									<Select
										value={deliverableForm.type}
										onChange={({ currentTarget }) => {
											setDeliverableForm((previousValue) => ({
												...previousValue,
												type: currentTarget.value,
											}));
										}}
										disabled={isSaving}
									>
										<option value="" disabled>
											Select type...
										</option>
										{deliverableTypeOptions.map((option) => {
											return (
												<option key={option.id} value={option.id}>
													{option.display}
												</option>
											);
										})}
									</Select>
								</Form.Group>
								<FadeTransition in={deliverableForm.type === 'BRIEFINGS'}>
									<Form.Group>
										<Form.Label>Hours</Form.Label>
										<Form.Control
											step="0.5"
											type="number"
											min="0"
											value={deliverableForm.hours}
											onChange={({ currentTarget }) => {
												let value: number;
												const converted = parseFloat(currentTarget.value);
												let decimal = converted - parseInt(String(converted), 10);
												decimal = Math.round(decimal * 10);
												if (decimal === 5) {
													value = parseInt(String(converted), 10) + 0.5;
												}
												if (decimal < 3 || decimal > 7) {
													value = Math.round(converted);
												} else {
													value = parseInt(String(converted), 10) + 0.5;
												}

												setDeliverableForm((previousValue) => ({
													...previousValue,
													hours: value,
												}));
											}}
										/>
									</Form.Group>
								</FadeTransition>
							</Col>
							<Col lg={6} className={classes.formCol}>
								<Form.Group className="mb-5">
									<Form.Label>Delivery Date</Form.Label>
									<DateInput
										selected={deliverableForm.deliveryDate}
										onChange={(date) => {
											if (!date) {
												return;
											}

											setDeliverableForm((previousValue) => ({
												...previousValue,
												deliveryDate: date,
											}));
										}}
										disabled={isSaving}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>
										Topic{' '}
										<span className="p text-muted font-italic font-weight-regular">- Optional</span>
									</Form.Label>
									<Select
										value={deliverableForm.topic}
										onChange={({ currentTarget }) => {
											setDeliverableForm((previousValue) => ({
												...previousValue,
												topic: currentTarget.value,
											}));
										}}
										disabled={isSaving}
									>
										<option value="" disabled>
											Select topic...
										</option>
										{topicOptions
											.slice(0)
											.reverse()
											.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
									</Select>
								</Form.Group>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col>
								<h3 className="mb-0">Programs</h3>
							</Col>
						</Row>
						<Row className={classNames(classes.formRow, 'mb-12')}>
							<Col className={classes.formCol}>
								<Form.Group>
									<Form.Label>Advisory Programs </Form.Label>
									<Typeahead
										id="typeahead--programs"
										labelKey="display"
										multiple
										selected={deliverableForm.programs}
										onChange={(selected) => {
											setDeliverableForm((previousValue) => ({
												...previousValue,
												programs: selected as FormOption[],
											}));
										}}
										options={programOptions}
										placeholder="Select programs..."
										disabled={isSaving}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col>
								<h3 className="mb-0">Description</h3>
							</Col>
						</Row>
						<Row className={classNames(classes.formRow, 'mb-12')}>
							<Col className={classes.formCol}>
								<Form.Group>
									<Form.Label>Description</Form.Label>
									<ExpandingTextarea
										value={deliverableForm.description}
										onChange={({ currentTarget }) => {
											setDeliverableForm((previousValue) => ({
												...previousValue,
												description: currentTarget.value,
											}));
										}}
										disabled={isSaving}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row className="mb-12">
							<Col>
								<div className="mb-3 d-flex align-items-end justify-content-between">
									<h3 className="mb-0">
										Hackett Client Portal Calendar Entries ({deliverableForm.entries.length}){' '}
										<span className="p text-muted font-italic font-weight-regular">- Optional</span>
									</h3>
									<Button
										variant="outline-primary"
										onClick={() => {
											setDeliverablesEntryModalIsOpen(true);
										}}
										disabled={isSaving}
									>
										Add Entry
									</Button>
								</div>
								<hr />
								{deliverableForm.entries.map((entry) => {
									return (
										<DeliverablesEntryListItem
											key={entry.eventId}
											id={entry.eventId}
											title={entry.name}
											description={entry.listDescription}
											date={entry.durationDescription ?? ''}
											topic={entry.eventTypeDesc}
											showRemove
											onRemove={() => handleEntryRemove(entry.eventId)}
										/>
									);
								})}
							</Col>
						</Row>
						<Row className="mb-12">
							<Col>
								<div className="mb-3 d-flex align-items-end justify-content-between">
									<h3 className="mb-0">
										Link Site Content ({deliverableForm.documents.length}){' '}
										<span className="p text-muted font-italic font-weight-regular">- Optional</span>
									</h3>
									<div className="d-flex align-items-center">
										{navigationConfig?.allowAdminAccess && (
											<a href={createDocumentUrl} target="_blank" rel="noreferrer">
												Create new document
											</a>
										)}
										<Button
											className="ml-4"
											variant="outline-primary"
											onClick={() => {
												setDeliverablesDocumentModalIsOpen(true);
											}}
											disabled={isSaving}
										>
											Add Document
										</Button>
									</div>
								</div>
								<hr />
								{deliverableForm.documents.map((document) => {
									return (
										<DeliverablesDocumentListItem
											key={document.documentId}
											title={document.name}
											submissionDate={document.publicationDateDescription}
											fileType={document.fileType}
											fileName={document.fileName ?? 'file name unknown'}
											showRemove
											onRemove={() => handleDocumentRemove(document.documentId)}
										/>
									);
								})}
							</Col>
						</Row>
						<Row className="mb-12">
							<Col>
								<div className="mb-3 d-flex align-items-end justify-content-between">
									<h3 className="mb-0">
										Upload Custom Content ({documentUploaded.length}){' '}
										<span className="p text-muted font-italic font-weight-regular">- Optional</span>
									</h3>
								</div>
								<div className="mb-1 align-items-start">
									<Form.Group className="mb-8">
										{documentUploaded.length > 0 &&
											documentUploaded.map((document) => {
												return (
													<AttachmentDropdown
														key={document.fileId}
														className="mb-2"
														title={document.file.name}
														downloadUrl={document.downloadUrl}
														onRemove={() => {
															handleDocumentUploadedRemove(document);
														}}
													/>
												);
											})}
										<FileDropzone
											accept={deliverableUploadedAttachment.accept}
											maxSize={deliverableUploadedAttachment.maxFileSize_bytes}
											onChange={handleFileDropzoneChange}
											disabled={isSaving}
										/>
									</Form.Group>
								</div>
							</Col>
						</Row>

						<Row className="mb-12">
							<Col>
								<div className="mb-3 d-flex align-items-end justify-content-between">
									<h3 className="mb-0">Contacts ({deliverableForm.contacts.length})</h3>
									<div className="d-flex align-items-center">
										{navigationConfig?.allowAdminAccess && (
											<a href={createClientUrl} target="_blank" rel="noreferrer">
												Create new contact
											</a>
										)}
										<Button
											className="ml-4"
											variant="link"
											onClick={() => {
												setDeliverablesExistingModalIsOpen(true);
											}}
										>
											Select existing contacts
										</Button>
										<FileInputButton
											className="ml-4"
											accept=".csv"
											onChange={handleContactsFileChange}
											disabled={isSaving}
										>
											<div className="hackett-button hackett-button-outline-primary">
												Import from file
											</div>
										</FileInputButton>
									</div>
								</div>
								<hr />

								{contactsRowData.length > 0 && (
									<>
										<div className="mt-6 text-right">
											<Button
												variant="link"
												onClick={() => {
													if (
														!window.confirm('Are you sure you want to remove all contacts?')
													) {
														return;
													}

													setDeliverableForm((previousValue) => ({
														...previousValue,
														contacts: [],
													}));
												}}
											>
												Remove All Contacts
											</Button>
										</div>
										<TableRenderer
											isLoading={tableIsLoading}
											tableColumnData={tableColumnsData}
											onTableColumnDataChange={setTableColumnsData}
											tableRowData={contactsRowData}
											tableRowDataTotal={contactsRowData.length}
											onTableRowDataChange={setContactsRowData}
											pageSize={tablePageSize}
											pageIndex={tablePageIndex}
											onPaginationChange={() => {
												return;
											}}
											hidePagination={true}
											hideColumnControls={true}
											tableHeaderRowRenderer={() => {
												return (
													<TableRow>
														{Object.values(tableColumnsData).map((columnConfig) => {
															if (!columnConfig.isShowing) {
																return null;
															}

															const tableHeader = tableHeadersData[columnConfig.columnId];
															const tableHeaderProps = {
																key: tableHeader.tableHeaderId,
																sortable: false,
																sortKey: tableHeader.tableHeaderId,
															};

															switch (columnConfig.columnId) {
																case CONTACTS_TABLE_COLUMN_IDS.ACTIONS:
																	return (
																		<TableHeader
																			className="justify-content-end"
																			{...tableHeaderProps}
																		>
																			{tableHeader.title}
																		</TableHeader>
																	);
																default:
																	return (
																		<TableHeader {...tableHeaderProps}>
																			{tableHeader.title}
																		</TableHeader>
																	);
															}
														})}
													</TableRow>
												);
											}}
											tableBodyRowRenderer={(data) => {
												return (
													<TableRow key={data.rowId}>
														{Object.values(tableColumnsData).map((columnConfig) => {
															if (!columnConfig.isShowing) {
																return null;
															}

															const { columnData } = data;

															switch (columnConfig.columnId) {
																case CONTACTS_TABLE_COLUMN_IDS.COMPANY:
																	return (
																		<TableCell
																			key={columnConfig.columnId}
																			className="text-nowrap"
																		>
																			{columnData.clientName ?? '-'}
																		</TableCell>
																	);
																case CONTACTS_TABLE_COLUMN_IDS.NAME:
																	return (
																		<TableCell
																			key={columnConfig.columnId}
																			className="text-nowrap"
																		>
																			{columnData.name ?? '-'}
																		</TableCell>
																	);
																case CONTACTS_TABLE_COLUMN_IDS.TITLE:
																	return (
																		<TableCell
																			key={columnConfig.columnId}
																			className="text-nowrap"
																		>
																			{columnData.title ?? '-'}
																		</TableCell>
																	);
																case CONTACTS_TABLE_COLUMN_IDS.EMAIL:
																	return (
																		<TableCell
																			key={columnConfig.columnId}
																			className="text-nowrap"
																		>
																			{columnData.emailAddress ?? '-'}
																		</TableCell>
																	);
																case CONTACTS_TABLE_COLUMN_IDS.PHONE:
																	return (
																		<TableCell
																			key={columnConfig.columnId}
																			className="text-nowrap"
																		>
																			{columnData.mobileNumber ?? '-'}
																		</TableCell>
																	);
																case CONTACTS_TABLE_COLUMN_IDS.DELIVERED:
																	return (
																		<TableCell key={columnConfig.columnId}>
																			<DateInput
																				popperProps={{ strategy: 'fixed' }}
																				selected={columnData.deliveredDate}
																				onChange={(date) => {
																					handleContactDateChange(
																						data,
																						date ? date : null
																					);
																				}}
																			/>
																		</TableCell>
																	);
																case CONTACTS_TABLE_COLUMN_IDS.ACTIONS:
																	return (
																		<TableCell
																			key={columnConfig.columnId}
																			className="text-right"
																		>
																			<Button
																				className="ml-4"
																				variant="link"
																				onClick={() => {
																					handleContactDeleteButtonClick(
																						data
																					);
																				}}
																			>
																				<TrashIcon />
																			</Button>
																		</TableCell>
																	);
																default:
																	return (
																		<TableCell key={columnConfig.columnId}>
																			-
																		</TableCell>
																	);
															}
														})}
													</TableRow>
												);
											}}
										/>
									</>
								)}
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="d-flex align-items-center justify-content-between">
									<Button
										variant="link"
										onClick={() => {
											history.goBack();
										}}
									>
										Cancel
									</Button>
									<div className="d-flex align-items-center">
										{deliverableId && (
											<LoadingButton
												className="mr-4 d-flex align-items-center"
												variant="outline-primary"
												onClick={handleDeliverableDeleteButtonClick}
												isLoading={isDeleting}
											>
												<TrashIcon className="mr-3" />
												Delete
											</LoadingButton>
										)}
										<LoadingButton className="mr-3" type="submit" isLoading={isSaving}>
											Save
										</LoadingButton>
									</div>
								</div>
							</Col>
						</Row>
					</Form>
				</AsyncPage>
			</Container>
		</>
	);
};
