import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles((theme) => ({
	upcomingEventLink: {
		display: 'flex',
	},
	imageOuter: {
		width: 120,
		height: 80,
		flexShrink: 0,
		marginRight: 24,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.colors.gray200,
	},
	link: {
		display: 'block',
		...theme.fonts.openSansBold,
	},
}));

interface UpcomingEventLinkProps {
	imageSrc: string;
	date: string;
	title: string;
	to: string;
	className?: string;
}

export const UpcomingEventLink = ({ imageSrc, date, title, to, className }: UpcomingEventLinkProps) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.upcomingEventLink, className)}>
			<div className={classes.imageOuter} style={{ backgroundImage: `url(${imageSrc})` }} />
			<div>
				<h4 className="text-muted">{date}</h4>
				<Link className={classes.link} to={to}>
					{title}
				</Link>
			</div>
		</div>
	);
};
