import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';
import { TagList, TagListItem } from '@/components';
import { ReactComponent as StarIcon } from '@/assets/icons/icon-star.svg';
import { ReactComponent as CheckmarkIcon } from '@/assets/icons/icon-checkmark-circle.svg';

const useStyles = createUseThemedStyles((theme) => ({
	listResult: {
		marginBottom: 40,
	},
	checkmarkIcon: {
		display: 'inline-flex',
		width: 14,
		height: 14,
		marginRight: 8,
	},
	starIcon: {
		display: 'flex',
		width: 16,
		height: 16,
		marginRight: 8,
	},
	imageOuter: {
		width: '100%',
		paddingBottom: '66.66%',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.colors.gray300,
	},
}));

interface ListResultProps {
	isRegistered?: boolean;
	hasAttended?: boolean;
	category: string;
	date?: string;
	to: string;
	title: string;
	isBookmarked?: boolean;
	description: string;
	tags?: TagListItem[];
	imageSrc?: string;
	className?: string;
}

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

export const ListResult: FC<ListResultProps> = ({
	isRegistered,
	hasAttended,
	category,
	date,
	to,
	title,
	isBookmarked,
	description,
	tags,
	imageSrc,
	className,
	children,
}) => {
	const classes = useStyles();
	const parser = new DOMParser();
	const descriptionParsed = parser.parseFromString(description, 'text/html');
	const descriptionParsedString = descriptionParsed.body?.innerHTML.replace('&amp;', '&');
	return (
		<div className={classNames(classes.listResult, className)}>
			<Row>
				<Col md={imageSrc ? 7 : 12}>
					<h4 className="mb-2 d-flex align-items-center text-muted">
						{isRegistered && (
							<>
								<CheckmarkIcon className={classes.checkmarkIcon} /> Registered &bull;{' '}
							</>
						)}
						{hasAttended && (
							<>
								<CheckmarkIcon className={classes.checkmarkIcon} /> Attended &bull;{' '}
							</>
						)}
						{category}
						{date && <> &bull; {date}</>}
					</h4>
					<h3 className={isBookmarked ? 'mb-1' : 'mb-4'}>
						<Link to={to}>{title}</Link>
					</h3>
					{isBookmarked && (
						<div className="mb-3 d-flex align-items-center">
							<StarIcon className={classes.starIcon} />
							<p className="mb-0 text-muted">Your bookmark</p>
						</div>
					)}
					<div className={!!tags?.length ? 'mb-3' : 'mb-0'}>
						<ResponsiveEllipsis unsafeHTML={descriptionParsedString ?? ''} maxLine="3" component="div" />
					</div>
					{!!tags?.length && <TagList tags={tags} />}
					{children}
				</Col>
				{imageSrc && (
					<Col md={5}>
						<div className={classes.imageOuter} style={{ backgroundImage: `url(${imageSrc})` }}></div>
					</Col>
				)}
			</Row>
		</div>
	);
};
