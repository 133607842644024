import React, { FC, useCallback, useRef } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { AsyncPage } from '@/components';

const PrivateView: FC = ({ children }) => {
	const routeIsInitialized = useRef(false);

	const fetchData = useCallback(async () => {
		if (routeIsInitialized.current) {
			return;
		}

		// on route checks go here

		routeIsInitialized.current = true;
	}, []);

	return <AsyncPage fetchData={fetchData}>{children}</AsyncPage>;
};

export const PrivateRoute: FC<RouteProps> = ({ children, ...routeProps }) => {
	return (
		<Route
			{...routeProps}
			render={() => {
				return <PrivateView>{children}</PrivateView>;
			}}
		/>
	);
};
