import { TableColumns, TableHeaders, TableRowConfig } from '@/components/table/models';

export enum PROCESS_FLOWS_TABLE_COLUMN_IDS {
	PROCESS_FLOWS = 'PROCESS_FLOWS',
	LEVEL = 'LEVEL',
}

export const processFlowsTableColumns: TableColumns<PROCESS_FLOWS_TABLE_COLUMN_IDS> = {
	[PROCESS_FLOWS_TABLE_COLUMN_IDS.PROCESS_FLOWS]: {
		columnId: PROCESS_FLOWS_TABLE_COLUMN_IDS.PROCESS_FLOWS,
		columnTitle: 'Process Flows',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[PROCESS_FLOWS_TABLE_COLUMN_IDS.LEVEL]: {
		columnId: PROCESS_FLOWS_TABLE_COLUMN_IDS.LEVEL,
		columnTitle: 'Level',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
};

export const processFlowsTableHeaders: TableHeaders<PROCESS_FLOWS_TABLE_COLUMN_IDS> = {
	[PROCESS_FLOWS_TABLE_COLUMN_IDS.PROCESS_FLOWS]: {
		tableHeaderId: PROCESS_FLOWS_TABLE_COLUMN_IDS.PROCESS_FLOWS,
		title: 'Process Flows',
		isSortable: true,
	},
	[PROCESS_FLOWS_TABLE_COLUMN_IDS.LEVEL]: {
		tableHeaderId: PROCESS_FLOWS_TABLE_COLUMN_IDS.LEVEL,
		title: 'Level',
		isSortable: true,
	},
};

export const processFlowsTableRows: TableRowConfig<{
	title: string;
	level: string;
}>[] = [
	{
		rowId: 'xxxx-xxxx-xxxx-xxxx-0',
		checked: false,
		expanded: false,
		columnData: {
			title: 'Absence and Leave Management',
			level: '1',
		},
	},
];
