import React, { PropsWithChildren } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { mediaQueries } from '@/jss';

const useStyles = createUseStyles({
	imageOuter: {
		width: '100%',
		height: '250px',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		'& iframe': {
			width: '100%',
			height: '100%',
			backgroundColor: 'black',
		},
		[mediaQueries.lg]: {
			top: 0,
			right: 0,
			bottom: 0,
			width: '50vw',
			height: '100%',
			position: 'absolute',
			'&.reverse': {
				left: 0,
				right: 'auto',
			},
		},
	},
	childrenOuter: {
		padding: '50px 0',
		[mediaQueries.lg]: {
			minHeight: 500,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
	},
});

interface SalesLeadCtaProps {
	reverse?: boolean;
	imageUrl: string;
	className?: string;
	imageOverlayContent?: React.ReactNode;
}

export const SalesLeadCta = ({
	reverse,
	imageUrl,
	className,
	imageOverlayContent,
	children,
}: PropsWithChildren<SalesLeadCtaProps>) => {
	const classes = useStyles();

	return (
		<Container fluid className={className}>
			<Container>
				<Row className={classNames({ 'flex-row-reverse': reverse })}>
					<Col lg={reverse ? { span: 6, offset: 1 } : 6} className="p-0 position-relative">
						<div
							className={classNames(classes.imageOuter, { reverse })}
							style={{ backgroundImage: `url(${imageUrl})` }}
						>
							{imageOverlayContent}
						</div>
					</Col>
					<Col lg={reverse ? { span: 5 } : { span: 5, offset: 1 }} className={classes.childrenOuter}>
						{children}
					</Col>
				</Row>
			</Container>
		</Container>
	);
};
