import { ENV_VARS } from './constants';

export type Environment = {
	[varName in ENV_VARS]: string;
};

function processEnvToConfig(env: NodeJS.ProcessEnv): Readonly<Environment> {
	return {
		HACKETT_ADVISORY_BASE_URL: env.REACT_APP_HACKETT_ADVISORY_BASE_URL || '',
		HACKETT_ADVISORY_SHOW_DEBUG: env.REACT_APP_HACKETT_ADVISORY_SHOW_DEBUG || '',
		HACKETT_ADVISORY_USE_REAL_AUTH: env.REACT_APP_HACKETT_ADVISORY_USE_REAL_AUTH || '',
		HACKETT_ADVISORY_AUTH_UI_URL: env.REACT_APP_HACKETT_ADVISORY_AUTH_UI_URL || '',
		HACKETT_ADVISORY_AUTH_API_URL: env.REACT_APP_HACKETT_ADVISORY_AUTH_API_URL || '',
		HACKETT_ADVISORY_COOKIE_DOMAIN: env.REACT_APP_HACKETT_ADVISORY_COOKIE_DOMAIN || 'localhost',
		HACKETT_ADVISORY_GOOGLE_ANALYTICS_ID: env.REACT_APP_HACKETT_ADVISORY_GOOGLE_ANALYTICS_ID || '',
		SENTRY_DSN: env.REACT_APP_SENTRY_DSN || '',
		HACKETT_ADVISORY_PACKAGE_VERSION: env.HACKETT_ADVISORY_PACKAGE_VERSION || ''
	};
}

export function getEnvConfig(): Readonly<Environment> {
	return processEnvToConfig(process.env);
}
