import { useState, useEffect } from 'react';

const cachedScripts: string[] = [];

export function useScript(scriptSource: string) {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		if (cachedScripts.includes(scriptSource)) {
			setLoading(false);
			setError(false);
		} else {
			cachedScripts.push(scriptSource);

			setLoading(true);
			setError(false);

			const script = document.createElement('script');
			script.src = scriptSource;
			script.async = true;

			const handleScriptLoad = () => {
				setLoading(false);
				setError(false);
			};

			const handleScriptError = () => {
				setLoading(false);
				setError(true);
			};

			script.addEventListener('load', handleScriptLoad);
			script.addEventListener('error', handleScriptError);

			document.body.appendChild(script);

			return () => {
				script.removeEventListener('load', handleScriptLoad);
				script.removeEventListener('error', handleScriptError);
			};
		}
	}, [scriptSource]);

	return [loading, error];
}
