import { httpSingleton } from '@/lib/http-client';
import { GetNavigationResponseModel } from '@/lib/models';

export const navigationService = {
	getNavigationItems() {
		return httpSingleton.orchestrateRequest<GetNavigationResponseModel>({
			method: 'GET',
			url: '/advisory-service/api/v1/navigation',
		});
	},
};
