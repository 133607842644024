import { httpSingleton } from '@/lib/http-client';
import { AiTool, FormOption, PaginatedResponseModel, UseCase } from '@/lib/models';
import { constructUrl } from '@/lib/utils';

export const useCaseService = {
	getUseCaseFilters(queryParameters?: {
		programId?: string;
		parentBusinessProcessIds?: string;
		businessProcessIds?: string;
		industryIds?: string;
		aiToolIds?: string;
		valueRatingIds?: string;
	}) {
		return httpSingleton.orchestrateRequest<{
			programs: FormOption[];
			businessProcesses: FormOption[];
			industries: FormOption[];
			aiTools: FormOption[];
			valueRatings: FormOption[];
			sortFields: FormOption[];
		}>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/use-case/filters', queryParameters),
		});
	},
	getUseCases(queryParameters?: {
		page?: string;
		size?: string;
		sortDirection?: string;
		sortField?: string;
		parentBusinessProcessIds?: string;
		businessProcessIds?: string;
		industryIds?: string;
		aiToolIds?: string;
		categoryObservedIds?: string;
		valueRatingIds?: string;
	}) {
		return httpSingleton.orchestrateRequest<PaginatedResponseModel & { useCases: UseCase[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/use-case', queryParameters),
		});
	},
	getAiTools() {
		return httpSingleton.orchestrateRequest<{ aiTools: AiTool[] }>({
			method: 'GET',
			url: `/advisory-service/api/v1/use-case/ai-tools`,
		});
	},
	getUseCase(useCaseId: string) {
		return httpSingleton.orchestrateRequest<{ useCase: UseCase; aiTools: AiTool[] }>({
			method: 'GET',
			url: `/advisory-service/api/v1/use-case/${useCaseId}`,
		});
	},
};
