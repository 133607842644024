import React from 'react';
import { Button, Form, FormCheckProps } from 'react-bootstrap';
import Color from 'color';

import { DOCUMENT_FILE_TYPES } from '@/lib/models';
import { createUseThemedStyles, useGetIconForFileType } from '@/hooks';
import { ReactComponent as TrashIcon } from '@/assets/icons/icon-trash.svg';

interface useStylesProps {
	checked: boolean;
}

const useStyles = createUseThemedStyles((theme) => ({
	deliverablesDocumentListItem: ({ checked }: useStylesProps) => ({
		display: 'flex',
		padding: '20px 16px',
		alignItems: 'stretch',
		borderBottom: `1px solid ${theme.colors.gray300}`,
		backgroundColor: checked
			? Color(theme.colors.primary)
					.alpha(0.08)
					.string()
			: 'transparent',
	}),
	documentInformation: {
		flex: 1,
	},
	fileIcon: {
		marginTop: -3.5,
		marginRight: 8,
	},
}));

interface DeliverablesDocumentListItemProps {
	showCheck?: boolean;
	checkboxProps?: FormCheckProps;
	title: string;
	submissionDate: string;
	fileType?: DOCUMENT_FILE_TYPES;
	fileName: string;
	showRemove?: boolean;
	onRemove?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export const DeliverablesDocumentListItem = ({
	showCheck,
	checkboxProps,
	title,
	submissionDate,
	fileType,
	fileName,
	showRemove,
	onRemove,
}: DeliverablesDocumentListItemProps) => {
	const classes = useStyles({
		checked: checkboxProps?.checked ?? false,
	});

	const getIconForFileType = useGetIconForFileType(classes.fileIcon, fileType);

	return (
		<div className={classes.deliverablesDocumentListItem}>
			{showCheck && (
				<div className="d-flex align-items-start">
					<Form.Check className="mr-4" label="" {...checkboxProps} />
				</div>
			)}
			<div className={classes.documentInformation}>
				<p className="mb-0 font-weight-semi-bold">{title}</p>
				<p className="mb-2 text-muted">Submitted {submissionDate}</p>
				<p className="mb-0">
					{getIconForFileType}
					{fileName}
				</p>
			</div>
			{showRemove && (
				<div className="d-flex align-items-start">
					<Button className="ml-4" variant="link" onClick={onRemove}>
						Remove <TrashIcon />
					</Button>
				</div>
			)}
		</div>
	);
};
