import { httpSingleton } from '@/lib/http-client';
import { constructUrl } from '@/lib/utils';
import { DocumentModel, PaginatedResponseModel, FormOption } from '@/lib/models';

export const documentService = {
	getDocuments(queryParameters?: {
		bookmarked?: boolean;
		parentBusinessProcessIds?: string[];
		businessProcessIds?: string[];
		businessTaxonomyId?: string;
		documentTypes?: string[];
		fromDate?: string;
		link?: string;
		page?: string;
		size?: string;
		sortDirection?: string;
		sortField?: string;
		toDate?: string;
		topicIds?: string[];
	}) {
		return httpSingleton.orchestrateRequest<PaginatedResponseModel & { documents: DocumentModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/documents', queryParameters),
		});
	},
	getDocumentById(documentId: string) {
		return httpSingleton.orchestrateRequest<DocumentModel>({
			method: 'GET',
			url: `advisory-service/api/v1/document/${documentId}`,
		});
	},
	addBookmark(documentId: string) {
		return httpSingleton.orchestrateRequest<DocumentModel>({
			method: 'POST',
			url: `/advisory-service/api/v1/document/bookmark/${documentId}`,
		});
	},
	removeBookmark(documentId: string) {
		return httpSingleton.orchestrateRequest<DocumentModel>({
			method: 'DELETE',
			url: `/advisory-service/api/v1/document/bookmark/${documentId}`,
		});
	},
	getDocumentFilters(queryParameters?: {
		parentBusinessProcessIds?: string[];
		businessProcessIds?: string[];
		topicIds?: string[];
	}) {
		return httpSingleton.orchestrateRequest<{
			businessProcesses: FormOption[];
			contentTypes: FormOption[];
			sortFields: FormOption[];
			topics: FormOption[];
		}>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/document-filter', queryParameters),
		});
	},
	trackDocumentDownload(documentId: string) {
		return httpSingleton.orchestrateRequest({
			method: 'GET',
			url: `/advisory-service/api/v1/document/${documentId}/download/activity`,
		});
	},
	trackDocumentView(documentId: string) {
		return httpSingleton.orchestrateRequest({
			method: 'GET',
			url: `/advisory-service/api/v1/document/${documentId}/view/activity`,
		});
	},
};
