import React, { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownToggleProps as ReactBootStrapDropdownToggleProps } from 'react-bootstrap/esm/DropdownToggle';
import Color from 'color';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

import { ReactComponent as DownChevron } from '@/assets/icons/icon-chevron-down.svg';

interface UseStylesProps {
	isActive?: boolean;
	hideCaret?: boolean;
}

const useStyles = createUseThemedStyles(
	(theme) => ({
		dropdownToggle: (props?: UseStylesProps) => ({
			display: 'flex',
			borderRadius: 2,
			...theme.fonts.s,
			padding: '12px 16px',
			alignItems: 'center',
			color: props?.isActive ? theme.colors.primary : theme.colors.black,
			backgroundColor: props?.isActive
				? Color(theme.colors.primary)
						.alpha(0.16)
						.string()
				: 'transparent',
			...(props?.isActive ? theme.fonts.openSansSemiBold : theme.fonts.openSansRegular),
			'&:hover': {
				textDecoration: 'underline',
				backgroundColor: props?.isActive
					? Color(theme.colors.primary)
							.alpha(0.16)
							.string()
					: 'transparent',
				color: theme.colors.black,
			},
			'&:active': {
				backgroundColor: props?.isActive
					? Color(theme.colors.primary)
							.alpha(0.16)
							.string()
					: 'transparent',
			},
			'&:after': {
				display: 'none',
			},
		}),
		childrenOuter: {
			flex: 1,
		},
		downChevron: (props?: UseStylesProps) => ({
			marginLeft: 8,
			display: props?.hideCaret ? 'none' : 'flex',
		}),
	}),
	{ index: 1 }
);

interface DropdownToggleProps extends ReactBootStrapDropdownToggleProps {
	isActive?: boolean;
	hideCaret?: boolean;
	className?: string;
	chevronClassName?: string;
}

export const DropdownToggle: FC<DropdownToggleProps> = ({
	isActive,
	hideCaret,
	className,
	chevronClassName,
	children,
	...props
}) => {
	const classes = useStyles({
		isActive,
		hideCaret,
	});

	return (
		<Dropdown.Toggle className={classNames(classes.dropdownToggle, className)} {...props}>
			<div className={classes.childrenOuter}>{children}</div>
			<DownChevron className={classNames(classes.downChevron, chevronClassName)} />
		</Dropdown.Toggle>
	);
};
