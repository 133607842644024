import React, { PropsWithChildren, useCallback, useState } from 'react';

import { accountService } from '@/lib/services';
import { LoadingButton } from '@/components';
import { useHandleError, useAnalytics } from '@/hooks';

interface FreshDeskLinkProps {
	className?: string;
	forTickets?: boolean
}

export const FreshDeskLink = ({ className, forTickets = false, children }: PropsWithChildren<FreshDeskLinkProps>) => {
	const handleError = useHandleError();
	const [isLoading, setIsLoading] = useState(false);
    const analytics = useAnalytics();

	const handleButtonClick = useCallback(async () => {
		try {
			setIsLoading(true);
			const serviceAction = forTickets ? accountService.getFreshdeskTicketsUrl : accountService.getFreshdeskSupportUrl;
			const response = await serviceAction().fetch();

			analytics.handleAnalyticOutbound(response.freshdeskSupportUrl);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleError, forTickets]);

	return (
		<LoadingButton variant="link" className={className} isLoading={isLoading} onClick={handleButtonClick}>
			{children}
		</LoadingButton>
	);
};
