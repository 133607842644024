import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Color from 'color';

import { accountService } from '@/lib/services';
import { createUseThemedStyles, useHandleError, useNavigationConfig } from '@/hooks';
import { HeaderDesktop, HeaderMobile, NavigationMain, WelcomeBanner } from '@/components';
import { BannerMessage } from '@/lib/models';
import { mediaQueries } from '@/jss';

const useStyles = createUseThemedStyles((theme) => ({
	header: {
		top: 0,
		left: 0,
		right: 0,
		zIndex: 10,
		position: 'fixed',
		backgroundColor: theme.colors.primary,
		backgroundImage: `linear-gradient(-45deg, ${Color(theme.colors.primary)
			.darken(0.4)
			.hex()} 0%, ${Color(theme.colors.primary)
			.lighten(0.2)
			.desaturate(0.2)
			.hex()} 100%)`,
	},
	headerDesktop: {
		display: 'none',
		[mediaQueries.nav]: {
			display: 'flex',
		},
	},
	headerMobile: {
		[mediaQueries.nav]: {
			display: 'none',
		},
	},
}));

export const Header = () => {
	const handleError = useHandleError();
	const classes = useStyles();
	const { navigationConfig } = useNavigationConfig();
	const headerRef = useRef<HTMLElement>(null);
	const navigationLinks = useMemo(() => {
		return {
			MAIN: [
				...(!navigationConfig?.inquiriesDisabled && !navigationConfig?.allowClientManagement
					? [
							{
								to: navigationConfig?.allowAccessToInquiries ? '/inquiries' : '/inquiries/learn-more',
								title: 'Expert<br/>Inquiries',
							},
					  ]
					: []),
				...(navigationConfig?.allowResearch ? [{ to: '/research', title: 'Research' }] : []),
				...(navigationConfig?.allowPerformanceMetrics
					? [{ to: '/performance-metrics', title: 'Benchmark<br/>Metrics' }]
					: []),
				...(navigationConfig?.allowBestPractices
					? [
							{
								to: '/best-practices',
								title:
									'<span class="d-block">Hackett-Certified<sup>&reg;</sup></span><span class="d-block">Best Practices</span>',
							},
					  ]
					: []),
				...(navigationConfig?.allowPerformanceStudies
					? [
							{
								to: '/events/performance-studies',
								title: 'Performance<br/>Studies',
							},
					  ]
					: []),
				...(navigationConfig?.allowBrowseEvents ? [{ to: '/events/browse-events', title: 'Events' }] : []),
				...(navigationConfig?.allowUseCases
					? [
							{
								to: '/use-cases',
								title:
									'<span class="d-block text-warning">NEW</span><span class="d-block">AI Use Cases</span>',
							},
					  ]
					: []),
			],
			CLIENT_MANAGEMENT: navigationConfig?.allowClientManagement
				? [
						...(navigationConfig?.allowAccessToInquiries
							? [
									{
										to: '/client-management/inquiries-dashboard',
										title: 'Inquiries',
									},
							  ]
							: []),
						...(navigationConfig?.allowDeliverables
							? [
									{
										to: '/client-management/deliverables',
										title: 'Deliverables',
									},
							  ]
							: []),
						...(navigationConfig?.allowReports
							? [{ to: '/client-management/reports', title: 'Reports' }]
							: []),
						...(navigationConfig?.allowHackettAi
							? [{ to: '/client-management/ask-hackett-ai', title: 'Ask Hackett.AI' }]
							: []),
				  ]
				: [],
			USER_MANAGEMENT: [
				{
					to: '/advisory-team',
					title: 'Meet Your Team',
				},
				{
					to: '/contact',
					title: 'Contact Us',
				},
			],
			ACCOUNT: [
				...(navigationConfig?.allowAdminAccess
					? [
							{
								to: navigationConfig?.adminUrl ?? '',
								title: 'Admin',
								external: true,
							},
					  ]
					: []),
				{ to: '/account', title: 'Account' },
				{ to: '/bookmarks', title: 'Bookmarks' },
			],
		};
	}, [navigationConfig]);

	/* ----------------------------------------------------------- */
	/* Banner messages */
	/* ----------------------------------------------------------- */
	const [bannerMessages, setBannerMessages] = useState<BannerMessage[]>([]);
	const bannerMessagesFetched = useRef(false);

	const fetchBannerMessages = useCallback(async () => {
		try {
			if (bannerMessagesFetched.current) {
				return;
			}

			const [bannerMessagesResponse] = await Promise.all([accountService.getBannerMessages().fetch()]);

			setBannerMessages(bannerMessagesResponse.bannerMessages);
			bannerMessagesFetched.current = true;
		} catch (error) {
			handleError(error);
		}
	}, [handleError]);

	useEffect(() => {
		fetchBannerMessages();
	}, [fetchBannerMessages]);

	/* ----------------------------------------------------------- */
	/* Resize Handler */
	/* ----------------------------------------------------------- */
	const handleWindowResize = useCallback(() => {
		setBodyPadding();
	}, []);

	/* ----------------------------------------------------------- */
	/* Body padding for fixed header */
	/* ----------------------------------------------------------- */
	function setBodyPadding() {
		if (!headerRef.current) {
			document.body.style.paddingTop = '0px';
			return;
		}

		const headerHeight = headerRef.current.clientHeight;
		document.body.style.paddingTop = `${headerHeight}px`;
	}

	/* ----------------------------------------------------------- */
	/* Set padding on window resize, bind handler */
	/* ----------------------------------------------------------- */
	useEffect(() => {
		setBodyPadding();

		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
			document.body.style.paddingTop = '0px';
		};
	}, [handleWindowResize]);

	/* ----------------------------------------------------------- */
	/* Set padding if bannerMessages changes */
	/* ----------------------------------------------------------- */
	useEffect(() => {
		setBodyPadding();
	}, [bannerMessages]);

	return (
		<>
			<header ref={headerRef} className={classes.header}>
				{bannerMessages.length > 0 && (
					<WelcomeBanner bannerMessages={bannerMessages} onBannerMessageRead={setBannerMessages} />
				)}
				<HeaderDesktop navigationLinks={navigationLinks} className={classes.headerDesktop} />
				<HeaderMobile navigationLinks={navigationLinks} className={classes.headerMobile} />
				<NavigationMain navigationLinks={navigationLinks} className={classes.headerDesktop} />
			</header>
		</>
	);
};
