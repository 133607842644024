import React, { FC } from 'react';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import { createUseThemedStyles } from '@/hooks';

const useTableCellStyles = createUseThemedStyles({
	tableCell: (props: any) => ({
		padding: 0,
		backgroundColor: 'inherit',
		'&.fixed-column': {
			zIndex: 1,
			position: 'sticky',
			left: props.fixedOffset ? props.fixedOffset : 0,
		},
	}),
	tableCellContent: (props: any) => ({
		overflow: 'hidden',
		padding: '18px 20px',
		transition: '0.3s opacity',
		width: props.width || 'auto',
		minWidth: props.minWidth || 'auto',
		opacity: props.expanded ? 1 : 0,
	}),
});

interface TableCellProps {
	width?: number | string;
	minWidth?: number | string;
	fixed?: boolean;
	fixedOffset?: number;
	className?: string;
	classNameOuter?: string;
	expanded?: boolean;
	colSpan?: number;
}

export const TableCell: FC<TableCellProps> = ({
	width,
	minWidth,
	fixed,
	fixedOffset,
	className,
	classNameOuter,
	expanded = true,
	children,
	colSpan,
}) => {
	const classes = useTableCellStyles({
		width,
		minWidth,
		fixedOffset,
		expanded,
	});

	return (
		<td
			className={classNames(classes.tableCell, classNameOuter, {
				'fixed-column': fixed,
			})}
			colSpan={colSpan}
		>
			<Collapse in={expanded}>
				<div>
					<div className={classNames(classes.tableCellContent, className)}>{children}</div>
				</div>
			</Collapse>
		</td>
	);
};
