import React, { FC, useRef, useEffect, useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { createUseThemedStyles } from '@/hooks';
import { ReactComponent as HackettLogo } from '@/assets/logos/logo-hackett-white.svg';

const useStyles = createUseThemedStyles((theme) => ({
	footer: {
		left: 0,
		bottom: 0,
		width: '100%',
		padding: '32px 0',
		position: 'absolute',
		alignItems: 'center',
		backgroundColor: theme.colors.primary,
		'& p a': {
			textDecoration: 'underline',
		},
	},
}));

export const Footer: FC = () => {
	const classes = useStyles();
	const footerRef = useRef<HTMLElement | null>(null);
	const year = useRef(new Date().getFullYear());

	const handleWindowResize = useCallback(() => {
		setBodyPadding();
	}, []);

	useEffect(() => {
		setBodyPadding();
		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [handleWindowResize]);

	function setBodyPadding() {
		if (!footerRef.current) return;

		const footerHeight = footerRef.current.clientHeight;
		document.body.style.paddingBottom = `${footerHeight}px`;
	}

	return (
		<footer ref={footerRef} className={classes.footer}>
			<Container>
				<Row>
					<Col>
						<h5 className="mb-2 text-white">Notice of Processing</h5>
						<p className="mb-5 text-white">
							<small>
								Any personal data that you provide us, which is collected or processed in connection
								with your use of our services or site, will be processed in accordance with our Privacy
								Policy located at{' '}
								<a
									href="https://www.thehackettgroup.com/privacy-statement/"
									className="text-white"
									target="_blank"
									rel="noreferrer"
								>
									www.thehackettgroup.com/privacy-statement
								</a>{' '}
								and, if you provide us with personal data from persons located in the European Economic
								Area (“EEA”), we may require your data to be processed outside of the EEA (including our
								designated third party sub-processors who assist us in our operations). We may aggregate
								and de-identify the responses we receive and place them into reports that we distribute
								to one or more of our current and prospective clients.
							</small>
						</p>
						<HackettLogo className="mb-1" />
						<p className="mb-0 text-white">
							<small>Copyright &copy; {year.current} The Hackett Group, Inc. All Rights Reserved.</small>
						</p>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};
