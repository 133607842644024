import React from 'react';
import { Link } from 'react-router-dom';

import { createUseThemedStyles } from '@/hooks';
import { boxShadow } from '@/jss';
import { ReactComponent as HackettLogo } from '@/assets/logos/logo-hackett-r.svg';

const useStyles = createUseThemedStyles((theme) => ({
	headerNoAuth: {
		...boxShadow,
		padding: '20px 40px',
		backgroundColor: theme.colors.white,
	},
	brandLink: {
		display: 'flex',
		alignItems: 'center',
		textDecoration: 'none',
		color: theme.colors.gray700,
		'&:hover': {
			textDecoration: 'none',
		},
	},
	brand: {
		...theme.fonts.m,
		...theme.fonts.openSansSemiBold,
	},
	vr: {
		width: 2,
		height: 28,
		flexShrink: 0,
		margin: '0 16px',
		display: 'inline-flex',
		backgroundColor: theme.colors.gray400,
	},
}));

export const HeaderNoAuth = () => {
	const classes = useStyles();

	return (
		<header className={classes.headerNoAuth}>
			<Link to="/" className={classes.brandLink}>
				<HackettLogo />
				<span className={classes.vr} />
				<span className={classes.brand}>Hackett Connect</span>
			</Link>
		</header>
	);
};
