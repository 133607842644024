import { httpSingleton } from '@/lib/http-client';

export const salesLeadService = {
	sendProgramBundleEmail(bundleId: string) {
		return httpSingleton.orchestrateRequest({
			method: 'GET',
			url: `/advisory-service/api/v1/sales-lead/program-bundle/${bundleId}`,
		});
	},
	sendExpertIquiryEmail() {
		return httpSingleton.orchestrateRequest<void>({
			method: 'GET',
			url: '/advisory-service/api/v1/sales-lead/expert-inquiry',
		});
	},
	sendHackettInstituteEmail() {
		return httpSingleton.orchestrateRequest<void>({
			method: 'GET',
			url: '/advisory-service/api/v1/sales-lead/hackett-institute',
		});
	},
	sendQuantumLoopEmail() {
		return httpSingleton.orchestrateRequest<void>({
			method: 'GET',
			url: '/advisory-service/api/v1/sales-lead/quantum-leap',
		});
	},
	sendAiXplrEmail() {
		return httpSingleton.orchestrateRequest<void>({
			method: 'GET',
			url: '/advisory-service/api/v1/sales-lead/ai-xplr',
		});
	},
};
