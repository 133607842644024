import { httpSingleton } from '@/lib/http-client';
import { LeftNavigationItem } from '@/lib/models';

export const leftNavigationService = {
	getLeftNavigation() {
		return httpSingleton.orchestrateRequest<{ navigationItems: LeftNavigationItem[] }>({
			method: 'GET',
			url: '/advisory-service/api/v1/left-navigation',
		});
	},
};
