import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import Color from 'color';

import { createUseThemedStyles } from '@/hooks';
import { ReactComponent as CalendarIcon } from '@/assets/icons/icon-calendar-v2.svg';

const useStyles = createUseThemedStyles((theme) => ({
	dateInput: {
		minWidth: 150,
		position: 'relative',
		'& .react-datepicker__input-container': {
			'& input': {
				flex: 1,
				height: 40,
				padding: 0,
				width: '100%',
				textIndent: 16,
				borderRadius: 2,
				...theme.fonts.s,
				paddingRight: 44,
				backgroundColor: theme.colors.white,
				border: `1px solid ${theme.colors.gray500}`,
				boxShadow: 'inset 0 1px 4px 0 rgba(0, 0, 0, 0.16)',
				'&:hover': {
					border: `1px solid ${theme.colors.gray600}`,
				},
				'&:focus': {
					outline: 'none',
					border: `1px solid ${theme.colors.primary}`,
				},
				'&:disabled': {
					backgroundColor: theme.colors.gray300,
					'&:hover': {
						border: `1px solid ${theme.colors.gray500}`,
					},
				},
			},
			'& .react-datepicker__close-icon': {
				right: 38,
			},
		},
		'& .react-datepicker-popper': {
			padding: '0 !important',
			'& .react-datepicker': {
				borderRadius: 2,
				...theme.fonts.s,
				border: `1px solid ${theme.colors.gray500}`,
				'&__triangle': {
					display: 'none',
				},
				'&__navigation': {
					top: 8,
					width: 40,
					height: 32,
					'&--previous': {
						left: 0,
					},
					'&--next': {
						right: 0,
					},
				},
				'&__navigation-icon': {
					top: 0,
					'&--previous, &--next': {
						left: 0,
						right: 0,
					},
					'&:before': {
						borderWidth: '2px 2px 0 0',
						borderColor: theme.colors.primary,
					},
				},
				'&__header': {
					padding: '8px 0',
					backgroundColor: theme.colors.gray100,
					borderBottom: `1px solid ${theme.colors.gray500}`,
				},
				'&__current-month': {
					height: 32,
					fontSize: '1.6rem',
					lineHeight: '3.2rem',
				},
				'&__day-names': {
					margin: 0,
				},
				'&__day-name': {
					margin: 0,
					width: 40,
					height: 32,
					lineHeight: '3.2rem',
					color: theme.colors.gray600,
				},
				'&__month': {
					margin: 0,
				},
				'&__day': {
					margin: 0,
					width: 40,
					height: 40,
					borderRadius: 2,
					lineHeight: '4.0rem',
					'&:hover': {
						textDecoration: 'underline',
						backgroundColor: 'transparent',
						color: theme.colors.primary,
					},
					'&--outside-month': {
						borderRadius: 0,
						backgroundColor: theme.colors.gray200,
						'&:hover': {
							backgroundColor: theme.colors.gray200,
						},
					},
					'&--today': {
						...theme.fonts.openSansSemiBold,
					},
					'&--selected': {
						...theme.fonts.openSansSemiBold,
						color: theme.colors.primary,
						backgroundColor: Color(theme.colors.primary)
							.alpha(0.16)
							.string(),
						'&:hover': {
							backgroundColor: Color(theme.colors.primary)
								.alpha(0.16)
								.string(),
						},
					},
					'&--keyboard-selected': {
						outline: 'none',
						color: theme.colors.black,
						backgroundColor: theme.colors.gray100,
					},
				},
			},
		},
	},
	calendarIcon: {
		top: '50%',
		right: 15,
		position: 'absolute',
		pointerEvents: 'none',
		transform: 'translateY(-50%)',
		color: theme.colors.primary,
	},
}));

export const DateInput = ({ maxDate, ...props }: ReactDatePickerProps) => {
	const classes = useStyles();
	const finalProps = {
		...(maxDate ? { maxDate } : {}),
		peekNextMonth: true,
		showMonthDropdown: true,
		showYearDropdown: true,
		dropdownMode: 'select' as const,
		...props,
	};

	return (
		<div className={classes.dateInput}>
			<DatePicker {...finalProps} />
			<CalendarIcon className={classes.calendarIcon} />
		</div>
	);
};
