import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';

import config from '@/lib/config';
import { contactsTableColumns, contactsTableHeaders, CONTACTS_TABLE_COLUMN_IDS } from '@/lib/__mocks__';
import { AccountModel, COOKIES, UnmatchedAccountModel } from '@/lib/models';
import { TableRowConfig } from '@/components/table/models';

import { createUseThemedStyles } from '@/hooks';

import { TableCell, TableHeader, TableRenderer, TableRow } from '@/components/table';

import { ReactComponent as DownloadIcon } from '@/assets/icons/icon-download.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/icon-trash.svg';

const useStyles = createUseThemedStyles((theme) => ({
	dialog: {
		width: '90%',
		height: '100%',
		maxWidth: 1240,
		margin: '0 auto',
		'& .modal-content': {
			maxHeight: '90vh',
		},
		'& .hackett-modal__body': {
			overflowY: 'auto',
			backgroundColor: theme.colors.gray100,
		},
	},
}));

interface DeliverablesContactsModalProps extends ModalProps {
	fileName: string;
	unmatchedContacts: UnmatchedAccountModel[];
	matchedContacts: AccountModel[];
	onSave(selectedContacts: AccountModel[]): void;
}

export const DeliverablesContactsModal = ({
	fileName,
	unmatchedContacts,
	matchedContacts,
	onSave,
	...props
}: DeliverablesContactsModalProps) => {
	const classes = useStyles();

	const [tableIsLoading] = useState(false);
	const [tablePageSize] = useState(1000);
	const [tablePageIndex] = useState(0);
	const [tableColumnsData, setTableColumnsData] = useState(contactsTableColumns);
	const [tableHeadersData] = useState(contactsTableHeaders);

	const [unmatchedContactsRowData, setUnmatchedContactsRowData] = useState<TableRowConfig<UnmatchedAccountModel>[]>(
		[]
	);
	const [matchedContactsRowData, setMatchedContactsRowData] = useState<TableRowConfig<AccountModel>[]>([]);

	const handleOnEnter = () => {
		const formattedUnmatchedContacts = unmatchedContacts.map((uc, ucIndex) => {
			return {
				rowId: String(ucIndex),
				checked: false,
				expanded: false,
				columnData: uc,
			};
		});

		const formattedMatchedContacts = matchedContacts.map((mc) => {
			return {
				rowId: mc.accountId,
				checked: false,
				expanded: false,
				columnData: mc,
			};
		});

		setUnmatchedContactsRowData(formattedUnmatchedContacts);
		setMatchedContactsRowData(formattedMatchedContacts);
	};

	const handleUnmatchedContactDeleteButtonClick = (rowData: TableRowConfig<UnmatchedAccountModel>) => {
		if (
			!window.confirm(
				'Are you sure you want to remove this unmatched contact? They will no longer appear in the downloaded CSV of unmatched contacts.'
			)
		) {
			return;
		}

		const unmatchedContactsRowDataClone = cloneDeep(unmatchedContactsRowData);
		const indexToRemove = unmatchedContactsRowDataClone.findIndex((rd) => rd.rowId === rowData.rowId);

		if (indexToRemove > -1) {
			unmatchedContactsRowDataClone.splice(indexToRemove, 1);
		}

		setUnmatchedContactsRowData(unmatchedContactsRowDataClone);
	};

	const handleMatchedContactDeleteButtonClick = (rowData: TableRowConfig<AccountModel>) => {
		if (!window.confirm('Are you sure you want to remove this matched contact? They will no longer be imported.')) {
			return;
		}

		const matchedContactsRowDataClone = cloneDeep(matchedContactsRowData);
		const indexToRemove = matchedContactsRowDataClone.findIndex((rd) => rd.rowId === rowData.rowId);

		if (indexToRemove > -1) {
			matchedContactsRowDataClone.splice(indexToRemove, 1);
		}

		setMatchedContactsRowData(matchedContactsRowDataClone);
	};

	const handleImportButtonClick = () => {
		onSave(matchedContactsRowData.map((rd) => rd.columnData));
	};

	return (
		<Modal dialogClassName={classes.dialog} centered onEntering={handleOnEnter} {...props}>
			<Modal.Header closeButton>
				<Modal.Title>Import Contacts ({fileName})</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{unmatchedContactsRowData.length > 0 && (
					<>
						<h1 className="mb-2">Warning: unmatched contacts</h1>
						<p className="mb-12">
							Some contacts could not be matched to any existing user in our database.
						</p>
						<TableRenderer
							className="mb-12"
							isLoading={tableIsLoading}
							tableColumnData={tableColumnsData}
							onTableColumnDataChange={setTableColumnsData}
							tableRowData={unmatchedContactsRowData}
							tableRowDataTotal={unmatchedContactsRowData.length}
							onTableRowDataChange={setUnmatchedContactsRowData}
							pageSize={tablePageSize}
							pageIndex={tablePageIndex}
							onPaginationChange={() => {
								return;
							}}
							hidePagination={true}
							hideColumnControls={true}
							tableHeaderRowRenderer={() => {
								return (
									<TableRow>
										{Object.values(tableColumnsData).map((columnConfig) => {
											if (!columnConfig.isShowing) {
												return null;
											}

											const tableHeader = tableHeadersData[columnConfig.columnId];
											const tableHeaderProps = {
												key: tableHeader.tableHeaderId,
												sortable: false,
												sortKey: tableHeader.tableHeaderId,
											};

											switch (columnConfig.columnId) {
												case CONTACTS_TABLE_COLUMN_IDS.DELIVERED:
													return null;
												case CONTACTS_TABLE_COLUMN_IDS.ACTIONS:
													return (
														<TableHeader
															className="justify-content-end"
															{...tableHeaderProps}
														>
															{tableHeader.title}
														</TableHeader>
													);
												default:
													return (
														<TableHeader {...tableHeaderProps}>
															{tableHeader.title}
														</TableHeader>
													);
											}
										})}
									</TableRow>
								);
							}}
							tableBodyRowRenderer={(data) => {
								return (
									<TableRow key={data.rowId}>
										{Object.values(tableColumnsData).map((columnConfig) => {
											if (!columnConfig.isShowing) {
												return null;
											}

											const { columnData } = data;

											switch (columnConfig.columnId) {
												case CONTACTS_TABLE_COLUMN_IDS.COMPANY:
													return (
														<TableCell key={columnConfig.columnId} className="text-nowrap">
															{columnData.company}
														</TableCell>
													);
												case CONTACTS_TABLE_COLUMN_IDS.NAME:
													return (
														<TableCell key={columnConfig.columnId} className="text-nowrap">
															{columnData.userName}
														</TableCell>
													);
												case CONTACTS_TABLE_COLUMN_IDS.TITLE:
													return (
														<TableCell key={columnConfig.columnId} className="text-nowrap">
															{columnData.title}
														</TableCell>
													);
												case CONTACTS_TABLE_COLUMN_IDS.EMAIL:
													return (
														<TableCell key={columnConfig.columnId} className="text-nowrap">
															{columnData.email}
														</TableCell>
													);
												case CONTACTS_TABLE_COLUMN_IDS.PHONE:
													return (
														<TableCell key={columnConfig.columnId} className="text-nowrap">
															{columnData.phone}
														</TableCell>
													);
												case CONTACTS_TABLE_COLUMN_IDS.DELIVERED:
													return null;
												case CONTACTS_TABLE_COLUMN_IDS.ACTIONS:
													return (
														<TableCell key={columnConfig.columnId} className="text-right">
															<Button
																className="ml-4"
																variant="link"
																onClick={() => {
																	handleUnmatchedContactDeleteButtonClick(data);
																}}
															>
																<TrashIcon />
															</Button>
														</TableCell>
													);
												default:
													return <TableCell key={columnConfig.columnId}>-</TableCell>;
											}
										})}
									</TableRow>
								);
							}}
						>
							<div className="d-flex flex-grow-1 align-items-center justify-content-between">
								<h3>Unmatched Contacts ({unmatchedContactsRowData.length})</h3>
								<div className="mr-6 d-flex align-items-center">
									<Form
										className="d-inline-block mr-1"
										action={`${config.HACKETT_ADVISORY_BASE_URL}advisory-service/api/v1/deliverable/unmatched-download`}
										method="post"
									>
										<Form.Control
											className="d-none"
											type="text"
											name="access_token"
											value={Cookies.get(COOKIES.ACCESS_TOKEN)}
											readOnly
										/>
										<Form.Control
											className="d-none"
											type="text"
											name="contacts"
											value={JSON.stringify(unmatchedContacts)}
											readOnly
										/>
										<Button
											className="d-flex align-items-center font-weight-semi-bold"
											variant="link"
											type="submit"
											formTarget="_blank"
										>
											<DownloadIcon className="mr-1" />
											Download
										</Button>
									</Form>
								</div>
							</div>
						</TableRenderer>
					</>
				)}

				<TableRenderer
					isLoading={tableIsLoading}
					tableColumnData={tableColumnsData}
					onTableColumnDataChange={setTableColumnsData}
					tableRowData={matchedContactsRowData}
					tableRowDataTotal={matchedContactsRowData.length}
					onTableRowDataChange={setMatchedContactsRowData}
					pageSize={tablePageSize}
					pageIndex={tablePageIndex}
					onPaginationChange={() => {
						return;
					}}
					hidePagination={true}
					hideColumnControls={true}
					tableHeaderRowRenderer={() => {
						return (
							<TableRow>
								{Object.values(tableColumnsData).map((columnConfig) => {
									if (!columnConfig.isShowing) {
										return null;
									}

									const tableHeader = tableHeadersData[columnConfig.columnId];
									const tableHeaderProps = {
										key: tableHeader.tableHeaderId,
										sortable: false,
										sortKey: tableHeader.tableHeaderId,
									};

									switch (columnConfig.columnId) {
										case CONTACTS_TABLE_COLUMN_IDS.DELIVERED:
											return null;
										case CONTACTS_TABLE_COLUMN_IDS.ACTIONS:
											return (
												<TableHeader className="justify-content-end" {...tableHeaderProps}>
													{tableHeader.title}
												</TableHeader>
											);
										default:
											return <TableHeader {...tableHeaderProps}>{tableHeader.title}</TableHeader>;
									}
								})}
							</TableRow>
						);
					}}
					tableBodyRowRenderer={(data) => {
						return (
							<TableRow key={data.rowId}>
								{Object.values(tableColumnsData).map((columnConfig) => {
									if (!columnConfig.isShowing) {
										return null;
									}

									const { columnData } = data;

									switch (columnConfig.columnId) {
										case CONTACTS_TABLE_COLUMN_IDS.COMPANY:
											return (
												<TableCell key={columnConfig.columnId}>
													{columnData.clientName ?? '-'}
												</TableCell>
											);
										case CONTACTS_TABLE_COLUMN_IDS.NAME:
											return (
												<TableCell key={columnConfig.columnId}>
													{columnData.name ?? '-'}
												</TableCell>
											);
										case CONTACTS_TABLE_COLUMN_IDS.TITLE:
											return (
												<TableCell key={columnConfig.columnId}>
													{columnData.title ?? '-'}
												</TableCell>
											);
										case CONTACTS_TABLE_COLUMN_IDS.EMAIL:
											return (
												<TableCell key={columnConfig.columnId}>
													{columnData.emailAddress ?? '-'}
												</TableCell>
											);
										case CONTACTS_TABLE_COLUMN_IDS.PHONE:
											return (
												<TableCell key={columnConfig.columnId}>
													{columnData.mobileNumber ?? '-'}
												</TableCell>
											);
										case CONTACTS_TABLE_COLUMN_IDS.DELIVERED:
											return null;
										case CONTACTS_TABLE_COLUMN_IDS.ACTIONS:
											return (
												<TableCell key={columnConfig.columnId} className="text-right">
													<Button
														className="ml-4"
														variant="link"
														onClick={() => {
															handleMatchedContactDeleteButtonClick(data);
														}}
													>
														<TrashIcon />
													</Button>
												</TableCell>
											);
										default:
											return <TableCell key={columnConfig.columnId}>-</TableCell>;
									}
								})}
							</TableRow>
						);
					}}
				>
					<div className="d-flex flex-grow-1 align-items-center justify-content-between">
						<h3>Matched Contacts ({matchedContactsRowData.length})</h3>
					</div>
				</TableRenderer>
			</Modal.Body>
			<Modal.Footer>
				<div className="text-right">
					<Button className="mr-2" variant="link" onClick={props.onHide}>
						Cancel
					</Button>
					<Button onClick={handleImportButtonClick}>
						Import Matched Contacts{' '}
						{matchedContactsRowData.length > 0 && `(${matchedContactsRowData.length})`}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
