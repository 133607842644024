import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { constructUrl } from '@/lib/utils';
import { DeliverableModel, FormOption } from '@/lib/models';
import { deliverablesService } from '@/lib/services';
import { useDebouncedState, useQuery } from '@/hooks';
import { AsyncPage, DateInput, DeliverableListItem, SearchInput, Select } from '@/components';
import { SORT_DIRECTION, TablePagination } from '@/components/table';

export const Deliverables = () => {
	const history = useHistory();
	const { path } = useRouteMatch();
	const { pathname, search } = useLocation();

	const searchParams = useQuery();
	const clientId = searchParams.get('clientId');
	const contactLastName = searchParams.get('contactLastName');
	const deliverableTypeId = searchParams.get('deliverableTypeId');
	const emailAddress = searchParams.get('emailAddress');
	const fromDate = searchParams.get('fromDate');
	const page = searchParams.get('page') ?? '0';
	const programIds = searchParams.get('programIds');
	const query = searchParams.get('query') ?? '';
	const sortField = searchParams.get('sortField');
	const sortDirection = searchParams.get('sortDirection');
	const size = 10;
	const toDate = searchParams.get('toDate');

	const [searchValue, setSearchValue] = useState(query);
	const debouncedSearchValue = useDebouncedState(searchValue);
	const [sortOptions, setSortOptions] = useState<FormOption[]>([]);
	const [companyOptions, setCompanyOptions] = useState<FormOption[]>([]);
	const [deliverableTypeOptions, setDeliverableTypeOptions] = useState<FormOption[]>([]);
	const [programOptions, setProgramOptions] = useState<FormOption[]>([]);
	const [filterValues, setFilterValues] = useState({
		company: clientId ?? '',
		contactLastName: contactLastName ?? '',
		contactEmail: emailAddress ?? '',
		deliverableType: deliverableTypeId ?? '',
		programs: programIds ?? '',
		fromDate: fromDate ? new Date(fromDate) : null,
		toDate: toDate ? new Date(toDate) : null,
	});

	const [deliverables, setDeliverables] = useState<DeliverableModel[]>([]);
	const [totalDeliverables, setTotalDeliverables] = useState(0);

	const fetchFilters = useCallback(async () => {
		const response = await deliverablesService.getDeliverableFilters().fetch();

		setSortOptions(response.sortFields);
		setCompanyOptions(response.clients);
		setDeliverableTypeOptions(response.deliverableType);
		setProgramOptions(response.programs);
	}, []);

	const fetchDeliverables = useCallback(async () => {
		const response = await deliverablesService
			.getDeliverables({
				size: String(size),
				...(clientId && { clientId }),
				...(contactLastName && { contactLastName }),
				...(deliverableTypeId && { deliverableTypeId }),
				...(emailAddress && { emailAddress }),
				...(fromDate && { fromDate }),
				...(page && { page }),
				...(programIds && { programIds }),
				...(query && { query }),
				...(sortDirection && { sortDirection }),
				...(sortField && { sortField }),
				...(toDate && { toDate }),
			})
			.fetch();

		setDeliverables(response.deliverables);
		setTotalDeliverables(response.totalCount);
	}, [
		clientId,
		contactLastName,
		deliverableTypeId,
		emailAddress,
		fromDate,
		page,
		programIds,
		query,
		sortDirection,
		sortField,
		toDate,
	]);

	useEffect(() => {
		history.replace(constructUrl(pathname, { page: '0', query: debouncedSearchValue }, search));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchValue, history, pathname]);

	const handlesortFieldChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		history.push(constructUrl(pathname, { page: '0', sortField: currentTarget.value }, search));
	};

	const handlesortDirectionChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		history.push(constructUrl(pathname, { page: '0', sortDirection: currentTarget.value }, search));
	};

	const handlePaginationChange = ({ pageIndex }: { pageIndex: number }) => {
		history.push(constructUrl(pathname, { page: String(pageIndex) }, search));
	};

	const handleClearFiltersButtonClick = () => {
		setFilterValues({
			company: '',
			contactLastName: '',
			contactEmail: '',
			deliverableType: '',
			programs: '',
			fromDate: null,
			toDate: null,
		});
		history.replace(pathname);
	};

	const handleFilterFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		history.replace(
			constructUrl(pathname, {
				...(filterValues.company && { clientId: filterValues.company }),
				...(filterValues.contactLastName && {
					contactLastName: filterValues.contactLastName,
				}),
				...(filterValues.deliverableType && {
					deliverableTypeId: filterValues.deliverableType,
				}),
				...(filterValues.contactEmail && {
					emailAddress: filterValues.contactEmail,
				}),
				...(filterValues.fromDate && {
					fromDate: format(filterValues.fromDate, 'yyyy-MM-dd'),
				}),
				...(filterValues.programs && {
					programIds: filterValues.programs,
				}),
				...(filterValues.toDate && {
					toDate: format(filterValues.toDate, 'yyyy-MM-dd'),
				}),
			})
		);
	};

	return (
		<Container className="py-13">
			<Helmet><title>Hackett Connect - Client Management - Deliverables</title></Helmet>
			<Row className="mb-6">
				<Col>
					<div className="d-flex justify-content-between align-items-end">
						<h1 className="mb-0">Deliverables</h1>
						<Button
							onClick={() => {
								history.push(`${path}/create`);
							}}
						>
							+ Create New Deliverable
						</Button>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={12} xl={8} className="mb-3">
					<div className="mb-6 d-flex justify-content-between">
						<SearchInput
							value={searchValue}
							onChange={({ currentTarget }) => {
								setSearchValue(currentTarget.value);
							}}
						/>
						<div className="d-flex align-items-center">
							<div className="d-flex align-items-center mr-2">
								<Form.Label className="mb-0">Sort By</Form.Label>
								<Select variant="secondary" value={sortField ?? ''} onChange={handlesortFieldChange}>
									{sortOptions.map((o) => {
										return (
											<option key={o.id} value={o.id}>
												{o.display}
											</option>
										);
									})}
								</Select>
							</div>
							<div className="d-flex align-items-center">
								<Form.Label className="mb-0">Sort Order</Form.Label>
								<Select
									variant="secondary"
									value={sortDirection ?? ''}
									onChange={handlesortDirectionChange}
								>
									<option value={SORT_DIRECTION.ASC}>Ascending</option>
									<option value={SORT_DIRECTION.DESC}>Descending</option>
								</Select>
							</div>
						</div>
					</div>
					<hr />
					<AsyncPage fetchData={fetchDeliverables}>
						{deliverables.length <= 0 && (
							<p className="mt-6 text-muted text-center">
								<strong>No Results</strong>
							</p>
						)}
						{deliverables.map((deliverable) => (
							<DeliverableListItem
								key={deliverable.deliverableId}
								type={deliverable.deliverableTypeDescription}
								to={`${path}/${deliverable.deliverableId}`}
								title={deliverable.title}
								programs={deliverable.programsDescription ?? ''}
								date={deliverable.deliverableDateDescription}
							/>
						))}
						{deliverables.length > 0 && (
							<TablePagination
								showPageSize={false}
								pageSize={size}
								pageIndex={parseInt(page ?? '0', 10)}
								total={totalDeliverables}
								onChange={handlePaginationChange}
							/>
						)}
					</AsyncPage>
				</Col>
				<Col xs={12} xl={4}>
					<Card>
						<Card.Body>
							<div className="mb-4 d-flex align-items-center justify-content-between">
								<Card.Title className="mb-0">Filters</Card.Title>
								<Button variant="link" onClick={handleClearFiltersButtonClick}>
									Clear
								</Button>
							</div>
							<AsyncPage fetchData={fetchFilters}>
								<Form onSubmit={handleFilterFormSubmit}>
									<Form.Group className="mb-6">
										<Form.Label>Company</Form.Label>
										<Select
											value={filterValues.company}
											onChange={({ currentTarget }) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													company: currentTarget.value,
												}));
											}}
										>
											<option value="">Select company...</option>
											{companyOptions.map((o) => (
												<option key={o.id} value={o.id}>
													{o.display}
												</option>
											))}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Contact Last Name</Form.Label>
										<Form.Control
											type="text"
											value={filterValues.contactLastName}
											onChange={({ currentTarget }) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													contactLastName: currentTarget.value,
												}));
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Contact Email</Form.Label>
										<Form.Control
											type="email"
											value={filterValues.contactEmail}
											onChange={({ currentTarget }) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													contactEmail: currentTarget.value,
												}));
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Deliverable Type</Form.Label>
										<Select
											value={filterValues.deliverableType}
											onChange={({ currentTarget }) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													deliverableType: currentTarget.value,
												}));
											}}
										>
											<option value="">Select deliverable type...</option>
											{deliverableTypeOptions.map((o) => (
												<option key={o.id} value={o.id}>
													{o.display}
												</option>
											))}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Programs</Form.Label>
										<Select
											value={filterValues.programs}
											onChange={({ currentTarget }) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													programs: currentTarget.value,
												}));
											}}
										>
											<option value="">Select programs...</option>
											{programOptions.map((o) => (
												<option key={o.id} value={o.id}>
													{o.display}
												</option>
											))}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>From Date</Form.Label>
										<DateInput
											isClearable
											maxDate={filterValues.toDate}
											selected={filterValues.fromDate}
											onChange={(date) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													fromDate: date,
												}));
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>To Date</Form.Label>
										<DateInput
											isClearable
											minDate={filterValues.fromDate}
											selected={filterValues.toDate}
											onChange={(date) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													toDate: date,
												}));
											}}
										/>
									</Form.Group>
									<hr className="mb-6" />
									<div className="text-center">
										<Button variant="outline-primary" type="submit">
											Apply Filters
										</Button>
									</div>
								</Form>
							</AsyncPage>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};
