import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import { createUseThemedStyles, useSignOut } from '@/hooks';
import { HeaderSearch } from '@/components';
import { mediaQueries } from '@/jss';

import { ReactComponent as HackettLogo } from '@/assets/logos/logo-hackett-connect.svg';

const transitionDuration = 200;

const useStyles = createUseThemedStyles((theme) => ({
	mobileHeader: {
		height: 60,
		display: 'flex',
		padding: '0 15px',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& svg': {
			maxWidth: 240,
			marginRight: 15,
		},
	},
	mobileNavigation: {
		top: 60,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 7,
		opacity: 0,
		position: 'fixed',
		overflowY: 'auto',
		transform: 'translateY(50px)',
		backgroundColor: theme.colors.white,
		transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
		'& ul': {
			margin: 0,
			padding: '5px 0',
			listStyle: 'none',
			'& li': {
				'& a': {
					display: 'block',
					padding: '5px 10px',
					textAlign: 'center',
				},
			},
		},
		[mediaQueries.nav]: {
			display: 'none',
		},
	},
	mobileSearchBar: {
		display: 'flex',
		padding: '15px 0 0',
		justifyContent: 'center',
	},
	menuButton: {
		width: 44,
		height: 44,
		padding: 0,
		alignItems: 'center',
		justifyContent: 'center',
		'& span': {
			width: 15,
			height: 2,
			flexShrink: 0,
			borderRadius: 1,
			position: 'relative',
			backgroundColor: theme.colors.white,
			transition: 'background-color 200ms',
			'&:before, &:after': {
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				content: '""',
				borderRadius: 1,
				position: 'absolute',
				transition: 'transform 200ms',
				backgroundColor: theme.colors.white,
			},
			'&:before': {
				transform: 'translateY(-4px)',
			},
			'&:after': {
				transform: 'translateY(4px)',
			},
		},
		'&.active span': {
			backgroundColor: 'transparent',
			'&:before': {
				transform: 'translateY(0) rotate(45deg)',
			},
			'&:after': {
				transform: 'translateY(0) rotate(-45deg)',
			},
		},
	},
	'@global': {
		'.mobile-nav-enter': {
			opacity: 0,
			transform: 'translateY(50px)',
		},
		'.mobile-nav-enter-active': {
			opacity: 1,
			transform: 'translateY(0px)',
		},
		'.mobile-nav-enter-done': {
			opacity: 1,
			transform: 'translateY(0px)',
		},
		'.mobile-nav-exit': {
			opacity: 1,
			transform: 'translateY(0px)',
		},
		'.mobile-nav-exit-active': {
			opacity: 0,
			transform: 'translateY(50px)',
		},
	},
}));

interface HeaderMobileProps {
	navigationLinks: Record<string, { to: string; title: string; external?: boolean }[]>;
	className?: string;
}

export const HeaderMobile = ({ navigationLinks, className }: HeaderMobileProps) => {
	const classes = useStyles();
	const { pathname } = useLocation();
	const { signOut } = useSignOut();

	const headerRef = useRef<HTMLElement>(null);
	const mobileNavRef = useRef<HTMLElement>(null);
	const [menuOpen, setMenuOpen] = useState<boolean>(false);

	const handleWindowResize = useCallback(() => {
		setMobileNavTop();

		if (menuOpen) {
			setMenuOpen(false);
			document.body.style.overflow = 'visible';
		}
	}, [menuOpen]);

	/* ----------------------------------------------------------- */
	/* Nav padding for fixed header */
	/* ----------------------------------------------------------- */
	function setMobileNavTop() {
		if (!mobileNavRef.current) {
			return;
		}

		if (!headerRef.current) {
			mobileNavRef.current.style.top = '0px';
			return;
		}

		const headerBottom = headerRef.current.getBoundingClientRect().bottom;
		mobileNavRef.current.style.top = `${headerBottom}px`;
	}

	/* ----------------------------------------------------------- */
	/* Set padding on window resize */
	/* ----------------------------------------------------------- */
	useEffect(() => {
		setMobileNavTop();

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [handleWindowResize]);

	/* ----------------------------------------------------------- */
	/* Disable scrolling when menu is open */
	/* ----------------------------------------------------------- */
	useEffect(() => {
		setMobileNavTop();

		if (menuOpen) {
			document.body.style.overflow = 'hidden';
			return;
		}

		document.body.style.overflow = 'visible';

		return () => {
			document.body.style.overflow = 'visible';
		};
	}, [menuOpen]);

	/* ----------------------------------------------------------- */
	/* Close menu if pathname changes */
	/* ----------------------------------------------------------- */
	useEffect(() => {
		setMenuOpen((previousValue) => {
			if (previousValue) {
				return false;
			}

			return previousValue;
		});

		document.body.style.overflow = 'visible';
	}, [pathname]);

	return (
		<>
			<header ref={headerRef} className={classNames(classes.mobileHeader, className)}>
				<Link to="/">
					<HackettLogo />
				</Link>
				<Button
					className={classNames(classes.menuButton, { active: menuOpen })}
					size="sm"
					variant="link"
					onClick={() => {
						setMenuOpen(!menuOpen);
					}}
				>
					<span />
				</Button>
			</header>

			<CSSTransition
				in={menuOpen}
				timeout={transitionDuration}
				classNames="mobile-nav"
				mountOnEnter={true}
				unmountOnExit={true}
			>
				<nav ref={mobileNavRef} className={classes.mobileNavigation}>
					<div className={classes.mobileSearchBar}>
						<HeaderSearch />
					</div>
					{Object.values(navigationLinks).map((section, sectionIndex) => {
						if (section.length <= 0) {
							return null;
						}

						return (
							<React.Fragment key={sectionIndex}>
								<ul>
									{section.map((link, linkIndex) => {
										if (link.external) {
											return (
												<li key={linkIndex}>
													<a
														href={link.to}
														target="_blank"
														rel="noreferrer"
														dangerouslySetInnerHTML={{ __html: link.title }}
													/>
												</li>
											);
										} else {
											return (
												<li key={linkIndex}>
													<Link
														to={link.to}
														dangerouslySetInnerHTML={{ __html: link.title }}
													/>
												</li>
											);
										}
									})}
								</ul>
								<hr />
							</React.Fragment>
						);
					})}
					<div className="py-3 text-center">
						<Button onClick={signOut}>Sign Out</Button>
					</div>
				</nav>
			</CSSTransition>
		</>
	);
};
