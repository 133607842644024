export enum DOCUMENT_UPLOAD_STATUS_ID {
	CREATED = 'CREATED',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETE = 'COMPLETE',
}

export interface PresignedUploadModel {
	accessUrl: string;
	contentType: string;
	documentId: string;
	expirationTimestamp: string;
	expirationTimestampDescription: string;
	httpHeaders: Record<string, string>;
	httpMethod: string;
	url: string;
}
