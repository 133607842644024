import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorConfig, ERROR_CODES } from '@/lib/http-client';
import { useAlert, useSignOut } from '@/hooks';
import { isErrorConfig } from '@/lib/utils';
import { RenderJson } from '@/components';

export function useHandleError() {
	const history = useHistory();
	const { signOut, signOutNoServiceCall } = useSignOut();
	const { showAlert } = useAlert();

	const handleError = useCallback(
		async (error: ErrorConfig | unknown) => {
			if (isErrorConfig(error)) {
				if (error.code === ERROR_CODES.REQUEST_ABORTED) {
					return;
				}

				if (error.status === 503) {
					history.replace('/maintenance');
					return;
				}

				if (error.code === 'AUTHENTICATION_REQUIRED' || error.code === 'NotAuthorizedException') {
					signOutNoServiceCall();
					return;
				}

				if (error.status === 401) {
					signOutNoServiceCall();
					return;
				}

				if (error.status === 403) {
					signOut();
					return;
				}

				let displayMessage = '';
				if (typeof error === 'string') {
					displayMessage = error;
				} else {
					displayMessage = error.message;
				}

				if (error.code === 'WARNING') {
					showAlert({
						variant: 'warning',
						children: () => {
							return (
								<p className="mb-0 text-white" style={{ whiteSpace: 'pre-line' }}>
									{displayMessage}
								</p>
							);
						},
					});
					return;
				}

				showAlert({
					variant: 'danger',
					children: () => {
						return (
							<p className="mb-0 text-white" style={{ whiteSpace: 'pre-line' }}>
								<strong>Error:</strong> {displayMessage}
							</p>
						);
					},
				});
			} else {
				showAlert({
					variant: 'danger',
					children: () => {
						return <RenderJson json={error} />;
					},
				});
			}
		},
		[history, showAlert, signOut, signOutNoServiceCall]
	);

	return handleError;
}
