import React, { Fragment, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import {
	engagementPlanTableColumns,
	engagementPlanTableHeaders,
	engagementPlanTableRows,
	ENGAGEMENT_PLAN_TABLE_COLUMN_IDS,
} from '@/lib/__mocks__';

import { TableCell, TableHeader, TableRenderer, TableRow } from '@/components/table';
import { Comment, CommentPost, LinkBox, Select } from '@/components';

import { ReactComponent as PaperclipIcon } from '@/assets/icons/icon-paperclip.svg';
import { ReactComponent as ChatIcon } from '@/assets/icons/icon-chat.svg';

enum VIEW_BY {
	OBJECTIVE = 'OBJECTIVE',
	DATES = 'DATES',
	STATUS = 'STATUS',
}

export const EngagementPlan = () => {
	const [viewByValue, setViewByValue] = useState<string>(VIEW_BY.OBJECTIVE);

	const [tableColumnsData, setTableColumnsData] = useState(engagementPlanTableColumns);
	const [tableHeadersData] = useState(engagementPlanTableHeaders);
	const [tableRowData, setTableRowData] = useState(engagementPlanTableRows);

	return (
		<Container className="pt-13">
			<Row className="mb-6">
				<Col>
					<h2 className="mb-3">Engagement Plan</h2>
					<Form.Group>
						<Form.Label className="mb-0 mr-3">View By:</Form.Label>
						<Form.Check
							inline
							type="radio"
							name="view-by"
							id="view-by--objective"
							label="Objective"
							value={VIEW_BY.OBJECTIVE}
							checked={viewByValue === VIEW_BY.OBJECTIVE}
							onChange={(event) => {
								setViewByValue(event.currentTarget.value);
							}}
						/>
						<Form.Check
							inline
							type="radio"
							name="view-by"
							id="view-by--dates"
							label="Dates"
							value={VIEW_BY.DATES}
							checked={viewByValue === VIEW_BY.DATES}
							onChange={(event) => {
								setViewByValue(event.currentTarget.value);
							}}
						/>
						<Form.Check
							inline
							type="radio"
							name="view-by"
							id="view-by--status"
							label="Status"
							value={VIEW_BY.STATUS}
							checked={viewByValue === VIEW_BY.STATUS}
							onChange={(event) => {
								setViewByValue(event.currentTarget.value);
							}}
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col xs={12} xl={8} className="mb-6">
					<TableRenderer
						// hideLoadMoreButton
						tableColumnData={tableColumnsData}
						onTableColumnDataChange={setTableColumnsData}
						tableRowData={tableRowData}
						tableRowDataTotal={1000}
						onTableRowDataChange={setTableRowData}
						pageSize={10}
						pageIndex={0}
						onPaginationChange={() => {
							return;
						}}
						tableHeaderRowRenderer={() => {
							return (
								<TableRow className="bg-transparent border-0">
									{Object.values(tableColumnsData).map((columnConfig) => {
										if (!columnConfig.isShowing) {
											return null;
										}

										const tableHeader = tableHeadersData[columnConfig.columnId];

										return (
											<TableHeader
												className="justify-content-center"
												classNameOuter="bg-transparent"
												key={tableHeader.tableHeaderId}
											>
												<span className="small text-muted">{tableHeader.title}</span>
											</TableHeader>
										);
									})}
								</TableRow>
							);
						}}
						tableBodyRowRenderer={(data) => {
							return (
								<Fragment key={data.rowId}>
									<TableRow>
										{Object.values(tableColumnsData).map((columnConfig) => {
											const { columnData } = data;

											switch (columnConfig.columnId) {
												case ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.ACTION_ITEM:
													return (
														<TableCell width={380} key={columnConfig.columnId}>
															<h4 className="mb-2">{columnData.description}</h4>
															<div className="d-flex align-items-center">
																<Button
																	className="mr-2 d-flex align-items-center small"
																	variant="link"
																>
																	<PaperclipIcon className="mr-1" />
																	{columnData.attachementCount} Attachments
																</Button>
																<Button
																	className="d-flex align-items-center small"
																	variant="link"
																	onClick={() => {
																		setTableRowData(
																			tableRowData.map((trd) => {
																				if (trd.rowId === data.rowId) {
																					trd.expanded = !trd.expanded;
																				}

																				return trd;
																			})
																		);
																	}}
																>
																	<ChatIcon className="mr-1" />
																	{columnData.commentCount === 0
																		? 'Start a Conversation'
																		: `${columnData.commentCount} Comments`}
																</Button>
															</div>
														</TableCell>
													);
												case ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.ADVISOR:
													return (
														<TableCell key={columnConfig.columnId} className="text-center">
															{columnData.advisor}
														</TableCell>
													);
												case ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.STATUS:
													return (
														<TableCell
															key={columnConfig.columnId}
															minWidth={145}
															className="pl-0 pr-0"
														>
															<Select
																value={columnData.statusId}
																onChange={() => {
																	return;
																}}
															>
																<option value="COMPLETE">Complete</option>
																<option value="IN_PROGRESS">In Progress</option>
																<option value="NONE">None</option>
															</Select>
														</TableCell>
													);
												case ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.MILESTONE_DATE:
													return (
														<TableCell key={columnConfig.columnId} className="text-center">
															{columnData.milestoneDate}
														</TableCell>
													);
												default:
													return <TableCell key={columnConfig.columnId}>N/A</TableCell>;
											}
										})}
									</TableRow>
									<TableRow className={!data.expanded ? 'border-0' : ''}>
										<TableCell
											colSpan={
												Object.values(tableColumnsData).filter(
													(columnConfig) => columnConfig.isShowing
												).length
											}
											expanded={data.expanded}
										>
											<Comment
												avatarUrl="https://via.placeholder.com/34"
												name="John Smith"
												title="Hackett Advisor"
												comment="<a href='#'>@a.miller</a> <a href='#'>@loremipsum.com</a> Anthony, here's that workshop I recommended to you last we spoke."
											/>
											<Comment
												avatarUrl="https://via.placeholder.com/34"
												name="Anthony Miller"
												title="Lorem Ipsum Enterprises"
												comment="Thanks. Do you recommend the enture Procurement team attned, or should we limit it to management?"
											/>
											<Comment
												avatarUrl="https://via.placeholder.com/34"
												name="John Smith"
												title="Hackett Advisor"
												comment="I’d recommend the full team attend. Here’s the agenda preview:"
												attachments={[
													{
														url: '#',
														title: 'Strategic Sourcing Workshop - Agenda Preview (.pdf)',
													},
												]}
											/>
											<CommentPost />
										</TableCell>
									</TableRow>
								</Fragment>
							);
						}}
					/>
				</Col>
				<Col xs={12} xl={4}>
					<LinkBox
						sections={[
							{
								title: "Can't find what you're looking for?",
								links: [{ title: 'Contact Hackett', to: '/contact', showIcon: true }],
							},
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
};
