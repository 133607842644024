import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';
import Color from 'color';

import { createUseThemedStyles } from '@/hooks';

import { ReactComponent as XIcon } from '@/assets/icons/icon-x.svg';
import { ReactComponent as ChevronIcon } from '@/assets/icons/icon-chevron-right.svg';

const useStyles = createUseThemedStyles((theme) => ({
	notificationItem: ({ isNew }: { isNew?: boolean }) => ({
		display: 'flex',
		alignItems: 'center',
		whiteSpace: 'normal',
		padding: '10px 16px 10px 10px',
		borderTop: `1px solid ${theme.colors.gray300}`,
		backgroundColor: isNew
			? Color(theme.colors.warning)
					.alpha(0.16)
					.string()
			: theme.colors.white,
		'&:hover, &:focus': {
			backgroundColor: isNew
				? Color(theme.colors.warning)
						.alpha(0.32)
						.string()
				: theme.colors.gray100,
		},
		'&:active': {
			color: theme.colors.black,
			backgroundColor: isNew
				? Color(theme.colors.warning)
						.alpha(0.56)
						.string()
				: Color(theme.colors.primary)
						.alpha(0.16)
						.string(),
		},
	}),
	messageOuter: {
		flex: 1,
	},
	dismissButtonOuter: {
		marginRight: 10,
		display: 'flex',
		alignSelf: 'flex-start',
	},
	xIcon: {
		width: 16,
		height: 16,
		display: 'flex',
		fill: theme.colors.primary,
	},
	chevronIcon: {
		width: 14,
		height: 14,
		display: 'flex',
		marginLeft: 16,
		fill: theme.colors.primary,
	},
}));

interface NotificationItemProps extends DropdownItemProps {
	message: string;
	date: string;
	isNew?: boolean;
	onDismiss?(event: React.MouseEvent<HTMLElement, MouseEvent>): void;
}

export const NotificationItem = ({ message, date, isNew, onDismiss, ...props }: NotificationItemProps) => {
	const classes = useStyles({
		isNew,
	});

	return (
		<Dropdown.Item className={classes.notificationItem} {...props}>
			<div className={classes.dismissButtonOuter}>
				<Button
					variant="link"
					onClick={(event) => {
						event.stopPropagation();
						if (onDismiss) {
							onDismiss(event);
						}
					}}
				>
					<XIcon className={classes.xIcon} />
				</Button>
			</div>
			<div className={classes.messageOuter}>
				<p className="mb-0 small">
					{message} <span className="text-muted font-italic">{date}</span>
				</p>
			</div>
			<ChevronIcon className={classes.chevronIcon} />
		</Dropdown.Item>
	);
};
