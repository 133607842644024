import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';
import { ReactComponent as ChevronIcon } from '@/assets/icons/icon-chevron-right.svg';
import { boxShadow } from '@/jss';

interface LinkBoxProps {
	sections: {
		title: string;
		links: {
			category?: string;
			to: string;
			title: string;
			showIcon?: boolean;
		}[];
	}[];
	callToAction?: {
		to: string;
		title: string;
	};
	className?: string;
}

const useStyles = createUseThemedStyles((theme) => ({
	linkBox: {
		...boxShadow,
		backgroundColor: theme.colors.white,
	},
	sectionsOuter: {
		padding: '2.4rem 3.2rem',
	},
	link: {
		display: 'block',
		...theme.fonts.openSansSemiBold,
	},
	arrowIcon: {
		marginTop: -3,
		marginLeft: 8,
	},
	callToActionOuter: {
		padding: '1.8rem 3.2rem',
		backgroundColor: theme.colors.primary,
	},
	callToAction: {
		color: theme.colors.white,
		'&:hover': {
			color: theme.colors.white,
		},
	},
}));

export const LinkBox = ({ sections, callToAction, className }: LinkBoxProps) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.linkBox, className)}>
			<div className={classNames(classes.sectionsOuter)}>
				{sections.map((section, sectionIndex) => {
					const isLastSection = sectionIndex === sections.length - 1;

					return (
						<div key={sectionIndex} className={isLastSection ? '' : 'mb-11'}>
							<h3 className="mb-4">{section.title}</h3>
							{section.links.map((link, linkIndex) => {
								const isLastLink = linkIndex === section.links.length - 1;

								return (
									<Fragment key={`${sectionIndex}-${linkIndex}`}>
										{link.category && <h4 className="mb-2 text-muted">{link.category}</h4>}
										<Link
											className={classNames(classes.link, {
												'mb-5': !isLastLink,
											})}
											to={link.to}
										>
											{link.title}
											{link.showIcon && <ChevronIcon className={classes.arrowIcon} />}
										</Link>
									</Fragment>
								);
							})}
						</div>
					);
				})}
			</div>
			{callToAction && (
				<div className={classes.callToActionOuter}>
					<Link className={classes.callToAction} to={callToAction.to}>
						<strong>{callToAction.title}</strong> <ChevronIcon className={classes.arrowIcon} />
					</Link>
				</div>
			)}
		</div>
	);
};
