import React, { useCallback } from 'react';
import { copyTextToClipboard as cttc, copyHtmlTextToClipboard as chttc } from '@/lib/utils';
import { useAlert } from '@/hooks';

export function useCopyTextToClipboard() {
	const { showAlert } = useAlert();

	const copyTextToClipboard = useCallback(
		(
			text: string,
			successMessage: string = 'Text was copied to clipboard.',
			errorMessage: string = 'Text could not be copied to clipboard.'
		) => {
			cttc(
				text,
				() => {
					showAlert({
						variant: 'success',
						children: () => {
							return (
								<p className="mb-0 text-white">
									<strong>Success:</strong> {successMessage}
								</p>
							);
						},
					});
				},
				() => {
					showAlert({
						variant: 'danger',
						children: () => {
							return (
								<p className="mb-0 text-white">
									<strong>Error:</strong> {errorMessage}
								</p>
							);
						},
					});
				}
			);
		},
		[showAlert]
	);

	const copyHtmlToClipboard = useCallback(
		(
			html: string,
			successMessage: string = 'Rich text was copied to clipboard.',
			errorMessage: string = 'Rich text could not be copied to clipboard.'
		) => {
			chttc(
				html,
				() => {
					showAlert({
						variant: 'success',
						children: () => {
							return (
								<p className="mb-0 text-white">
									<strong>Success:</strong> {successMessage}
								</p>
							);
						},
					});
				},
				() => {
					showAlert({
						variant: 'danger',
						children: () => {
							return (
								<p className="mb-0 text-white">
									<strong>Error:</strong> {errorMessage}
								</p>
							);
						},
					});
				}
			);
		},
		[showAlert]
	);

	return { copyTextToClipboard, copyHtmlToClipboard };
}
