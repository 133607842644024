import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';

import config from '@/lib/config';
import { accessTokenCookieAttributes, COOKIES } from '@/lib/models';
import { publicService } from '@/lib/services';
import { useHandleError } from '@/hooks';
import { LoadingButton } from '@/components';
import { constructUrl } from '@/lib/utils';
import { Helmet } from 'react-helmet';

export const SignIn = () => {
	const handleError = useHandleError();
	const [isLoading, setIsLoading] = useState(false);
	const [emaillAddressInputValue, setEmaillAddressInputValue] = useState('');

	async function handleSignInFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		try {
			setIsLoading(true);

			const { token } = await publicService.authenticate({ emailAddress: emaillAddressInputValue }).fetch();
			const targetUrl = Cookies.get(COOKIES.TARGET_URL);

			Cookies.set(COOKIES.ACCESS_TOKEN, token, accessTokenCookieAttributes);

			if (targetUrl) {
				window.location.href = targetUrl;
			} else {
				window.location.href = '/';
			}
		} catch (error) {
			setIsLoading(false);
			handleError(error);
		}
	}

	return (
		<Container className="py-17">
			<Helmet>
				<title>Hackett Connect - Sign In</title>
			</Helmet>
			<Row>
				<Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
					<Card>
						<Card.Header>
							<Card.Title>Sign In</Card.Title>
						</Card.Header>
						<Card.Body>
							{config.HACKETT_ADVISORY_USE_REAL_AUTH === 'true' ? (
								<Button
									className="d-block w-100"
									onClick={() => {
										window.location.href = constructUrl(config.HACKETT_ADVISORY_AUTH_UI_URL, {
											redirectUrl: encodeURIComponent(window.location.origin),
											platform: 'hackett-connect',
										});
									}}
								>
									Sign In
								</Button>
							) : (
								<>
									<Form onSubmit={handleSignInFormSubmit}>
										<Form.Group className="mb-2">
											<Form.Label>Email Address</Form.Label>
											<Form.Control
												type="email"
												value={emaillAddressInputValue}
												onChange={(event) => {
													setEmaillAddressInputValue(event.currentTarget.value);
												}}
												required
											/>
										</Form.Group>
										<div className="text-right">
											<LoadingButton
												isLoading={isLoading}
												className="d-block w-100"
												type="submit"
											>
												Sign In
											</LoadingButton>
										</div>
									</Form>
									{config.HACKETT_ADVISORY_SHOW_DEBUG === 'true' && (
										<div className="mt-5">
											<h3 className="mb-2">Dev Accounts</h3>
											<Button
												className="d-block w-100 mb-2"
												variant="outline-primary"
												onClick={() => {
													setEmaillAddressInputValue('maa@xmog.com');
												}}
											>
												Super Admin
											</Button>
											<Button
												className="d-block w-100 mb-2"
												variant="outline-primary"
												onClick={() => {
													setEmaillAddressInputValue('inquiry-advisor@advisory.com');
												}}
											>
												Inquiry Advisor
											</Button>
											<Button
												className="d-block w-100"
												variant="outline-primary"
												onClick={() => {
													setEmaillAddressInputValue('inquiry-client@advisory.com');
												}}
											>
												Inquiry Client
											</Button>
										</div>
									)}
								</>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};
