import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

import { ReactComponent as FileIcon } from '@/assets/icons/icon-file.svg';
import { ReactComponent as CalendarIcon } from '@/assets/icons/icon-calendar.svg';
import { ReactComponent as ChevronIcon } from '@/assets/icons/icon-chevron-right.svg';
import { ReactComponent as ClockIcon } from '@/assets/icons/icon-clock.svg';
import { ReactComponent as StarIcon } from '@/assets/icons/icon-star.svg';

const useStyles = createUseThemedStyles((theme) => ({
	featureCardSecondary: {
		display: 'flex',
		color: theme.colors.white,
	},
	icon: {
		width: 56,
		height: 56,
		flexShrink: 0,
		marginRight: 24,
		opacity: 0.5,
	},
	link: {
		display: 'block',
		color: theme.colors.white,
		...theme.fonts.openSansBold,
		'&:hover': {
			color: theme.colors.white,
		},
	},
	chevronIcon: {
		marginTop: -3,
		marginLeft: 8,
	},
	callToActionOuter: {
		display: 'flex',
		alignItems: 'center',
		'&-primary': {
			color: theme.colors.primary,
		},
		'&-success': {
			color: theme.colors.success,
		},
		'&-warning': {
			color: theme.colors.warning,
		},
		'&-danger': {
			color: theme.colors.danger,
		},
		'&-info': {
			color: theme.colors.info,
		},
	},
	callToActionIcon: {
		flexShrink: 0,
		marginRight: 8,
	},
}));

interface FeatureCardSecondaryProps {
	icon?: 'file' | 'calendar';
	category: string;
	title: string;
	to: string;
	callToActionVariant?: 'primary' | 'success' | 'warning' | 'danger' | 'info';
	callToActionIcon?: 'clock' | 'star';
	callToActionText?: string;
	className?: string;
}

export const FeatureCardSecondary = ({
	icon,
	category,
	title,
	to,
	callToActionVariant,
	callToActionText,
	callToActionIcon,
	className,
}: FeatureCardSecondaryProps) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.featureCardSecondary, className)}>
			{icon && (
				<>
					{icon === 'file' && <FileIcon className={classes.icon} />}
					{icon === 'calendar' && <CalendarIcon className={classes.icon} />}
				</>
			)}
			<div>
				<h4 className="mb-2">{category}</h4>
				<Link className={classNames(classes.link, callToActionText ? 'mb-3' : '')} to={to}>
					{title}
					<ChevronIcon className={classes.chevronIcon} />
				</Link>
				{callToActionText && (
					<div
						className={classNames(
							classes.callToActionOuter,
							callToActionVariant ? `${[classes.callToActionOuter]}-${callToActionVariant}` : ''
						)}
					>
						{callToActionIcon && (
							<>
								{callToActionIcon === 'clock' && <ClockIcon className={classes.callToActionIcon} />}
								{callToActionIcon === 'star' && <StarIcon className={classes.callToActionIcon} />}
							</>
						)}
						<h4 className="mb-0">{callToActionText}</h4>
					</div>
				)}
			</div>
		</div>
	);
};
