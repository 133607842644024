import React from 'react';
import { DOCUMENT_FILE_TYPES } from '@/lib/models';
import classNames from 'classnames';

import { ReactComponent as WordIcon } from '@/assets/icons/icon-file-word.svg';
import { ReactComponent as PdfIcon } from '@/assets/icons/icon-file-pdf.svg';
import { ReactComponent as PowerpointIcon } from '@/assets/icons/icon-file-powerpoint.svg';
import { ReactComponent as TextIcon } from '@/assets/icons/icon-file-text.svg';
import { ReactComponent as VideoIcon } from '@/assets/icons/icon-file-video.svg';
import { ReactComponent as ExcelIcon } from '@/assets/icons/icon-file-excel.svg';
import { ReactComponent as ZipIcon } from '@/assets/icons/icon-file-zip.svg';

export const useGetIconForFileType = (className?: string, fileType?: string) => {
	switch (fileType) {
		case DOCUMENT_FILE_TYPES.DOCX:
			return <WordIcon className={classNames(className, 'text-info')} />;
		case DOCUMENT_FILE_TYPES.PDF:
			return <PdfIcon className={classNames(className, 'text-danger')} />;
		case DOCUMENT_FILE_TYPES.PPTX:
			return <PowerpointIcon className={classNames(className, 'text-danger')} />;
		case DOCUMENT_FILE_TYPES.TXT:
			return <TextIcon className={classNames(className, 'text-muted')} />;
		case DOCUMENT_FILE_TYPES.VIDEO_LINK:
			return <VideoIcon className={classNames(className, 'text-muted')} />;
		case DOCUMENT_FILE_TYPES.XLSX:
			return <ExcelIcon className={classNames(className, 'text-success')} />;
		case DOCUMENT_FILE_TYPES.ZIP:
			return <ZipIcon className={classNames(className, 'text-muted')} />;
		default:
			return null;
	}
};
