import React, { useRef } from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

import { useNavigationConfig } from '@/hooks';
import { BrowseEvents, EventDetail, NoMatch, PerformanceStudies } from '@/pages';

export const Events = () => {
	const { path } = useRouteMatch();
	const { navigationConfig } = useNavigationConfig();

	const routes = useRef([
		...(navigationConfig?.allowBrowseEvents
			? [
				{
					path: `${path}/browse-events`,
					exact: true,
					main: BrowseEvents,
				},
				{
					path: `${path}/browse-events/:eventId`,
					exact: true,
					main: EventDetail,
				},
			]
			: []),
		...(navigationConfig?.allowPerformanceStudies
			? [
				{
					path: `${path}/performance-studies`,
					exact: true,
					main: PerformanceStudies,
				},
				{
					path: `${path}/performance-studies/:eventId`,
					exact: true,
					main: EventDetail,
				},
			]
			: []),
		{
			path: `${path}/*`,
			exact: false,
			main: NoMatch,
		},
	]).current;

	return (
		<>
			<Switch>
				{routes.map((route, index) => {
					return <Route key={index} path={route.path} exact={route.exact} children={<route.main />} />;
				})}
			</Switch>
		</>
	);
};
