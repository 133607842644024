import { cloneDeep } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Button, Collapse, Form } from 'react-bootstrap';

import { createUseThemedStyles } from '@/hooks';
import { Dropdown, DropdownMenu, DropdownToggle, FadeTransition } from '@/components';

import { ReactComponent as DownChevron } from '@/assets/icons/icon-chevron-down.svg';

export interface MultiSelectOption {
	id: string;
	title: string;
	value: string;
	options?: MultiSelectOption[];
	disabled?: boolean;
}

const useMultiSelectStyles = createUseThemedStyles(
	(theme) => ({
		dropdown: {
			width: '100%',
		},
		dropdownToggle: {
			width: '100%',
			borderRadius: 2,
			display: 'flex',
			padding: '8px 14px',
			color: theme.colors.black,
			justifyContent: 'space-between',
			backgroundColor: theme.colors.white,
			border: `1px solid ${theme.colors.gray500}`,
			boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.16)',
			'&:hover': {
				textDecoration: 'none',
				color: theme.colors.black,
				backgroundColor: theme.colors.white,
				border: `1px solid ${theme.colors.gray600}`,
			},
			'&:focus': {
				outline: 'none',
				border: `1px solid ${theme.colors.primary}`,
			},
		},
		selectedLength: {
			height: 22,
			minWidth: 22,
			padding: '0 6px',
			borderRadius: 11,
			color: theme.colors.white,
			fontSize: '1.4rem !important',
			lineHeight: '2.2rem !important',
			backgroundColor: theme.colors.primary,
		},
		dropdownMenu: {
			width: '100%',
			maxHeight: 226,
			overflowY: 'auto',
			padding: '18px 12px',
		},
	}),
	{ index: 3 }
);

interface MultiSelectProps {
	options: MultiSelectOption[];
	selected: string[];
	onChange(selectedValues: string[]): void;
	disabled?: boolean;
}

interface FlattenedMultiSelectOption {
	id: string;
	title: string;
	value: string;
	parentId: string;
	childIds: string[];
}

export const MultiSelect: FC<MultiSelectProps> = ({ options, selected, onChange, disabled, children }) => {
	const classes = useMultiSelectStyles();
	const [flattendOptions, setFlattenedOptions] = useState<Record<string, FlattenedMultiSelectOption>>({});

	useEffect(() => {
		const flatten = (array: MultiSelectOption[], parentId: string = 'root'): FlattenedMultiSelectOption[] => {
			return array.flatMap(({ options = [], id, ...rest }) => [
				{ id, ...rest, parentId, childIds: options.map((o) => o.id) },
				...flatten(options ?? [], id),
			]);
		};

		const flattenedOps = flatten(options);
		const flattenedOptionsAsObject = flattenedOps.reduce((previous, current) => {
			return {
				...previous,
				[current.id]: current,
			};
		}, {} as Record<string, FlattenedMultiSelectOption>);

		setFlattenedOptions(flattenedOptionsAsObject);
	}, [options]);

	const getAllDescendantValues = (option?: FlattenedMultiSelectOption): string[] => {
		if (!option) {
			return [];
		}

		return [...option.childIds, ...option.childIds.flatMap((cid) => getAllDescendantValues(flattendOptions[cid]))];
	};

	const getAllAncestorValues = (option?: FlattenedMultiSelectOption): string[] => {
		if (!option) {
			return [];
		}

		return [option.parentId, ...getAllAncestorValues(flattendOptions[option.parentId])];
	};

	const handleOnChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		const selectionsClone = cloneDeep(selected);

		const currentOption = flattendOptions[currentTarget.value];
		const childValues = getAllDescendantValues(currentOption);
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const parentValues = getAllAncestorValues(currentOption);

		const valuesToUpdate = [currentTarget.value, ...childValues];

		// child selection logic
		if (selectionsClone.includes(currentTarget.value)) {
			for (const value of valuesToUpdate) {
				const indexToRemove = selectionsClone.indexOf(value);
				if (indexToRemove > -1) {
					selectionsClone.splice(indexToRemove, 1);
				}
			}
		} else {
			for (const value of valuesToUpdate) {
				if (!selectionsClone.includes(value)) {
					selectionsClone.push(value);
				}
			}
		}

		// parent selection logic
		const parentId = flattendOptions[currentTarget.value]?.parentId;
		const currentParent = flattendOptions[parentId];

		if (currentParent) {
			const allChildrenValues = getAllDescendantValues(currentParent);
			const allValuesExist = allChildrenValues.every((v) => {
				return selectionsClone.includes(v);
			});

			if (allValuesExist) {
				if (!selectionsClone.includes(currentParent.value)) {
					selectionsClone.push(currentParent.value);
				}
			} else {
				if (selectionsClone.includes(currentParent.value)) {
					const indexToRemove = selectionsClone.indexOf(currentParent.value);
					if (indexToRemove > -1) {
						selectionsClone.splice(indexToRemove, 1);
					}
				}
			}
		}

		onChange(selectionsClone);
	};

	return (
		<Dropdown className={classes.dropdown}>
			<DropdownToggle className={classes.dropdownToggle} disabled={disabled}>
				<div className="d-flex align-items-center justify-content-between">
					<span>{children}</span>
					<FadeTransition in={selected.length > 0}>
						<div className={classes.selectedLength}>{selected.length}</div>
					</FadeTransition>
				</div>
			</DropdownToggle>
			<DropdownMenu className={classes.dropdownMenu}>
				{options.length <= 0 ? (
					<p className="m-0 text-muted small text-center">
						<strong>No options found</strong>
					</p>
				) : (
					options.map((option) => {
						return (
							<MultiSelectItem
								key={option.id}
								selected={selected}
								onChange={handleOnChange}
								{...option}
							/>
						);
					})
				)}
			</DropdownMenu>
		</Dropdown>
	);
};

interface useMultiSelectItemStylesProps {
	isOpen: boolean;
	hasOptions: boolean;
}

const useMultiSelectItemStyles = createUseThemedStyles((theme) => ({
	collapseButtonOuter: ({ hasOptions }: useMultiSelectItemStylesProps) => ({
		paddingTop: 4,
		display: 'flex',
		paddingBottom: 4,
		alignItems: 'center',
		paddingLeft: hasOptions ? 0 : 24,
		'& label': {
			fontSize: `${theme.fonts.xs.fontSize} !important`,
			lineHeight: `${theme.fonts.xs.lineHeight} !important`,
		},
	}),
	collapseButton: {
		width: 24,
		height: 24,
	},
	chevronIcon: ({ isOpen }: useMultiSelectItemStylesProps) => ({
		transition: '0.2s all',
		transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
	}),
	collapseInner: {
		paddingLeft: 28,
	},
}));

interface MultiSelectItemProps extends MultiSelectOption {
	selected: string[];
	onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const MultiSelectItem = ({ id, title, value, options = [], selected, disabled, onChange }: MultiSelectItemProps) => {
	const hasOptions = options.length > 0;
	const [isOpen, setIsOpen] = useState(false);

	const classes = useMultiSelectItemStyles({
		isOpen,
		hasOptions,
	});

	return (
		<div>
			<div className={classes.collapseButtonOuter}>
				{hasOptions && (
					<Button
						className={classes.collapseButton}
						variant="link"
						onClick={() => {
							setIsOpen(!isOpen);
						}}
					>
						<DownChevron className={classes.chevronIcon} />
					</Button>
				)}
				<Form.Check
					id={id}
					label={title}
					value={value}
					checked={selected.includes(value)}
					onChange={onChange}
					disabled={disabled}
				/>
			</div>
			{React.createElement(hasOptions ? Collapse : 'div', {
				in: hasOptions ? isOpen : undefined,
				children: (
					<div className={classes.collapseInner}>
						{options.map((option) => {
							return (
								<MultiSelectItem key={option.id} selected={selected} onChange={onChange} {...option} />
							);
						})}
					</div>
				),
			})}
		</div>
	);
};

export const getTopLevelParentAndChildrenIds = (options: MultiSelectOption[], selectedIds: string[]) => {
	const optionsClone = cloneDeep(options);
	const selectedIdsClone = cloneDeep(selectedIds);

	const selectedParents = optionsClone.filter((bpf) => {
		return selectedIdsClone.includes(bpf.id);
	});
	const selectedParentIds = selectedParents.map((sp) => sp.id);
	const childrenIdsToExclude = selectedParents
		.reduce((previous, current) => {
			return [...previous, ...(current.options ?? [])];
		}, [] as MultiSelectOption[])
		.map((i) => i.id);

	selectedParentIds.forEach((pid) => {
		const targetIndex = selectedIdsClone.findIndex((id) => id === pid);
		if (targetIndex > -1) {
			selectedIdsClone.splice(targetIndex, 1);
		}
	});

	childrenIdsToExclude.forEach((pid) => {
		const targetIndex = selectedIdsClone.findIndex((id) => id === pid);
		if (targetIndex > -1) {
			selectedIdsClone.splice(targetIndex, 1);
		}
	});

	return {
		parentIds: selectedParentIds,
		remainingChildrenIds: selectedIdsClone,
	};
};

export const flattenParentAndChildIdsForSelection = (
	options: MultiSelectOption[],
	parentIds?: string[],
	remainingChildrenIds?: string[]
) => {
	const optionsClone = cloneDeep(options);
	const parentIdsClone = cloneDeep(parentIds) ?? [];
	const idsForSelection = cloneDeep(remainingChildrenIds) ?? [];

	parentIdsClone.forEach((pid) => {
		const targetOption = optionsClone.find((o) => o.id === pid);

		if (!targetOption) {
			return;
		}

		if (!idsForSelection.includes(targetOption.id)) {
			idsForSelection.push(targetOption.id);
		}

		targetOption.options?.forEach((o) => {
			if (!idsForSelection.includes(o.id)) {
				idsForSelection.push(o.id);
			}
		});
	});

	return idsForSelection;
};
