export enum DOCUMENT_FILE_TYPES {
	VIDEO_LINK = 'VIDEO_LINK',
	XLSX = 'XLSX',
	ZIP = 'ZIP',
	DOCX = 'DOCX',
	TXT = 'TXT',
	PPTX = 'PPTX',
	PDF = 'PDF',
}

export interface ContentType {
	contentTypeId: string;
	count: number;
	description: string;
	name: string;
}

export interface DocumentModel {
	bookmarkedFlag: boolean;
	description: string;
	documentId: string;
	documentTypeName: string;
	fileName?: string;
	fileType?: DOCUMENT_FILE_TYPES;
	hasImage: boolean;
	imageType?: string;
	imageUrl?: string;
	listDescription: string;
	name: string;
	publicationDate: string;
	publicationDateDescription: string;
	topicTags: TopicTagModel[];
	videoLink?: string;
	viewUrl: string;
	podcastUrl?: string;
	canDownload: boolean;
}

export interface TopicTagModel {
	tagId: string;
	name: string;
	tagTypeId: string;
	created: string;
	lastUpdated: string;
}
