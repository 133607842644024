import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { constructUrl } from '@/lib/utils';
import { EventModel, ProgramModel } from '@/lib/models';
import { eventsService } from '@/lib/services';
import { useAccount, useHandleError, useQuery } from '@/hooks';
import { AsyncPage, FilterTabs, LinkBox, ListResult, Loader, Select } from '@/components';
import { TablePagination } from '@/components/table';

import logoCPE from '@/assets/logos/logo-cpe.gif';

enum VIEW {
	UPCOMING = 'UPCOMING',
	PAST = 'PAST',
}

export const BrowseEvents = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const { path } = useRouteMatch();
	const { account } = useAccount();
	const handleError = useHandleError();

	const searchParams = useQuery();
	const view = searchParams.get('view') ?? VIEW.UPCOMING;
	const page = searchParams.get('page') ?? '0';
	const programId = searchParams.get('programId') ?? '';
	const pageSize = useRef(5);

	const [isLoading, setIsLoading] = useState(false);
	//const [mainRecommendedEvent, setMainRecommendedEvent] = useState<Recommendation>();
	//const [recommendedEvents, setRecommendedEvents] = useState<Recommendation[]>([]);
	const [programOptions, setProgramOptions] = useState<ProgramModel[]>([]);
	const [events, setEvents] = useState<EventModel[]>([]);
	const [eventsTotal, setEventsTotal] = useState(0);

	const [showCpeDetailsModal, setShowCpeDetailsModal] = useState(false);

	// const fetchRecommendedEvents = useCallback(async () => {
	// 	const response = await recommendationsService.getRecommendedEvents({ eventTypeId: 'DEFAULT' }).fetch();
	// 	const firstRecommendation = response.recommendedContent.shift();
	// 	const remainingThreeRecommendations = response.recommendedContent.slice(0, 3);

	// 	setMainRecommendedEvent(firstRecommendation);
	// 	setRecommendedEvents(remainingThreeRecommendations);
	// }, []);

	const fetchPrograms = useCallback(async () => {
		if (!account) {
			throw new Error('account is undefined');
		}

		const response = await eventsService.getRelevantEventProgramOptions(view === VIEW.PAST).fetch();
		setProgramOptions(response.programs);
	}, [account, view]);

	const getUpcomingEventsPromise = useMemo(() => {
		return eventsService.getUpcomingEvents({
			size: pageSize.current,
			...(page && { page }),
			...(programId && { programId }),
		});
	}, [page, programId]);

	const getUpcomingEvents = useCallback(async () => {
		try {
			setIsLoading(true);
			const response = await getUpcomingEventsPromise.fetch();

			setEvents(response.events);
			setEventsTotal(response.totalCount);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}, [getUpcomingEventsPromise, handleError]);

	const getPastEventsPromise = useMemo(() => {
		return eventsService.getPastEvents({
			size: pageSize.current,
			...(page && { page }),
			...(programId && { programId }),
		});
	}, [page, programId]);

	const getPastEvents = useCallback(async () => {
		try {
			setIsLoading(true);
			const response = await getPastEventsPromise.fetch();

			setEvents(response.events);
			setEventsTotal(response.totalCount);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}, [getPastEventsPromise, handleError]);

	useEffect(() => {
		if (view === VIEW.UPCOMING) {
			getPastEventsPromise.abort();
			getUpcomingEvents();
		} else {
			getUpcomingEventsPromise.abort();
			getPastEvents();
		}
	}, [getPastEvents, getPastEventsPromise, getUpcomingEvents, getUpcomingEventsPromise, view]);

	const handleFilterTabsChange = (value: VIEW) => {
		history.push(
			constructUrl(
				pathname,
				{
					view: value,
					page: '0',
				},
				search
			)
		);
	};

	const handleProgramSelectChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		history.push(
			constructUrl(
				pathname,
				{
					page: '0',
					programId: currentTarget.value,
				},
				search
			)
		);
	};

	const handlePaginationChange = ({ pageIndex }: { pageIndex: number }) => {
		history.push(constructUrl(pathname, { page: pageIndex }, search));
	};

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Events - Browse Events</title>
			</Helmet>
			<Modal
				centered
				show={showCpeDetailsModal}
				onHide={() => {
					setShowCpeDetailsModal(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Learn More</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="font-weight-semi-bold">
						Most states require continuing professional education (CPE) for CPAs to maintain active
						licenses. NASBA's Regulatory Compliance Services division offers several programs that assist
						state boards and their licensees by determining high quality CPE providers. The Hackett Group is
						registered with the National Association of State Boards of Accountancy (NASBA) as a sponsor of
						continuing professional education on the National Registry of CPE Sponsors. State Boards of
						Accountancy have the final authority on the acceptance of individual course for CPE credit.
					</p>
					<p>
						Complaints regarding registered sponsors may be submitted to the National Registry of CPE
						Sponsors through its website:{' '}
						<a
							href="https://www.nasbaregistry.org/"
							title="Nasba Registry"
							target="_blank"
							rel="noreferrer"
						>
							www.nasbaregistry.org
						</a>
						. For more information regarding administrative policies such as complaint and refund, please
						contact our offices at 770-225-3600.
					</p>
					<p>
						<span className="font-weight-semi-bold">Program Prerequisites:</span> You must be a member of
						Hackett's Business Advisory Service program and must be employed by the member company
						participating in the membership.
					</p>
					<p className="mb-0">
						<span className="font-weight-semi-bold">How to Register:</span> Find an event in the Upcoming
						Events calendar that relates to your topic area for continuing education and click on the event
						title. From that event, you will find more information on the registration process.
					</p>
				</Modal.Body>
			</Modal>

			{/* <AsyncPage fetchData={fetchRecommendedEvents}> */}
			<Container className="pt-7">
				<Row>
					<Col>
						<div className="d-flex align-items-center justify-content-between">
							<h1 className="mb-0 text-primary">Events</h1>
						</div>
					</Col>
				</Row>
			</Container>

			{/* <ColorBlockContainer className="pt-11 pb-10">
					<Row className={classNames({ 'mb-9': mainRecommendedEvent })}>
						<Col>
							<h2 className="mb-0 text-white">Browse Events</h2>
						</Col>
					</Row>
					{mainRecommendedEvent && (
						<Row className={classNames({ 'mb-11': recommendedEvents.length > 0 })}>
							<Col>
								<FeatureHero
									category={mainRecommendedEvent.sortDate ?? ''}
									title={mainRecommendedEvent.title}
									description={mainRecommendedEvent.description}
									buttonTitle="Get details and register"
									onButtonClick={() => {
										history.push(mainRecommendedEvent.detailsUrl);
									}}
								/>
							</Col>
						</Row>
					)}
					{recommendedEvents.length > 0 && (
						<Row>
							{recommendedEvents.map((event) => {
								return (
									<Col lg={4} className="mb-6">
										<FeatureCardSecondary
											icon="calendar"
											category={event.sortDate ?? ''}
											title={event.title}
											to={event.detailsUrl}
											callToActionVariant="warning"
											callToActionIcon="star"
											callToActionText="Advisor Recommended"
										/>
									</Col>
								);
							})}
						</Row>
					)}
				</ColorBlockContainer>
			</AsyncPage>*/}
			<Container className="py-7">
				<Row>
					<Col xl={8} className="mb-6">
						<FilterTabs
							className="mb-6"
							value={view}
							tabs={[
								{ title: 'Upcoming Events', value: VIEW.UPCOMING },
								{ title: 'Past Events', value: VIEW.PAST },
							]}
							onChange={handleFilterTabsChange}
						/>
						{isLoading && <Loader />}
						{!isLoading && (
							<>
								{events.length <= 0 && (
									<p className="mb-2 text-muted text-center">
										<strong>No Results</strong>
									</p>
								)}
								{events.map((event, index) => {
									return (
										<ListResult
											key={`${event.eventId}-${index}`}
											category={event.eventTypeDesc}
											date={`${event.durationDescription ?? ''}`}
											to={`${path}/${event.eventId}`}
											title={event.name}
											description={event.listDescription}
											imageSrc={event.imageUrl}
										/>
									);
								})}
								{events.length > 0 && (
									<TablePagination
										showPageSize={false}
										pageSize={pageSize.current}
										pageIndex={parseInt(page ?? '0', 10)}
										total={eventsTotal}
										onChange={handlePaginationChange}
									/>
								)}
							</>
						)}
					</Col>
					<Col xl={4}>
						<Card className="mb-2">
							<Card.Body>
								<div className="mb-4 d-flex align-items-center justify-content-between">
									<Card.Title className="mb-0">Filters</Card.Title>
								</div>
								<AsyncPage fetchData={fetchPrograms}>
									<Form.Group>
										<Form.Label>Program</Form.Label>
										<Select value={programId} onChange={handleProgramSelectChange}>
											<option value="">All Programs</option>;
											{programOptions.map((po) => {
												return (
													<option key={po.programId} value={po.programId}>
														{po.name}
													</option>
												);
											})}
										</Select>
									</Form.Group>
								</AsyncPage>
							</Card.Body>
						</Card>
						<LinkBox
							className="mb-2"
							sections={[
								{
									title: "Can't find what you're looking for?",
									links: [{ title: 'Contact Hackett', to: '/contact', showIcon: true }],
								},
							]}
						/>
						<Card className="mb-2">
							<Card.Body>
								<p className="mb-2">
									The Hackett Group is registered with the National Association of State Boards of
									Accountancy (NASBA) as a sponsor of continuing professional education on the
									National Registry of CPE Sponsors. State boards of accountancy have final authority
									on the acceptance of individual courses for CPE credit. Complaints regarding
									registered sponsors may be addressed to National Registry of CPE Sponsors, 150
									Fourth Avenue North, Nashville, TN 37219-2417. Web site:{' '}
									<a
										href="http://www.nasba.org"
										title="Nasba website"
										target="_blank"
										rel="noreferrer"
									>
										www.nasba.org
									</a>
								</p>
								<div className="d-flex align-items-center justify-content-between">
									<Button
										variant="link"
										onClick={() => {
											setShowCpeDetailsModal(true);
										}}
									>
										Learn More
									</Button>
									<img className="d-block" src={logoCPE} alt="logo CPE" width={100} />
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};
