import { httpSingleton } from '@/lib/http-client';
import { EventModel, Recommendation } from '@/lib/models';
import { constructUrl } from '@/lib/utils';

export const recommendationsService = {
	getRecommendations() {
		return httpSingleton.orchestrateRequest<{
			recommendedContent: Recommendation[];
			trendingContent: Recommendation[];
			events: EventModel[];
		}>({
			method: 'GET',
			url: '/advisory-service/api/v1/recommendations',
		});
	},
	getRecommendedEvents(queryParameters?: { eventTypeId?: 'DEFAULT' | 'PERFORMANCE_STUDY' }) {
		return httpSingleton.orchestrateRequest<{
			recommendedContent: Recommendation[];
		}>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/recommendations/event', queryParameters),
		});
	},
};
