import React, { useCallback, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';

import { salesLeadService } from '@/lib/services';
import { useAlert, useHandleError } from '@/hooks';

import { ReactComponent as InquiriesGraphic } from '@/assets/illustrations/inquiries-graphic.svg';

export const InquiryMarketing = () => {
	const { showAlert } = useAlert();
	const handleError = useHandleError();
	const [showInquiriesModal, setShowInquiriesModal] = useState(false);

	const handleInquiriesConfirm = useCallback(async () => {
		try {
			await salesLeadService.sendExpertIquiryEmail().fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return (
						<p className="mb-0 text-white">
							Thank you for your interest. Your Advisory Team will be in contact with you.
						</p>
					);
				},
			});

			setShowInquiriesModal(false);
		} catch (error) {
			handleError(error);
		}
	}, [handleError, showAlert]);

	return (
		<>
			<Modal
				show={showInquiriesModal}
				centered
				onHide={() => {
					setShowInquiriesModal(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Expert Inquiries</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="mb-0">
						To learn more about our Expert Inquiries service please click the "Confirm" button below, and a
						member of your Advisory Team will get in touch with you.
					</p>
				</Modal.Body>
				<Modal.Footer className="text-right">
					<Button onClick={handleInquiriesConfirm}>Confirm</Button>
				</Modal.Footer>
			</Modal>

			<Container className="py-11">
				<Row className="align-items-center">
					<Col lg={{ span: 8, offset: 2 }}>
						<div className="text-center mb-4">
							<InquiriesGraphic width={352} height={240} />
						</div>
						<h1 className="mb-4 text-dark text-center">Expert Inquiries</h1>
						<p className="mb-1">
							Expert Inquiries enable designated users at your organization to engage with subject-matter
							advisors for guided insights via curated custom materials by request.
						</p>
						<p className="mb-4">
							Your user account has not been designated with inquiry access at this time. To learn more
							about your organization's designated user access to Expert Inquiries, please click below.
						</p>
						<div className="text-center">
							<Button
								variant="warning"
								onClick={() => {
									setShowInquiriesModal(true);
								}}
							>
								Learn More
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
};
