import { ROLE_IDS } from '@/lib/models';
import { useAccount } from '@/hooks';

export const useCanManageBookingsUrl = () => {
	const { account } = useAccount();

	const canManageRoles = [ROLE_IDS.ADVISOR, ROLE_IDS.ACCOUNT_MANAGER];
	const canManage = (account?.roles && account.roles.some((role) => canManageRoles.includes(role))) ?? false;

	return canManage;
};
