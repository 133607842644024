import React, { useCallback, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { EventModel, EVENT_TYPE_ID } from '@/lib/models';
import { eventsService } from '@/lib/services';
import { useCopyTextToClipboard } from '@/hooks';
import { AsyncPage, EventDocumentListItem, LinkBox } from '@/components';

import { ReactComponent as CalendarIcon } from '@/assets/icons/icon-calendar-v2.svg';
import { Helmet } from 'react-helmet';

export const EventDetail = () => {
	const { eventId } = useParams<{ eventId: string }>();
	const { copyTextToClipboard } = useCopyTextToClipboard();

	const [event, setEvent] = useState<EventModel>();

	const fetchData = useCallback(async () => {
		const response = await eventsService.getEventbyId(eventId).fetch();
		setEvent(response);
	}, [eventId]);

	const handleShareButtonClick = () => {
		copyTextToClipboard(window.location.href, 'Link copied to clipboard.');
	};

	const parser = new DOMParser();
	const descriptionParsed = parser.parseFromString(event?.description ?? '', 'text/html');
	const descriptionParsedString = descriptionParsed.body?.innerHTML.replace('&amp;', '&');
	return (
		<AsyncPage fetchData={fetchData}>
			<Container className="py-14">
				<Row className="mb-6">
					<Col xl={8} className="mb-2">
						<h4 className="mb-2 text-muted">{event?.eventTypeDesc}</h4>
						<h1 className="mb-6">{event?.name}</h1>

						{event?.presenterName && <h3 className="mb-1">{event?.presenterName}</h3>}
						<div className="mb-4 d-flex align-items-center">
							<CalendarIcon className="text-primary mr-2" />
							<h3 className="mb-0">{event?.durationDescription}</h3>
						</div>

						<div className="mb-8">
							{event?.eventTypeId === EVENT_TYPE_ID.DEFAULT && (
								<>
									<Helmet>
										<title>Hackett Connect - Events - Browse Events - Event Detail</title>
									</Helmet>
									{!event.pastEvent && event.registrationUrl && (
										<Button
											className="mr-2"
											href={event.registrationUrl}
											target="_blank"
											rel="noopener"
										>
											Register
										</Button>
									)}
									{event.pastEvent && event.webexRecordingUrl && (
										<Button
											className="mr-2"
											href={event.webexRecordingUrl}
											target="_blank"
											rel="noopener"
										>
											View Recording
										</Button>
									)}
								</>
							)}
							{event?.eventTypeId === EVENT_TYPE_ID.PERFORMANCE_STUDY && (
								<>
									<Helmet>
										<title>
											Hackett Connect - Events - Performance Studies - Performance Study Detail
										</title>
									</Helmet>

									{event.webexRecordingUrl && (
										<Button
											className="mr-2"
											href={event.webexRecordingUrl}
											target="_blank"
											rel="noopener"
										>
											View Results
										</Button>
									)}
									{event.registrationUrl && (
										<Button
											className="mr-2"
											href={event.registrationUrl}
											target="_blank"
											rel="noopener"
										>
											Participate in the Study
										</Button>
									)}
								</>
							)}
							<Button variant="outline-primary" onClick={handleShareButtonClick}>
								Share
							</Button>
						</div>

						<div className="mb-8">
							<hr />
							{event?.documents.documents.map((document) => {
								return (
									<React.Fragment key={document.documentId}>
										<EventDocumentListItem
											documentId={document.documentId}
											to={`/research/${document.documentId}`}
											title={document.name}
											fileType={document.fileType}
											fileName={document.fileName}
											viewUrl={document.viewUrl}
										/>
										<hr />
									</React.Fragment>
								);
							})}
						</div>

						<div dangerouslySetInnerHTML={{ __html: descriptionParsedString ?? '' }} />
					</Col>
					<Col xl={4}>
						<LinkBox
							sections={[
								{
									title: "Can't find what you're looking for?",
									links: [{ title: 'Contact Hackett', to: '/contact', showIcon: true }],
								},
							]}
						/>
					</Col>
				</Row>
				{event?.recommendedCpeCredit && (
					<Row className="mb-8">
						<Col>
							<h3 className="mb-2">CPE Credit Information</h3>
							<h4 className="mb-0 text-muted">
								Amount of Recommended CPE Credit: {event.recommendedCpeCredit}
							</h4>
						</Col>
					</Row>
				)}
				{(event?.registrationRequirements || event?.courseLevel || event?.programPrerequisites) && (
					<>
						<Row>
							{event?.registrationRequirements && (
								<Col className="mb-6">
									<p className="font-weight-bold">Registration Requirements</p>
									<div dangerouslySetInnerHTML={{ __html: event?.registrationRequirements ?? '' }} />
								</Col>
							)}
							{event?.courseLevel && (
								<Col className="mb-6">
									<p className="font-weight-bold">Course Level</p>
									<div dangerouslySetInnerHTML={{ __html: event?.courseLevel ?? '' }} />
								</Col>
							)}
							{event?.programPrerequisites && (
								<Col className="mb-6">
									<p className="font-weight-bold">Program Prerequisites</p>
									<div dangerouslySetInnerHTML={{ __html: event?.programPrerequisites ?? '' }} />
								</Col>
							)}
						</Row>
						<Row className="mb-8">
							<Col>
								<hr />
							</Col>
						</Row>
					</>
				)}
				{(event?.refundPolicy || event?.complaintResolutionPolicy || event?.advancePreparation) && (
					<Row>
						<Col>
							{event?.refundPolicy && (
								<p>
									<span className="font-weight-semi-bold">Refund Policy:</span>
									<div
										dangerouslySetInnerHTML={{
											__html: event?.refundPolicy,
										}}
									/>
								</p>
							)}
							{event?.complaintResolutionPolicy && (
								<p>
									<span className="font-weight-semi-bold">Complaint Resolution Policy:</span>{' '}
									<div
										dangerouslySetInnerHTML={{
											__html: event?.complaintResolutionPolicy,
										}}
									/>
								</p>
							)}
							{event?.advancePreparation && (
								<p>
									<span className="font-weight-semi-bold">Advance Preparation:</span>{' '}
									<div
										dangerouslySetInnerHTML={{
											__html: event?.advancePreparation,
										}}
									/>
								</p>
							)}
						</Col>
					</Row>
				)}
			</Container>
		</AsyncPage>
	);
};
