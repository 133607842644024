import React, { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownMenuProps } from 'react-bootstrap/esm/DropdownMenu';
import classNames from 'classnames';
import Color from 'color';

import { createUseThemedStyles } from '@/hooks';
import { boxShadow } from '@/jss';

const useStyles = createUseThemedStyles((theme) => ({
	dropdownMenu: {
		border: 0,
		...boxShadow,
		borderRadius: 2,
		padding: '8px 0',
		overflow: 'hidden',
		'& .dropdown-header': {
			...theme.fonts.xs,
			padding: '8px 24px',
			letterSpacing: '0.083rem',
			textTransform: 'uppercase',
			color: theme.colors.gray600,
			...theme.fonts.openSansSemiBold,
		},
		'& .dropdown-item': {
			...theme.fonts.s,
			padding: '8px 24px',
			...theme.fonts.openSansSemiBold,
			color: theme.colors.primary,
			'&:hover': {
				textDecoration: 'underline',
				backgroundColor: 'transparent',
			},
			'&:active': {
				backgroundColor: Color(theme.colors.primary)
					.alpha(0.16)
					.string(),
			},
			'&.active': {
				color: theme.colors.primary,
				backgroundColor: Color(theme.colors.primary)
					.alpha(0.16)
					.string(),
			},
		},
	},
}));

export const DropdownMenu: FC<DropdownMenuProps> = ({ children, className, ...props }) => {
	const classes = useStyles();

	return (
		<Dropdown.Menu className={classNames(classes.dropdownMenu, className)} {...props}>
			{children}
		</Dropdown.Menu>
	);
};
