import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { DocumentModel } from '@/lib/models';
import { documentService } from '@/lib/services';
import { useAlert, useCopyTextToClipboard, useHandleError, useNavigationConfig, useAnalytics } from '@/hooks';
import { AsyncPage, LinkBox, LoadingButton, RecentlyViewed } from '@/components';
import { downloadFileAtUrl } from '@/lib/utils';

export const ResearchDetail = () => {
	const handleError = useHandleError();
	const { documentId } = useParams<{ documentId: string }>();
	const { showAlert } = useAlert();
	const { navigationConfig } = useNavigationConfig();
	const analytics = useAnalytics();
	const { copyTextToClipboard } = useCopyTextToClipboard();

	const [documents, setDocuments] = useState<DocumentModel>();
	const [isBookmarking, setIsBookmarking] = useState(false);

	const fetchData = useCallback(async () => {
		const response = await documentService.getDocumentById(documentId).fetch();
		setDocuments(response);
	}, [documentId]);

	const handleSaveBookmarkButtonClick = useCallback(async () => {
		try {
			setIsBookmarking(true);

			if (!documents) {
				throw new Error('document is undefined');
			}

			const response = await documentService.addBookmark(documents.documentId).fetch();
			setDocuments(response);

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Bookmark saved.</p>;
				},
			});
		} catch (error) {
			handleError(error);
		} finally {
			setIsBookmarking(false);
		}
	}, [documents, handleError, showAlert]);

	const handleRemoveBookmarkButtonClick = useCallback(async () => {
		try {
			setIsBookmarking(true);

			if (!documents) {
				throw new Error('document is undefined');
			}

			const response = await documentService.removeBookmark(documents.documentId).fetch();
			setDocuments(response);

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Bookmark removed.</p>;
				},
			});
		} catch (error) {
			handleError(error);
		} finally {
			setIsBookmarking(false);
		}
	}, [documents, handleError, showAlert]);

	const handleShareButtonClick = () => {
		copyTextToClipboard(window.location.href, 'Link copied to clipboard.');
	};

	const handleDownloadClick = useCallback(async () => {
		const docUrl = documents?.viewUrl;
		const documentId = documents?.documentId;
		if (docUrl && documentId) {
			try {
				await downloadFileAtUrl(docUrl, documents?.fileName ?? documents?.name ?? 'file');
				documentService.trackDocumentDownload(documentId).fetch();
				analytics.handleClickEvent(docUrl);
			} catch (error) {
				handleError(error);
			}
		}
	}, [documents, handleError, analytics]);

	return (
		<AsyncPage fetchData={fetchData}>
			<Helmet>
				<title>Hackett Connect - Research - Research Detail</title>
			</Helmet>
			<Container className="py-6">
				<Row className="mb-5">
					<Col>
						<h4 className="mb-0 d-flex align-items-center text-muted">{documents?.documentTypeName}</h4>
						<h1 className="mb-1">{documents?.name}</h1>
						<h4 className="mb-0 d-flex align-items-center text-muted">
							{documents?.topicTags
								.map((tag) => {
									return tag.name;
								})
								.join(', ')}
						</h4>
						<h4 className="mb-0 text-muted">{documents?.publicationDateDescription}</h4>
					</Col>
				</Row>
				<Row>
					<Col xl={8}>
						<Row className="mb-6">
							<Col>
								{documents && (
									<>
										{!documents.podcastUrl && (
											<Button
												className="mr-2 mb-2"
												href={documents.viewUrl}
												target="_blank"
												rel="noopener"
												onClick={() => {
													if (documents?.viewUrl) {
														analytics.handleClickEvent(documents.viewUrl);
													}
												}}
											>
												View
											</Button>
										)}
										{navigationConfig?.allowDownloads &&
											documents?.canDownload &&
											documents?.viewUrl && (
												<Button className="mr-2 mb-2" onClick={handleDownloadClick}>
													Download
												</Button>
											)}
									</>
								)}
								{documents?.bookmarkedFlag ? (
									<LoadingButton
										isLoading={isBookmarking}
										className="mr-2 mb-2"
										variant="outline-primary"
										onClick={handleRemoveBookmarkButtonClick}
									>
										Remove Bookmark
									</LoadingButton>
								) : (
									<LoadingButton
										isLoading={isBookmarking}
										className="mr-2 mb-2"
										variant="outline-primary"
										onClick={handleSaveBookmarkButtonClick}
									>
										Save Bookmark
									</LoadingButton>
								)}
								<Button variant="outline-primary" onClick={handleShareButtonClick}>
									Share
								</Button>
							</Col>
						</Row>
						<Row className="mb-11">
							<Col>
								<h2 className="mb-3">Summary</h2>
								<div
									className={classNames({ 'mb-8': documents?.podcastUrl })}
									dangerouslySetInnerHTML={{ __html: documents?.description ?? '' }}
								/>
								{documents?.podcastUrl && (
									<iframe
										src={documents.podcastUrl}
										width="100%"
										height="200"
										scrolling="no"
										title={documents.name}
									/>
								)}
							</Col>
						</Row>
					</Col>
					<Col xl={4}>
						<RecentlyViewed className="mb-2" />
						<LinkBox
							sections={[
								{
									title: "Can't find what you're looking for?",
									links: [{ title: 'Contact Hackett', to: '/contact', showIcon: true }],
								},
							]}
						/>
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
