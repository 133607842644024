import React, { FC, useRef } from 'react';
import classNames from 'classnames';
import { createUseThemedStyles } from '@/hooks';
import { focus } from '@/jss';

const useStyles = createUseThemedStyles((theme) => ({
	fileInputButton: {
		margin: 0,
		cursor: 'pointer',
		overflow: 'hidden',
		position: 'relative',
		'&:focus-within': {
			...focus(theme.colors.primary),
		},
	},
	disabled: {
		cursor: 'default',
		'&:hover': {
			cursor: 'default',
		},
	},
	fileInput: {
		top: 0,
		left: 0,
		width: 0,
		height: 0,
		opacity: 0,
		position: 'absolute',
		pointerEvents: 'none',
	},
}));

interface FileInputButtonProps {
	accept: string;
	onClick?(event: React.MouseEvent<HTMLInputElement, MouseEvent>): void;
	onChange(file: File): void;
	className?: string;
	disabled?: boolean;
}

export const FileInputButton: FC<FileInputButtonProps> = ({
	accept,
	onClick,
	onChange,
	className,
	disabled,
	children,
}) => {
	const classes = useStyles();
	const inputRef = useRef<HTMLInputElement>(null);

	function handleFileClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
		if (onClick) {
			onClick(event);
		}

		if (inputRef.current) {
			inputRef.current.value = '';
		}
	}

	function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (disabled) {
			return;
		}

		const { files } = event.target;

		if (!files || (files && files.length > 1)) {
			return;
		}

		const file = files[0];

		onChange(file);
	}

	return (
		<label className={classNames(classes.fileInputButton, className, { [classes.disabled]: disabled })}>
			<input
				ref={inputRef}
				className={classes.fileInput}
				type="file"
				accept={accept}
				onChange={handleFileChange}
				onClick={handleFileClick}
				disabled={disabled}
			/>
			{children}
		</label>
	);
};
