import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { DocumentModel } from '@/lib/models';
import { documentService } from '@/lib/services';
import { useHandleError } from '@/hooks';
import { LinkBox, LoadingButton, RecentlyViewed, ResearchListItem } from '@/components';
import { Helmet } from 'react-helmet';

export const Bookmarks = () => {
	const handleError = useHandleError();

	const pageRef = useRef(0);
	const sizeRef = useRef(10);

	const [documents, setDocuments] = useState<DocumentModel[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [loadingDocuments, setLoadingDocuments] = useState(false);

	const fetchDocuments = useCallback(async () => {
		try {
			setLoadingDocuments(true);

			const response = await documentService
				.getDocuments({
					page: String(pageRef.current),
					size: String(sizeRef.current),
					bookmarked: true,
				})
				.fetch();

			setTotalCount(response.totalCount);
			setDocuments((previousValue) => {
				if (pageRef.current === 0) {
					return response.documents;
				}

				return previousValue.concat(response.documents);
			});
		} catch (error) {
			handleError(error);
		} finally {
			setLoadingDocuments(false);
		}
	}, [handleError]);

	useEffect(() => {
		fetchDocuments();
	}, [fetchDocuments]);

	const handleResearchListItemChange = (document: DocumentModel) => {
		const documentsClone = cloneDeep(documents);
		const indexToUpdate = documentsClone.findIndex((d) => d.documentId === document.documentId);

		documentsClone[indexToUpdate] = {
			...documentsClone[indexToUpdate],
			bookmarkedFlag: document.bookmarkedFlag,
		};

		setDocuments(documentsClone);
	};

	const handleShowMoreButtonClick = () => {
		pageRef.current += 1;
		fetchDocuments();
	};

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Bookmarks</title>
			</Helmet>

			<Container className="pt-7 pb-16">
				<Row className="pb-10">
					<Col>
						<h2 className="mb-0 text-primary">
							Your {totalCount} Bookmark{totalCount === 1 ? '' : 's'}
						</h2>
					</Col>
				</Row>
				<Row>
					<Col xs={12} xl={8} className="mb-6">
						{documents.length === 0 ? (
							<p className="mb-2 text-muted text-center">
								<strong>No Results</strong>
							</p>
						) : (
							<>
								{documents.map((document) => (
									<ResearchListItem
										key={document.documentId}
										documentId={document.documentId}
										category={document.documentTypeName}
										fileType={document.fileType ?? undefined}
										viewUrl={document.viewUrl}
										canDownload={document.canDownload}
										fileName={document.fileName}
										isBookmarked={document.bookmarkedFlag}
										to={`/research/${document.documentId}`}
										title={document.name}
										description={document.listDescription ?? ''}
										imageSrc={document.imageUrl ?? ''}
										date={document.publicationDateDescription}
										tags={document.topicTags.map((tag) => tag.name)}
										onChange={handleResearchListItemChange}
										isPodcast={!!document.podcastUrl}
									/>
								))}
								{documents.length < totalCount && (
									<LoadingButton
										isLoading={loadingDocuments}
										variant="outline-primary"
										onClick={handleShowMoreButtonClick}
									>
										Show 10 More
									</LoadingButton>
								)}
							</>
						)}
					</Col>
					<Col xs={12} xl={4}>
						<RecentlyViewed className="mb-2" />
						<LinkBox
							sections={[
								{
									title: "Can't find what you're looking for?",
									links: [{ title: 'Contact Hackett', to: '/contact', showIcon: true }],
								},
							]}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};
