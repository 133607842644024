export const downloadFileAtUrl = async (url: string, title: string, callback?: () => void) => {
	try {
		const response = await fetch(url);
		const blob = await response.blob();
		const objectUrl = URL.createObjectURL(blob);

		const anchor = document.createElement('a');
		anchor.href = objectUrl;
		anchor.download = title;

		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);

		URL.revokeObjectURL(objectUrl);

		if (callback) {
			callback();
		}
	} catch (error) {
		throw error;
	}
};
