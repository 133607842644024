import { cloneDeep } from 'lodash';
import Cookies from 'js-cookie';
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Form } from 'react-bootstrap';
import classNames from 'classnames';

import { AccountOptionModel, COOKIES, ProgramModel, FormOption } from '@/lib/models';
import { accountService, programSettingService } from '@/lib/services';
import { createUseThemedStyles, useAccount, useAlert, useHandleError } from '@/hooks';
import { AsyncPage, LoadingButton, Select } from '@/components';
import { mediaQueries } from '@/jss';
import { mutateBpicLocationFormatToInputFormat, mutateGooglePlaceResultToBpicLocationFormat } from '@/lib/utils';
import { Typeahead } from 'react-bootstrap-typeahead';

const useStyles = createUseThemedStyles((theme) => ({
	formRow: {
		boxShadow: `${theme.colors.gray300} 0px 2px 8px`,
		margin: '0',
	},
	formCol: {
		background: theme.colors.white,
		padding: '40px 55px',
	},
	firstFormCol: {
		borderBottom: `1px solid ${theme.colors.gray200}`,
		[mediaQueries.lg]: {
			borderRight: `1px solid ${theme.colors.gray200}`,
			borderBottom: '0',
		},
	},
}));

export const CompleteProfile = () => {
	const classes = useStyles();
	const handleError = useHandleError();
	const history = useHistory();
	const { account, setAccount } = useAccount();
	const { showAlert } = useAlert();

	const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
	const [isLocationValid, setIsLocationValid] = useState(true);
	const [levelOptions, setLevelOptions] = useState<AccountOptionModel[]>([]);
	const [accountFunctionSelectedOptions, setAccountFunctionSelectedOptions] = useState<FormOption[]>([]);
	const [accountFunctionOptions, setAccountFunctionOptions] = useState<FormOption[]>([]);

	const [programs, setPrograms] = useState<ProgramModel[]>([]);
	const [suppressProgramEmails, setSuppressProgramEmails] = useState<Boolean>(false);
	const [formValues, setFormValues] = useState({
		name: account?.name ?? '',
		title: account?.title ?? '',
		level: account?.userLevelTagId ?? '',
		function: account?.userFunctionTagIds ?? [],
		location: account?.location ? mutateBpicLocationFormatToInputFormat(account.location) : '',
		officePhoneNumber: account?.mobileNumber ?? '',
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const fetchData = useCallback(async () => {
		if (account?.hasAdvisoryProfile) {
			history.replace('/account/profile');
		}

		if (!account) {
			throw new Error('account is undefined.');
		}

		const [levelsResponse, functionsResponse, programResponse] = await Promise.all([
			accountService.getAccountOptions('USER_LEVEL').fetch(),
			accountService.getAccountOptions('USER_FUNCTION').fetch(),
			programSettingService.getPreferences(account.accountId).fetch(),
		]);

		setLevelOptions(levelsResponse.options);
		const reformattedOptionArray = functionsResponse.options.map(function(obj) {
			const rObj = {
				id: obj.tagId,
				display: obj.name,
			};
			return rObj;
		});
		setAccountFunctionOptions(reformattedOptionArray);
		setPrograms(programResponse.programs);
		setSuppressProgramEmails(programResponse.suppressProgramEmail);
		if (account?.userFunctionTagIds) {
			const functionSelectedOptionFilter = reformattedOptionArray.filter(({ id }) =>
				account?.userFunctionTagIds?.includes(id)
			);
			setAccountFunctionSelectedOptions(functionSelectedOptionFilter);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, history]);

	useEffect(() => {
		setFormValues({
			...formValues,
			function: accountFunctionSelectedOptions.map((value) => value.id),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountFunctionSelectedOptions]);

	const locationInputRef = useCallback((node: HTMLInputElement | null) => {
		if (!node) {
			return;
		}

		if (!window.google?.maps?.places?.Autocomplete) {
			return;
		}

		autoCompleteRef.current = new window.google.maps.places.Autocomplete(node, {
			types: ['(cities)'],
		});

		autoCompleteRef.current.addListener('place_changed', () => {
			if (!autoCompleteRef.current) {
				return;
			}

			const location = mutateGooglePlaceResultToBpicLocationFormat(autoCompleteRef.current.getPlace());

			setFormValues((previousValue) => {
				return {
					...previousValue,
					location,
				};
			});
			setIsLocationValid(true);
		});
	}, []);

	const handleEmailCommunicationPreferenceSwitchChange = useCallback(
		({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
			const programsClone = cloneDeep(programs);
			const indexToUpdate = programsClone.findIndex((p) => p.programId === currentTarget.value);

			if (indexToUpdate < 0) {
				return;
			}

			programsClone[indexToUpdate].allowEmail = currentTarget.checked;
			setPrograms(programsClone);
		},
		[programs]
	);

	const handleFormSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			try {
				if (!account) {
					throw new Error('account is undefined.');
				}

				setIsSubmitting(true);

				const [updateAccountResponse] = await Promise.all([
					accountService
						.updateCurrentAccount(account.accountId, {
							name: formValues.name,
							location: isLocationValid ? formValues.location : '',
							title: formValues.title,
							userLevelTagId: formValues.level,
							userFunctionTagIds: formValues.function,
							mobileNumber: formValues.officePhoneNumber,
						})
						.fetch(),
					programSettingService
						.setPreferences(account.accountId, {
							accountCommPreferences: programs
								.filter(({ allowUserPreferences }) => allowUserPreferences)
								.map(({ allowEmail, ...rest }) => ({
									...rest,
									allowEmails: allowEmail,
								})),
						})
						.fetch(),
				]);

				setAccount(updateAccountResponse.account);
				showAlert({
					variant: 'success',
					children: () => {
						return (
							<p className="mb-0 text-white">
								Your account and email communication preferences have been updated.
							</p>
						);
					},
				});

				const targetUrl = Cookies.get(COOKIES.TARGET_URL);
				if (targetUrl) {
					history.push(targetUrl);
				} else {
					history.push('/');
				}
			} catch (error) {
				setIsSubmitting(false);
				handleError(error);
			}
		},
		[
			account,
			formValues.name,
			formValues.location,
			formValues.title,
			formValues.level,
			formValues.officePhoneNumber,
			isLocationValid,
			formValues.function,
			programs,
			setAccount,
			showAlert,
			history,
			handleError,
		]
	);

	return (
		<Container className="pt-17 pb-10">
			<Row>
				<Col>
					<Row className="mb-7">
						<Col>
							<h1 className="mb-0">Complete Your Profile</h1>
						</Col>
					</Row>
					<AsyncPage fetchData={fetchData}>
						<Form onSubmit={handleFormSubmit}>
							<Row className={classNames(classes.formRow, 'mb-4')}>
								<Col
									lg={suppressProgramEmails ? 12 : 6}
									className={classNames(classes.firstFormCol, classes.formCol)}
								>
									<Form.Group className="mb-6">
										<Form.Label>Name</Form.Label>
										<Form.Control
											type="text"
											value={formValues.name}
											onChange={({ currentTarget }) => {
												setFormValues({
													...formValues,
													name: currentTarget.value,
												});
											}}
											required
											disabled={isSubmitting}
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Title</Form.Label>
										<Form.Control
											type="text"
											value={formValues.title}
											onChange={({ currentTarget }) => {
												setFormValues({
													...formValues,
													title: currentTarget.value,
												});
											}}
											required
											disabled={isSubmitting}
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Level</Form.Label>
										<Select
											value={formValues.level}
											onChange={({ currentTarget }) => {
												setFormValues({
													...formValues,
													level: currentTarget.value,
												});
											}}
											required
											disabled={isSubmitting}
										>
											<option value="">Select level...</option>
											{levelOptions.map((option) => {
												return (
													<option key={option.tagId} value={option.tagId}>
														{option.name}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Function</Form.Label>
										<Typeahead
											id="typeahead--jobFunction"
											labelKey="display"
											multiple
											selected={accountFunctionSelectedOptions}
											onChange={(selected) => {
												setAccountFunctionSelectedOptions(selected as FormOption[]);
											}}
											options={accountFunctionOptions}
											placeholder="Select Job Function"
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>
											Location{' '}
											<span className="text-muted font-italic font-weight-regular">
												- Optional
											</span>
										</Form.Label>
										<Form.Control
											ref={locationInputRef}
											type="text"
											defaultValue={formValues.location}
											onChange={(e) => {
												setIsLocationValid(!e.target.value.length);
											}}
											disabled={isSubmitting}
										/>
										{!isLocationValid && (
											<p className="mt-1 mb-0">
												<small className="text-muted">
													Location should be selected from the suggestion list, otherwise it
													will be discarded.
												</small>
											</p>
										)}
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label>
											Office Phone Number{' '}
											<span className="text-muted font-italic font-weight-regular">
												- Optional
											</span>
										</Form.Label>
										<Form.Control
											type="tel"
											value={formValues.officePhoneNumber}
											onChange={({ currentTarget }) => {
												setFormValues({
													...formValues,
													officePhoneNumber: currentTarget.value,
												});
											}}
											disabled={isSubmitting}
										/>
									</Form.Group>
								</Col>
								{!suppressProgramEmails ? (
									<Col lg={6} className={classes.formCol}>
										<Form.Label>Email Communication Preferences</Form.Label>
										<p className="mb-4">
											<small className="text-muted">
												Enabling email communication allows you to receive regular push emails
												with program newsletters and invitations to participate in performance
												assessments, webcasts and other events, helping you make the most of
												your Advisory program membership.
											</small>
										</p>
										{programs.length > 0 ? (
											programs
												.filter((p) => p.allowUserPreferences)
												.map((program, index) => {
													return (
														<div key={index}>
															<div className="py-2 d-flex align-items-center justify-content-between">
																<p className="mb-0 pr-4 ">{program.name}</p>
																<Form.Switch
																	id={`switch--${program.programId}`}
																	checked={program.allowEmail}
																	onChange={
																		handleEmailCommunicationPreferenceSwitchChange
																	}
																	value={program.programId}
																	label={program.allowEmail ? 'Enabled' : 'Disabled'}
																/>
															</div>
															<hr />
														</div>
													);
												})
										) : (
											<p className="mb-0 bold w-100 text-center">No Programs</p>
										)}
									</Col>
								) : (
									<></>
								)}
							</Row>
							<div className="text-right">
								<LoadingButton type="submit" isLoading={isSubmitting}>
									Confirm
								</LoadingButton>
							</div>
						</Form>
					</AsyncPage>
				</Col>
			</Row>
		</Container>
	);
};
