import React, { FC } from 'react';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

import { ReactComponent as SortAscend } from './assets/sort-ascend.svg';
import { ReactComponent as SortDescend } from './assets/sort-descend.svg';
import { ReactComponent as SortNone } from './assets/sort-none.svg';

interface UseStyleProps {
	width?: number | string;
	fixedOffset?: number;
	sortable?: boolean;
}

const useTableHeaderStyles = createUseThemedStyles((theme) => ({
	tableHeader: ({ width, fixedOffset }: UseStyleProps) => ({
		padding: 0,
		backgroundColor: theme.colors.white,
		width: width ? width : 'auto',
		'&.fixed-column': {
			zIndex: 1,
			position: 'sticky',
			left: fixedOffset ? fixedOffset : 0,
		},
	}),
	tableHeaderContent: ({ width, sortable }: UseStyleProps) => ({
		display: 'flex',
		overflow: 'hidden',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		padding: '14px 20px',
		justifyContent: 'space-between',
		...(sortable ? { cursor: 'pointer' } : {}),
		width: typeof width === 'string' ? 'auto' : width,
	}),
	sortableIconOuter: {
		marginLeft: 16,
	},
	sortableIcon: {
		display: 'flex',
	},
}));

export enum SORT_DIRECTION {
	ASC = 'ASC',
	DESC = 'DESC',
}

interface TableHeaderProps {
	width?: number | string;
	fixed?: boolean;
	fixedOffset?: number;
	sortable?: boolean;
	sortKey?: string;
	sortDirection?: SORT_DIRECTION | null;
	onSort?(sortKey: string, sortDirection: SORT_DIRECTION): void;
	colspan?: number;
	className?: string;
	classNameOuter?: string;
}

export const TableHeader: FC<TableHeaderProps> = (props) => {
	const classes = useTableHeaderStyles({
		width: props.width,
		fixedOffset: props.fixedOffset,
		sortable: props.sortable,
	});

	function handleSortButtonClick() {
		if (!props.onSort || !props.sortKey) {
			return;
		}

		if (props.sortDirection === SORT_DIRECTION.ASC) {
			props.onSort(props.sortKey, SORT_DIRECTION.DESC);
		} else {
			props.onSort(props.sortKey, SORT_DIRECTION.ASC);
		}
	}

	return (
		<th
			className={classNames(classes.tableHeader, props.classNameOuter, {
				'fixed-column': props.fixed,
			})}
			colSpan={props.colspan}
		>
			<div
				className={classNames(classes.tableHeaderContent, props.className)}
				onClick={props.sortable ? handleSortButtonClick : undefined}
				tabIndex={props.sortable ? 0 : -1}
			>
				{props.children}
				{props.sortable && (
					<div className={classes.sortableIconOuter}>
						{!props.sortDirection && <SortNone className={classes.sortableIcon} />}
						{props.sortDirection === SORT_DIRECTION.ASC && <SortAscend className={classes.sortableIcon} />}
						{props.sortDirection === SORT_DIRECTION.DESC && (
							<SortDescend className={classes.sortableIcon} />
						)}
					</div>
				)}
			</div>
		</th>
	);
};
