import { PresignedUploadModel } from '@/lib/models';

interface DocumentUploader {
	start: () => this;
	onBeforeUpload: (callback: (previewUrl: string) => void) => this;
	onPresignedUploadObtained: (callback: (presignedUpload: PresignedUploadModel) => void) => this;
	onProgress: (callback: (percentage: number) => void) => this;
	onComplete: (callback: (presignedUpload: PresignedUploadModel) => void) => this;
	onError: (callback: (error: unknown) => void) => this;
}

export const documentUploader = (
	blob: Blob,
	presignedUploadGetter: () => Promise<{ upload: PresignedUploadModel }>
) => {
	let onPresignedUploadObtainedCallback: (presignedUpload: PresignedUploadModel) => void;
	let onProgressCallback: (percentage: number) => void;
	let onCompleteCallback: (presignedUpload: PresignedUploadModel) => void;
	let onErrorCallback: (error: unknown) => void;

	const documentUpload: DocumentUploader = {
		onBeforeUpload: (callback: (previewUrl: string) => void) => {
			callback(URL.createObjectURL(blob));
			return documentUpload;
		},
		onPresignedUploadObtained: (callback) => {
			onPresignedUploadObtainedCallback = callback;
			return documentUpload;
		},
		onProgress: (callback) => {
			onProgressCallback = callback;
			return documentUpload;
		},
		onComplete: (callback) => {
			onCompleteCallback = callback;
			return documentUpload;
		},
		onError: (callback) => {
			onErrorCallback = callback;
			return documentUpload;
		},
		start: () => {
			presignedUploadGetter()
				.then(({ upload }) => {
					onPresignedUploadObtainedCallback(upload);

					return new Promise((resolve: (presignedUpload: PresignedUploadModel) => void, reject) => {
						const xhr = new XMLHttpRequest();

						xhr.upload.addEventListener('progress', (event) => {
							if (event.lengthComputable) {
								const percentCompleted = Math.round((event.loaded * 100) / event.total);
								onProgressCallback(percentCompleted);
							}
						});

						xhr.addEventListener('load', () => {
							resolve(upload);
						});

						xhr.addEventListener('error', () => {
							reject({
								code: 'UPLOAD_ERROR',
								message: 'There was an error uploading your document.',
							});
						});

						xhr.addEventListener('abort', () => {
							reject({
								code: 'UPLOAD_ABORTED',
								message: 'The document upload was aborted.',
							});
						});

						xhr.open(upload.httpMethod, upload.url, true);

						for (const httpHeaderName in upload.httpHeaders) {
							xhr.setRequestHeader(httpHeaderName, upload.httpHeaders[httpHeaderName]);
						}

						xhr.send(blob);
					});
				})
				.then((presignedUpload) => {
					onCompleteCallback(presignedUpload);
				})
				.catch((error) => {
					onErrorCallback(error);
				});

			return documentUpload;
		},
	};

	return documentUpload;
};
