import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { bestPracticeTableColumns, bestPracticeTableHeaders, BEST_PRACTICE_TABLE_COLUMN_IDS } from '@/lib/__mocks__';
import { BestPracticeModel, BestPracticeFiltersModel } from '@/lib/models';
import { TableRowConfig, BestPracticeDownloadParametersModel } from '@/components/table/models';
import { constructUrl, formOptionToMultiSelectOption } from '@/lib/utils';
import { bestPracticeService } from '@/lib/services';
import { useHandleError, useNavigationConfig, useQuery } from '@/hooks';
import {
	MultiSelect,
	MultiSelectOption,
	flattenParentAndChildIdsForSelection,
	getTopLevelParentAndChildrenIds,
} from '@/components';
import {
	SORT_DIRECTION,
	TableCell,
	TableHeader,
	TableRenderer,
	TableRow,
	TableFilterDropdown,
} from '@/components/table';
import config from '@/lib/config';

export const BestPractices = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const handleError = useHandleError();
	const { navigationConfig } = useNavigationConfig();

	const query = useQuery();
	const parentBusinessProcessIds = useMemo(() => query.get('parentBusinessProcessIds'), [query]);
	const businessProcessIds = useMemo(() => query.get('businessProcessIds'), [query]);
	const serviceDeliveryComponentIds = useMemo(() => query.get('serviceDeliveryComponentIds'), [query]);
	const programId = useMemo(() => query.get('programId'), [query]);
	const themeIds = useMemo(() => query.get('themeIds'), [query]);
	const sortField = useMemo(() => query.get('sortField'), [query]);
	const sortDirection = useMemo(() => query.get('sortDirection'), [query]);
	const page = useMemo(() => query.get('page') ?? '0', [query]);
	const size = useMemo(() => query.get('size') ?? '10', [query]);

	const clearSelectionsRef = useRef<() => void>();
	const [filtersAreLoading, setFiltersAreLoading] = useState(false);
	const [tableIsLoading, setTableIsLoading] = useState(false);
	const [tableSelectAll, setTableSelectAll] = useState(false);
	const [tableColumnsData, setTableColumnsData] = useState(bestPracticeTableColumns);
	const [tableHeadersData] = useState(bestPracticeTableHeaders);
	const [totalCount, setTotalCount] = useState(0);
	const [totalCountDescription, setTotalCountDescription] = useState('0');
	const [tableFilters, setTableFilters] = useState<BestPracticeFiltersModel>();

	const [uncheckedRowsIds, setUncheckedRowsIds] = useState<string[]>([]);
	const [businessProcessFilters, setBusinessProcessFilters] = useState<MultiSelectOption[]>([]);
	const [tableRowData, setTableRowData] = useState<TableRowConfig<BestPracticeModel>[]>([]);
	const [downloadData, setDownloadData] = useState<BestPracticeDownloadParametersModel>();
	const downloadUrl = `${config.HACKETT_ADVISORY_BASE_URL}advisory-service/api/v1/best-practice/download`;
	const [checkedRowsIds, setCheckedRowsIds] = useState<string[]>([]);
	/* ------------------------------------- */
	/* Must default a programId */
	/* ------------------------------------- */
	useEffect(() => {
		if (!tableFilters || tableFilters.programs.length <= 0) {
			return;
		}

		if (!programId) {
			history.replace(constructUrl(pathname, { page: '0', programId: tableFilters.programs[0].id }, search));
		}
	}, [history, pathname, programId, search, tableFilters]);

	const fetchTableFilters = useCallback(async () => {
		try {
			setFiltersAreLoading(true);

			const response = await bestPracticeService
				.getFilters({
					...(programId && { programId: programId }),
				})
				.fetch();

			setBusinessProcessFilters(formOptionToMultiSelectOption(response.businessProcesses));
			setTableFilters({
				businessProcesses: response.businessProcesses,
				serviceDeliveryComponents: response.serviceDeliveryComponents,
				themes: response.themes,
				topics: response.topics,
				programs: response.programs,
			});
		} catch (error) {
			handleError(error);
		} finally {
			setFiltersAreLoading(false);
		}
	}, [handleError, programId]);

	useEffect(() => {
		fetchTableFilters();
	}, [fetchTableFilters]);

	const fetchData = useCallback(async () => {
		setTableIsLoading(true);

		if (!programId) {
			return;
		}

		try {
			const response = await bestPracticeService
				.getBestPractices({
					...(parentBusinessProcessIds && { parentBusinessProcessIds }),
					...(businessProcessIds && { businessProcessIds }),
					...(page && { page }),
					...(serviceDeliveryComponentIds && {
						serviceDeliveryComponentIds,
					}),
					...(size && { size }),
					...(sortDirection && { sortDirection }),
					...(sortField && { sortField }),
					...(themeIds && { themeIds }),
					programId,
				})
				.fetch();
			const formattedBestPractices = response.bestPractices.map((bp) => {
				let selectAll = tableSelectAll;
				if (uncheckedRowsIds.length && uncheckedRowsIds?.includes(bp.bestPracticeId)) {
					selectAll = false;
				} else if (checkedRowsIds.length && checkedRowsIds?.includes(bp.bestPracticeId)) {
					selectAll = true;
				}

				return {
					rowId: bp.bestPracticeId,
					checked: selectAll,
					expanded: false,
					columnData: bp,
				};
			});

			setTotalCount(response.totalCount);
			setTotalCountDescription(response.totalCountDescription);
			setTableRowData(formattedBestPractices);
		} catch (error) {
			handleError(error);
		} finally {
			setTableIsLoading(false);
		}
	}, [
		businessProcessIds,
		handleError,
		page,
		parentBusinessProcessIds,
		programId,
		serviceDeliveryComponentIds,
		size,
		sortDirection,
		sortField,
		themeIds,
		tableSelectAll,
		checkedRowsIds,
		uncheckedRowsIds,
	]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const allBpSelections = useMemo(() => {
		return flattenParentAndChildIdsForSelection(
			businessProcessFilters,
			parentBusinessProcessIds?.split('|').filter((s) => s),
			businessProcessIds?.split('|').filter((s) => s)
		);
	}, [businessProcessFilters, businessProcessIds, parentBusinessProcessIds]);

	const updateFilterSelection = useCallback(
		(filterKey: string, filterValue: string) => {
			if (clearSelectionsRef.current) {
				clearSelectionsRef.current();
			}

			history.replace(
				constructUrl(pathname, { page: '0', [filterKey]: filterValue }, filterKey !== 'programId' ? search : '')
			);
		},
		[history, pathname, search]
	);

	const handleBusinessProcessesChange = useCallback(
		(selectedBusinessProcessIds: string[]) => {
			if (clearSelectionsRef.current) {
				clearSelectionsRef.current();
			}

			const { parentIds, remainingChildrenIds } = getTopLevelParentAndChildrenIds(
				businessProcessFilters,
				selectedBusinessProcessIds
			);

			history.replace(
				constructUrl(
					pathname,
					{
						page: '0',
						parentBusinessProcessIds: parentIds,
						businessProcessIds: remainingChildrenIds,
					},
					search
				)
			);
		},
		[businessProcessFilters, history, pathname, search]
	);

	const handleTableSort = useCallback(
		(sortKey: string, sortDirection: SORT_DIRECTION) => {
			if (clearSelectionsRef.current) {
				clearSelectionsRef.current();
			}

			history.replace(constructUrl(pathname, { page: '0', sortField: sortKey, sortDirection }, search));
		},
		[history, pathname, search]
	);

	const handlePaginationChange = useCallback(
		({ pageSize, pageIndex }) => {
			history.replace(constructUrl(pathname, { page: String(pageIndex), size: String(pageSize) }, search));
		},
		[history, pathname, search]
	);

	useEffect(() => {
		let checkedRowIds = checkedRowsIds.join('|');
		let uncheckedRowIds = '';
		if (tableSelectAll) {
			uncheckedRowIds = uncheckedRowsIds.join('|');
		}

		setDownloadData({
			selectAll: tableSelectAll,
			...(checkedRowsIds.length > 0 && tableSelectAll ? { uncheckedRowIds } : { checkedRowIds }),
			selectedColumns: Object.values(tableColumnsData)
				.filter((item) => item.isShowing)
				.map((item) => item.columnId)
				.join('|'),
			...(businessProcessIds && { businessProcessIds }),
			...(parentBusinessProcessIds && { parentBusinessProcessIds }),
			...(serviceDeliveryComponentIds && { serviceDeliveryComponentIds }),
			...(themeIds && { themeIds }),
			...(programId && { programId }),
		});
	}, [
		businessProcessIds,
		parentBusinessProcessIds,
		programId,
		serviceDeliveryComponentIds,
		tableColumnsData,
		tableRowData,
		tableSelectAll,
		themeIds,
		uncheckedRowsIds,
		checkedRowsIds,
	]);

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Best Practices</title>
			</Helmet>

			<Container className="pt-7">
				<Row>
					<Col>
						<div className="d-flex align-items-center justify-content-between">
							<h1 className="mb-0 text-primary">
								Hackett-Certified<sup>&reg;</sup> Best Practices
							</h1>
						</div>
					</Col>
				</Row>
			</Container>
			<Container className="py-7">
				<Row className="mb-4">
					<Col lg={6}>
						<TableFilterDropdown
							filterLabel="Program"
							filterKey="programId"
							tableFilters={tableFilters?.programs ?? []}
							selectedFilters={{
								businessProcessIds: businessProcessIds ?? '',
								serviceDeliveryComponentIds: serviceDeliveryComponentIds ?? undefined,
								themeIds: themeIds ?? undefined,
								programId: programId ?? undefined,
							}}
							updateFilterSelection={updateFilterSelection}
							allowAny={false}
							disabled={filtersAreLoading}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={4} className="mb-5">
						<Form.Label>Business Processes</Form.Label>
						<MultiSelect
							options={businessProcessFilters}
							selected={allBpSelections}
							onChange={handleBusinessProcessesChange}
						>
							{businessProcessIds ? 'Select' : 'Any'}
						</MultiSelect>
					</Col>
					<Col lg={4} className="mb-5">
						{tableFilters && (
							<TableFilterDropdown
								filterLabel="Service Delivery"
								filterKey="serviceDeliveryComponentIds"
								tableFilters={tableFilters.serviceDeliveryComponents}
								selectedFilters={{
									businessProcessIds: businessProcessIds ?? '',
									serviceDeliveryComponentIds: serviceDeliveryComponentIds ?? undefined,
									themeIds: themeIds ?? undefined,
									programId: programId ?? undefined,
								}}
								updateFilterSelection={updateFilterSelection}
							/>
						)}
					</Col>
					<Col lg={4} className="mb-5">
						{tableFilters && (
							<TableFilterDropdown
								filterLabel="Theme"
								filterKey="themeIds"
								tableFilters={tableFilters.themes}
								selectedFilters={{
									businessProcessIds: businessProcessIds ?? '',
									serviceDeliveryComponentIds: serviceDeliveryComponentIds ?? undefined,
									themeIds: themeIds ?? undefined,
									programId: programId ?? undefined,
								}}
								updateFilterSelection={updateFilterSelection}
							/>
						)}
					</Col>
				</Row>

				<Row className="mb-5">
					<Col>
						<TableRenderer
							isLoading={tableIsLoading}
							tableSelectAll={tableSelectAll}
							onTableSelectAllChange={setTableSelectAll}
							tableColumnData={tableColumnsData}
							onTableColumnDataChange={setTableColumnsData}
							tableRowData={tableRowData}
							onTableRowDataChange={setTableRowData}
							tableRowDataTotal={totalCount}
							setCheckedRowsIds={setCheckedRowsIds}
							checkedRowsIds={checkedRowsIds}
							downloadUrl={navigationConfig?.allowDownloads ? downloadUrl : undefined}
							downloadData={navigationConfig?.allowDownloads ? downloadData : undefined}
							pageSize={parseInt(size, 10)}
							pageIndex={parseInt(page, 10)}
							setUncheckedRowsIds={setUncheckedRowsIds}
							uncheckedRowsIds={uncheckedRowsIds}
							onPaginationChange={handlePaginationChange}
							tableHeaderRowRenderer={(checkboxProps, clearSelections) => {
								clearSelectionsRef.current = clearSelections;

								return (
									<TableRow>
										<TableHeader fixed width={55} className="pr-0">
											<Form.Check {...checkboxProps} />
										</TableHeader>
										{Object.values(tableColumnsData).map((columnConfig) => {
											if (!columnConfig.isShowing) {
												return null;
											}

											const tableHeader = tableHeadersData[columnConfig.columnId];
											const tableHeaderProps = {
												key: tableHeader.tableHeaderId,
												sortable: tableHeader.isSortable,
												sortKey: tableHeader.tableHeaderId,
												onSort: handleTableSort,
												sortDirection:
													sortField && sortField === tableHeader.tableHeaderId
														? (sortDirection as SORT_DIRECTION)
														: undefined,
											};

											switch (columnConfig.columnId) {
												case BEST_PRACTICE_TABLE_COLUMN_IDS.BEST_PRACTICES:
													return (
														<TableHeader
															className="pl-0"
															fixed={true}
															fixedOffset={55}
															width={406}
															{...tableHeaderProps}
														>
															{totalCountDescription} {tableHeader.title}
														</TableHeader>
													);
												default:
													return (
														<TableHeader {...tableHeaderProps}>
															{tableHeader.title}
														</TableHeader>
													);
											}
										})}
									</TableRow>
								);
							}}
							tableBodyRowRenderer={(
								data,
								{ id: checkboxId, label: checkboxLabel, ...checkboxProps }
							) => {
								return (
									<TableRow
										key={data.rowId}
										onClick={() => {
											history.push(`/best-practices/${data.rowId}`);
										}}
									>
										<TableCell fixed width={55} className="pr-0">
											<Form.Check id={checkboxId}>
												<Form.Check.Input {...checkboxProps} />
												<Form.Check.Label onClick={checkboxProps.onClick} />
											</Form.Check>
										</TableCell>

										{Object.values(tableColumnsData).map((columnConfig) => {
											if (!columnConfig.isShowing) {
												return null;
											}

											const { columnData } = data;

											switch (columnConfig.columnId) {
												case BEST_PRACTICE_TABLE_COLUMN_IDS.BEST_PRACTICES:
													return (
														<TableCell
															key={columnConfig.columnId}
															className="pl-0"
															fixed={true}
															fixedOffset={55}
															width={406}
														>
															{columnData.description}
														</TableCell>
													);
												case BEST_PRACTICE_TABLE_COLUMN_IDS.BUSINESS_PROCESS:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.businessProcess}
														</TableCell>
													);

												case BEST_PRACTICE_TABLE_COLUMN_IDS.SERVICE_DELIVERY_COMPONENT:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.serviceDeliveryComponent}
														</TableCell>
													);
												case BEST_PRACTICE_TABLE_COLUMN_IDS.CERTIFICATION_STATUS:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.certificationStatus}
														</TableCell>
													);
												case BEST_PRACTICE_TABLE_COLUMN_IDS.THEMES:
													return (
														<TableCell key={columnConfig.columnId} width={406}>
															{columnData.themes}
														</TableCell>
													);
												case BEST_PRACTICE_TABLE_COLUMN_IDS.LEVEL_OF_EFFORT:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.levelOfEffort}
														</TableCell>
													);
												case BEST_PRACTICE_TABLE_COLUMN_IDS.TIME_FRAME:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.timeFrame}
														</TableCell>
													);
												case BEST_PRACTICE_TABLE_COLUMN_IDS.CODE:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.code}
														</TableCell>
													);
												default:
													return <TableCell key={columnConfig.columnId}>N/A</TableCell>;
											}
										})}
									</TableRow>
								);
							}}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};
