export enum PROGRAM_ACCESS {
	FULL = 'FULL',
	PARTIAL = 'PARTIAL',
	NONE = 'NONE',
}

export interface BundleModel {
	description: string;
	fiveReasons: boolean;
	name: string;
	programs: BundleProgramModel[];
	programAccess: PROGRAM_ACCESS;
	programDeliverables?: string;
}

export interface BundleProgramModel {
	businessProcesses: string[];
	name: string;
	iconUrl: string;
	hasAccess: boolean;
}
