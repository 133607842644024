import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Modal, ModalProps, Row } from 'react-bootstrap';

import { EventModel, FormOption } from '@/lib/models';
import { eventsService } from '@/lib/services';
import { createUseThemedStyles, useDebouncedState, useHandleError } from '@/hooks';
import { DateInput, DeliverablesEntryListItem, Loader, SearchInput, Select } from '@/components';
import { TablePagination } from '@/components/table';

const useStyles = createUseThemedStyles((theme) => ({
	dialog: {
		width: '90%',
		height: '100%',
		maxWidth: 1240,
		margin: '0 auto',
		'& .modal-content': {
			maxHeight: '90vh',
		},
		'& .hackett-modal__body': {
			overflowY: 'auto',
			backgroundColor: theme.colors.gray100,
		},
	},
}));

const initialFilterValues = {
	fromDate: null as null | Date,
	toDate: null as null | Date,
};

interface DeliverablesEntryModalProps extends ModalProps {
	value: EventModel[];
	onSave(selectedEntries: EventModel[]): void;
}

export const DeliverablesEntryModal = ({ value, onSave, ...props }: DeliverablesEntryModalProps) => {
	const handleError = useHandleError();
	const classes = useStyles();
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearchValue = useDebouncedState(searchValue);
	const [sortOptions, setSortOptions] = useState<FormOption[]>([]);
	const [filterValues, setFilterValues] = useState(initialFilterValues);
	const [appliedFilters, setAppliedFilters] = useState(initialFilterValues);

	const pageSize = useRef(4);
	const page = useRef(0);
	const [sortField, setSortField] = useState('');
	const [entries, setEntries] = useState<EventModel[]>([]);
	const [totalEntries, setTotalEntries] = useState(0);
	const [selectedEntries, setSelectedEntries] = useState<EventModel[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchSortOptions = useCallback(async () => {
		try {
			const response = await eventsService.getEventFilters().fetch();
			setSortOptions(response.sortFields);
		} catch (error) {
			handleError(error);
		}
	}, [handleError]);

	const fetchEntries = useCallback(async () => {
		try {
			setIsLoading(true);

			const response = await eventsService
				.getAllEvents({
					...(debouncedSearchValue && { searchQuery: debouncedSearchValue }),
					...(sortField && { sortField }),
					page: String(page.current),
					size: pageSize.current,
					...(appliedFilters.fromDate && {
						start: format(appliedFilters.fromDate, 'yyyy-MM-dd'),
					}),
					...(appliedFilters.toDate && {
						end: format(appliedFilters.toDate, 'yyyy-MM-dd'),
					}),
				})
				.fetch();

			setEntries(response.events);
			setTotalEntries(response.totalCount);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}, [appliedFilters.fromDate, appliedFilters.toDate, debouncedSearchValue, handleError, sortField]);

	useEffect(() => {
		page.current = 0;
	}, [debouncedSearchValue]);

	const handleEnter = useCallback(() => {
		setSearchValue('');
		setSortField('');
		page.current = 0;
		setFilterValues(initialFilterValues);
		setAppliedFilters(initialFilterValues);

		fetchSortOptions();
		setSelectedEntries(value);
	}, [fetchSortOptions, value]);

	useEffect(() => {
		if (props.show) {
			fetchEntries();
		}
	}, [fetchEntries, props.show]);

	const handleDocumentListItemChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		const selectedEntriesClone = cloneDeep(selectedEntries);
		const indexToRemove = selectedEntriesClone.findIndex((e) => e.eventId === currentTarget.value);

		if (indexToRemove > -1) {
			selectedEntriesClone.splice(indexToRemove, 1);
		} else {
			const documentToAdd = entries.find((e) => e.eventId === currentTarget.value);
			if (documentToAdd) {
				selectedEntriesClone.push(documentToAdd);
			}
		}

		setSelectedEntries(selectedEntriesClone);
	};

	const handlePaginationChange = ({ pageIndex }: { pageIndex: number }) => {
		page.current = pageIndex;
		fetchEntries();
	};

	const handleClearButtonClick = () => {
		page.current = 0;
		setFilterValues(initialFilterValues);
		setAppliedFilters(initialFilterValues);
	};

	const handleApplyFiltersButtonClick = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		page.current = 0;
		setAppliedFilters(filterValues);
	};

	const handleAddSelectedButtonClick = () => {
		onSave(selectedEntries);
	};

	return (
		<Modal dialogClassName={classes.dialog} centered onEnter={handleEnter} {...props}>
			<Modal.Header closeButton>
				<Modal.Title>Add Entries</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xl={8} className="mb-3">
						<Row>
							<Col xl={6}>
								<SearchInput
									className="mb-6"
									value={searchValue}
									onChange={({ currentTarget }) => {
										setSearchValue(currentTarget.value);
									}}
								/>
							</Col>
						</Row>
						<div className="mb-6 d-flex justify-content-between align-items-center">
							<div className="d-flex align-items-center">
								<h3 className="mb-0">
									{selectedEntries.length > 0 ? (
										<>
											{selectedEntries.length} Selected{' '}
											<Button
												className="pl-2"
												variant="link"
												onClick={() => {
													setSelectedEntries([]);
												}}
											>
												Clear Selections
											</Button>
										</>
									) : (
										`Entries (${totalEntries})`
									)}
								</h3>
								{isLoading && <Loader className="ml-2" size={30} />}
							</div>
							<div className="d-flex align-items-center">
								<Form.Label className="mb-0 text-muted">Sort By</Form.Label>
								<Select
									variant="secondary"
									value={sortField}
									onChange={({ currentTarget }) => {
										page.current = 0;
										setSortField(currentTarget.value);
									}}
								>
									{sortOptions.map((o) => {
										return (
											<option key={o.id} value={o.id}>
												{o.display}
											</option>
										);
									})}
								</Select>
							</div>
						</div>
						<hr />
						{!isLoading && entries.length <= 0 && (
							<p className="mt-6 text-muted text-center">
								<strong>No Results</strong>
							</p>
						)}
						<div style={{ opacity: isLoading ? 0.5 : 1 }}>
							{entries.map((entry) => {
								return (
									<DeliverablesEntryListItem
										key={entry.eventId}
										id={entry.eventId}
										showCheck
										title={entry.name}
										description={entry.listDescription}
										date={entry.durationDescription ?? ''}
										topic={entry.eventTypeDesc}
										checkboxProps={{
											id: entry.eventId,
											value: entry.eventId,
											checked: selectedEntries.some((e) => e.eventId === entry.eventId),
											onChange: handleDocumentListItemChange,
										}}
									/>
								);
							})}
						</div>
						{entries.length > 0 && (
							<TablePagination
								showPageSize={false}
								pageSize={pageSize.current}
								pageIndex={page.current}
								total={totalEntries}
								onChange={handlePaginationChange}
							/>
						)}
					</Col>
					<Col xl={4}>
						<Card>
							<Card.Body>
								<div className="mb-4 d-flex align-items-center justify-content-between">
									<Card.Title className="mb-0">Filters</Card.Title>
									<Button variant="link" onClick={handleClearButtonClick}>
										Clear ({Object.values(appliedFilters).filter((v) => v).length})
									</Button>
								</div>
								<Form onSubmit={handleApplyFiltersButtonClick}>
									<Form.Group className="mb-6">
										<Form.Label>From Date</Form.Label>
										<DateInput
											isClearable
											maxDate={filterValues.toDate}
											selected={filterValues.fromDate}
											onChange={(date) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													fromDate: date,
												}));
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>To Date</Form.Label>
										<DateInput
											isClearable
											minDate={filterValues.fromDate}
											selected={filterValues.toDate}
											onChange={(date) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													toDate: date,
												}));
											}}
										/>
									</Form.Group>
									<hr className="mb-6" />
									<div className="text-center">
										<Button variant="outline-primary" type="submit">
											Apply Filters
										</Button>
									</div>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<div className="text-right">
					<Button className="mr-2" variant="link" onClick={props.onHide}>
						Cancel
					</Button>
					<Button onClick={handleAddSelectedButtonClick}>
						Add Selected {selectedEntries.length > 0 && `(${selectedEntries.length})`}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
