export enum TOPIC_FILTERS {
	STRATEGY_AND_ORGANIZATION = 'STRATEGY_AND_ORGANIZATION',
	TALENT = 'TALENT',
	AGILITY = 'AGILITY',
	SOURCING_AND_SUPPLY_BASE_STRATEGY = 'SOURCING_AND_SUPPLY_BASE_STRATEGY',
	SUPPLY_CHAIN = 'SUPPLY_CHAIN',
	PURCHASING_OPTIONS = 'PURCHASING_OPTIONS',
	TECHNOLOGY = 'TECHNOLOGY',
	DIGITAL_TRANSFORMATION = 'DIGITAL_TRANSFORMATION',
	PERFORMANCE_AND_VALUE_MANAGEMENT = 'PERFORMANCE_AND_VALUE_MANAGEMENT',
}

export const topicFilters = {
	[TOPIC_FILTERS.STRATEGY_AND_ORGANIZATION]: {
		topicFilterId: TOPIC_FILTERS.STRATEGY_AND_ORGANIZATION,
		topicFilterTitle: 'Strategy and Organization',
	},
	[TOPIC_FILTERS.TALENT]: {
		topicFilterId: TOPIC_FILTERS.TALENT,
		topicFilterTitle: 'Talent',
	},
	[TOPIC_FILTERS.AGILITY]: {
		topicFilterId: TOPIC_FILTERS.AGILITY,
		topicFilterTitle: 'Agility',
	},
	[TOPIC_FILTERS.SOURCING_AND_SUPPLY_BASE_STRATEGY]: {
		topicFilterId: TOPIC_FILTERS.SOURCING_AND_SUPPLY_BASE_STRATEGY,
		topicFilterTitle: 'Sourcing and Supply Base Strategy',
	},
	[TOPIC_FILTERS.SUPPLY_CHAIN]: {
		topicFilterId: TOPIC_FILTERS.SUPPLY_CHAIN,
		topicFilterTitle: 'Supply Chain',
	},
	[TOPIC_FILTERS.PURCHASING_OPTIONS]: {
		topicFilterId: TOPIC_FILTERS.PURCHASING_OPTIONS,
		topicFilterTitle: 'Purchasing Options',
	},
	[TOPIC_FILTERS.TECHNOLOGY]: {
		topicFilterId: TOPIC_FILTERS.TECHNOLOGY,
		topicFilterTitle: 'Technology',
	},
	[TOPIC_FILTERS.DIGITAL_TRANSFORMATION]: {
		topicFilterId: TOPIC_FILTERS.DIGITAL_TRANSFORMATION,
		topicFilterTitle: 'Digital Transformation',
	},
	[TOPIC_FILTERS.PERFORMANCE_AND_VALUE_MANAGEMENT]: {
		topicFilterId: TOPIC_FILTERS.PERFORMANCE_AND_VALUE_MANAGEMENT,
		topicFilterTitle: 'Performance and Value Management',
	},
};
