import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useQuery, useHandleError } from '@/hooks';
import { searchService } from '@/lib/services';

import { SearchResultDocumentModel, FormOption, DocumentModel, searchSortOptions, SEARCH_SORT } from '@/lib/models';
import { constructUrl } from '@/lib/utils';

import { LinkBox, LoadingButton, Select, RecentlyViewed, SearchListItem } from '@/components';
import { cloneDeep } from 'lodash';
import { Helmet } from 'react-helmet';

export const Search = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const handleError = useHandleError();

	const loadMoreRef = useRef(false);
	const resultsLengthRef = useRef(0);
	const query = useQuery();
	const searchQuery = useMemo(() => {
		loadMoreRef.current = false;
		resultsLengthRef.current = 0;
		return query.get('query');
	}, [query]);
	const filterQuery = useMemo(() => query.get('filter'), [query]);
	const searchSort = useMemo(() => query.get('searchSort') ?? SEARCH_SORT.RELEVANCE, [query]);
	const [sortingOptions, setSortingOptions] = useState(searchSortOptions);
	const [isLoadingFilters, setIsLoadingFilters] = useState(false);
	const [searchResults, setSearchResults] = useState<SearchResultDocumentModel[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [hasNext, setHasNext] = useState(false);
	const [contentTypeFilters, setContentTypeFilters] = useState<FormOption[]>([]);
	const [loadingSearchResults, setLoadingSearchResults] = useState(false);

	const fetchResults = useCallback(async () => {
		try {
			setLoadingSearchResults(true);
			const response = await searchService
				.getSearchResults({
					...(filterQuery && { filter: filterQuery }),
					...(searchQuery && { query: searchQuery }),
					...(searchSort && { searchSort }),
					from: String(resultsLengthRef.current),
				})
				.fetch();

			setTotalCount(response.count === null ? 0 : response.count);
			setHasNext(response.hasNext);
			setSearchResults((previousValue) => {
				if (loadMoreRef.current) {
					const newValue = previousValue.concat(response.documents);
					resultsLengthRef.current = newValue.length;

					return newValue;
				}

				resultsLengthRef.current = response.documents.length;
				loadMoreRef.current = false;
				return response.documents;
			});
		} catch (error) {
			handleError(error);
		} finally {
			setLoadingSearchResults(false);
		}
	}, [filterQuery, searchQuery, searchSort, handleError]);


	const checkSortOption = () => {

		if (window.location.href.indexOf('BEST_PRACTICE') > -1 || window.location.href.indexOf("PERFORMANCE_METRIC") > -1) {
			setSortingOptions(searchSortOptions.filter(({ searchSort }) => searchSort !== SEARCH_SORT.DATE_ASC && searchSort !== SEARCH_SORT.DATE_DESC));
		}
		else {
			fetchContentTypes();
		}
	};
	const fetchContentTypes = useCallback(async () => {
		setIsLoadingFilters(true);

		try {
			const response = await searchService.getSearchFilters().fetch();
			setContentTypeFilters(response.searchFilters);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoadingFilters(false);
		}
	}, [handleError]);

	const handleSortByChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		loadMoreRef.current = false;
		if ((window.location.href.indexOf('BEST_PRACTICE') > -1 && (currentTarget.value === SEARCH_SORT.DATE_ASC || currentTarget.value === SEARCH_SORT.DATE_DESC)) || (window.location.href.indexOf("PERFORMANCE_METRIC") > -1 && (currentTarget.value === SEARCH_SORT.DATE_ASC || currentTarget.value === SEARCH_SORT.DATE_DESC))) {
			history.replace(constructUrl(pathname, { searchSort: SEARCH_SORT.RELEVANCE, }, search));
		}
		else {
			history.replace(constructUrl(pathname, { searchSort: currentTarget.value }, search));
		}
	};

	const handleTypeFilterChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		loadMoreRef.current = false;
		history.replace(constructUrl(pathname, { filter: currentTarget.value, searchSort: SEARCH_SORT.RELEVANCE }, search));
		setSortingOptions(searchSortOptions);
		checkSortOption();
	};

	const handleClearButtonClick = () => {
		loadMoreRef.current = false;
		history.replace(constructUrl(pathname, { filter: '' }, search));
	};

	const handleLoadMoreButtonClick = () => {
		loadMoreRef.current = true;
		fetchResults();
	};

	useEffect(() => {
		fetchResults();
	}, [fetchResults]);
	useEffect(() => {
		fetchContentTypes();
	}, [fetchContentTypes]);
	useEffect(() => {
		checkSortOption();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleListItemChange = useCallback(
		(document: DocumentModel) => {
			const searchResultsClone = cloneDeep(searchResults);
			const indexToUpdate = searchResultsClone.findIndex((d) => d.id === document.documentId);

			searchResultsClone[indexToUpdate] = {
				...searchResultsClone[indexToUpdate],
				bookmarkFlag: document.bookmarkedFlag,
			};

			setSearchResults(searchResultsClone);
		},
		[searchResults]
	);

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Search</title>
			</Helmet>

			<Container className="pt-7">
				<Row>
					<Col>
						<div className="d-flex align-items-center justify-content-between">
							<h1 className="mb-0 text-primary">
								{loadingSearchResults ? (
									'Loading...'
								) : (
									<>
										{searchQuery
											? totalCount === 1
												? `${totalCount} Result for "${searchQuery}"`
												: `${totalCount} Results for "${searchQuery}"`
											: `${totalCount} Results`}
									</>
								)}
							</h1>
						</div>
					</Col>
				</Row>
			</Container>

			<Container className="pt-7 pb-16">
				<Row>
					<Col xs={12} xl={8} className="mb-6 pr-1">
						<Row className="mb-6">
							<Col>
								<div className="d-flex align-items-center justify-content-end">
									<Form.Label className="mb-0 text-muted">Sort By</Form.Label>
									<Select variant="secondary" value={searchSort} onChange={handleSortByChange}>
										{sortingOptions.map((option) => (
											<option key={option.searchSort} value={option.searchSort}>
												{option.title}
											</option>
										))}
									</Select>
								</div>
							</Col>
						</Row>

						{!loadingSearchResults && searchResults.length <= 0 ? (
							<p className="mb-2 text-muted text-center">
								<strong>No Results</strong>
							</p>
						) : (
							<>
								{searchResults.map((searchItem) => {
									return (
										<SearchListItem
											id={searchItem.id}
											key={searchItem.id}
											tags={searchItem.topicTags}
											category={searchItem.contentTypes}
											title={searchItem.title}
											description={searchItem.description}
											detailsUrl={searchItem.detailsUrl}
											downloadUrl={searchItem.downloadUrl}
											viewUrl={searchItem.viewUrl}
											canDownload={searchItem.canDownload}
											fileType={searchItem.fileType}
											fileName={searchItem.fileName}
											type={searchItem.type}
											isBookmarked={searchItem.bookmarkFlag ?? false}
											onChange={handleListItemChange}
											isPodcast={!!searchItem.podcastUrl}
										/>
									);
								})}

								{searchResults.length > 0 && hasNext && (
									<Row>
										<Col xs={12} xl={12} className="d-flex justify-content-center mt-5">
											<LoadingButton
												isLoading={loadingSearchResults}
												variant="outline-dark"
												onClick={handleLoadMoreButtonClick}
											>
												Load More
											</LoadingButton>
										</Col>
									</Row>
								)}
							</>
						)}
					</Col>

					<Col xs={12} xl={4} className="mb-6">
						<Card className="mb-2">
							<Card.Body>
								<div className="mb-4 d-flex align-items-end justify-content-between">
									<h3 className="mb-0">Filter by type</h3>
									<Button variant="link" onClick={handleClearButtonClick}>
										Clear
									</Button>
								</div>
								<Form.Group className="mb-6">
									<Select
										value={filterQuery ?? ''}
										onChange={handleTypeFilterChange}
										disabled={isLoadingFilters}
									>
										<option value="">All</option>;
										{contentTypeFilters.map((typeFilter) => {
											return (
												<option key={typeFilter.id} value={typeFilter.id}>
													{typeFilter.display}
												</option>
											);
										})}
									</Select>
								</Form.Group>
							</Card.Body>
						</Card>
						<RecentlyViewed className="mb-2" />
						<LinkBox
							sections={[
								{
									title: "Can't find what you're looking for?",
									links: [{ title: 'Contact Hackett', to: '/contact', showIcon: true }],
								},
							]}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};
