import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { constructUrl } from '@/lib/utils';
import { EventModel, PERFORMANCE_STUDY_STATUS_ID, ProgramModel } from '@/lib/models';
import { eventsService } from '@/lib/services';
import { useAccount, useHandleError, useQuery } from '@/hooks';
import { AsyncPage, FilterTabs, LinkBox, ListResult, Loader, Select } from '@/components';
import { TablePagination } from '@/components/table';

enum VIEW {
	ACTIVE = 'ACTIVE',
	PAST = 'PAST',
}

export const PerformanceStudies = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const { path } = useRouteMatch();
	const { account } = useAccount();
	const handleError = useHandleError();

	const searchParams = useQuery();
	const view = searchParams.get('view') ?? VIEW.ACTIVE;
	const page = searchParams.get('page') ?? '0';
	const programId = searchParams.get('programId') ?? '';
	const pageSize = useRef(5);

	const [isLoading, setIsLoading] = useState(false);
	// const [recommendedEvents, setRecommendedEvents] = useState<Recommendation[]>([]);
	const [programOptions, setProgramOptions] = useState<ProgramModel[]>([]);
	const [performanceStudies, setPerformanceStudies] = useState<EventModel[]>([]);
	const [performanceStudiesTotal, setPerformanceStudiesTotal] = useState(0);

	// const fetchRecommendedEvents = useCallback(async () => {
	// 	const response = await recommendationsService
	// 		.getRecommendedEvents({ eventTypeId: 'PERFORMANCE_STUDY' })
	// 		.fetch();
	// 	const firstFourRecommendations = response.recommendedContent.slice(0, 4);

	// 	setRecommendedEvents(firstFourRecommendations);
	// }, []);

	const fetchPrograms = useCallback(async () => {
		if (!account) {
			throw new Error('account is undefined');
		}

		const response = await eventsService.getRelevantPerformanceStudyProgramOptions(view === VIEW.PAST).fetch();
		setProgramOptions(response.programs);
	}, [account, view]);

	const getUpcomingPerformanceStudiesPromise = useMemo(() => {
		return eventsService.getUpcomingPerformanceStudies({
			size: pageSize.current,
			...(page && { page }),
			...(programId && { programId }),
		});
	}, [page, programId]);

	const getUpcomingPerformanceStudies = useCallback(async () => {
		try {
			setIsLoading(true);
			const response = await getUpcomingPerformanceStudiesPromise.fetch();

			setPerformanceStudies(response.events);
			setPerformanceStudiesTotal(response.totalCount);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}, [getUpcomingPerformanceStudiesPromise, handleError]);

	const getPastPerformanceStudiesPromise = useMemo(() => {
		return eventsService.getPastPerformanceStudies({
			size: pageSize.current,
			...(page && { page }),
			...(programId && { programId }),
		});
	}, [page, programId]);

	const getPastPerformanceStudies = useCallback(async () => {
		try {
			setIsLoading(true);
			const response = await getPastPerformanceStudiesPromise.fetch();

			setPerformanceStudies(response.events);
			setPerformanceStudiesTotal(response.totalCount);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}, [getPastPerformanceStudiesPromise, handleError]);

	useEffect(() => {
		if (view === VIEW.ACTIVE) {
			getPastPerformanceStudiesPromise.abort();
			getUpcomingPerformanceStudies();
		} else {
			getUpcomingPerformanceStudiesPromise.abort();
			getPastPerformanceStudies();
		}
	}, [
		getPastPerformanceStudies,
		getPastPerformanceStudiesPromise,
		getUpcomingPerformanceStudies,
		getUpcomingPerformanceStudiesPromise,
		view,
	]);

	const handleFilterTabsChange = (value: VIEW) => {
		history.push(
			constructUrl(
				pathname,
				{
					view: value,
					page: '0',
				},
				search
			)
		);
	};

	const handleProgramSelectChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		history.push(
			constructUrl(
				pathname,
				{
					page: '0',
					programId: currentTarget.value,
				},
				search
			)
		);
	};

	const handlePaginationChange = ({ pageIndex }: { pageIndex: number }) => {
		history.push(constructUrl(pathname, { page: pageIndex }, search));
	};

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Events - Performance Studies</title>
			</Helmet>
			{/*<AsyncPage fetchData={fetchRecommendedEvents}>*/}
			<Container className="pt-7">
				<Row>
					<Col>
						<div className="d-flex align-items-center justify-content-between">
							<h1 className="mb-0 text-primary">Performance Studies</h1>
						</div>
					</Col>
				</Row>
			</Container>

			{/* <ColorBlockContainer
					angle={174}
					className={classNames('pt-11', {
						'pb-20': recommendedEvents.length > 0,
						'pb-10': recommendedEvents.length <= 0,
					})}
				>
					<Row className={classNames({ 'mb-10': recommendedEvents.length > 0 })}>
						<Col>
							<h2 className="mb-0 text-white">Performance Studies</h2>
						</Col>
					</Row>
					{recommendedEvents.length > 0 && (
						<Row>
							{recommendedEvents.map((event) => {
								return (
									<Col md={6} lg={3}>
										<FeatureCard
											smaller
											category={event.contentTypeDescription}
											to={event.detailsUrl}
											title={event.title}
											callToActionVariant="warning"
											callToActionIcon="star"
											callToActionText="Advisor Recommended"
										/>
									</Col>
								);
							})}
						</Row>
					)}
				</ColorBlockContainer>
			</AsyncPage>*/}
			<Container className="py-7">
				<Row>
					<Col xs={12} xl={8} className="mb-6">
						<FilterTabs
							className="mb-6"
							value={view}
							tabs={[
								{ title: 'Active & Upcoming', value: VIEW.ACTIVE },
								{ title: 'Results from Past Studies', value: VIEW.PAST },
							]}
							onChange={handleFilterTabsChange}
						/>
						{isLoading && <Loader />}
						{!isLoading && (
							<>
								{performanceStudies.length <= 0 && (
									<p className="mb-2 text-muted text-center">
										<strong>No Results</strong>
									</p>
								)}
								{performanceStudies.map((event, index) => {
									return (
										<ListResult
											key={`${event.eventId}-${index}`}
											category={event.performanceStudyStatusId ?? ''}
											date={`${event.durationDescription ?? ''}`}
											to={`${path}/${event.eventId}`}
											title={event.name}
											description={event.listDescription}
											imageSrc={event.imageUrl}
										>
											{event.performanceStudyStatusId !== PERFORMANCE_STUDY_STATUS_ID.CLOSED &&
												event.registrationUrl && (
													<div>
														<Button
															className="mt-2"
															variant={
																event.performanceStudyStatusId ===
																	PERFORMANCE_STUDY_STATUS_ID.OPEN
																	? 'primary'
																	: 'outline-primary'
															}
															size="sm"
															href={event.registrationUrl}
															target="_blank"
															rel="noopener"
														>
															{event.performanceStudyStatusId ===
																PERFORMANCE_STUDY_STATUS_ID.OPEN && 'Take Now'}
															{event.performanceStudyStatusId ===
																PERFORMANCE_STUDY_STATUS_ID.UPCOMING && 'Register'}
														</Button>
													</div>
												)}
										</ListResult>
									);
								})}
								{performanceStudies.length > 0 && (
									<TablePagination
										showPageSize={false}
										pageSize={pageSize.current}
										pageIndex={parseInt(page ?? '0', 10)}
										total={performanceStudiesTotal}
										onChange={handlePaginationChange}
									/>
								)}
							</>
						)}
					</Col>
					<Col xs={12} xl={4}>
						<Card className="mb-2">
							<Card.Body>
								<div className="mb-4 d-flex align-items-center justify-content-between">
									<Card.Title className="mb-0">Filters</Card.Title>
								</div>
								<AsyncPage fetchData={fetchPrograms}>
									<Form.Group>
										<Form.Label>Program</Form.Label>
										<Select value={programId} onChange={handleProgramSelectChange}>
											<option value="">All Programs</option>;
											{programOptions.map((po) => {
												return (
													<option key={po.programId} value={po.programId}>
														{po.name}
													</option>
												);
											})}
										</Select>
									</Form.Group>
								</AsyncPage>
							</Card.Body>
						</Card>
						<LinkBox
							sections={[
								{
									title: "Can't find what you're looking for?",
									links: [{ title: 'Contact Hackett', to: '/contact', showIcon: true }],
								},
							]}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};
