import React, { FC } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import classNames from 'classnames';
import Color from 'color';

import { createUseThemedStyles } from '@/hooks';
import { ReactComponent as DownChevron } from '@/assets/icons/icon-chevron-down.svg';

const useSelectStyles = createUseThemedStyles((theme) => ({
	select: {
		position: 'relative',
		'& select': {
			zIndex: 0,
			...theme.fonts.s,
			height: 40,
			width: '100%',
			borderRadius: 2,
			cursor: 'pointer',
			appearance: 'none',
			position: 'relative',
			padding: '0 40px 0 15px',
			color: theme.colors.black,
			backgroundColor: theme.colors.white,
			border: `1px solid ${theme.colors.gray500}`,
			boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.16)',
			'&:hover': {
				border: `1px solid ${theme.colors.gray600}`,
			},
			'&:focus': {
				outline: 'none',
				border: `1px solid ${theme.colors.primary}`,
			},
			'&:disabled': {
				cursor: 'default',
				color: theme.colors.gray500,
				backgroundColor: theme.colors.gray300,
				'&:hover': {
					border: `1px solid ${theme.colors.gray500}`,
				},
			},
			'&:invalid': {
				fontStyle: 'italic',
				color: theme.colors.gray600,
			},
			'&::-ms-expand': {
				display: 'none',
			},
		},
		'&--secondary': {
			'& select': {
				boxShadow: 'none',
				padding: '0 35px 0 12px',
				borderColor: 'transparent',
				backgroundColor: 'transparent',
				...theme.fonts.openSansSemiBold,
				color: theme.colors.primary,
				'&:hover': {
					border: '1px solid transparent !important',
					backgroundColor: Color(theme.colors.primary)
						.alpha(0.16)
						.string(),
				},
				'&:focus': {
					outline: 'none',
					border: '1px solid transparent !important',
					boxShadow: `0 0 0 0.2rem ${Color(theme.colors.primary)
						.alpha(0.32)
						.rgb()}`,
				},
			},
		},
	},
	downChevron: {
		zIndex: 1,
		right: 15,
		top: '50%',
		position: 'absolute',
		pointerEvents: 'none',
		transform: 'translateY(-50%)',
		fill: theme.colors.primary,
	},
}));

interface SelectProps extends FormControlProps {
	variant?: 'primary' | 'secondary';
	required?: boolean;
	className?: string;
	name?: string;
}

export const Select: FC<SelectProps> = ({ variant = 'primary', required, className, name, children, ...props }) => {
	const classes = useSelectStyles();

	return (
		<div className={classNames(classes.select, `${classes.select}--${variant}`, className)}>
			<Form.Control as="select" bsPrefix="hackett-select" required={required} name={name} {...props}>
				{children}
			</Form.Control>
			<DownChevron className={classes.downChevron} />
		</div>
	);
};
