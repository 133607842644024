import { TableColumns, TableHeaders } from '@/components/table/models';

export enum BEST_PRACTICE_TABLE_COLUMN_IDS {
	BEST_PRACTICES = 'DESCRIPTION',
	BUSINESS_PROCESS = 'BUSINESS_PROCESS',
	SERVICE_DELIVERY_COMPONENT = 'SERVICE_DELIVERY_COMPONENT',
	CERTIFICATION_STATUS = 'CERTIFICATION_STATUS',
	THEMES = 'THEMES',
	LEVEL_OF_EFFORT = 'LEVEL_OF_EFFORT',
	TIME_FRAME = 'TIME_FRAME',
	CODE = 'CODE',
}

export const bestPracticeTableColumns: TableColumns<BEST_PRACTICE_TABLE_COLUMN_IDS> = {
	[BEST_PRACTICE_TABLE_COLUMN_IDS.BEST_PRACTICES]: {
		columnId: BEST_PRACTICE_TABLE_COLUMN_IDS.BEST_PRACTICES,
		columnTitle: 'Best Practices',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.BUSINESS_PROCESS]: {
		columnId: BEST_PRACTICE_TABLE_COLUMN_IDS.BUSINESS_PROCESS,
		columnTitle: 'Business Process',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.SERVICE_DELIVERY_COMPONENT]: {
		columnId: BEST_PRACTICE_TABLE_COLUMN_IDS.SERVICE_DELIVERY_COMPONENT,
		columnTitle: 'Service Delivery Component',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.CERTIFICATION_STATUS]: {
		columnId: BEST_PRACTICE_TABLE_COLUMN_IDS.CERTIFICATION_STATUS,
		columnTitle: 'Certification Status',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.THEMES]: {
		columnId: BEST_PRACTICE_TABLE_COLUMN_IDS.THEMES,
		columnTitle: 'Themes',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.LEVEL_OF_EFFORT]: {
		columnId: BEST_PRACTICE_TABLE_COLUMN_IDS.LEVEL_OF_EFFORT,
		columnTitle: 'Level of Effort',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.TIME_FRAME]: {
		columnId: BEST_PRACTICE_TABLE_COLUMN_IDS.TIME_FRAME,
		columnTitle: 'Time Frame',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.CODE]: {
		columnId: BEST_PRACTICE_TABLE_COLUMN_IDS.CODE,
		columnTitle: 'Code',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
};

export const bestPracticeTableHeaders: TableHeaders<BEST_PRACTICE_TABLE_COLUMN_IDS> = {
	[BEST_PRACTICE_TABLE_COLUMN_IDS.BEST_PRACTICES]: {
		tableHeaderId: BEST_PRACTICE_TABLE_COLUMN_IDS.BEST_PRACTICES,
		title: 'Best Practices',
		isSortable: true,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.BUSINESS_PROCESS]: {
		tableHeaderId: BEST_PRACTICE_TABLE_COLUMN_IDS.BUSINESS_PROCESS,
		title: 'Business Process',
		isSortable: true,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.SERVICE_DELIVERY_COMPONENT]: {
		tableHeaderId: BEST_PRACTICE_TABLE_COLUMN_IDS.SERVICE_DELIVERY_COMPONENT,
		title: 'Service Delivery Component',
		isSortable: true,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.CERTIFICATION_STATUS]: {
		tableHeaderId: BEST_PRACTICE_TABLE_COLUMN_IDS.CERTIFICATION_STATUS,
		title: 'Certification Status',
		isSortable: true,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.THEMES]: {
		tableHeaderId: BEST_PRACTICE_TABLE_COLUMN_IDS.THEMES,
		title: 'Themes',
		isSortable: true,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.LEVEL_OF_EFFORT]: {
		tableHeaderId: BEST_PRACTICE_TABLE_COLUMN_IDS.LEVEL_OF_EFFORT,
		title: 'Level or Effort',
		isSortable: true,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.TIME_FRAME]: {
		tableHeaderId: BEST_PRACTICE_TABLE_COLUMN_IDS.TIME_FRAME,
		title: 'Time Frame',
		isSortable: true,
	},
	[BEST_PRACTICE_TABLE_COLUMN_IDS.CODE]: {
		tableHeaderId: BEST_PRACTICE_TABLE_COLUMN_IDS.CODE,
		title: 'Code',
		isSortable: true,
	},
};
