import Color from 'color';

import { ThemeConfig } from '@/contexts';
import { boxShadow } from '@/jss/mixins';

import checkmarkIcon from '@/assets/icons/icon-checkmark-checkbox.svg';
import downChevronIcon from '@/assets/icons/icon-chevron-down.svg';

export const form = (theme: ThemeConfig) => ({
	'.hackett-form': {
		'&__group': {
			marginBottom: 30,
		},
		'&__label': {
			...theme.fonts.s,
			marginBottom: 5,
			color: theme.colors.black,
			...theme.fonts.openSansSemiBold,
		},
		'&__control': {
			flex: 1,
			padding: 0,
			...theme.fonts.s,
			width: '100%',
			textIndent: 15,
			borderRadius: 2,
			display: 'block',
			appearance: 'none',
			lineHeight: 'normal',
			color: theme.colors.black,
			backgroundColor: theme.colors.white,
			border: `1px solid ${theme.colors.gray500}`,
			boxShadow: 'inset 0 1px 4px 0 rgba(0, 0, 0, 0.16)',
			'&:hover': {
				border: `1px solid ${theme.colors.gray600}`,
			},
			'&:focus': {
				outline: 'none',
				border: `1px solid ${theme.colors.primary}`,
			},
			'&:disabled': {
				backgroundColor: theme.colors.gray300,
				'&:hover': {
					border: `1px solid ${theme.colors.gray500}`,
				},
			},
			'&[type=date], &[type=datetime-local], &[type=email], &[type=month], &[type=number], &[type=password], &[type=search], &[type=tel], &[type=text], &[type=time], &[type=url], &[type=week]': {
				height: 40,
			},
		},
		'&__check': {
			position: 'relative',
			'&-inline': {
				marginRight: 16,
				display: 'inline-block',
			},
			'& input[type=checkbox], & input[type=radio]': {
				width: 0,
				height: 0,
				opacity: 0,
				position: 'absolute',
				'& + label': {
					...theme.fonts.s,
					display: 'flex',
					cursor: 'pointer',
					alignItems: 'baseline',
					color: theme.colors.gray700,
					'&:before': {
						width: 18,
						height: 18,
						content: '""',
						flexShrink: 0,
						marginRight: 8,
						display: 'block',
						transform: 'translateY(2px)',
						backgroundColor: theme.colors.white,
						border: `1px solid ${theme.colors.gray500}`,
						boxShadow: `inset 0 1px 3px 0 rgba(51,51,51,0.1)`,
					},
					'&:hover:before': {
						backgroundColor: Color(theme.colors.white)
							.darken(0.16)
							.hex(),
					},
				},
				'&:checked + label': {
					'&:before': {
						borderColor: theme.colors.primary,
					},
					'&:hover:before': {
						borderColor: Color(theme.colors.primary)
							.lighten(0.16)
							.hex(),
						backgroundColor: Color(theme.colors.primary)
							.lighten(0.16)
							.hex(),
					},
				},
				'&:focus + label': {
					'&:before': {
						border: `1px solid ${theme.colors.primary}`,
					},
				},
				'&:disabled + label': {
					cursor: 'not-allowed',
					'&:hover:before': {
						backgroundColor: theme.colors.white,
					},
				},
			},
			'& input[type=checkbox]': {
				'& + label': {
					'&:before': {
						borderRadius: 2,
					},
				},
				'&:checked + label': {
					'&:before': {
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundImage: `url(${checkmarkIcon})`,
						backgroundColor: theme.colors.primary,
					},
				},
			},
			'& input[type=radio]': {
				'& + label': {
					'&:before': {
						borderRadius: '50%',
					},
				},
				'&:checked + label': {
					'&:before': {
						backgroundColor: theme.colors.primary,
					},
					'&:after': {
						top: 7,
						left: 5,
						width: 8,
						height: 8,
						content: '""',
						borderRadius: '50%',
						position: 'absolute',
						backgroundColor: theme.colors.white,
					},
				},
			},
			'&--badge': {
				'&-inline': {
					marginRight: 8,
					marginBottom: 8,
					display: 'inline-block',
				},
				'& input[type=checkbox], & input[type=radio]': {
					width: 0,
					height: 0,
					opacity: 0,
					position: 'absolute',
					'& + label': {
						display: 'inline-flex',
						flexDirection: 'column',
						alignItems: 'center',
						...theme.fonts.s,
						cursor: 'pointer',
						borderRadius: 500,
						padding: '0.8rem 2.4rem',
						...theme.fonts.openSansRegular,
						backgroundColor: 'transparent',
						color: theme.colors.primary,
						border: `1px solid ${theme.colors.primary}`,
						'&:hover': {
							backgroundColor: Color(theme.colors.primary)
								.alpha(0.16)
								.string(),
						},
						'&:active': {
							backgroundColor: Color(theme.colors.primary)
								.alpha(0.32)
								.string(),
						},
						// CSS Trick to not increase width when font changes from regular to bold when checked
						'&:after': {
							height: 0,
							overflow: 'hidden',
							userSelect: 'none',
							visibility: 'hidden',
							pointerEvents: 'none',
							...theme.fonts.openSansBold,
							content: 'attr(data-title) / ""',
						},
					},
					'&:checked + label': {
						color: theme.colors.white,
						...theme.fonts.openSansBold,
						backgroundColor: theme.colors.primary,
						'&:hover': {
							backgroundColor: Color(theme.colors.primary)
								.lighten(0.16)
								.hex(),
						},
						'&:active': {
							backgroundColor: Color(theme.colors.primary)
								.darken(0.16)
								.hex(),
						},
					},
					'&:focus + label': {
						outline: 'none',
						boxShadow: `0 0 0 0.2rem ${Color(theme.colors.primary)
							.alpha(0.3)
							.rgb()}`,
					},
				},
			},
		},
	},
	'textarea.hackett-form__control': {
		height: 74,
		textIndent: 0,
		resize: 'none',
		padding: '8px 15px',
	},
	'.form-switch': {
		paddingLeft: 0,
		position: 'relative',
		'& input[type=checkbox]': {
			width: 0,
			height: 0,
			'& + label': {
				paddingLeft: 0,
				...theme.fonts.s,
				position: 'relative',
				'&:before': {
					width: 40,
					height: 20,
					borderRadius: 500,
					transition: '0.2s all',
					transform: 'translateY(4px)',
					borderColor: theme.colors.gray600,
					backgroundColor: theme.colors.gray600,
				},
				'&:after': {
					top: 0,
					left: 0,
					width: 16,
					height: 16,
					content: '""',
					borderRadius: 8,
					position: 'absolute',
					transition: '0.2s all',
					transform: 'translate(2px, 6px)',
					backgroundColor: theme.colors.white,
				},
				'&:hover:before': {
					borderColor: theme.colors.gray500,
					backgroundColor: theme.colors.gray500,
				},
			},
			'&:focus + label:before': {
				boxShadow: `0 0 0 0.2rem ${Color(theme.colors.primary)
					.alpha(0.32)
					.rgb()}`,
			},
			'&:checked + label': {
				...theme.fonts.openSansBold,
				'&:before': {
					backgroundImage: 'none',
					borderColor: theme.colors.primary,
					backgroundColor: theme.colors.primary,
				},
				'&:after': {
					transform: 'translate(22px, 6px)',
				},
				'&:hover:before': {
					borderColor: Color(theme.colors.primary)
						.lighten(0.16)
						.hex(),
					backgroundColor: Color(theme.colors.primary)
						.lighten(0.16)
						.hex(),
				},
			},
		},
	},
	// Style overrides for react-bootstrap-typeahead package
	// https://github.com/ericgio/react-bootstrap-typeahead
	'.rbt': {
		position: 'unset !important',
		'& .form-control': {
			borderRadius: 2,
			...theme.fonts.s,
			color: theme.colors.black,
			padding: '5px 40px 5px 5px',
			border: `1px solid ${theme.colors.gray500}`,
			boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.16)',
			'&:hover': {
				border: `1px solid ${theme.colors.gray600}`,
			},
			'&:focus-within': {
				outline: 'none',
				border: `1px solid ${theme.colors.primary}`,
			},
			'& input': {
				textIndent: 8,
			},
			'&:after': {
				zIndex: 1,
				right: 11,
				width: 16,
				height: 16,
				top: '50%',
				content: '""',
				position: 'absolute',
				pointerEvents: 'none',
				transform: 'translateY(-50%)',
				// Trick to use the shape of the SVG as a mask, and color it using the background
				maskPosition: 'center',
				maskRepeat: 'no-repeat',
				maskImage: `url(${downChevronIcon})`,
				backgroundColor: theme.colors.primary,
			},
		},
		'& .rbt-input-multi .rbt-input-wrapper': {
			overflow: 'visible',
			margin: '0 0 -5px 0',
		},
		'& .rbt-input-multi .rbt-input-main': {
			height: 30,
			margin: '0 0 5px 0',
		},
		'& .rbt-token-label': {
			lineHeight: '20px',
		},
		'& .rbt-token': {
			borderRadius: 0,
			padding: '7px 10px',
			alignItems: 'center',
			margin: '0 5px 5px 0',
			display: 'inline-flex',
			backgroundColor: '#F2F3F3',
			color: theme.colors.primary,
			// '&.rbt-token-removeable': {
			// 	paddingRight: 30,
			// },
			'&.rbt-token-active': {
				borderRadius: 2,
				boxShadow: `0 0 0 0.2rem ${Color(theme.colors.primary)
					.alpha(0.32)
					.rgb()}`,
			},
			'& .rbt-token-remove-button': {
				top: -1,
				border: 0,
				width: 30,
				height: 30,
				padding: 0,
				display: 'flex',
				...theme.fonts.m,
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'transparent',
			},
		},
		'& .rbt-menu': {
			border: 0,
			borderRadius: 2,
			...boxShadow,
			'& .dropdown-item': {
				...theme.fonts.s,
				padding: '8px 16px',
				'&.disabled': {
					color: theme.colors.gray600,
				},
				'&.active, &:hover': {
					color: theme.colors.black,
					backgroundColor: Color(theme.colors.primary)
						.alpha(0.16)
						.string(),
				},
				'&:active': {
					color: theme.colors.white,
					backgroundColor: theme.colors.primary,
				},
			},
		},
	},
});
