import { TableColumns, TableHeaders, TableRowConfig } from '@/components/table/models';

export enum ENGAGEMENT_PLAN_TABLE_COLUMN_IDS {
	ACTION_ITEM = 'ACTION_ITEM',
	ADVISOR = 'ADVISOR',
	STATUS = 'STATUS',
	MILESTONE_DATE = 'MILESTONE_DATE',
}

export const engagementPlanTableColumns: TableColumns<ENGAGEMENT_PLAN_TABLE_COLUMN_IDS> = {
	[ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.ACTION_ITEM]: {
		columnId: ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.ACTION_ITEM,
		columnTitle: 'Action Item',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.ADVISOR]: {
		columnId: ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.ADVISOR,
		columnTitle: 'ADVISOR',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.STATUS]: {
		columnId: ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.STATUS,
		columnTitle: 'STATUS',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.MILESTONE_DATE]: {
		columnId: ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.MILESTONE_DATE,
		columnTitle: 'MILESTONE_DATE',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
};

export const engagementPlanTableHeaders: TableHeaders<ENGAGEMENT_PLAN_TABLE_COLUMN_IDS> = {
	[ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.ACTION_ITEM]: {
		tableHeaderId: ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.ACTION_ITEM,
		title: 'Action Item',
		isSortable: false,
	},
	[ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.ADVISOR]: {
		tableHeaderId: ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.ADVISOR,
		title: 'Advisor',
		isSortable: false,
	},
	[ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.STATUS]: {
		tableHeaderId: ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.STATUS,
		title: 'Status',
		isSortable: false,
	},
	[ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.MILESTONE_DATE]: {
		tableHeaderId: ENGAGEMENT_PLAN_TABLE_COLUMN_IDS.MILESTONE_DATE,
		title: 'Milestone Date',
		isSortable: false,
	},
};

export const engagementPlanTableRows: TableRowConfig<{
	description: string;
	attachementCount: number;
	commentCount: number;
	advisor: string;
	statusId: string;
	milestoneDate: string;
}>[] = [
	{
		rowId: 'xxxx-xxxx-xxxx-xxxx-0',
		checked: false,
		expanded: false,
		columnData: {
			description: 'Review Stategy',
			attachementCount: 2,
			commentCount: 0,
			advisor: 'John Smith',
			statusId: 'COMPLETE',
			milestoneDate: '12/10/20',
		},
	},
	{
		rowId: 'xxxx-xxxx-xxxx-xxxx-1',
		checked: false,
		expanded: false,
		columnData: {
			description: 'Share Best Practices & Research',
			attachementCount: 0,
			commentCount: 0,
			advisor: 'John Smith',
			statusId: 'COMPLETE',
			milestoneDate: '12/29/20',
		},
	},
	{
		rowId: 'xxxx-xxxx-xxxx-xxxx-2',
		checked: false,
		expanded: false,
		columnData: {
			description: 'Share Talent Research',
			attachementCount: 1,
			commentCount: 2,
			advisor: 'John Smith',
			statusId: 'IN_PROGRESS',
			milestoneDate: '1/14/21',
		},
	},
];
