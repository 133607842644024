import { createUseThemedStyles } from '@/hooks';

export const useGlobalStyles = createUseThemedStyles((theme) => ({
	'@global': {
		'#root': {
			overflow: 'hidden',
		},

		/* ----------------------------------------------------------- */
		/* Search input controls */
		/* ----------------------------------------------------------- */
		'input[type=search]::-ms-clear': {
			display: 'none',
			width: 0,
			height: 0,
		},
		'input[type=search]::-ms-reveal': {
			display: 'none',
			width: 0,
			height: 0,
		},
		'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration': {
			display: 'none',
		},

		/* ----------------------------------------------------------- */
		/* Jasper reports */
		/* ----------------------------------------------------------- */
		'.visualizejs': {
			lineHeight: 'normal',
		},

		'._jrHyperLink': {
			cursor: 'pointer',
		},

		/* ----------------------------------------------------------- */
		/* Google place autocomplete */
		/* ----------------------------------------------------------- */
		'.pac': {
			'&-container': {
				fontFamily: 'inherit',
			},
			'&-item': {
				lineHeight: theme.fonts.s.lineHeight,
				padding: '8px 16px',
				'&:hover': {
					textDecoration: 'underline',
					backgroundColor: theme.colors.gray200,
				},
				'&-query': {
					fontSize: 'initial',
				},
			},
		},

		/* ----------------------------------------------------------- */
		/* Placeholder text */
		/* ----------------------------------------------------------- */
		'::-webkit-input-placeholder': {
			fontStyle: 'italic',
			color: theme.colors.gray600,
		},
		'::-moz-placeholder': {
			fontStyle: 'italic',
			color: theme.colors.gray600,
		},
		':-ms-input-placeholder': {
			fontStyle: 'italic',
			color: theme.colors.gray600,
		},
		':-moz-placeholder': {
			fontStyle: 'italic',
			color: theme.colors.gray600,
		},

		/* ----------------------------------------------------------- */
		/* Generics */
		/* ----------------------------------------------------------- */
		html: {
			height: '100%',
			fontSize: '10px',
		},
		body: {
			...theme.fonts.s,
			minHeight: '100%',
			position: 'relative',
			color: theme.colors.black,
			...theme.fonts.openSansRegular,
			backgroundColor: theme.colors.gray100,
		},
		hr: {
			margin: 0,
			opacity: 1,
			backgroundColor: theme.colors.gray300,
		},
		'.flex-1': {
			flex: 1,
		},

		'.html-editor': {
			'& li.ql-indent-1:not(.ql-direction-rtl)': {
				marginLeft: '4em',
			},
			'& li.ql-indent-2:not(.ql-direction-rtl)': {
				marginLeft: '8em',
			},
			'& li.ql-indent-3:not(.ql-direction-rtl)': {
				marginLeft: '12em',
			},
		},
	},
}));
