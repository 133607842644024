import { httpSingleton } from '@/lib/http-client';
import { AiConversation, AiConversationSections, ConversationQuery, FEEDBACK_RATING_ID } from '@/lib/models';

export const aiConversationService = {
	getPastConversations() {
		return httpSingleton.orchestrateRequest<{
			aiConversations: AiConversationSections;
		}>({
			method: 'GET',
			url: '/advisory-service/api/v1/ai-conversations',
		});
	},
	sendQuery(data: { query: string; aiConversationId?: string }) {
		return httpSingleton.orchestrateRequest<{ query: ConversationQuery }>({
			method: 'POST',
			url: '/advisory-service/api/v1/ai-conversation/query',
			data,
		});
	},
	getConversation(converstaionId: string) {
		return httpSingleton.orchestrateRequest<{ aiConversation: AiConversation }>({
			method: 'GET',
			url: `/advisory-service/api/v1/ai-conversation/${converstaionId}`,
		});
	},
	rateConversationQuery(data: {
		conversationQueryId: string;
		feedbackRatingId: FEEDBACK_RATING_ID;
		reason?: string;
	}) {
		return httpSingleton.orchestrateRequest<void>({
			method: 'POST',
			url: '/advisory-service/api/v1/ai-conversation/feedback',
			data,
		});
	},
};
