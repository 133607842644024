import React, { useEffect, useRef } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

export const ExpandingTextarea = ({ ...props }: FormControlProps) => {
	const textareaRef = useRef<HTMLElement | null>(null);

	useEffect(() => {
		if (!textareaRef.current) {
			return;
		}

		textareaRef.current.style.height = '0px';
		const scrollHeight = textareaRef.current.scrollHeight;
		textareaRef.current.style.height = `${scrollHeight + 2}px`;
	}, [props.value]);

	return <Form.Control ref={textareaRef} as="textarea" {...props} />;
};
