import { httpSingleton } from '@/lib/http-client';
import { constructUrl } from '@/lib/utils';
import { EventModel, FormOption, PaginatedResponseModel, ProgramModel } from '@/lib/models';

interface GetEventsQueryParameters {
	page?: string;
	size?: number;
	programId?: string;
}

interface GetAllEventsQueryParameters extends GetEventsQueryParameters {
	start?: string;
	end?: string;
	searchQuery?: string;
	sortField?: string;
	sortDirection?: string;
}
export const eventsService = {
	getAllEvents(queryParameters?: GetAllEventsQueryParameters) {
		return httpSingleton.orchestrateRequest<PaginatedResponseModel & { events: EventModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/events', queryParameters),
		});
	},
	getUpcomingEvents(queryParameters?: GetEventsQueryParameters) {
		return httpSingleton.orchestrateRequest<PaginatedResponseModel & { events: EventModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/events/upcoming', queryParameters),
		});
	},
	getPastEvents(queryParameters?: GetEventsQueryParameters) {
		return httpSingleton.orchestrateRequest<PaginatedResponseModel & { events: EventModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/events/past', queryParameters),
		});
	},
	getEventbyId(eventId: string) {
		return httpSingleton.orchestrateRequest<EventModel>({
			method: 'GET',
			url: `/advisory-service/api/v1/event/${eventId}`,
		});
	},
	getUpcomingPerformanceStudies(queryParameters?: GetEventsQueryParameters) {
		return httpSingleton.orchestrateRequest<PaginatedResponseModel & { events: EventModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/events/performance-studies/upcoming', queryParameters),
		});
	},
	getPastPerformanceStudies(queryParameters?: GetEventsQueryParameters) {
		return httpSingleton.orchestrateRequest<PaginatedResponseModel & { events: EventModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/events/performance-studies/past', queryParameters),
		});
	},
	getRelevantPerformanceStudyProgramOptions(isPast?: boolean) {
		return httpSingleton.orchestrateRequest<{ programs: ProgramModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/event/performance-studies/programs', { isPast }),
		});
	},
	getRelevantEventProgramOptions(isPast?: boolean) {
		return httpSingleton.orchestrateRequest<{ programs: ProgramModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/event/programs', { isPast }),
		});
	},
	getEventFilters() {
		return httpSingleton.orchestrateRequest<{ sortFields: FormOption[] }>({
			method: 'GET',
			url: '/advisory-service/api/v1/event-filter',
		});
	},
	getNearestPerformanceStudy() {
		return httpSingleton.orchestrateRequest<EventModel>({
			method: 'GET',
			url: '/advisory-service/api/v1/events/performance-studies/nearest',
		});
	},
};
