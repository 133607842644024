import React from 'react';
import { Link } from 'react-router-dom';

import { createUseThemedStyles } from '@/hooks';
import { ReactComponent as ClockIcon } from '@/assets/icons/icon-clock.svg';
import { ReactComponent as StarIcon } from '@/assets/icons/icon-star.svg';

const useStyles = createUseThemedStyles((theme) => ({
	imageOuter: {
		width: '100%',
		marginBottom: 16,
		paddingBottom: '70%',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.colors.gray200,
	},
	link: {
		display: 'block',
		color: theme.colors.white,
		'&:hover': {
			color: theme.colors.white,
		},
	},
}));

interface DashboardRecommendationProps {
	imageSrc: string;
	type: string;
	to: string;
	title: string;
	recentlyPublished?: boolean;
	recommendedByAdvisor?: boolean;
}

export const DashboardRecommendation = ({
	imageSrc,
	type,
	to,
	title,
	recentlyPublished,
	recommendedByAdvisor,
}: DashboardRecommendationProps) => {
	const classes = useStyles();

	return (
		<Link to={to} className={classes.link}>
			<div className={classes.imageOuter} style={{ backgroundImage: `url(${imageSrc})` }} />
			<h4 className="mb-2 text-white">{type}</h4>
			{recentlyPublished && (
				<div className="mb-2 d-flex align-items-center">
					<ClockIcon className="mr-2 flex-shrink-0 text-success" />
					<p className="mb-0 small text-white">Recently published</p>
				</div>
			)}
			{recommendedByAdvisor && (
				<div className="mb-2 d-flex align-items-center">
					<StarIcon className="mr-2 flex-shrink-0 text-warning" />
					<p className="mb-0 small text-white">Recommended by your advisor</p>
				</div>
			)}
			<p className="mb-0 text-white font-weight-bold">{title}</p>
		</Link>
	);
};
