import React, { FC, createContext, useState } from 'react';
import { GetNavigationResponseModel } from '@/lib/models';

interface NavigationConfigContextConfig {
	navigationConfig?: GetNavigationResponseModel;
	setNavigationConfig: React.Dispatch<React.SetStateAction<GetNavigationResponseModel | undefined>>;
}

export const NavigationConfigContext = createContext({} as NavigationConfigContextConfig);

export const NavigationConfigProvider: FC = ({ children }) => {
	const [navigationConfig, setNavigationConfig] = useState<GetNavigationResponseModel>();

	const value = {
		navigationConfig,
		setNavigationConfig,
	};

	return <NavigationConfigContext.Provider value={value}>{children}</NavigationConfigContext.Provider>;
};
