import React, { FC, createContext, useState } from 'react';

import { AccountModel } from '@/lib/models';

interface AccountContextConfig {
	account: AccountModel | undefined;
	setAccount: React.Dispatch<React.SetStateAction<AccountModel | undefined>>;
}

export const AccountContext = createContext({} as AccountContextConfig);

export const AccountProvider: FC = ({ children }) => {
	const [account, setAccount] = useState<AccountModel | undefined>(undefined);

	const value = {
		account,
		setAccount,
	};

	return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};
