import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { Helmet } from 'react-helmet';
import { ReportListItem } from '@/lib/models';
import { jasperServices } from '@/lib/services';
import { AsyncPage } from '@/components';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const Reports = () => {
	const [reportList, setReportList] = useState<ReportListItem[]>([]);

	const fetchData = useCallback(async () => {
		const reportItems = await jasperServices.getReportList().fetch();
		setReportList(reportItems.reports);
	}, []);

	return (
		<AsyncPage fetchData={fetchData}>
			<Helmet>
				<title>Hackett Connect - Client Management - Reports</title>
			</Helmet>
			<Container className="py-7">
				<Row className="mb-11">
					<Col>
						<h1 className="mb-0 text-primary">Reports</h1>
					</Col>
				</Row>

				{reportList.length > 0 ? (
					reportList.map((report) => {
						return (
							<Row key={report.reportId} className="mb-4">
								<Col>
									<Link to={`/client-management/reports/${report.reportId}`}>
										<ResponsiveEllipsis text={report.name ?? ''} maxLine="3" component="h3" />
									</Link>
									{report.description && (
										<ResponsiveEllipsis text={report.description ?? ''} maxLine="3" component="p" />
									)}
								</Col>
							</Row>
						);
					})
				) : (
					<Row>
						<Col>
							<p className="mt-6 text-muted text-center">
								<strong>No Results</strong>
							</p>
						</Col>
					</Row>
				)}
			</Container>
		</AsyncPage>
	);
};
