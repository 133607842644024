import React from 'react';
import {
	Account,
	AdvisorInquiries,
	AdvisorInquiriesDashboard,
	AdvisorInquiriesDetailEdit,
	AdvisoryTeam,
	BestPracticeDetail,
	BestPractices,
	Bookmarks,
	ClientInquiries,
	CompleteProfile,
	Contact,
	CreateInquiry,
	DashboardV2,
	Deliverables,
	DeliverablesCreate,
	Engagement,
	Events,
	InquiryDetailReadOnly,
	NoMatch,
	PerformanceMetrics,
	PerformanceMetricsDetail,
	Reports,
	ReportDetail,
	Research,
	ResearchDetail,
	Search,
	SignIn,
	Version,
	Maintenance,
	ProgramBundle,
	InquiryMarketing,
	UseCases,
	UseCaseDetail,
	VendorEnablement,
	MarketIntelligence,
	AskHackettAi,
} from '@/pages';

import { HeaderNoAuth, Header } from '@/components';
import { Redirect } from 'react-router-dom';

export const routes = [
	{
		path: '/sign-in',
		exact: true,
		private: false,
		header: HeaderNoAuth,
		main: SignIn,
	},
	{
		path: '/complete-profile',
		exact: true,
		private: true,
		header: HeaderNoAuth,
		main: CompleteProfile,
	},
	{
		path: '/',
		exact: true,
		private: true,
		header: Header,
		main: DashboardV2,
	},
	{
		path: '/search',
		exact: true,
		private: true,
		header: Header,
		main: Search,
	},
	{
		path: '/search/:query',
		exact: true,
		private: true,
		header: Header,
		main: Search,
	},
	{
		path: '/program-bundle/market-intelligence',
		exact: true,
		private: true,
		header: Header,
		main: MarketIntelligence,
	},
	{
		path: '/program-bundle/vendor-enablement',
		exact: true,
		private: true,
		header: Header,
		main: VendorEnablement,
	},
	{
		path: '/program-bundle/:bundleId',
		exact: true,
		private: true,
		header: Header,
		main: ProgramBundle,
	},
	{
		path: '/research',
		exact: true,
		private: true,
		header: Header,
		main: Research,
	},
	{
		path: '/research/:documentId',
		exact: true,
		private: true,
		header: Header,
		main: ResearchDetail,
	},
	{
		path: '/best-practices',
		exact: true,
		private: true,
		header: Header,
		main: BestPractices,
	},
	{
		path: '/best-practices/:bestPracticeId',
		exact: true,
		private: true,
		header: Header,
		main: BestPracticeDetail,
	},
	{
		path: '/performance-metrics',
		exact: true,
		private: true,
		header: Header,
		main: PerformanceMetrics,
	},
	{
		path: '/performance-metrics/:performanceMetricId',
		exact: true,
		private: true,
		header: Header,
		main: PerformanceMetricsDetail,
	},
	{
		path: '/engagement',
		exact: false,
		private: true,
		header: Header,
		main: Engagement,
	},
	{
		path: '/events',
		exact: false,
		private: true,
		header: Header,
		main: Events,
	},
	{
		path: '/client-management',
		exact: true,
		private: true,
		header: Header,
		main: () => <Redirect to="/client-management/inquiries-dashboard" />,
	},
	{
		path: '/client-management/inquiries-dashboard',
		exact: true,
		private: true,
		header: Header,
		main: AdvisorInquiriesDashboard,
	},
	{
		path: '/client-management/inquiries',
		exact: true,
		private: true,
		header: Header,
		main: AdvisorInquiries,
	},
	{
		path: '/client-management/inquiries/:inquiryId',
		exact: true,
		private: true,
		header: Header,
		main: InquiryDetailReadOnly,
	},
	{
		path: '/client-management/inquiries/:inquiryId/edit',
		exact: true,
		private: true,
		header: Header,
		main: AdvisorInquiriesDetailEdit,
	},
	{
		path: '/client-management/deliverables',
		exact: true,
		private: true,
		header: Header,
		main: Deliverables,
	},
	{
		path: '/client-management/deliverables/create',
		exact: true,
		private: true,
		header: Header,
		main: DeliverablesCreate,
	},
	{
		path: '/client-management/deliverables/:deliverableId',
		exact: true,
		private: true,
		header: Header,
		main: DeliverablesCreate,
	},
	{
		path: '/client-management/reports',
		exact: true,
		private: true,
		header: Header,
		main: Reports,
	},
	{
		path: '/client-management/reports/:reportId',
		exact: true,
		private: true,
		header: Header,
		main: ReportDetail,
	},
	{
		path: '/client-management/ask-hackett-ai',
		exact: true,
		private: true,
		header: Header,
		main: AskHackettAi,
	},
	{
		path: '/inquiries',
		exact: true,
		private: true,
		header: Header,
		main: ClientInquiries,
	},
	{
		path: '/inquiries/learn-more',
		exact: true,
		private: true,
		header: Header,
		main: InquiryMarketing,
	},
	{
		path: '/inquiries/:inquiryId',
		exact: true,
		private: true,
		header: Header,
		main: InquiryDetailReadOnly,
	},
	{
		path: '/contact',
		exact: true,
		private: true,
		header: Header,
		main: Contact,
	},
	{
		path: '/contact/create-inquiry',
		exact: true,
		private: true,
		header: Header,
		main: CreateInquiry,
	},
	{
		path: '/advisory-team',
		exact: true,
		private: true,
		header: Header,
		main: AdvisoryTeam,
	},
	{
		path: '/account',
		exact: false,
		private: true,
		header: Header,
		main: Account,
	},
	{
		path: '/bookmarks',
		exact: true,
		private: true,
		header: Header,
		main: Bookmarks,
	},
	{
		path: '/use-cases',
		exact: true,
		private: true,
		header: Header,
		main: UseCases,
	},
	{
		path: '/use-cases/:useCaseId',
		exact: true,
		private: true,
		header: Header,
		main: UseCaseDetail,
	},
	{
		path: '/version',
		exact: true,
		private: true,
		header: Header,
		main: Version,
	},
	{
		path: '/maintenance',
		exact: true,
		private: false,
		header: HeaderNoAuth,
		main: Maintenance,
	},
	{
		path: '*',
		exact: false,
		private: false,
		header: Header,
		main: NoMatch,
	},
];
