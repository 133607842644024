import { httpSingleton } from '@/lib/http-client';
import {
	AccountModel,
	DOCUMENT_UPLOAD_STATUS_ID,
	FormOption,
	InquiryModel,
	InquiryStat,
	INQUIRY_STATUS_ID,
	PaginatedResponseModel,
	PresignedUploadModel,
} from '@/lib/models';
import { constructUrl } from '@/lib/utils';

interface GetInquiryFormOptionsResponseBody {
	accountManagers: FormOption[];
	accounts: FormOption[];
	advisors: FormOption[];
	clients: FormOption[];
	createAccountUrl: string;
	grcAdvisors: FormOption[];
	inquiryStatus: FormOption[];
	inquiryType: FormOption[];
	programs: FormOption[];
	topics: FormOption[];
	shouldShowNotes: boolean;
	reachedInquiryLimit: boolean;
}

interface CreateInquiryRequestBody {
	clientId?: string;
	requestingAccountId?: string;
	programId: string;
	inquiryTypeId: string;
	topicIds?: string[];
	inquiryTitle: string;
	inquiryText: string;
	notes?: string;
	requiresImmediateResponse?: boolean;
}

interface GetInquiriesQueryParameters {
	advisorId?: string;
	clientId?: string;
	page?: string;
	programId?: string;
	query?: string;
	size?: string;
	sortDirection?: string;
	sortField?: string;
	status?: INQUIRY_STATUS_ID[];
	type?: string;
	topicIds?: string[];
	requestNumber?: string;
	fromDate?: string;
	toDate?: string;
}

interface GetInquiryFiltersResponseBody {
	accountManagers: FormOption[];
	advisors: FormOption[];
	clients: FormOption[];
	programs: FormOption[];
	grcAdvisors: FormOption[];
	status: FormOption[];
	type: FormOption[];
	topics: FormOption[];
	isOrCondition: FormOption[];
}

interface UpdateInquiryRequestBody {
	accountManagerAccountId?: string;
	advisorAccountId?: string;
	grcAdvisorAccountId?: string;
	inquiryId: string;
	inquiryStatus?: string;
	inquiryTypeId?: string;
	programId?: string;
	grcAdvisorAccount?: string;
	responseText?: string;
	uploadedDocumentIds: string[];
	topicIds?: string[];
	inquiryAccountIds?: string[];
	inquiryCcIds?: string[];
	inquiryTitle?: string;
	inquiryText?: string;
	notes?: string;
	isRecipientNotificationRequired?: boolean;
	requesterNotes?: string;
	isShowAdvisorEmail?: boolean;
	isShowAdvisorBookingsUrl?: boolean;
}

interface GetPresignedUploadRequestBody {
	inquiryId: string;
	contentType: string;
	title: string;
}

interface UpdateInquiryDocumentStatusRequestBody {
	documentUploadStatusId: DOCUMENT_UPLOAD_STATUS_ID;
	inquiryId: string;
	inquiryDocumentId: string;
}

interface GetInquiryStatsResponseBody {
	myStats: InquiryStat;
	teamStats: InquiryStat[];
	flagShowMyStats: boolean;
}

export const inquiryService = {
	getInquiryFormOptions(queryParameters?: {
		accountId?: string;
		clientId?: string;
		inquiryId?: string;
		programId?: string;
	}) {
		return httpSingleton.orchestrateRequest<GetInquiryFormOptionsResponseBody>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/inquiry-form', queryParameters),
		});
	},
	createInquiry(data: CreateInquiryRequestBody) {
		return httpSingleton.orchestrateRequest<{ inquiry: InquiryModel }>({
			method: 'POST',
			url: `/advisory-service/api/v1/inquiry`,
			data,
		});
	},
	getInquiries(queryParameters?: GetInquiriesQueryParameters) {
		return httpSingleton.orchestrateRequest<PaginatedResponseModel & { inquiries: InquiryModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/inquiry', queryParameters),
		});
	},
	getInquiryFilters() {
		return httpSingleton.orchestrateRequest<GetInquiryFiltersResponseBody>({
			method: 'GET',
			url: '/advisory-service/api/v1/inquiry-filters',
		});
	},
	getInquiryDetails(inquiryId: string) {
		return httpSingleton.orchestrateRequest<{ inquiry: InquiryModel }>({
			method: 'GET',
			url: `/advisory-service/api/v1/inquiry/${inquiryId}`,
		});
	},
	updateInquiry(data: UpdateInquiryRequestBody) {
		return httpSingleton.orchestrateRequest<{ inquiry: InquiryModel }>({
			method: 'PUT',
			url: '/advisory-service/api/v1/inquiry',
			data,
		});
	},
	postNotesRequester(data: UpdateInquiryRequestBody) {
		return httpSingleton.orchestrateRequest<{ inquiry: InquiryModel }>({
			method: 'PUT',
			url: '/advisory-service/api/v1/inquiry-notes',
			data,
		});
	},
	getPresignedDocumentUpload(data: GetPresignedUploadRequestBody) {
		return httpSingleton.orchestrateRequest<{ upload: PresignedUploadModel }>({
			method: 'POST',
			url: `/advisory-service/api/v1/inquiry-document`,
			data,
		});
	},
	updateInquiryDocumentStatus(data: UpdateInquiryDocumentStatusRequestBody) {
		return httpSingleton.orchestrateRequest<any>({
			method: 'PUT',
			url: '/advisory-service/api/v1/inquiry-document/status',
			data,
		});
	},
	getInquiryStatFilters() {
		return httpSingleton.orchestrateRequest<{ advisorTypes: FormOption[] }>({
			method: 'GET',
			url: `/advisory-service/api/v1/inquiry-stat-filters`,
		});
	},
	getInquiryStats(queryParameters?: { advisorType?: string }) {
		return httpSingleton.orchestrateRequest<GetInquiryStatsResponseBody>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/inquiry-stats', queryParameters),
		});
	},
	getAccounts(queryParameters?: { clientId?: string }) {
		return httpSingleton.orchestrateRequest<{ accounts: AccountModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/inquiry/accounts', queryParameters),
		});
	},
	resendEmail(inquiryId: string) {
		return httpSingleton.orchestrateRequest<any>({
			method: 'POST',
			url: `/advisory-service/api/v1/inquiry/${inquiryId}/resend`,
		});
	},
	deleteInquiry(inquiryId: string) {
		return httpSingleton.orchestrateRequest<any>({
			method: 'DELETE',
			url: `/advisory-service/api/v1/inquiry/${inquiryId}`,
		});
	},
};
