import React from 'react';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

interface MeterUseStyleProps {
	value: number;
	max: number;
	pill?: boolean;
	size?: 'sm' | 'md' | 'lg';
	showBackground?: boolean;
	variant?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'info' | 'danger';
}

const useStyles = createUseThemedStyles((theme) => ({
	title: {
		display: 'block',
		marginBottom: 15,
		textTransform: 'uppercase',
		color: theme.colors.gray600,
		...theme.fonts.openSansSemiBold,
	},
	label: {
		marginLeft: 20,
		marginBottom: 0,
		...theme.fonts.openSansBold,
	},
	meter: ({ pill, size, showBackground }: MeterUseStyleProps) => ({
		height: 12,
		display: 'flex',
		alignItems: 'center',
		borderRadius: pill ? 500 : 0,
		...(size === 'sm' ? { height: 12 } : {}),
		...(size === 'md' ? { height: 24 } : {}),
		...(size === 'lg' ? { height: 44 } : {}),
		backgroundColor: showBackground ? theme.colors.gray500 : 'transparent',
	}),
	fill: ({ value, max, pill, variant }: MeterUseStyleProps) => ({
		height: '100%',
		transition: '0.2s width',
		borderRadius: pill ? 500 : 0,
		width: `${(value / max) * 100}%`,
		backgroundColor: theme.colors.primary,
		...(variant === 'primary' ? { backgroundColor: theme.colors.primary } : {}),
		...(variant === 'secondary' ? { backgroundColor: theme.colors.secondary } : {}),
		...(variant === 'tertiary' ? { backgroundColor: theme.colors.tertiary } : {}),
		...(variant === 'success' ? { backgroundColor: theme.colors.success } : {}),
		...(variant === 'warning' ? { backgroundColor: theme.colors.warning } : {}),
		...(variant === 'info' ? { backgroundColor: theme.colors.info } : {}),
		...(variant === 'danger' ? { backgroundColor: theme.colors.danger } : {}),
	}),
}));

interface MeterProps extends MeterUseStyleProps {
	title?: string;
	label?: string;
	className?: string;
}

export const Meter = ({ value, max, pill, size, showBackground, variant, title, label, className }: MeterProps) => {
	const classes = useStyles({
		value,
		max,
		pill,
		size,
		showBackground,
		variant,
	});

	return (
		<>
			{title && <small className={classes.title}>{title}</small>}
			<div className={classNames(classes.meter, className)}>
				<div className={classes.fill} />
				{label && <p className={classes.label}>{label}</p>}
			</div>
		</>
	);
};
