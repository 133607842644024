import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { createUseThemedStyles } from '@/hooks';

import { ReactComponent as ConsultationsIcon } from '@/assets/icons/hackett-consultations-icon.svg';
import { ReactComponent as ResearchReportsIcon } from '@/assets/icons/hackett-research-reports-icon.svg';
import { ReactComponent as CustomContentIcon } from '@/assets/icons/hackett-custom-content-icon.svg';

const useStyles = createUseThemedStyles((theme) => ({
	iconOuter: {
		width: 160,
		height: 'auto',
		display: 'block',
		margin: '0 auto 8px',
	},
}));

export const VendorEnablement = () => {
	const classes = useStyles();

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Vendor Enablement</title>
			</Helmet>

			<Container className="bg-white" fluid>
				<Container className="py-11">
					<Row>
						<Col>
							<h1 className="mb-4 text-primary">Vendor Enablement Program</h1>
							<p>
								This program is designed to help <strong>software and service vendors</strong> stand out
								from your competitors and gain visibility with potential buyers. Our insights, curated
								from our many years of measuring top performance, help identify the buying decision
								factors most important to your current and future clients.
							</p>
							<p className="mb-0">
								The program provides clarity on what decision-makers in your market really value and how
								you can deliver that value better than the competition. Through the combination of
								empirical, data-driven insights and a cutting-edge method of competitive evaluation, The
								Hackett Group positions you to accelerate sales.
							</p>
						</Col>
					</Row>
				</Container>
			</Container>

			<Container className="bg-gray-100" fluid>
				<Container className="py-11">
					<Row className="mb-4">
						<Col>
							<h2 className="mb-4 text-primary">Program Deliverables</h2>
							<p className="mb-0">Members of the Vendor Enablement Program has access to:</p>
						</Col>
					</Row>
					<Row>
						<Col lg={4} className="mb-4">
							<ConsultationsIcon className={classNames(classes.iconOuter, 'text-danger')} />
							<h4 className="mb-2 text-uppercase text-danger text-center">Consultations</h4>
							<p className="mb-0 text-center">
								Regular consultations with The Hackett Group's analysts who can provide deeper knowledge
								of your competency area, market and competitors, as well as advice on product road maps
								and service delivery design
							</p>
						</Col>
						<Col lg={4} className="mb-4">
							<ResearchReportsIcon className={classNames(classes.iconOuter, 'text-secondary')} />
							<h4 className="mb-2 text-uppercase text-secondary text-center">Research Reports</h4>
							<p className="mb-0 text-center">
								Relevant research reports, including full versions of the Digital World Class Matix™
								report process-level best practices and emerging trends
							</p>
						</Col>
						<Col lg={4} className="mb-4">
							<CustomContentIcon className={classNames(classes.iconOuter, 'text-warning')} />
							<h4 className="mb-2 text-uppercase text-warning text-center">Custom Content</h4>
							<p className="mb-0 text-center">
								Vendor profiles made available to members of The Hackett Group's Advisory member
								community. Bespoke content such as blog posts and case studies. Our participation in
								your events, and your sponsorship or participation in our events
							</p>
						</Col>
					</Row>
				</Container>
			</Container>
		</>
	);
};
