import { httpSingleton } from '@/lib/http-client';
import { constructUrl } from '@/lib/utils';
import { SearchResultModel, FormOption, ResultItemModel } from '@/lib/models';

export const searchService = {
	getSearchSuggestions(queryParameters?: { query?: string }) {
		return httpSingleton.orchestrateRequest<{ suggestions: ResultItemModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/search/autocomplete', queryParameters),
		});
	},
	getSearchResults(queryParameters?: { filter?: string; from?: string; query?: string; searchSort?: string }) {
		return httpSingleton.orchestrateRequest<SearchResultModel>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/search', queryParameters),
		});
	},
	getSearchFilters() {
		return httpSingleton.orchestrateRequest<{ searchFilters: FormOption[] }>({
			method: 'GET',
			url: '/advisory-service/api/v1/search/filters',
		});
	},
};
