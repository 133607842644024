import React from 'react';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles((theme) => ({
	filterTabs: {
		display: 'flex',
		borderBottom: `1px solid ${theme.colors.gray300}`,
	},
	tab: {
		border: 0,
		marginRight: 32,
		...theme.fonts.s,
		padding: '20px 0',
		appearance: 'none',
		position: 'relative',
		backgroundColor: 'transparent',
		'&:last-child': {
			marginRight: 0,
		},
	},
	activeTab: {
		...theme.fonts.openSansBold,
		'&:after': {
			content: '""',
			display: 'block',
			position: 'absolute',
			left: 0,
			right: 0,
			bottom: 0,
			height: 2,
			backgroundColor: theme.colors.primary,
		},
	},
}));

interface FilterTab {
	title: string;
	value: string;
}

interface FilterTabProps {
	className?: string;
	tabs: FilterTab[];
	value: string;
	onChange(value: string): void;
}

export const FilterTabs = ({ className, tabs, value, onChange }: FilterTabProps) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.filterTabs, className)}>
			{tabs.map((tab, index) => {
				return (
					<button
						key={index}
						className={classNames({
							[classes.tab]: true,
							[classes.activeTab]: value === tab.value,
						})}
						onClick={() => {
							onChange(tab.value);
						}}
					>
						{tab.title}
					</button>
				);
			})}
		</div>
	);
};
