import React, { FC } from 'react';
import { Badge, Button } from 'react-bootstrap';

import { createUseThemedStyles } from '@/hooks';
import { Select } from '@/components/select';

import { boxShadow } from '@/jss';
import { ReactComponent as XIcon } from '@/assets/icons/icon-x.svg';

const useStyles = createUseThemedStyles((theme) => ({
	selectedInterestsContainer: {
		...boxShadow,
	},
	selectedInterestRow: {
		display: 'flex',
		padding: '20px 30px',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.white,
		borderBottom: `1px solid ${theme.colors.gray300}`,
		'&:last-child': {
			borderBottom: 0,
		},
	},
	xIcon: {
		width: 24,
		height: 24,
		display: 'flex',
		fill: theme.colors.white,
	},
}));

export const SelectedInterestsContainer: FC = ({ children }) => {
	const classes = useStyles();

	return <div className={classes.selectedInterestsContainer}>{children}</div>;
};

interface SelectedInterestRowProps {
	title: string;
	onRemoveButtonClick(): void;
	category: string;
	priority: string;
	onPriorityChange(): void;
}

export const SelectedInterestsRow = ({
	title,
	onRemoveButtonClick,
	category,
	priority,
	onPriorityChange,
}: SelectedInterestRowProps) => {
	const classes = useStyles();

	return (
		<div className={classes.selectedInterestRow}>
			<div>
				<Badge className="mr-2 pr-2" bg="primary" pill>
					{title}
					<Button className="ml-2" variant="link" onClick={onRemoveButtonClick}>
						<XIcon className={classes.xIcon} />
					</Button>
				</Badge>
				<span className="text-muted font-italic">{category}</span>
			</div>
			<Select value={priority} onChange={onPriorityChange}>
				<option value="HIGH">High</option>
				<option value="MEDIUM">Medium</option>
				<option value="LOW">Low</option>
			</Select>
		</div>
	);
};
