import { flattenDeep } from 'lodash';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import * as Sentry from '@sentry/react';

import {
	AuthReportModel,
	JasperBookmarkModel,
	LinkReportModel,
	VisualizeModel,
	ReportModel,
	ReportExtensionModel,
	ReportParametersModel,
	SourceModel,
	InputControlModel,
	OptionStateModel,
	OutputUrlReportModel,
	OutputUrlErrorReportModel,
} from '@/lib/models';
import { useAlert, useHandleError } from '@/hooks';
import { jasperServices } from '@/lib/services';
import {
	AsyncPage,
	Loader,
	ReportPagination,
	ReportOptions,
	ReportInputControlsModal,
	LoadingButton,
} from '@/components';
import { Helmet } from 'react-helmet';

export const ReportDetail = () => {
	const handleError = useHandleError();
	const { showAlert } = useAlert();
	const { reportId } = useParams<{ reportId: string }>();
	const [isReportInProgress, setReportInProgress] = useState(false);
	const [inputControlsAreLoading, setInputControlsAreLoading] = useState(false);
	const [isFirstRender, setIsFirstRender] = useState(true);

	const [bookmarks, setBookmarks] = useState<JasperBookmarkModel[]>([]);
	const [currentBookmark, setCurrentBookmark] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [report, setReport] = useState<ReportModel>();
	const [reportTitle, setReportTitle] = useState('');
	const [inputControls, setInputControls] = useState<InputControlModel[]>([]);
	const [downloadReportExtensions, setDownloadReportExtensions] = useState<ReportExtensionModel[]>();
	const [currentReportOutput, setCurrentReportOutput] = useState('');
	const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
	const [reportHeight, setReportHeight] = useState('auto');
	const [authConfig, setAuthConfig] = useState<AuthReportModel>();

	const reportTimeoutRef = useRef<NodeJS.Timeout>();
	const reportTimeoutDuration = useRef(10000);

	const [filtersModalIsShowing, setFiltersModalIsShowing] = useState(false);

	const [reportResources, setReportResources] = useState<SourceModel>({
		resourceType: '',
		chartParams: {
			master_client_id: [''],
		},
	});

	const [drillDowns, setDrilldowns] = useState<SourceModel[]>([]);

	const fetchData = useCallback(async () => {
		const [reportDetailResponse, authTokenResponse] = await Promise.all([
			jasperServices.getReportDetail(reportId).fetch(),
			jasperServices.getAuthToken().fetch(),
		]);

		setReportTitle(reportDetailResponse.name);
		setDownloadReportExtensions(reportDetailResponse.reportDownloadExtensions);
		setReportResources({
			resourceType: reportDetailResponse.reportUrl,
			chartParams: {
				master_client_id: [reportDetailResponse.masterClientId],
			},
		});
		setAuthConfig({
			authToken: authTokenResponse.jasperToken,
			connecting: false,
			isAuth: false,
			jasperTokenUrl: '/jasper/authenticationToken',
		});
	}, [reportId]);

	useEffect(() => {
		if (!authConfig) {
			return;
		}

		setInputControlsAreLoading(true);

		globalThis
			.visualize({
				scripts: 'optimized-scripts',
				auth: {
					token: encodeURIComponent(authConfig.authToken),
					preAuth: true,
					tokenName: 'pp',
				},
			})
			.done((v: VisualizeModel) => {
				v.inputControls({
					resource: reportResources.resourceType,
					params: reportResources.chartParams,
					success: (data: InputControlModel[]) => {
						if (data.length <= 0) {
							setInputControls([]);
							setInputControlsAreLoading(false);
							setIsFirstRender(false);
							return;
						}

						const rawInputControls = data.map((inputControlItem: InputControlModel) => {
							const value =
								inputControlItem.state.value ||
								(inputControlItem.state.options || [])
									.filter((opt: OptionStateModel) => opt.selected)
									.map((opt: OptionStateModel) => opt.value);

							inputControlItem.state.value =
								Array.isArray(value) || /multi/i.test(inputControlItem.type) ? value : [value];

							if (
								inputControlItem.type === 'bool' &&
								/^(false|true)$/i.test(
									Array.isArray(inputControlItem.state.value)
										? inputControlItem.state.value[0]
										: inputControlItem.state.value
								)
							) {
								inputControlItem.state.value = `${/^true$/i.test(
									Array.isArray(inputControlItem.state.value)
										? inputControlItem.state.value[0]
										: inputControlItem.state.value
								)}`;
							}

							if (inputControlItem.state.value === '~NULL~') {
								inputControlItem.state.value = '';
							}

							return inputControlItem;
						});

						setInputControls(rawInputControls);
						setInputControlsAreLoading(false);
					},
					error: (error: any) => {
						setInputControls([]);
						setInputControlsAreLoading(false);
						handleError(error);
					},
				});
			})
			.fail((_error: any) => {
				const visualizeError = {
					code: 'VISUALIZE_ERROR',
					message: 'Visualize input controls failed to initialize.',
				};

				setInputControls([]);
				handleError(visualizeError);
				setInputControlsAreLoading(false);
			});
	}, [authConfig, handleError, reportResources]);

	const clearReportTimeout = useCallback(() => {
		if (!reportTimeoutRef.current) {
			return;
		}

		clearTimeout(reportTimeoutRef.current);
		reportTimeoutRef.current = undefined;
	}, []);

	const startReportTimeout = useCallback(() => {
		if (reportTimeoutRef.current) {
			clearReportTimeout();
		}

		reportTimeoutRef.current = setTimeout(() => {
			reportTimeoutRef.current = undefined;

			showAlert({
				variant: 'warning',
				children: () => {
					return (
						<p className="mb-0">
							<strong>
								We're sorry, this report is taking longer than usual to load. Please be patient or try
								refreshing the page.
							</strong>
						</p>
					);
				},
			});

			Sentry.captureMessage(`Report has taken longer than ${reportTimeoutDuration.current}ms to load.`, {
				extra: {
					reportResources,
					reportResourcesStringified: JSON.stringify(reportResources),
				},
			});
		}, reportTimeoutDuration.current);
	}, [clearReportTimeout, reportResources, showAlert]);

	useEffect(() => {
		if (!authConfig || isFirstRender) {
			return;
		}

		setReportInProgress(true);
		setReportHeight('auto');
		startReportTimeout();

		globalThis
			.visualize({
				scripts: 'optimized-scripts',
				auth: {
					token: encodeURIComponent(authConfig.authToken),
					preAuth: true,
					tokenName: 'pp',
				},
			})
			.done((v: VisualizeModel) => {
				setReport(
					v.report({
						resource: reportResources.resourceType,
						container: '#jasper-report',
						scale: 'width',
						autoresize: true,
						loadingOverlay: true,
						params: reportResources.chartParams,
						runImmediately: false,
						error: (_error: any) => {
							const visualizeError = {
								code: 'VISUALIZE_ERROR',
								message: 'Report failed to initialize.',
							};

							handleError(visualizeError);
							setReportInProgress(false);
							clearReportTimeout();
						},
						events: {
							reportPartsReady: (bookmarks: JasperBookmarkModel[]) => setBookmarks(bookmarks),
							changeTotalPages: (pages: number) => setTotalPages(pages),
							reportCompleted: (status: string, error: any) => {
								if (status === 'ready') {
									setReportHeight(
										(document.getElementsByClassName('jrPage')?.[0]?.getBoundingClientRect()
											.height as unknown) as string
									);
								} else if (status === 'failed') {
									const visualizeError = {
										code: 'VISUALIZE_ERROR',
										message: 'Report load failed.',
									};

									handleError(visualizeError);
								}

								setReportInProgress(false);
								clearReportTimeout();
							},
						},
						linkOptions: {
							events: {
								click: function(_event: any, link: LinkReportModel) {
									if (!link) {
										return;
									}

									let targetParametersReport: ReportParametersModel = {
										master_client_id: [''],
									};

									switch (link.type) {
										case 'LocalAnchor':
											//'this' is the report itself
											if (link.anchor && this) {
												((this as unknown) as ReportModel)
													.pages({
														anchor: link.anchor,
													})
													.run()
													.fail((error: string) =>
														handleError(
															`${error}, Unexpected error processing a LocalAnchor`
														)
													);
											}
											break;
										case 'ReportExport':
											handleReportDetailsLoading(true);
											if (link.parameters && link.parameters.output) {
												for (let key in link.parameters) {
													if (!Array.isArray(link.parameters[key])) {
														link.parameters[key] = [link.parameters[key]];
													}
												}

												link.parameters.download = [true];

												const innerReport = v.report({
													resource: link.parameters._report[0],
													params: link.parameters,
													runImmediately: false,
													error: (error: string) => handleError(error),
												});

												innerReport.run(() => {
													innerReport.export(
														{
															outputFormat: link.parameters.output[0].replace(
																'NoPag',
																''
															),
															ignorePagination:
																link.parameters.output[0].indexOf('NoPag') !== -1,
														},
														(_link: LinkReportModel) => {
															globalThis.location.href = _link.href
																? _link.href
																: ((_link as unknown) as string);
															handleReportDetailsLoading(false);
														},
														(error: string) => handleError(error)
													);
												});
											}
											break;
										case 'ReportExecution':
											Object.keys(link.parameters)
												.filter((key) => key !== '_report' && key !== 'decorate')
												.map((key) => {
													const linkParameterItem =
														link.parameters[key as keyof ReportParametersModel];
													targetParametersReport[key as keyof ReportParametersModel] =
														typeof linkParameterItem === 'string'
															? [linkParameterItem]
															: linkParameterItem;
													return linkParameterItem;
												});

											// this verification prevents the same drill down
											if (
												JSON.stringify([targetParametersReport, link.resource]) !==
												JSON.stringify([
													reportResources.chartParams,
													reportResources.resourceType,
												])
											) {
												const innerReport: SourceModel = {
													resourceType: link.resource,
													chartParams: targetParametersReport,
													inputControls: [],
												};

												link.resource &&
													setDrilldowns((prevState) => {
														return flattenDeep([...prevState, reportResources]);
													});

												setCurrentPage(1);
												setReportResources(innerReport);
											}
											break;
										case 'Reference':
											link.target === 'Blank'
												? globalThis.open(link.href)
												: (globalThis.location = (link.href as unknown) as Location);
											break;
										default:
											handleError(
												`${link.type} link type is not supported or it is missing some data`
											);
											break;
									}
								},
							},
						},
					})
				);
			})
			.fail((_error: any) => {
				const visualizeError = {
					code: 'VISUALIZE_ERROR',
					message: 'Visualize failed to initialize.',
				};

				handleError(visualizeError);
				setReportInProgress(false);
				clearReportTimeout();
			});
	}, [authConfig, clearReportTimeout, handleError, isFirstRender, reportResources, startReportTimeout]);

	useEffect(() => {
		if (!report) {
			return;
		}

		report.pages(currentPage).run();
	}, [currentPage, currentBookmark, inputControls, report]);

	const handleDownloadReport = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		const outputFormat = target.value;
		if (!outputFormat) {
			return;
		}

		const downloadParams = { ...reportResources.chartParams, ...{ download: [true] } };

		setIsDownloadInProgress(true);

		globalThis.visualize((v: VisualizeModel) => {
			const reportFile = v.report({
				resource: reportResources.resourceType,
				params: downloadParams,
				runImmediately: false,
				error: (err: string) => {
					handleError(`${err} Unexpected chart error`);
					setIsDownloadInProgress(false);
				},
			});

			reportFile.run((_resourceLookups: OutputUrlReportModel) => {
				reportFile.export(
					{
						outputFormat,
						ignorePagination: outputFormat === 'xlsx',
					},
					(url: OutputUrlReportModel) => {
						setCurrentReportOutput(outputFormat);
						setIsDownloadInProgress(false);
						globalThis.location.href = url.href;
					},
					(error: OutputUrlErrorReportModel) => {
						handleError(`${error}, Unexpected error exporting data`);
						setIsDownloadInProgress(false);
						setCurrentReportOutput('');
					}
				);
			});
		});
	};

	const handlePageChange = (targetPage: number) => {
		for (let index = 0; index < bookmarks.length; index++) {
			if (targetPage < bookmarks[index].page) {
				setCurrentBookmark(bookmarks[index - 1].page);
				break;
			}

			if (
				targetPage === bookmarks[index].page ||
				(targetPage > bookmarks[index].page && index === bookmarks.length - 1)
			) {
				setCurrentBookmark(bookmarks[index].page);
				break;
			}
		}

		setCurrentPage(targetPage);
	};

	const handleOnBackButtonClick = () => {
		const newDrillDownList = drillDowns.slice(0, -1);
		const nextReportReference = drillDowns[drillDowns.length - 1];

		setTotalPages(1);
		setCurrentPage(1);
		setDrilldowns(newDrillDownList);
		setReportResources({
			resourceType: nextReportReference.resourceType,
			chartParams: nextReportReference.chartParams,
		});
	};

	const handleBookmarkChange = (targetPage: number) => {
		if (typeof targetPage === 'undefined' || targetPage === null) {
			return;
		}

		const targetBookmarkPage = bookmarks.find((bookmark) => bookmark.page === targetPage);
		targetBookmarkPage && setCurrentPage(targetBookmarkPage.page);
		setCurrentBookmark(targetPage);
	};

	const handleReportDetailsLoading = (loading: boolean) => {
		const reportExportLink = document.querySelectorAll('._jrHyperLink.ReportExport')[0] as HTMLSpanElement;
		if (reportExportLink?.innerText !== 'Details Export' && reportExportLink?.innerText !== 'Export Report') return;

		if (loading) {
			const downloadingWrapper = document.createElement('div');
			const downloadingIcon = document.createElement('div');
			const downloadingAnimation = document.createElement('style');
			const spinAnimation = document.createTextNode(
				`@keyframes rotate {from {transform: rotate(0deg)} to {transform: rotate(360deg)}}`
			);
			downloadingAnimation.appendChild(spinAnimation);
			downloadingWrapper.setAttribute('id', 'downloadingWrapper');
			downloadingWrapper.classList.add('text-center', 'd-flex', 'justify-content-center', 'align-items-center');
			downloadingIcon.setAttribute(
				'style',
				`
				width: 20px;
				height: 20px;
				border-width: 7.84px;
				border-style: solid;
				border-color: rgb(19, 87, 137) rgb(233, 236, 239) rgb(233, 236, 239);
				border-image: initial;
				animation: rotate 2s linear infinite;
				border-radius: 50%;`
			);
			downloadingWrapper.append(downloadingIcon);
			downloadingWrapper.append(downloadingAnimation);
			reportExportLink.parentElement?.append(downloadingWrapper);
			reportExportLink.style.display = 'none';
		} else {
			document.getElementById('downloadingWrapper')?.remove();
			reportExportLink.style.display = 'inline';
		}
	};

	return (
		<AsyncPage fetchData={fetchData}>
			<Helmet>
				<title>Hackett Connect - Client Management - Reports - Report Detail</title>
			</Helmet>
			<ReportInputControlsModal
				inputControls={inputControls}
				reportResources={reportResources}
				show={filtersModalIsShowing}
				onApply={(updatedReportResources) => {
					setIsFirstRender(false);
					setReportResources(updatedReportResources);
					setFiltersModalIsShowing(false);
					handlePageChange(1);
				}}
				onHide={() => {
					setFiltersModalIsShowing(false);
				}}
			/>

			<Container className="py-13">
				<Row className="mb-2">
					<Col>
						<p className="mb-0 text-muted">
							<Link to="/client-management/reports">Reports</Link>
						</p>
					</Col>
				</Row>

				<Row className="mb-6">
					<Col xs={7}>
						<h2 className="mb-0">{reportTitle}</h2>
					</Col>

					<Col xs={5} className="d-flex justify-content-end">
						{!!inputControls.length && (
							<LoadingButton
								isLoading={inputControlsAreLoading}
								variant="primary"
								size="sm"
								className="mr-2"
								onClick={() => {
									setFiltersModalIsShowing(true);
								}}
							>
								Filters
							</LoadingButton>
						)}

						{!isFirstRender && (
							<ReportOptions
								downloadOptions={downloadReportExtensions}
								isDownloadInProgress={isDownloadInProgress}
								currentReportOutput={currentReportOutput}
								handleDownloadReport={handleDownloadReport}
							/>
						)}

						{drillDowns.length > 0 && (
							<LoadingButton
								variant="primary"
								size="sm"
								className="ml-2"
								onClick={handleOnBackButtonClick}
							>
								Back ({drillDowns.length})
							</LoadingButton>
						)}
					</Col>
				</Row>

				<Row className="py-8">
					<Col xs={{ span: 12 }} md={{ span: 4, offset: 4 }}>
						<div className="text-center">
							{isFirstRender && inputControlsAreLoading ? (
								<strong>Please wait...</strong>
							) : !!inputControls.length && isFirstRender ? (
								<>
									<strong>There are no results to show.</strong>
									<p>
										Please click on Filters box and complete any required information to run the
										report
									</p>
								</>
							) : null}
						</div>
					</Col>
				</Row>

				{!isReportInProgress && !isFirstRender && (
					<Row className="mb-5">
						<ReportPagination
							bookmarks={bookmarks}
							currentBookmark={currentBookmark}
							currentPage={currentPage}
							totalPages={totalPages}
							handleBookmarkChange={handleBookmarkChange}
							handlePageChange={handlePageChange}
						/>
					</Row>
				)}

				<Row>
					<Col>
						{isReportInProgress && (
							<Loader className="pt-20 d-flex justify-content-center align-items-center" />
						)}
						<div id="jasper-report" style={{ height: reportHeight }} />
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
