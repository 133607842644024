import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown as ReactBootstrapDropdown } from 'react-bootstrap';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';
import { Dropdown, DropdownMenu, DropdownToggle, NavigationResearchDropdownMenu } from '@/components';

const useStyles = createUseThemedStyles((theme) => ({
	navOuter: {
		padding: '10px 50px',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.colors.info,
		'& nav ul': {
			margin: 0,
			padding: 0,
			height: '100%',
			display: 'flex',
			listStyle: 'none',
			borderLeft: `1px solid rgba(255,255,255,0.4)`,
			'& li': {
				borderRight: `1px solid rgba(255,255,255,0.4)`,
				'& a': {
					height: '100%',
					display: 'flex',
					padding: '0 30px',
					textAlign: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					justifyContent: 'center',
					color: theme.colors.white,
					...theme.fonts.openSansSemiBold,
				},
			},
		},
	},
	dropdownToggle: {
		fontFamily: `${theme.fonts.openSansSemiBold.fontFamily} !important`,
		fontWeight: `${theme.fonts.openSansSemiBold.fontWeight} !important`,
		borderRadius: `0 !important`,
		color: `${theme.colors.white} !important`,
		borderRight: `1px solid rgba(255,255,255,0.4) !important`,
	},
	dropdownMenuOuter: {
		padding: 0,
	},
	dropdownMenu: {
		height: 470,
		display: 'flex',
		'& .dropdown-item': {
			padding: '24px 50px !important',
		},
	},
	dropdownTabs: {
		width: 300,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		borderRight: `1px solid ${theme.colors.gray300}`,
	},
	contactCallout: {
		padding: '32px 50px',
		borderTop: `1px solid ${theme.colors.gray300}`,
	},
	dropdownContent: {
		width: 570,
		overflowY: 'auto',
		padding: '24px 50px',
	},
	filterLink: {
		display: 'block',
		...theme.fonts.s,
		...theme.fonts.openSansSemiBold,
	},
}));

interface NavigationMainProps {
	navigationLinks: Record<string, { to: string; title: string; external?: boolean }[]>;
	className?: string;
}

export const NavigationMain = ({ navigationLinks, className }: NavigationMainProps) => {
	const classes = useStyles();
	const [showDropdown, setShowDropdown] = useState(false);

	return (
		<div className={classNames(classes.navOuter, className)}>
			<nav>
				<ul>
					{navigationLinks.MAIN.map((link, linkIndex) => {
						if (link.to === '/research') {
							return (
								<Dropdown key={linkIndex} show={showDropdown} onToggle={setShowDropdown}>
									<DropdownToggle id="dropdown--research" className={classes.dropdownToggle}>
										Research
									</DropdownToggle>
									<DropdownMenu
										className={classes.dropdownMenuOuter}
										popperConfig={{ strategy: 'fixed' }}
										renderOnMount
									>
										<NavigationResearchDropdownMenu
											onCloseDropdown={() => {
												setShowDropdown(false);
											}}
										/>
									</DropdownMenu>
								</Dropdown>
							);
						}

						return link.external ? (
							<li key={linkIndex}>
								<a
									href={link.to}
									target="_blank"
									rel="noreferrer"
									dangerouslySetInnerHTML={{ __html: link.title }}
								/>
							</li>
						) : (
							<li key={linkIndex}>
								<Link key={linkIndex} to={link.to} dangerouslySetInnerHTML={{ __html: link.title }} />
							</li>
						);
					})}
					{navigationLinks.CLIENT_MANAGEMENT.length > 0 && (
						<Dropdown>
							<DropdownToggle id="dropdown--client-management" className={classes.dropdownToggle}>
								Client Management
							</DropdownToggle>
							<DropdownMenu align="start" popperConfig={{ strategy: 'fixed' }} renderOnMount>
								{navigationLinks.CLIENT_MANAGEMENT.map((link, linkIndex) => {
									return link.external ? (
										<ReactBootstrapDropdown.Item key={linkIndex} href={link.to} target="_blank">
											{link.title}
										</ReactBootstrapDropdown.Item>
									) : (
										<ReactBootstrapDropdown.Item key={linkIndex} as={Link} to={link.to}>
											{link.title}
										</ReactBootstrapDropdown.Item>
									);
								})}
							</DropdownMenu>
						</Dropdown>
					)}
				</ul>
			</nav>
			<Button size="sm" className="text-nowrap" variant="secondary" href="/contact">
				Contact Us
			</Button>
		</div>
	);
};
