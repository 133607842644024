import { httpSingleton } from '@/lib/http-client';
import { BasicProgramModel, ProgramModel } from '@/lib/models/program-models';

export const programSettingService = {
	getPreferences(accountId: string) {
		return httpSingleton.orchestrateRequest<{ programs: ProgramModel[], suppressProgramEmail: boolean }>({
			method: 'GET',
			url: `/advisory-service/api/v1/account/${accountId}/programs`,
		});
	},
	setPreferences(accountId: string, data: BasicProgramModel) {
		return httpSingleton.orchestrateRequest<{ programs: ProgramModel[] }>({
			method: 'PUT',
			url: `/advisory-service/api/v1/account/${accountId}/communication-preferences`,
			data: data,
		});
	},
};
