import Color from 'color';
import { ThemeConfig } from '@/contexts';

const solidButton = (
	textColor: string,
	backgroundColor: string,
	disabledTextColor: string,
	disabledBackgroundColor: string
) => {
	return {
		color: textColor,
		backgroundColor: backgroundColor,
		'&:hover': {
			color: textColor,
			backgroundColor: Color(backgroundColor)
				.lighten(0.16)
				.hex(),
			textDecoration: 'none',
		},
		'&:active': {
			backgroundColor: Color(backgroundColor)
				.darken(0.16)
				.hex(),
		},
		'&:focus': {
			outline: 'none',
			boxShadow: `0 0 0 0.2rem ${Color(backgroundColor)
				.alpha(0.32)
				.rgb()}`,
		},
		'&:disabled': {
			color: disabledTextColor,
			backgroundColor: disabledBackgroundColor,
		},
	};
};

const outlineButton = (activeColor: string, disabledColor: string) => {
	return {
		color: activeColor,
		position: 'relative',
		backgroundColor: 'transparent',
		'&:before': {
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			content: '""',
			position: 'absolute',
			pointerEvents: 'none',
			borderRadius: 'inherit',
			border: `1px solid ${activeColor}`,
		},
		'&:hover': {
			color: activeColor,
			backgroundColor: Color(activeColor)
				.alpha(0.16)
				.string(),
		},
		'&:active': {
			backgroundColor: Color(activeColor)
				.alpha(0.32)
				.string(),
		},
		'&:disabled': {
			color: disabledColor,
			borderColor: disabledColor,
		},
	};
};

export const button = (theme: ThemeConfig) => ({
	'.hackett-button': {
		display: 'inline-flex',
		border: 0,
		borderRadius: 2,
		...theme.fonts.s,
		appearance: 'none',
		padding: '1.2rem 2.4rem',
		letterSpacing: '0.026rem',
		...theme.fonts.openSansBold,
		color: theme.colors.white,
		backgroundColor: theme.colors.primary,
		'&:hover': {
			backgroundColor: Color(theme.colors.primary)
				.lighten(0.16)
				.hex(),
			color: theme.colors.white,
			textDecoration: 'none',
		},
		'&:active': {
			backgroundColor: Color(theme.colors.primary)
				.darken(0.16)
				.hex(),
		},
		'&:focus': {
			outline: 'none',
			boxShadow: `0 0 0 0.2rem ${Color(theme.colors.primary)
				.alpha(0.32)
				.rgb()}`,
		},
		'&:disabled': {
			color: theme.colors.gray500,
			backgroundColor: theme.colors.gray300,
		},

		'&-sm': {
			padding: '0.8rem 2.4rem',
		},

		/* ---------------------------------------------- */
		/* Solid variants */
		/* ---------------------------------------------- */
		'&-secondary': {
			...solidButton(theme.colors.white, theme.colors.secondary, theme.colors.gray500, theme.colors.gray300),
		},
		'&-success': {
			...solidButton(theme.colors.white, theme.colors.success, theme.colors.gray500, theme.colors.gray300),
		},
		'&-warning': {
			...solidButton(theme.colors.white, theme.colors.warning, theme.colors.gray500, theme.colors.gray300),
		},
		'&-danger': {
			...solidButton(theme.colors.white, theme.colors.danger, theme.colors.gray500, theme.colors.gray300),
		},
		'&-info': {
			...solidButton(theme.colors.white, theme.colors.info, theme.colors.gray500, theme.colors.gray300),
		},

		/* ---------------------------------------------- */
		/* Outline variant */
		/* ---------------------------------------------- */
		'&-outline-primary': {
			justifyContent: 'center',
			...outlineButton(theme.colors.primary, theme.colors.gray300),
		},
		'&-outline-primary&:hover': {
			color: theme.colors.primary,
		},
		'&-outline-secondary': {
			...outlineButton(theme.colors.secondary, theme.colors.gray300),
		},
		'&-outline-success': {
			...outlineButton(theme.colors.success, theme.colors.gray300),
		},
		'&-outline-warning': {
			...outlineButton(theme.colors.warning, theme.colors.gray300),
		},
		'&-outline-danger': {
			...outlineButton(theme.colors.danger, theme.colors.gray300),
		},
		'&-outline-info': {
			...outlineButton(theme.colors.info, theme.colors.gray300),
		},

		/* ---------------------------------------------- */
		/* Link variant */
		/* ---------------------------------------------- */
		'&-link': {
			padding: 0,
			borderRadius: 0,
			...theme.fonts.openSansRegular,
			backgroundColor: 'transparent',
			color: `${theme.colors.info} !important`,
			'&:hover': {
				textDecoration: 'underline',
				backgroundColor: 'transparent',
			},
			'&:active': {
				backgroundColor: 'transparent',
			},
			'&:disabled': {
				backgroundColor: 'transparent',
				color: `${theme.colors.gray500} !important`,
				'&:hover': {
					textDecoration: 'none',
				},
			},
		},
	},
});
