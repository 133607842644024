import React, { FC } from 'react';
import { Container, ContainerProps } from 'react-bootstrap';

import { createUseThemedStyles } from '@/hooks';
import Color from 'color';

const useStyles = createUseThemedStyles((theme) => ({
	colorBlockContainer: ({ angle }: { angle: number }) => ({
		backgroundColor: theme.colors.primary,
		backgroundImage: `linear-gradient(${angle}deg, ${Color(theme.colors.primary)
			.lighten(0)
			.hex()} 0%, ${Color(theme.colors.primary)
			.lighten(0)
			.hex()} 50%,  ${Color(theme.colors.primary)
			.lighten(0.2)
			.desaturate(0.2)
			.hex()} 50%,  ${Color(theme.colors.primary)
			.lighten(0.2)
			.desaturate(0.2)
			.hex()} 100%)`,
	}),
}));

interface ColorBlockContainerProps extends ContainerProps {
	angle?: number;
}

export const ColorBlockContainer: FC<ColorBlockContainerProps> = ({ angle, children, ...props }) => {
	const classes = useStyles({
		angle: angle || 16,
	});

	return (
		<div className={classes.colorBlockContainer}>
			<Container {...props}>{children}</Container>
		</div>
	);
};
