import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useHandleError, useQuery } from '@/hooks';
import {
	MultiSelect,
	MultiSelectOption,
	Select,
	flattenParentAndChildIdsForSelection,
	getTopLevelParentAndChildrenIds,
} from '@/components';
import { SORT_DIRECTION, TableCell, TableHeader, TableRenderer, TableRow } from '@/components/table';
import { USE_CASES_TABLE_COLUMN_IDS, useCasesTableColumns, useCasesTableHeaders } from '@/lib/__mocks__';
import { constructUrl, formOptionToMultiSelectOption } from '@/lib/utils';
import { TableRowConfig } from '@/components/table/models';
import { FormOption, UseCase } from '@/lib/models';
import { useCaseService } from '@/lib/services';

export const UseCases = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const handleError = useHandleError();

	const query = useQuery();
	const programId = useMemo(() => query.get('programId') ?? '', [query]);
	const parentBusinessProcessIds = useMemo(() => query.get('parentBusinessProcessIds'), [query]);
	const businessProcessIds = useMemo(() => query.get('businessProcessIds'), [query]);
	const industryIds = useMemo(() => query.get('industryIds') ?? '', [query]);
	const aiToolIds = useMemo(() => query.get('aiToolIds') ?? '', [query]);
	const valueRatingIds = useMemo(() => query.get('valueRatingIds') ?? '', [query]);
	const page = useMemo(() => query.get('page') ?? '0', [query]);
	const size = useMemo(() => query.get('size') ?? '10', [query]);
	const sortDirection = useMemo(() => query.get('sortDirection'), [query]);
	const sortField = useMemo(() => query.get('sortField'), [query]);

	const [filtersAreLoading, setFiltersAreLoading] = useState(false);
	const [programOptions, setProgramOptions] = useState<FormOption[]>([]);
	const [businessProcessOptions, setBusinessProcessOptions] = useState<MultiSelectOption[]>([]);
	const [industryOptions, setIndustryOptions] = useState<FormOption[]>([]);
	const [aiToolOptions, setAiToolOptions] = useState<FormOption[]>([]);
	const [valueRatingOptions, setValueRatingOptions] = useState<FormOption[]>([]);

	const [tableIsLoading, setTableIsLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [totalCountDescription, setTotalCountDescription] = useState('');
	const [tableColumnsData, setTableColumnsData] = useState(useCasesTableColumns);
	const [tableHeadersData] = useState(useCasesTableHeaders);
	const clearSelectionsRef = useRef<() => void>();
	const [tableRowData, setTableRowData] = useState<TableRowConfig<UseCase>[]>([]);

	/* ------------------------------------- */
	/* Must default a programId */
	/* ------------------------------------- */
	useEffect(() => {
		if (!programOptions || programOptions.length <= 0) {
			return;
		}

		if (!programId) {
			history.replace(constructUrl(pathname, { page: '0', programId: programOptions[0].id }, search));
		}
	}, [history, pathname, programId, programOptions, search]);

	const allBpSelections = useMemo(() => {
		return flattenParentAndChildIdsForSelection(
			businessProcessOptions,
			parentBusinessProcessIds?.split('|').filter((s) => s),
			businessProcessIds?.split('|').filter((s) => s)
		);
	}, [businessProcessIds, businessProcessOptions, parentBusinessProcessIds]);

	const fetchFilters = useCallback(async () => {
		try {
			setFiltersAreLoading(true);

			const { programs, businessProcesses, industries, aiTools, valueRatings } = await useCaseService
				.getUseCaseFilters({
					...(programId && { programId }),
					...(parentBusinessProcessIds && { parentBusinessProcessIds }),
					...(businessProcessIds && { businessProcessIds }),
					...(industryIds && { industryIds }),
					...(aiToolIds && { aiToolIds }),
					...(valueRatingIds && { valueRatingIds }),
				})
				.fetch();

			setProgramOptions(programs);
			setBusinessProcessOptions(formOptionToMultiSelectOption(businessProcesses));
			setIndustryOptions(industries);
			setAiToolOptions(aiTools);
			setValueRatingOptions(valueRatings);
		} catch (error) {
			handleError(error);
		} finally {
			setFiltersAreLoading(false);
		}
	}, [aiToolIds, businessProcessIds, handleError, industryIds, parentBusinessProcessIds, programId, valueRatingIds]);

	const fetchData = useCallback(async () => {
		if (!programId) {
			return;
		}
		try {
			setTableIsLoading(true);

			const response = await useCaseService
				.getUseCases({
					...(page && { page }),
					...(size && { size }),
					...(sortField && { sortField }),
					...(sortDirection && { sortDirection }),
					...(programId && { programId }),
					...(parentBusinessProcessIds && { parentBusinessProcessIds }),
					...(businessProcessIds && { businessProcessIds }),
					...(industryIds && { industryIds }),
					...(aiToolIds && { aiToolIds }),
					...(valueRatingIds && { valueRatingIds }),
				})
				.fetch();

			const formattedUseCases = response.useCases.map((uc) => {
				return {
					rowId: uc.useCaseId,
					checked: false,
					expanded: false,
					columnData: uc,
				};
			});

			setTotalCount(response.totalCount);
			setTotalCountDescription(response.totalCountDescription);
			setTableRowData(formattedUseCases);
		} catch (error) {
			handleError(error);
		} finally {
			setTableIsLoading(false);
		}
	}, [
		aiToolIds,
		businessProcessIds,
		handleError,
		industryIds,
		page,
		parentBusinessProcessIds,
		programId,
		size,
		sortDirection,
		sortField,
		valueRatingIds,
	]);

	useEffect(() => {
		fetchFilters();
	}, [fetchFilters]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleBusinessProcessesChange = useCallback(
		(selectedBusinessProcessIds: string[]) => {
			if (clearSelectionsRef.current) {
				clearSelectionsRef.current();
			}

			const { parentIds, remainingChildrenIds } = getTopLevelParentAndChildrenIds(
				businessProcessOptions,
				selectedBusinessProcessIds
			);

			history.replace(
				constructUrl(
					pathname,
					{
						page: '0',
						parentBusinessProcessIds: parentIds,
						businessProcessIds: remainingChildrenIds,
					},
					search
				)
			);
		},
		[businessProcessOptions, history, pathname, search]
	);

	const handleFilterChange = useCallback(
		({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
			clearSelectionsRef.current?.();
			history.replace(
				constructUrl(
					pathname,
					{ page: '0', [currentTarget.name]: currentTarget.value },
					currentTarget.name === 'programId' ? '' : search
				)
			);
		},
		[history, pathname, search]
	);

	const handleTableSort = useCallback(
		(sortKey: string, sortDirection: SORT_DIRECTION) => {
			clearSelectionsRef.current?.();
			history.replace(constructUrl(pathname, { page: '0', sortField: sortKey, sortDirection }, search));
		},
		[history, pathname, search]
	);

	const handlePaginationChange = useCallback(
		({ pageSize, pageIndex }) => {
			history.replace(constructUrl(pathname, { page: String(pageIndex), size: String(pageSize) }, search));
		},
		[history, pathname, search]
	);

	return (
		<>
			<Helmet>
				<title>Hackett Connect - AI Use Cases</title>
			</Helmet>

			<Container className="pt-7">
				<Row>
					<Col>
						<div className="d-flex align-items-center justify-content-between">
							<h1 className="mb-0 text-primary">AI Use Cases</h1>
						</div>
					</Col>
				</Row>
			</Container>
			<Container className="py-7">
				<Row className="mb-4">
					<Col lg={6}>
						<Form.Group>
							<Form.Label>Program</Form.Label>
							<Select
								name="programId"
								value={programId}
								onChange={handleFilterChange}
								disabled={filtersAreLoading}
							>
								{programOptions.map(({ id, display, disabled }) => (
									<option key={id} value={id} disabled={disabled}>
										{display}
									</option>
								))}
							</Select>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col lg={3} className="mb-4">
						<Form.Group>
							<Form.Label>Business Process</Form.Label>
							<MultiSelect
								options={businessProcessOptions}
								selected={allBpSelections}
								onChange={handleBusinessProcessesChange}
							>
								{businessProcessIds ? 'Select' : 'Any'}
							</MultiSelect>
						</Form.Group>
					</Col>
					<Col lg={3} className="mb-4">
						<Form.Group>
							<Form.Label>Industry</Form.Label>
							<Select
								name="industryIds"
								value={industryIds}
								onChange={handleFilterChange}
								disabled={filtersAreLoading}
							>
								<option value="">Any</option>
								{industryOptions.map(({ id, display, disabled }) => (
									<option key={id} value={id} disabled={disabled}>
										{display}
									</option>
								))}
							</Select>
						</Form.Group>
					</Col>
					<Col lg={3} className="mb-4">
						<Form.Group>
							<Form.Label>AI Technology</Form.Label>
							<Select
								name="aiToolIds"
								value={aiToolIds}
								onChange={handleFilterChange}
								disabled={filtersAreLoading}
							>
								<option value="">Any</option>
								{aiToolOptions.map(({ id, display, disabled }) => (
									<option key={id} value={id} disabled={disabled}>
										{display}
									</option>
								))}
							</Select>
						</Form.Group>
					</Col>
					<Col lg={3} className="mb-4">
						<Form.Group>
							<Form.Label>Value Rating</Form.Label>
							<Select
								name="valueRatingIds"
								value={valueRatingIds}
								onChange={handleFilterChange}
								disabled={filtersAreLoading}
							>
								<option value="">Any</option>
								{valueRatingOptions.map(({ id, display, disabled }) => (
									<option key={id} value={id} disabled={disabled}>
										{display}
									</option>
								))}
							</Select>
						</Form.Group>
					</Col>
				</Row>
				<Row className="mb-5">
					<Col>
						<TableRenderer
							isLoading={tableIsLoading}
							tableColumnData={tableColumnsData}
							onTableColumnDataChange={setTableColumnsData}
							tableRowData={tableRowData}
							onTableRowDataChange={setTableRowData}
							tableHeaderRowRenderer={(_checkboxProps, clearSelections) => {
								clearSelectionsRef.current = clearSelections;

								return (
									<TableRow>
										{Object.values(tableColumnsData).map((columnConfig) => {
											if (!columnConfig.isShowing) {
												return null;
											}

											const tableHeader = tableHeadersData[columnConfig.columnId];
											const tableHeaderProps = {
												key: tableHeader.tableHeaderId,
												sortable: tableHeader.isSortable,
												sortKey: tableHeader.tableHeaderId,
												onSort: handleTableSort,
												sortDirection:
													sortField && sortField === tableHeader.tableHeaderId
														? (sortDirection as SORT_DIRECTION)
														: undefined,
											};

											switch (columnConfig.columnId) {
												case USE_CASES_TABLE_COLUMN_IDS.USE_CASES:
													return (
														<TableHeader {...tableHeaderProps}>
															{totalCountDescription} {tableHeader.title}
														</TableHeader>
													);
												default:
													return (
														<TableHeader {...tableHeaderProps}>
															{tableHeader.title}
														</TableHeader>
													);
											}
										})}
									</TableRow>
								);
							}}
							tableBodyRowRenderer={(data, _checkboxProps) => {
								return (
									<TableRow
										key={data.rowId}
										onClick={() => {
											history.push(`/use-cases/${data.rowId}`);
										}}
									>
										{Object.values(tableColumnsData).map((columnConfig) => {
											if (!columnConfig.isShowing) {
												return null;
											}

											const { columnData } = data;

											switch (columnConfig.columnId) {
												case USE_CASES_TABLE_COLUMN_IDS.USE_CASES:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.name}
														</TableCell>
													);
												case USE_CASES_TABLE_COLUMN_IDS.NUMBER:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.useCaseSequence}
														</TableCell>
													);

												case USE_CASES_TABLE_COLUMN_IDS.FUNCTION:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.functionDescription}
														</TableCell>
													);
												case USE_CASES_TABLE_COLUMN_IDS.BUSINESS_PROCESS:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.businessProcessesDescription}
														</TableCell>
													);
												case USE_CASES_TABLE_COLUMN_IDS.AI_TECHNOLOGY:
													return (
														<TableCell key={columnConfig.columnId} width={406}>
															{columnData.aiToolsDescription}
														</TableCell>
													);
												case USE_CASES_TABLE_COLUMN_IDS.VALUE_RATING:
													return (
														<TableCell key={columnConfig.columnId}>
															{columnData.valueRating.name}
														</TableCell>
													);
												default:
													return <TableCell key={columnConfig.columnId}>N/A</TableCell>;
											}
										})}
									</TableRow>
								);
							}}
							pageSize={parseInt(size, 10)}
							pageIndex={parseInt(page, 10)}
							tableRowDataTotal={totalCount}
							onPaginationChange={handlePaginationChange}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};
