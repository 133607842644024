export enum CHALLENGE_NAMES {
	SMS_MFA = 'SMS_MFA',
	SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
	NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
	MFA_SETUP = 'MFA_SETUP',
}

export const challengeNameDescriptions = {
	[CHALLENGE_NAMES.SMS_MFA]: 'SMS MFA',
	[CHALLENGE_NAMES.SOFTWARE_TOKEN_MFA]: 'Software Token MFA',
	[CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED]: 'New Password Required',
	[CHALLENGE_NAMES.MFA_SETUP]: 'MFA Setup',
};

export enum MFA_TYPES {
	TOTP = 'TOTP',
	SMS = 'SMS',
	NOMFA = 'NOMFA',
}

export enum PREFERRED_MFA_TYPES {
	SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
	SMS_MFA = 'SMS_MFA',
	NOMFA = 'NOMFA',
}
