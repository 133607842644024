import React, { FC } from 'react';
import { Dropdown as ReactBootstrapDropdown, DropdownProps } from 'react-bootstrap';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles({
	dropdown: {
		'&:after': {
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			opacity: 0,
			content: "''",
			visibility: 'hidden',
			pointerEvents: 'none',
			backgroundColor: 'rgba(0, 0, 0, 0.16)',
			transition: 'opacity 0.15s ease-in-out',
		},
		'&.show:after': {
			zIndex: 10,
			opacity: 1,
			position: 'fixed',
			visibility: 'visible',
		},
	},
});

export const Dropdown: FC<DropdownProps> = ({ children, className, ...props }) => {
	const classes = useStyles();

	return (
		<ReactBootstrapDropdown className={classNames(classes.dropdown, className)} {...props}>
			{children}
		</ReactBootstrapDropdown>
	);
};
