import React, { useEffect, useState } from 'react';
import Color from 'color';

import { BannerMessage } from '@/lib/models';
import { accountService } from '@/lib/services';
import { SCROLL_DIRECTION, createUseThemedStyles, useHandleError, useScrollDirection } from '@/hooks';
import { LoadingButton } from '@/components';

import { ReactComponent as XIcon } from '@/assets/icons/icon-x.svg';
import { Collapse } from 'react-bootstrap';

const useStyles = createUseThemedStyles(
	(theme) => ({
		welcomeBanner: {
			position: 'relative',
			backgroundColor: theme.colors.success,
			'& .container': {
				position: 'relative',
			},
		},
		messages: {
			padding: '0 15px',
			'@media(min-width: 1175px)': {
				padding: '0 50px',
			},
		},
		message: {
			display: 'flex',
			padding: '1.2rem 0',
			alignItems: 'center',
			justifyContent: 'space-between',
			borderBottom: `1px solid ${Color(theme.colors.white)
				.alpha(0.12)
				.string()}`,
			'& p a': {
				color: theme.colors.white,
				textDecoration: 'underline',
			},
			'&:last-child': {
				borderBottom: 0,
			},
		},
	}),
	{ index: 6 }
);

interface Props {
	bannerMessages: BannerMessage[];
	onBannerMessageRead(bannerMessages: BannerMessage[]): void;
}

export const WelcomeBanner = ({ bannerMessages, onBannerMessageRead }: Props) => {
	const handleError = useHandleError();
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const scrollDirection = useScrollDirection();
	const [isMinified, setIsMinified] = useState(false);

	const handleDismissButtonClick = async (bannerMessage: BannerMessage) => {
		try {
			setIsLoading(true);

			const response = await accountService.markBannerMessageRead(bannerMessage.bannerMessageId).fetch();
			onBannerMessageRead(response.bannerMessages);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (scrollDirection === SCROLL_DIRECTION.DOWN) {
			setIsMinified(true);
		} else {
			setIsMinified(false);
		}
	}, [scrollDirection]);

	return (
		<Collapse in={!isMinified} timeout={300}>
			<div className={classes.welcomeBanner}>
				<div className={classes.messages}>
					{bannerMessages.map((bannerMessage) => {
						return (
							<div key={bannerMessage.bannerMessageId} className={classes.message}>
								<p className="mb-0 text-white">
									{bannerMessage.title}{' '}
									<a href={bannerMessage.linkUrl} target="_blank" rel="noreferrer">
										{bannerMessage.linkDescription}
									</a>
								</p>
								<LoadingButton
									variant="link"
									isLoading={isLoading}
									onClick={() => {
										handleDismissButtonClick(bannerMessage);
									}}
								>
									<XIcon className="text-white" />
								</LoadingButton>
							</div>
						);
					})}
				</div>
			</div>
		</Collapse>
	);
};
