import React, { FC } from 'react';
import { Fade } from 'react-bootstrap';
import classNames from 'classnames';
import ReactMultiCarousel, { ButtonGroupProps } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Color from 'color';

import { screenWidths } from '@/jss';
import { createUseThemedStyles, useTheme } from '@/hooks';

import { ReactComponent as LeftIcon } from '@/assets/icons/icon-carousel-arrow-left.svg';
import { ReactComponent as RightIcon } from '@/assets/icons/icon-carousel-arrow-right.svg';

/* ----------------------------------------------------------- */
/* General config  */
/* ----------------------------------------------------------- */
const gutterSize = 30;
const responsiveConfig = {
	xl: {
		breakpoint: {
			max: 4000,
			min: screenWidths.lg,
		},
		items: 4,
	},
	lg: {
		breakpoint: {
			max: screenWidths.lg,
			min: screenWidths.md,
		},
		items: 3,
	},
	md: {
		breakpoint: {
			max: screenWidths.md,
			min: screenWidths.sm,
		},
		items: 2,
	},
	sm: {
		breakpoint: {
			max: screenWidths.sm,
			min: screenWidths.xs,
		},
		items: 1,
	},
	xs: {
		breakpoint: {
			max: screenWidths.xs,
			min: 0,
		},
		items: 1,
	},
};

/* ----------------------------------------------------------- */
/* Button group component  */
/* ----------------------------------------------------------- */
const useButtonGroupStyles = createUseThemedStyles({
	carouselButton: ({ buttonColor }: { buttonColor: string }) => ({
		top: 0,
		border: 0,
		zIndex: 2,
		padding: 15,
		height: '100%',
		display: 'flex',
		appearance: 'none',
		position: 'absolute',
		alignItems: 'center',
		backgroundColor: Color(buttonColor)
			.alpha(0.2)
			.string(),
		'&:after': {
			top: 0,
			content: '""',
			width: '200%',
			height: '100%',
			position: 'absolute',
			background: `linear-gradient(90deg, ${Color(buttonColor)
				.alpha(0.2)
				.string()} 0%, ${Color(buttonColor)
				.alpha(0)
				.string()} 100%)`,
		},
		'&:hover': {
			backgroundColor: Color(buttonColor)
				.alpha(0.3)
				.string(),
			'&:after': {
				background: `linear-gradient(90deg, ${Color(buttonColor)
					.alpha(0.3)
					.string()} 0%, ${Color(buttonColor)
					.alpha(0)
					.string()} 100%)`,
			},
		},
		'&:active': {
			backgroundColor: Color(buttonColor)
				.alpha(0.1)
				.string(),
			'&:after': {
				background: `linear-gradient(90deg, ${Color(buttonColor)
					.alpha(0.1)
					.string()} 0%, ${Color(buttonColor)
					.alpha(0)
					.string()} 100%)`,
			},
		},
	}),
	carouselButtonPrevious: {
		left: -(gutterSize / 2),
		transform: 'translateX(-100%)',
		borderTopRightRadius: 2,
		borderBottomRightRadius: 2,
		'&:after': {
			right: '100%',
			transform: 'scaleX(-1)',
		},
	},
	carouselButtonNext: {
		right: -(gutterSize / 2),
		transform: 'translateX(100%)',
		borderTopLeftRadius: 2,
		borderBottomLeftRadius: 2,
		'&:after': {
			left: '100%',
		},
	},
});

interface CustomButtonGroupProps extends ButtonGroupProps {
	buttonColor?: string;
}

const CustomButtonGroup = ({ buttonColor, next, previous, carouselState }: CustomButtonGroupProps) => {
	const { theme } = useTheme();
	const classes = useButtonGroupStyles({
		buttonColor: buttonColor || theme.colors.white,
	});

	let noMorePreviousSlides = true;
	let noMoreNextSlides = true;

	if (carouselState) {
		noMorePreviousSlides = carouselState.currentSlide === 0;

		if (carouselState.totalItems - carouselState.slidesToShow < 0) {
			noMoreNextSlides = true;
		} else {
			noMoreNextSlides = carouselState.currentSlide === carouselState.totalItems - carouselState.slidesToShow;
		}
	}

	return (
		<>
			<Fade in={!noMorePreviousSlides}>
				<button
					className={classNames(classes.carouselButton, classes.carouselButtonPrevious)}
					onClick={() => (previous ? previous() : undefined)}
					disabled={noMorePreviousSlides}
				>
					<LeftIcon />
				</button>
			</Fade>
			<Fade in={!noMoreNextSlides}>
				<button
					className={classNames(classes.carouselButton, classes.carouselButtonNext)}
					onClick={() => (next ? next() : undefined)}
					disabled={noMoreNextSlides}
				>
					<RightIcon />
				</button>
			</Fade>
		</>
	);
};

/* ----------------------------------------------------------- */
/* Main component  */
/* ----------------------------------------------------------- */
const useCarouselStyles = createUseThemedStyles({
	containerClass: {
		position: 'relative',
		marginLeft: -(gutterSize / 2),
		marginRight: -(gutterSize / 2),
	},
	sliderClass: {},
	itemClass: {
		padding: `0 ${gutterSize / 2}px`,
	},
});

interface CarouselProps {
	buttonColor?: string;
}

export const Carousel: FC<CarouselProps> = ({ buttonColor, children }) => {
	const classes = useCarouselStyles();

	return (
		<div className="position-relative">
			<ReactMultiCarousel
				containerClass={classes.containerClass}
				sliderClass={classes.sliderClass}
				itemClass={classes.itemClass}
				swipeable={false}
				draggable={false}
				showDots={false}
				ssr={false}
				responsive={responsiveConfig}
				infinite={false}
				autoPlay={false}
				customButtonGroup={<CustomButtonGroup buttonColor={buttonColor} />}
				renderButtonGroupOutside={true}
				arrows={false}
			>
				{children}
			</ReactMultiCarousel>
		</div>
	);
};
