import { range } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';

import { Select } from '@/components';

import { ReactComponent as LeftChevron } from '@/assets/icons/icon-chevron-left.svg';
import { ReactComponent as RightChevron } from '@/assets/icons/icon-chevron-right.svg';
import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles(
	(theme) => ({
		arrowButton: {
			width: 32,
			height: 24,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			'&:disabled': {
				backgroundColor: 'transparent !important',
			},
		},
		paginationButton: {
			width: 32,
			height: 24,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: `${theme.colors.gray500} !important`,
		},
		paginationButtonActive: {
			textDecoration: 'underline',
			color: `${theme.colors.primary} !important`,
		},
	}),
	{ index: 4 }
);

const pageSizeOptions = [
	{
		value: 5,
		title: '5',
	},
	{
		value: 10,
		title: '10',
	},
	{
		value: 15,
		title: '15',
	},
];

interface TablePaginationProps {
	pageSize: number;
	pageIndex: number;
	total: number;
	onChange(values: { pageSize: number; pageIndex: number }): void;
	showPageSize?: boolean;
}

export const TablePagination: FC<TablePaginationProps> = ({
	pageSize,
	pageIndex,
	total,
	onChange,
	showPageSize = true,
}) => {
	const classes = useStyles();

	const totalPages = Math.ceil(total / pageSize);
	const lastPage = totalPages > 0 ? totalPages - 1 : 0;

	const getPageButtonConfig = useCallback(
		(index: number) => {
			return {
				index,
				title: String(index + 1),
				isActive: index === pageIndex,
			};
		},
		[pageIndex]
	);

	const pageButtons = useMemo(() => {
		const blockSize = 5;
		const blockBuffer = 2;
		const firstPage = 0;

		if (totalPages <= blockSize) {
			return range(firstPage, lastPage + 1).map((index) => {
				return getPageButtonConfig(index);
			});
		}

		const currentBlock = range(pageIndex - blockBuffer, pageIndex + 1 + blockBuffer);

		if (currentBlock[0] <= firstPage) {
			return range(firstPage, blockSize + firstPage).map((index) => {
				return getPageButtonConfig(index);
			});
		}

		if (currentBlock[currentBlock.length - 1] >= lastPage) {
			return range(lastPage + 1 - blockSize, lastPage + 1).map((index) => {
				return getPageButtonConfig(index);
			});
		}

		return currentBlock.map((index) => {
			return getPageButtonConfig(index);
		});
	}, [getPageButtonConfig, lastPage, pageIndex, totalPages]);

	return (
		<div className="mt-6 d-flex align-items-center justify-content-between">
			<div className="d-flex align-items-center">
				{showPageSize && (
					<>
						<Form.Label className="mb-0 mr-1">Page Size</Form.Label>
						<Select
							variant="secondary"
							value={String(pageSize)}
							onChange={(event) => {
								onChange({ pageSize: parseInt(event.currentTarget.value, 10), pageIndex: 0 });
							}}
						>
							{pageSizeOptions.map((pageSizeOption) => {
								return (
									<option key={pageSizeOption.value} value={pageSizeOption.value}>
										{pageSizeOption.title}
									</option>
								);
							})}
						</Select>
					</>
				)}
			</div>

			<div className="d-flex">
				<Button
					className={classes.arrowButton}
					variant="link"
					disabled={pageIndex === 0}
					onClick={() => {
						onChange({ pageSize, pageIndex: pageIndex - 1 });
					}}
				>
					<LeftChevron className="d-flex" />
				</Button>
				{pageButtons.map((pageButton) => {
					return (
						<Button
							key={pageButton.index}
							className={classNames(classes.paginationButton, {
								[classes.paginationButtonActive]: pageButton.isActive,
							})}
							variant="link"
							onClick={() => {
								onChange({ pageSize, pageIndex: pageButton.index });
							}}
						>
							{pageButton.title}
						</Button>
					);
				})}
				<Button
					className={classes.arrowButton}
					variant="link"
					disabled={pageIndex === lastPage}
					onClick={() => {
						onChange({ pageSize, pageIndex: pageIndex + 1 });
					}}
				>
					<RightChevron className="d-flex" />
				</Button>
			</div>
		</div>
	);
};
