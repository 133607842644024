import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';
import { Avatar } from '@/components';

const useStyles = createUseThemedStyles((theme) => ({
	contactRequest: {
		display: 'flex',
		padding: '16px 0',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.colors.gray300}`,
		color: 'inherit',
	},
	requestInformation: {
		flex: 1,
		overflow: 'hidden',
	},
}));

interface ContactRequestProps {
	avatarUrl?: string;
	namePrefix?: string;
	name: string;
	nameSupplement?: string;
	time?: string;
	lastMessage?: string;
	className?: string;
	href?: string;
}

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const ContactRequest: FC<ContactRequestProps> = ({
	avatarUrl,
	namePrefix,
	name,
	nameSupplement,
	time,
	lastMessage,
	className,
	href,
	children,
}) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.contactRequest, className)}>
			{avatarUrl && <Avatar className="mr-5" size={64} url={avatarUrl} />}
			<div className={classes.requestInformation}>
				{namePrefix && <p className="mb-1 text-muted">{namePrefix}</p>}
				<div className="mb-1 d-flex justify-content-between align-items-center">
					<p className="mb-0">
						<strong>
							<Link to={href || '/#'}>{`${name}${nameSupplement ? `, ${nameSupplement}` : ''}`}</Link>
						</strong>
					</p>
					{time && <p className="mb-0 text-muted">{time}</p>}
				</div>
				<ResponsiveEllipsis text={lastMessage ?? ''} maxLine="3" component="p" />
				{children}
			</div>
		</div>
	);
};
