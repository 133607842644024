import React, { FC, useMemo } from 'react';

import { NavigationLink } from '@/components';
import { createUseThemedStyles } from '@/hooks';
import { boxShadow } from '@/jss';
import { Col, Container, Row } from 'react-bootstrap';

const useNavigationSubStyles = createUseThemedStyles((theme) => ({
	navigationSubBar: {
		...boxShadow,
		backgroundColor: theme.colors.white,
		borderBottom: `1px solid ${theme.colors.gray300}`,
	},
	navigationSub: {
		height: 60,
		display: 'flex',
		alignItems: 'center',
	},
	childrenOuter: {
		flexShrink: 0,
		padding: '0 6px 0 0',
	},
	verticalRule: {
		width: 1,
		flexShrink: 0,
		height: '100%',
		marginRight: 6,
		backgroundColor: theme.colors.gray300,
	},
	subNavItem: {
		whiteSpace: 'nowrap',
	},
}));

interface NavigationSubProps {
	navigationItems: NavigationItem[];
}

type NavigationItem = {
	to: string;
	match?: string;
	title: string;
};

export const NavigationSub: FC<NavigationSubProps> = ({ navigationItems, children }) => {
	const classes = useNavigationSubStyles();

	const hasValidChildren = useMemo(() => {
		const validChildren = React.Children.toArray(children).filter((child) => {
			return React.isValidElement(child);
		});

		return React.Children.count(validChildren) > 0;
	}, [children]);

	return (
		<Container fluid className={classes.navigationSubBar}>
			<Container>
				<Row>
					<Col>
						<nav className={classes.navigationSub}>
							{hasValidChildren && (
								<>
									<div className={classes.childrenOuter}>{children}</div>
									<div className={classes.verticalRule} />
								</>
							)}
							{navigationItems.map((navigationItem: NavigationItem, index: number) => {
								return (
									<NavigationLink
										key={index}
										to={navigationItem.to}
										match={navigationItem.match}
										className={classes.subNavItem}
									>
										{navigationItem.title}
									</NavigationLink>
								);
							})}
						</nav>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};
