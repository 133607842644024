import { DocumentModel, PaginatedResponseModel } from '@/lib/models';

export enum PERFORMANCE_STUDY_STATUS_ID {
	OPEN = 'OPEN',
	UPCOMING = 'UPCOMING',
	CLOSED = 'CLOSED',
}

export enum EVENT_TYPE_ID {
	DEFAULT = 'DEFAULT',
	PERFORMANCE_STUDY = 'PERFORMANCE_STUDY',
}

export interface EventModel {
	advancePreparation?: string;
	complaintResolutionPolicy?: string;
	courseLevel?: string;
	description: string;
	documents: PaginatedResponseModel & { documents: DocumentModel[] };
	durationDescription: string;
	eventId: string;
	eventImage?: string;
	eventTypeId: EVENT_TYPE_ID;
	eventTypeDesc: string;	
	imageType?: string;
	listDescription: string;
	name: string;
	pastEvent: boolean;
	performanceStudyStatusId?: PERFORMANCE_STUDY_STATUS_ID;
	presenterName: string;
	programPrerequisites?: string;
	recommendedCpeCredit?: string;
	refundPolicy?: string;
	registrationRequirements?: string;
	registrationUrl?: string;
	webexRecordingUrl?: string;
	imageUrl?: string;
}
