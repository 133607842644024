import { useContext } from 'react';
import { createUseStyles, DefaultTheme, Theming } from 'react-jss';
import { Classes, StyleSheetFactoryOptions, Styles } from 'jss';

import { ThemeConfig, ThemeContext } from '@/contexts/theme-context';

export function useTheme() {
	const themeContext = useContext(ThemeContext);
	return themeContext;
}

interface BaseOptions<Theme = DefaultTheme> extends StyleSheetFactoryOptions {
	index?: number;
	theming?: Theming<Theme>;
}

interface CreateUseStylesOptions<Theme = DefaultTheme> extends BaseOptions<Theme> {
	name?: string;
}

export function createUseThemedStyles<C extends string = string, Props = unknown, Theme = ThemeConfig>(
	styles: Styles<C, Props, Theme> | ((theme: Theme) => Styles<C, Props, undefined>),
	options?: CreateUseStylesOptions<Theme>
): (data?: Props & { theme?: Theme }) => Classes<C> {
	return createUseStyles(styles, options);
}
