import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { JasperBookmarkModel } from '@/lib/models';
import { createUseThemedStyles } from '@/hooks';

interface ReportPaginationItemProps {
	bookmarks: JasperBookmarkModel[];
	currentBookmark: number;
	currentPage: number;
	totalPages: number;
	handleBookmarkChange: (targetPage: number) => void;
	handlePageChange: (targetPage: number) => void;
}

const useStyles = createUseThemedStyles((theme) => ({
	btnBookmarkActive: {
		borderRadius: '25px !important',
	},
	btnBookmark: {
		color: 'black !important',
		background: 'unset !important',
	},
}));

export const ReportPagination = ({
	bookmarks,
	currentBookmark,
	currentPage,
	totalPages,
	handleBookmarkChange,
	handlePageChange,
}: ReportPaginationItemProps) => {
	const classes = useStyles();

	return (
		<>
			<Col xs={8}>
				{!!bookmarks.length && (
					<>
						{bookmarks.map((bookmark, index) =>
							bookmark.page === currentBookmark ? (
								<Button
									variant="primary"
									className={`${classes.btnBookmarkActive} mr-1 mb-1`}
									key={`${bookmark.name}-${index}`}
									onClick={() => {
										handleBookmarkChange(bookmark.page);
									}}
								>
									{bookmark.name}
								</Button>
							) : (
								<Button
									variant="light"
									className={`${classes.btnBookmark} mr-1 mb-1`}
									key={`${bookmark.name}-${index}`}
									onClick={() => {
										handleBookmarkChange(bookmark.page);
									}}
								>
									{bookmark.name}
								</Button>
							)
						)}
					</>
				)}
			</Col>

			{totalPages > 1 && (
				<Col xs={4} className="align-self-center">
					<div className="d-flex justify-content-end">
						<Button
							variant="link"
							className="mr-2"
							onClick={(e) => handlePageChange(1)}
							disabled={currentPage === 1}
						>
							First
						</Button>
						<Button
							variant="link"
							className="mr-2"
							onClick={(e) => handlePageChange(currentPage - 1)}
							disabled={currentPage === 1}
						>
							Previous
						</Button>
						<span className="align-self-center font-weight-bold">{`${currentPage} / ${totalPages}`}</span>
						<Button
							variant="link"
							className="ml-2"
							onClick={(e) => handlePageChange(currentPage + 1)}
							disabled={currentPage === totalPages}
						>
							Next
						</Button>
						<Button
							variant="link"
							className="ml-2"
							onClick={(e) => handlePageChange(totalPages)}
							disabled={currentPage === totalPages}
						>
							Last
						</Button>
					</div>
				</Col>
			)}
		</>
	);
};
