import { createUseThemedStyles } from '@/hooks';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';

import { TableColumnConfig, TableColumns } from './models';

const useStyles = createUseThemedStyles({
	dialog: {
		width: '90%',
		maxWidth: 610,
		margin: '0 auto',
	},
});

interface TableColumnModalProps<ColumnId extends string> {
	tableColumnData: TableColumns<ColumnId>;
	onChange(tableColumnData: TableColumns<ColumnId>): void;
}

export function TableColumnModal<ColumnId extends string>({
	tableColumnData,
	onChange,
	...props
}: TableColumnModalProps<ColumnId> & ModalProps) {
	const classes = useStyles();
	const [tableColumns, setTableColumns] = useState<TableColumns<ColumnId>>();

	useEffect(() => {
		if (!props.show) {
			return;
		}

		setTableColumns(tableColumnData);
	}, [props.show, tableColumnData]);

	return (
		<Modal dialogClassName={classes.dialog} centered {...props}>
			<Modal.Header closeButton>
				<Modal.Title>Show or Hide Columns</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{tableColumns &&
					Object.values<TableColumnConfig<ColumnId>>(tableColumns).map((tableColumn) => {
						if (!tableColumn.isHidable) {
							return null;
						}

						return (
							<Form.Check
								key={tableColumn.columnId}
								id={tableColumn.columnId}
								label={tableColumn.columnTitle}
								checked={tableColumn.isShowing}
								onChange={(event) => {
									const tableColumnsClone = cloneDeep(tableColumns);
									tableColumnsClone[tableColumn.columnId].isShowing = event.currentTarget.checked;

									setTableColumns(tableColumnsClone);
								}}
							/>
						);
					})}
			</Modal.Body>
			<Modal.Footer>
				<div className="w-100 d-flex justify-content-between align-items-center">
					<Button
						variant="link"
						onClick={() => {
							if (!tableColumns) {
								return;
							}

							const tableColumnsClone = Object.entries<TableColumnConfig<ColumnId>>(tableColumns).reduce(
								(previous, [columnId, columnConfig]) => {
									return {
										...previous,
										[columnId]: {
											...columnConfig,
											isShowing: columnConfig.isShownByDefault,
										},
									};
								},
								{} as TableColumns<ColumnId>
							);

							onChange(tableColumnsClone);
						}}
					>
						Reset to default
					</Button>
					<div>
						<Button className="mr-2" variant="link" onClick={props.onHide}>
							Cancel
						</Button>
						<Button
							onClick={() => {
								if (!tableColumns) {
									return;
								}

								onChange(tableColumns);
							}}
						>
							Save Columns &amp; Close
						</Button>
					</div>
				</div>
			</Modal.Footer>
		</Modal>
	);
}
