export const prefixes = {
	badge: 'hackett-badge',
	btn: 'hackett-button',
	'form-group': 'hackett-form__group',
	'form-label': 'hackett-form__label',
	'form-control': 'hackett-form__control',
	'form-check': 'hackett-form__check',
	card: 'hackett-card',
	'card-header': 'hackett-card__header',
	'card-title': 'hackett-card__title',
	'card-body': 'hackett-card__body',
	'card-footer': 'hackett-card__footer',
	'modal-header': 'hackett-modal__header',
	'modal-title': 'hackett-modal__title',
	'modal-body': 'hackett-modal__body',
	'modal-footer': 'hackett-modal__footer',
};
