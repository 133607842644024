import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Collapse, Dropdown as ReactBootstrapDropdown } from 'react-bootstrap';
import classNames from 'classnames';

import { SCROLL_DIRECTION, createUseThemedStyles, useAccount, useScrollDirection, useSignOut } from '@/hooks';
import { Dropdown, DropdownMenu, DropdownToggle, HeaderSearch } from '@/components';

import { ReactComponent as HackettLogo } from '@/assets/logos/logo-hackett-connect.svg';
import { ReactComponent as PeopleIcon } from '@/assets/icons/icon-people.svg';

const useStyles = createUseThemedStyles((theme) => ({
	desktopHeader: {
		display: 'flex',
		padding: '30px 50px',
		alignItems: 'center',
		transition: '300ms padding',
		justifyContent: 'space-between',
		'& .hackett-logo': {
			marginBottom: 15,
			transition: '300ms margin, 300ms width',
		},
		'& a': {
			display: 'block',
			'&:hover': {
				textDecoration: 'none',
			},
		},
		'&.minified': {
			padding: '10px 50px',
			'& .hackett-logo': {
				width: 345,
				marginBottom: 0,
			},
		},
	},
	advisorsIconButton: {
		color: `${theme.colors.white} !important`,
		marginRight: 15,
		'& svg': {
			fill: theme.colors.white,
		},
	},
	headerSearch: {
		width: '100%',
	},
}));

interface HeaderDesktopProps {
	navigationLinks: Record<string, { to: string; title: string; external?: boolean }[]>;
	className?: string;
}

export const HeaderDesktop = ({ navigationLinks, className }: HeaderDesktopProps) => {
	const classes = useStyles();
	const { account } = useAccount();
	const { signOut } = useSignOut();
	const scrollDirection = useScrollDirection();
	const headerRef = useRef<HTMLDivElement>(null);
	const [isMinified, setIsMinified] = useState(false);
	const utilityNavRef = useRef<HTMLDivElement>(null);
	const searchBarRef = useRef<HTMLDivElement>(null);

	function setSearchBarWidth() {
		const utilityWidth = utilityNavRef.current?.clientWidth ?? 0;

		if (!searchBarRef.current) {
			return;
		}

		searchBarRef.current.style.width = utilityWidth > 0 ? `${utilityWidth}px` : '100%';
	}

	const minifyHeader = useCallback(() => {
		headerRef.current?.classList.add('minified');
		setIsMinified(true);
		setSearchBarWidth();
	}, []);

	function expandHeader() {
		headerRef.current?.classList.remove('minified');
		setIsMinified(false);
	}

	const handleWindowResize = useCallback(() => {
		expandHeader();
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [handleWindowResize]);

	useEffect(() => {
		if (scrollDirection === SCROLL_DIRECTION.DOWN) {
			minifyHeader();
		} else {
			expandHeader();
		}
	}, [minifyHeader, scrollDirection]);

	return (
		<div ref={headerRef} className={classNames(classes.desktopHeader, className)}>
			<Link to="/">
				<HackettLogo className="hackett-logo" />
				<Collapse in={!isMinified} timeout={300}>
					<p className="mb-0 text-white">
						Tap into the collective knowledge of the world's best businesses and our experts.
					</p>
				</Collapse>
			</Link>
			<div>
				<Collapse in={!isMinified} timeout={300}>
					<div>
						<div ref={utilityNavRef} className="pb-3 d-flex align-items-center justify-content-end">
							<Button variant="link" className={classes.advisorsIconButton} href="/advisory-team">
								<PeopleIcon width={40} height={40} />
							</Button>
							<Button variant="info" size="sm" className="text-nowrap" href="/advisory-team">
								Meet Your Team
							</Button>
							<Dropdown>
								<DropdownToggle
									className="text-white"
									chevronClassName="text-white"
									id="dropdown--account"
								>
									{account?.name || 'N/A'}
								</DropdownToggle>
								<DropdownMenu align="end" popperConfig={{ strategy: 'fixed' }} renderOnMount>
									{navigationLinks.ACCOUNT.map((link, linkIndex) => {
										return link.external ? (
											<ReactBootstrapDropdown.Item key={linkIndex} href={link.to} target="_blank">
												{link.title}
											</ReactBootstrapDropdown.Item>
										) : (
											<ReactBootstrapDropdown.Item key={linkIndex} as={Link} to={link.to}>
												{link.title}
											</ReactBootstrapDropdown.Item>
										);
									})}
									<ReactBootstrapDropdown.Item onClick={signOut}>
										Sign Out
									</ReactBootstrapDropdown.Item>
								</DropdownMenu>
							</Dropdown>
						</div>
					</div>
				</Collapse>
				<HeaderSearch ref={searchBarRef} className={classes.headerSearch} />
			</div>
		</div>
	);
};
