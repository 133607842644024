import { v4 as uuid } from 'uuid';
import React, { useRef } from 'react';
import { Dropdown as ReactBootstrapDropdown } from 'react-bootstrap';

import { createUseThemedStyles, useCopyTextToClipboard, useHandleError } from '@/hooks';
import { Dropdown, DropdownMenu, DropdownToggle } from '@/components';

import { ReactComponent as LinkIcon } from '@/assets/icons/icon-link.svg';

import { downloadFileAtUrl } from '@/lib/utils';

interface UseStylesProps {
	showBorder?: boolean;
}

const useStyles = createUseThemedStyles(
	(theme) => ({
		attachmentDropdownToggle: ({ showBorder }: UseStylesProps) => ({
			width: '100%',
			textAlign: 'left',
			justifyContent: 'space-between',
			backgroundColor: `transparent`,
			...(!showBorder && { padding: '0' }),
			color: `${theme.colors.primary}`,
			fontWeight: `${theme.fonts.openSansBold.fontWeight}`,
			border: `1px solid ${showBorder ? theme.colors.primary : 'transparent'}`,
		}),
	}),
	{ index: 5 }
);

interface AttachmentDropdownProps {
	title: string;
	downloadUrl?: string;
	showBorder?: boolean;
	showRemoveOption?: boolean;
	className?: string;
	onRemove?(): void;
}

export const AttachmentDropdown = ({
	title,
	downloadUrl,
	showBorder = true,
	showRemoveOption = true,
	className,
	onRemove,
}: AttachmentDropdownProps) => {
	const handleError = useHandleError();
	const id = useRef(uuid()).current;
	const classes = useStyles({ showBorder });
	const { copyTextToClipboard } = useCopyTextToClipboard();

	async function handleDownloadClick() {
		if (!downloadUrl) {
			return;
		}

		try {
			await downloadFileAtUrl(downloadUrl, title);
		} catch (error) {
			handleError(error);
		}
	}

	async function handleCopyLinkClick() {
		if (!downloadUrl) {
			return;
		}
		copyTextToClipboard(downloadUrl, 'Success: Link was copied to clipboard.');
	}

	function handleRemoveAttachmentClick() {
		if (!onRemove) {
			return;
		}

		onRemove();
	}

	return (
		<Dropdown className={className}>
			<DropdownToggle id={`dropdown--${id}`} className={classes.attachmentDropdownToggle}>
				<LinkIcon className="mr-3" />
				<span className="mr-1">{title}</span>
			</DropdownToggle>
			<DropdownMenu align="end">
				{downloadUrl && (
					<>
						<ReactBootstrapDropdown.Item onClick={handleDownloadClick}>
							<span className="p">Download</span>
						</ReactBootstrapDropdown.Item>
						<ReactBootstrapDropdown.Item onClick={handleCopyLinkClick}>
							<span className="p">Copy Link</span>
						</ReactBootstrapDropdown.Item>
					</>
				)}
				{showRemoveOption && (
					<ReactBootstrapDropdown.Item onClick={handleRemoveAttachmentClick}>
						<span className="p">Remove Attachment</span>
					</ReactBootstrapDropdown.Item>
				)}
			</DropdownMenu>
		</Dropdown>
	);
};
