import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import { createUseThemedStyles, useAlert, useHandleError, useNavigationConfig } from '@/hooks';
import {
	AspectRatioLockedImage,
	AsyncPage,
	CarouselSingle,
	FeaturedContentTile,
	InquiryForm,
	SalesLeadCta,
} from '@/components';
import {
	advisoryTeamService,
	documentService,
	eventsService,
	leftNavigationService,
	recommendationsService,
	salesLeadService,
} from '@/lib/services';
import { AdvisorModel, DocumentModel, EventModel, LeftNavigationItem, Recommendation } from '@/lib/models';
import { mediaQueries } from '@/jss';

import { ReactComponent as StarIcon } from '@/assets/icons/icon-star.svg';
import { ReactComponent as InquiriesGraphic } from '@/assets/illustrations/inquiries-graphic.svg';
import aiXplrPng from '@/assets/images/ai-xplr.png';
import quantumLeapPng from '@/assets/images/quantum-leap.png';

const useStyles = createUseThemedStyles((theme) => ({
	programSectionsCol: {
		minHeight: '100%',
		padding: '0 15px 25px',
		[mediaQueries.lg]: {
			borderRight: `1px solid ${theme.colors.gray300}`,
		},
	},
}));

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

export const DashboardV2 = () => {
	const classes = useStyles();
	const { navigationConfig } = useNavigationConfig();
	const handleError = useHandleError();
	const { showAlert } = useAlert();
	const [leftNaviationItems, setLeftNavigationItems] = useState<LeftNavigationItem[]>([]);
	const [trendingContent, setTrendingContent] = useState<Recommendation[]>([]);
	const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
	const [upcomingEvents, setUpcomingEvents] = useState<EventModel[]>([]);
	const [performanceStudy, setPerformanceStudy] = useState<EventModel>();
	const [featuredPodcasts, setFeaturedPodcasts] = useState<DocumentModel[]>([]);
	const [advisors, setAdvisors] = useState<AdvisorModel[]>([]);

	const [showInquiriesModal, setShowInquiriesModal] = useState(false);
	const [showQuantumLeapModal, setShowQuantumLeapModal] = useState(false);
	const [showAiXplrModal, setShowAiXplrModal] = useState(false);

	const fetchData = useCallback(async () => {
		const [leftNavigationResponse, recommendationsResponse, advisoryTeamResponse] = await Promise.all([
			leftNavigationService.getLeftNavigation().fetch(),
			recommendationsService.getRecommendations().fetch(),
			advisoryTeamService.getAdvisoryTeam({ count: 4 }).fetch(),
		]);

		setLeftNavigationItems(leftNavigationResponse.navigationItems);
		setTrendingContent(recommendationsResponse.trendingContent);
		setRecommendations(recommendationsResponse.recommendedContent.slice(0, 3));
		setUpcomingEvents(recommendationsResponse.events);

		setAdvisors(advisoryTeamResponse.advisors);

		if (navigationConfig?.allowPerformanceStudies) {
			const performanceStudy = await eventsService.getNearestPerformanceStudy().fetch();
			setPerformanceStudy(performanceStudy);
		}

		if (navigationConfig?.allowResearch) {
			const documentResponse = await documentService
				.getDocuments({ documentTypes: ['PODCASTS'], size: '4', page: '0' })
				.fetch();
			setFeaturedPodcasts(documentResponse.documents);
		}
	}, [navigationConfig?.allowPerformanceStudies, navigationConfig?.allowResearch]);

	const handleInquiriesConfirm = useCallback(async () => {
		try {
			await salesLeadService.sendExpertIquiryEmail().fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return (
						<p className="mb-0 text-white">
							Thank you for your interest. Your Advisory Team will be in contact with you.
						</p>
					);
				},
			});

			setShowInquiriesModal(false);
		} catch (error) {
			handleError(error);
		}
	}, [handleError, showAlert]);

	const handleQuantumLeapConfirm = useCallback(async () => {
		try {
			await salesLeadService.sendQuantumLoopEmail().fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return (
						<p className="mb-0 text-white">
							Thank you for your interest. Your Advisory Team will be in contact with you.
						</p>
					);
				},
			});

			setShowQuantumLeapModal(false);
		} catch (error) {
			handleError(error);
		}
	}, [handleError, showAlert]);

	const handleAiXplrConfirm = useCallback(async () => {
		try {
			await salesLeadService.sendAiXplrEmail().fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return (
						<p className="mb-0 text-white">
							Thank you for your interest. Your Advisory Team will be in contact with you.
						</p>
					);
				},
			});

			setShowAiXplrModal(false);
		} catch (error) {
			handleError(error);
		}
	}, [handleError, showAlert]);

	return (
		<>
			<Helmet>
				<title>Hackett Connect</title>
			</Helmet>

			<Modal
				show={showInquiriesModal}
				centered
				onHide={() => {
					setShowInquiriesModal(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Expert Inquiries</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="mb-0">
						To learn more about our Expert Inquiries service please click the "Confirm" button below, and a
						member of your Advisory Team will get in touch with you.
					</p>
				</Modal.Body>
				<Modal.Footer className="text-right">
					<Button onClick={handleInquiriesConfirm}>Confirm</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showQuantumLeapModal}
				centered
				onHide={() => {
					setShowQuantumLeapModal(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Are You Ready to Take a Quantum Leap?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="mb-0">
						To learn more about our Quantum Leap™ platform please click the "Confirm" button below, and a
						member of your Advisory Team will get in touch with you.
					</p>
				</Modal.Body>
				<Modal.Footer className="text-right">
					<Button onClick={handleQuantumLeapConfirm}>Confirm</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showAiXplrModal}
				centered
				onHide={() => {
					setShowAiXplrModal(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Unlock the Power of Artificial Intelligence Transformation with AI XPLR™</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="mb-0">
						To learn more about our AI XPLR™ platform please click the "Confirm" button below, and a member
						of your Advisory Team will get in touch with you.
					</p>
				</Modal.Body>
				<Modal.Footer className="text-right">
					<Button onClick={handleAiXplrConfirm}>Confirm</Button>
				</Modal.Footer>
			</Modal>

			<AsyncPage fetchData={fetchData}>
				<Container className="bg-white" fluid>
					<Container className="pt-6">
						<Row>
							<Col lg={3}>
								<div className={classes.programSectionsCol}>
									<h4 className="mb-4 text-primary">
										Digital World Class<sup>&reg;</sup> Programs
									</h4>
									{leftNaviationItems.map((navigationItem, navigationItemIndex) => {
										const isLast = navigationItemIndex === leftNaviationItems.length - 1;

										return (
											<React.Fragment key={navigationItemIndex}>
												<h4 className="mb-2 text-primary">{navigationItem.name}</h4>
												<ul className="mb-5 list-unstyled">
													{navigationItem.bundles.map((bundle) => {
														if (bundle.comingSoonFlag) {
															return (
																<li key={bundle.id} className="mb-2 text-info h5 pl-4">
																	{bundle.name}{' '}
																	<span className="text-gray-500">(coming soon)</span>
																</li>
															);
														}

														return (
															<li key={bundle.id} className="mb-2">
																<Link
																	to={`/program-bundle/${bundle.staticUrlName ??
																		bundle.id}`}
																	className={classNames(
																		'mb-0 d-flex align-items-top h5',
																		{
																			'pl-4': !bundle.hasAccess,
																		}
																	)}
																>
																	{bundle.hasAccess && (
																		<StarIcon
																			width={15}
																			height={15}
																			className="mr-1 text-warning flex-shrink-0"
																			style={{ marginTop: 2 }}
																		/>
																	)}
																	{bundle.name}
																	{bundle.newFlag && (
																		<span className="text-secondary">
																			&nbsp;- NEW
																		</span>
																	)}
																</Link>
															</li>
														);
													})}
												</ul>
												{!isLast && <hr className="mb-5" />}
											</React.Fragment>
										);
									})}
									<h4 className="mb-2 text-primary">Related Services</h4>
									<ul className="mb-5 list-unstyled">
										<li>
											<a
												className="pl-4 d-flex align-items-top h5"
												href="http://www.thehackettgroup.com/business-benchmarking"
												target="_blank"
												rel="noreferrer"
											>
												Benchmarking
											</a>
										</li>
										<li>
											<a
												className="pl-4 d-flex align-items-top h5"
												href="http://www.thehackettgroup.com/digital-transformation"
												target="_blank"
												rel="noreferrer"
											>
												Consulting
											</a>
										</li>
										<li>
											<a
												className="pl-4 d-flex align-items-top h5"
												href="http://www.thehackettgroup.com/about/investor-relations"
												target="_blank"
												rel="noreferrer"
											>
												IPaaS<sup style={{ top: '0.5em' }}>&reg;</sup>
											</a>
										</li>
										<li>
											<a
												className="pl-4 d-flex align-items-top h5"
												href="https://www.thehackettgroup.com/the-hackett-institute/"
												target="_blank"
												rel="noreferrer"
											>
												The Hackett Institute<sup style={{ top: '0.5em' }}>&reg;</sup>
											</a>
										</li>
									</ul>
									<p className="d-flex align-items-start h5 small">
										<StarIcon
											width={15}
											height={15}
											className="mr-1 text-warning flex-shrink-0"
											style={{ marginTop: 2 }}
										/>
										= included in your membership
									</p>
								</div>
							</Col>
							<Col lg={9}>
								<Row className="mb-8">
									<Col>
										<h2 className="mb-5 text-dark">Trending Insights</h2>
										<CarouselSingle
											slides={trendingContent.map((recommendation) => ({
												to: recommendation.detailsUrl,
												title: recommendation.title,
												description: recommendation.description,
												imageUrl: recommendation.imageUrl ?? '',
											}))}
										/>
									</Col>
								</Row>
								<Row className="mb-5">
									<Col>
										<h3 className="mb-0 text-primary">We Also Recommend</h3>
									</Col>
									<Col className="text-right">
										{navigationConfig?.allowResearch && <Link to="/research">All Research</Link>}
									</Col>
								</Row>
								<Row>
									{recommendations.map((recommendation, recommendationIndex) => (
										<Col key={recommendationIndex} className="mb-10" sm={12} md={4} lg={4}>
											<FeaturedContentTile
												to={recommendation.detailsUrl}
												imageUrl={recommendation.imageUrl}
												contentType={recommendation.contentTypeDescription}
												title={recommendation.title}
												description={recommendation.description}
											/>
										</Col>
									))}
								</Row>
							</Col>
						</Row>
					</Container>
				</Container>

				{upcomingEvents.length > 0 && (
					<>
						<hr />
						<Container className="bg-white" fluid>
							<Container className="py-6">
								<Row className="mb-5">
									<Col>
										<h2 className="mb-0 text-primary">Events</h2>
									</Col>
									<Col className="text-right">
										<Link to="/events/browse-events">All Events</Link>
									</Col>
								</Row>
								<Row>
									{upcomingEvents.slice(0, 4).map((event) => (
										<Col key={event.eventId} className="mb-3" sm={12} md={6} lg={3}>
											<FeaturedContentTile
												border
												to={`/events/browse-events/${event.eventId}`}
												contentType={event.pastEvent ? 'Replay Event' : 'Upcoming Event'}
												title={event.name}
												bottomText={event.durationDescription}
											/>
										</Col>
									))}
								</Row>
							</Container>
						</Container>
					</>
				)}

				{performanceStudy && (
					<Container className="bg-success" fluid>
						<Container className="py-8">
							<Row className="align-items-center">
								<Col lg={{ offset: 1, span: 7 }}>
									<h4 className="mb-1 text-white">{performanceStudy?.name}</h4>
									<ResponsiveEllipsis
										className="text-white"
										unsafeHTML={performanceStudy?.description ?? ''}
										maxLine="2"
										component="div"
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Button
										variant="warning"
										href={performanceStudy?.registrationUrl}
										target="_blank"
										rel="noopener noreferrer"
									>
										Start Survey
									</Button>
								</Col>
							</Row>
						</Container>
					</Container>
				)}

				<SalesLeadCta reverse className="bg-tertiary" imageUrl={quantumLeapPng}>
					<h2 className="mb-3 text-white">Are You Ready to Take a Quantum Leap?</h2>
					<p className="mb-6 text-white">
						Marking the leap from benchmarking to continuous improvement can deliver powerful benefits to
						your organization. Our revolutionary Quantum Leap™ platform digitizes your world-class journey.
						Talk to us to learn out how.
					</p>
					<div className="text-center">
						<Button
							onClick={() => {
								setShowQuantumLeapModal(true);
							}}
						>
							Learn more
						</Button>
					</div>
				</SalesLeadCta>

				<SalesLeadCta className="bg-success" imageUrl={aiXplrPng}>
					<h2 className="mb-3 text-white">
						Unlock the Power of Artificial Intelligence Transformation with AI XPLR™
					</h2>
					<p className="mb-6 text-white">
						Generative artificial intelligence (Gen AI) will transform the way business functions work and
						perform. Our AI XPLR™ platform helps executives understand real-work use cases, determining
						readiness and prioritize implementation. Ask us how or schedule a demo.
					</p>
					<div className="text-center">
						<Button
							variant="dark"
							onClick={() => {
								setShowAiXplrModal(true);
							}}
						>
							Learn More
						</Button>
					</div>
				</SalesLeadCta>

				<Container className="bg-white" fluid>
					<Container className="py-12">
						<Row className="mb-5">
							<Col>
								<h2 className="mb-0 text-primary">Featured Podcasts</h2>
							</Col>
							<Col className="text-right">
								<Link to="/research?contentTypes=PODCASTS">All Podcasts</Link>
							</Col>
						</Row>
						<Row>
							{featuredPodcasts.map((podcast) => (
								<Col key={podcast.documentId} className="mb-3" sm={12} md={6} lg={3}>
									<FeaturedContentTile
										to={`/research/${podcast.documentId}`}
										imageUrl={podcast.imageUrl}
										title={podcast.name}
										bottomText={podcast.publicationDateDescription}
									/>
								</Col>
							))}
						</Row>
					</Container>
				</Container>

				<Container className="bg-gray-100" fluid>
					<Container className="pt-12">
						<Row className="mb-6">
							<Col>
								<h2 className="mb-0 text-dark">Meet Your Team</h2>
							</Col>
							<Col className="text-right">
								<Link to="/advisory-team">All Advisors</Link>
							</Col>
						</Row>
						<Row className="mb-16 align-items-center">
							<Col xs={12} lg={8}>
								<Row>
									{advisors.map((advisor, advisorIndex) => {
										const isFirst = advisorIndex === 0;

										return (
											<Col
												key={advisor.advisorId}
												xs={3}
												xl={{ span: 2, offset: isFirst ? 0 : 1 }}
												className="mb-3"
											>
												<Link to="/advisory-team">
													<AspectRatioLockedImage
														width={176}
														height={176}
														className="mb-2"
														src={advisor.imageUrl}
													/>
													<p className="small mb-0 text-center">
														{advisor.name}
														<br />
														{advisor.role}
													</p>
												</Link>
											</Col>
										);
									})}
								</Row>
							</Col>
							<Col xs={12} lg={4}>
								<h4 className="mb-2">We're here to help</h4>
								<p className="mb-2 small">
									<Link to="/contact">Contact us</Link> to get answers to your questions, assistance
									with relevant research and more.
								</p>
								<Button variant="secondary" href="/contact">
									Contact Us
								</Button>
							</Col>
						</Row>
					</Container>
				</Container>
				{!navigationConfig?.inquiriesDisabled && !navigationConfig?.allowClientManagement && (
					<>
						{navigationConfig?.allowAccessToInquiries ? (
							<Container className="bg-gray-100" fluid>
								<Container className="pb-12">
									<Row>
										<Col>
											<Card>
												<Card.Body>
													<Row>
														<Col
															xs={{ offset: 4, span: 4 }}
															lg={{ offset: 0, span: 2 }}
															xl={{ offset: 1, span: 2 }}
														>
															<InquiriesGraphic />
														</Col>
														<Col xs={12} lg={8} xl={6}>
															<div className="pt-7 pb-14">
																<h2 className="mb-0 text-primary text-center">
																	Expert Inquiries: Tell us what you need help with
																</h2>
															</div>
															<InquiryForm />
														</Col>
													</Row>
												</Card.Body>
											</Card>
										</Col>
									</Row>
								</Container>
							</Container>
						) : (
							<Container id="inquiry-cta" className="bg-success" fluid>
								<Container className="py-8">
									<Row className="align-items-center">
										<Col lg={{ offset: 1, span: 7 }}>
											<h4 className="mb-1 text-white">Expert Inquiries</h4>
											<p className="mb-1 text-white">
												Expert Inquiries enable designated users at your organization to engage
												with subject-matter advisors for guided insights via curated custom
												materials by request.
											</p>
											<p className="mb-0 text-white">
												Your user account has not been designated with inquiry access at this
												time. To learn more about your organization's designated user access to
												Expert Inquiries, please click below.
											</p>
										</Col>
										<Col lg={3} className="text-center">
											<Button
												variant="warning"
												onClick={() => {
													setShowInquiriesModal(true);
												}}
											>
												Learn More
											</Button>
										</Col>
									</Row>
								</Container>
							</Container>
						)}
					</>
				)}
			</AsyncPage>
		</>
	);
};
