import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { createUseThemedStyles } from '@/hooks';
import { TempCitation } from '@/lib/models';

const useStyles = createUseThemedStyles((theme) => ({
	chatAnswerSources: {
		padding: 15,
		borderRadius: 5,
		backgroundColor: theme.colors.gray100,
	},
}));

interface AiAnswerSourcesProps {
	className?: string;
	sources: TempCitation[];
}

export const AiAnswerSources = ({ className, sources }: AiAnswerSourcesProps) => {
	const classes = useStyles();
	const [isShowingMore, setIsShowingMore] = useState(false);

	return (
		<div className={classNames(classes.chatAnswerSources, className)}>
			<h6>
				Sources <span className="text-muted">({sources.length} referenced)</span>
			</h6>
			<ol
				className={classNames('small', {
					'mb-0': sources.length <= 2,
					'mb-2': sources.length > 2,
				})}
			>
				{sources.slice(0, isShowingMore ? sources.length : 2).map((source, sourceIndex) => (
					<li key={sourceIndex}>
						<a href={source.documentUrl} target="_blank" rel="noreferrer">
							{source.documentTitle}
						</a>
					</li>
				))}
			</ol>
			{sources.length > 2 && (
				<Button
					variant="link"
					className="small"
					onClick={() => {
						setIsShowingMore(!isShowingMore);
					}}
				>
					{isShowingMore ? 'Show less' : 'Show more'}
				</Button>
			)}
		</div>
	);
};
