import React, { useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { FEEDBACK_RATING_ID } from '@/lib/models';
import { aiConversationService } from '@/lib/services';
import { createUseThemedStyles, useHandleError } from '@/hooks';
import { FadeTransition, LoadingButton } from '@/components';
import { ReactComponent as FaceSatisfiedLineIcon } from '@/assets/icons/icon-face-satisfied-line.svg';
import { ReactComponent as FaceSatisfiedFillIcon } from '@/assets/icons/icon-face-satisfied-fill.svg';
import { ReactComponent as FaceNeutralLineIcon } from '@/assets/icons/icon-face-neutral-line.svg';
import { ReactComponent as FaceNeutralFillIcon } from '@/assets/icons/icon-face-neutral-fill.svg';
import { ReactComponent as FaceDissatisfiedLineIcon } from '@/assets/icons/icon-face-dissatisfied-line.svg';
import { ReactComponent as FaceDissatisfiedFillIcon } from '@/assets/icons/icon-face-dissatisfied-fill.svg';

const useStyles = createUseThemedStyles((theme) => ({
	dialog: {
		width: '90%',
		height: '100%',
		maxWidth: 610,
		margin: '0 auto',
		'& .modal-content': {
			maxHeight: '90vh',
		},
		'& .hackett-modal__body': {
			overflowY: 'auto',
		},
	},
	radioButtonLabel: {
		'&:after, &:before': {
			display: 'none !important',
		},
	},
}));

interface AiRateModalProps extends ModalProps {
	conversationQueryId: string;
	onSave(): void;
}

const satisfactionOptions = [
	{
		value: FEEDBACK_RATING_ID.POSITIVE,
		checkedIcon: <FaceSatisfiedFillIcon />,
		uncheckedIcon: <FaceSatisfiedLineIcon />,
	},
	{
		value: FEEDBACK_RATING_ID.NEUTRAL,
		checkedIcon: <FaceNeutralFillIcon />,
		uncheckedIcon: <FaceNeutralLineIcon />,
	},
	{
		value: FEEDBACK_RATING_ID.NEGATIVE,
		checkedIcon: <FaceDissatisfiedFillIcon />,
		uncheckedIcon: <FaceDissatisfiedLineIcon />,
	},
];

export const AiRateModal = ({ conversationQueryId, onSave, ...props }: AiRateModalProps) => {
	const handleError = useHandleError();
	const classes = useStyles();
	const [formValues, setFormValues] = useState({
		satisfaction: undefined as FEEDBACK_RATING_ID | undefined,
		reason: '',
	});
	const [isSaving, setIsSaving] = useState(false);

	const handleOnEnter = () => {
		setIsSaving(false);
		setFormValues({
			satisfaction: undefined,
			reason: '',
		});
	};

	const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		try {
			if (!formValues.satisfaction) {
				throw new Error('Feedback is undefined.');
			}

			setIsSaving(true);

			await aiConversationService
				.rateConversationQuery({
					conversationQueryId,
					feedbackRatingId: formValues.satisfaction,
					...(formValues.reason && { reason: formValues.reason }),
				})
				.fetch();

			onSave();
		} catch (error) {
			handleError(error);
			setIsSaving(false);
		}
	};

	return (
		<Modal dialogClassName={classes.dialog} centered onEntering={handleOnEnter} {...props}>
			<Modal.Header closeButton>
				<Modal.Title>Rate AI Answer</Modal.Title>
			</Modal.Header>
			<Form onSubmit={handleFormSubmit}>
				<Modal.Body>
					<p className="mb-6">How satisfied were you with the answer provided by the AI?</p>
					<Form.Group className="d-flex align-items-center justify-content-around">
						{satisfactionOptions.map((option) => (
							<Form.Check id={`satisfaction--${option.value}`}>
								<Form.Check.Input
									type="radio"
									name="satisfaction"
									value={option.value}
									checked={formValues.satisfaction === option.value}
									onChange={({ currentTarget }) => {
										setFormValues((previousValue) => ({
											...previousValue,
											satisfaction: currentTarget.value as FEEDBACK_RATING_ID,
										}));
									}}
								/>
								<Form.Check.Label className={classes.radioButtonLabel}>
									{formValues.satisfaction === option.value
										? option.checkedIcon
										: option.uncheckedIcon}
								</Form.Check.Label>
							</Form.Check>
						))}
					</Form.Group>
					<FadeTransition in={formValues.satisfaction === FEEDBACK_RATING_ID.NEGATIVE}>
						<Form.Group className="pt-4">
							<Form.Label>Tell us more about why you were dissatisfied</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter text..."
								value={formValues.reason}
								onChange={({ currentTarget }) => {
									setFormValues((previousValue) => ({
										...previousValue,
										reason: currentTarget.value,
									}));
								}}
							/>
						</Form.Group>
					</FadeTransition>
				</Modal.Body>
				<Modal.Footer>
					<div className="text-right">
						<Button className="mr-2" variant="link" onClick={props.onHide}>
							Cancel
						</Button>
						<LoadingButton type="submit" isLoading={isSaving} disabled={!formValues.satisfaction}>
							Submit
						</LoadingButton>
					</div>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
