import { TableColumns, TableHeaders, TableRowConfig } from '@/components/table/models';

export enum ASSESSMENTS_TABLE_COLUMN_IDS {
	ASSESSMENTS = 'ASSESSMENTS',
	DESCRIPTION = 'DESCRIPTION',
	LAST_TAKEN = 'LAST_TAKEN',
	ACTIONS = 'ACTIONS',
}

export const assessmentsTableColumns: TableColumns<ASSESSMENTS_TABLE_COLUMN_IDS> = {
	[ASSESSMENTS_TABLE_COLUMN_IDS.ASSESSMENTS]: {
		columnId: ASSESSMENTS_TABLE_COLUMN_IDS.ASSESSMENTS,
		columnTitle: 'Assessments',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[ASSESSMENTS_TABLE_COLUMN_IDS.DESCRIPTION]: {
		columnId: ASSESSMENTS_TABLE_COLUMN_IDS.DESCRIPTION,
		columnTitle: 'Description',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[ASSESSMENTS_TABLE_COLUMN_IDS.LAST_TAKEN]: {
		columnId: ASSESSMENTS_TABLE_COLUMN_IDS.LAST_TAKEN,
		columnTitle: 'Last Taken',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[ASSESSMENTS_TABLE_COLUMN_IDS.ACTIONS]: {
		columnId: ASSESSMENTS_TABLE_COLUMN_IDS.ACTIONS,
		columnTitle: 'Actions',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
};

export const assessmentsTableHeaders: TableHeaders<ASSESSMENTS_TABLE_COLUMN_IDS> = {
	[ASSESSMENTS_TABLE_COLUMN_IDS.ASSESSMENTS]: {
		tableHeaderId: ASSESSMENTS_TABLE_COLUMN_IDS.ASSESSMENTS,
		title: 'Assessments',
		isSortable: true,
	},
	[ASSESSMENTS_TABLE_COLUMN_IDS.DESCRIPTION]: {
		tableHeaderId: ASSESSMENTS_TABLE_COLUMN_IDS.DESCRIPTION,
		title: 'Description',
		isSortable: true,
	},
	[ASSESSMENTS_TABLE_COLUMN_IDS.LAST_TAKEN]: {
		tableHeaderId: ASSESSMENTS_TABLE_COLUMN_IDS.LAST_TAKEN,
		title: 'Last Taken',
		isSortable: true,
	},
	[ASSESSMENTS_TABLE_COLUMN_IDS.ACTIONS]: {
		tableHeaderId: ASSESSMENTS_TABLE_COLUMN_IDS.ACTIONS,
		title: 'Actions',
		isSortable: true,
	},
};

export const assessmentsTableRows: TableRowConfig<{
	title: string;
	description: string;
	lastTakenDescription: string;
	actionTitle: string;
}>[] = [
	{
		rowId: 'xxxx-xxxx-xxxx-xxxx-0',
		checked: false,
		expanded: false,
		columnData: {
			title: 'Finance Executive Heights',
			description: 'Summarize Finance improvement opportunities with comparisons to Digital World Class®',
			lastTakenDescription: 'June 22nd 2020',
			actionTitle: 'View Results',
		},
	},
];
