import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';

import { constructUrl } from '@/lib/utils';
import { PerformanceMetricModel, ProgramFormOption } from '@/lib/models';
import { performanceMetricService } from '@/lib/services';
import { useQuery } from '@/hooks';
import { AsyncPage, LinkBox, Meter, Select } from '@/components';
import { PERFORMANCE_METRICS_TABLE_COLUMN_IDS } from '@/lib/__mocks__';
import { Helmet } from 'react-helmet';

export const PerformanceMetricsDetail = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const { performanceMetricId } = useParams<{
		performanceMetricId: string;
	}>();
	const query = useQuery();
	const programId = useMemo(() => query.get('programId'), [query]);

	const [programOptions, setProgramOptions] = useState<ProgramFormOption[]>([]);
	const [performanceMetric, setPerformanceMetric] = useState<PerformanceMetricModel>();
	const [metricsToShow, setMetricsToShow] = useState<PERFORMANCE_METRICS_TABLE_COLUMN_IDS[]>([]);
	const [maxValue, setMaxValue] = useState(100);

	/* ------------------------------------- */
	/* Must default a programId */
	/* ------------------------------------- */
	useEffect(() => {
		if (!programOptions || programOptions.length <= 0) {
			return;
		}

		if (!programId) {
			history.replace(constructUrl(pathname, { page: '0', programId: programOptions[0].id }, search));
		}
	}, [history, pathname, programId, programOptions, search]);

	const fetchFilters = useCallback(async () => {
		const response = await performanceMetricService.getPerformanceMetricDetailsFilters(performanceMetricId).fetch();
		setProgramOptions(response.programs);
	}, [performanceMetricId]);

	const fetchData = useCallback(async () => {
		if (!programId) {
			throw new Error('programId is undefined.');
		}

		const response = await performanceMetricService
			.getPerformanceMetricById(performanceMetricId, {
				programId,
			})
			.fetch();

		const largestValue = Math.max(
			response.peerGroupValue,
			response.worldClassValue,
			response.industryPeerValue,
			response.firstQuartileValue,
			response.topPerformerValue
		);

		setPerformanceMetric(response);
		setMaxValue(largestValue);
	}, [performanceMetricId, programId]);

	const handleProgramSelectChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		history.replace(constructUrl(pathname, { programId: currentTarget.value }, search));
	};

	useEffect(() => {
		if (!programId) {
			return;
		}

		const selectedOption = programOptions.find((p) => p.id === programId);

		if (selectedOption) {
			setMetricsToShow(selectedOption.performanceMetricTypes as PERFORMANCE_METRICS_TABLE_COLUMN_IDS[]);
		}
	}, [programId, programOptions]);

	const showDigitalWorldClass = useMemo(() => {
		return metricsToShow.includes(PERFORMANCE_METRICS_TABLE_COLUMN_IDS.DIGITAL_WORLD_CLASS);
	}, [metricsToShow]);

	const showIndustryPeer = useMemo(() => {
		return metricsToShow.includes(PERFORMANCE_METRICS_TABLE_COLUMN_IDS.INDUSTRY_PEER);
	}, [metricsToShow]);

	const showFirstQuartile = useMemo(() => {
		return metricsToShow.includes(PERFORMANCE_METRICS_TABLE_COLUMN_IDS.FIRST_QUARTILE);
	}, [metricsToShow]);

	const showTopPerformer = useMemo(() => {
		return metricsToShow.includes(PERFORMANCE_METRICS_TABLE_COLUMN_IDS.TOP_PERFORMER);
	}, [metricsToShow]);

	return (
		<AsyncPage fetchData={fetchFilters}>
			<Helmet>
				<title>Hackett Connect - Benchmark Metrics - Benchmark Metric Detail</title>
			</Helmet>
			<Container className="py-6">
				<Row className="mb-8">
					<Col xl={8}>
						<AsyncPage fetchData={fetchData} errorDisplayClassName={'p-0 m-0 w-100'}>
							<Row className="mb-6">
								<Col>
									<h1 className="mb-0">{performanceMetric?.description}</h1>
								</Col>
							</Row>
							<Row className="mb-10">
								<Col>
									<Meter
										className="mb-6"
										title="Peer Group"
										variant="tertiary"
										size="lg"
										label={performanceMetric?.peerGroup}
										value={performanceMetric?.peerGroupValue ?? 0}
										max={maxValue}
									/>
									{showIndustryPeer && (
										<Meter
											className="mb-6"
											title="Industry Peer"
											variant="success"
											size="lg"
											label={performanceMetric?.industryPeer}
											value={performanceMetric?.industryPeerValue ?? 0}
											max={maxValue}
										/>
									)}
									{showTopPerformer && (
										<Meter
											className="mb-6"
											// This is referred to as 1stQ internally at Hackett
											title="First Quartile"
											variant="primary"
											size="lg"
											label={performanceMetric?.topPerformer}
											value={performanceMetric?.topPerformerValue ?? 0}
											max={maxValue}
										/>
									)}
									{showFirstQuartile && (
										<Meter
											className="mb-6"
											title="First Quartile"
											variant="info"
											size="lg"
											label={performanceMetric?.firstQuartile}
											value={performanceMetric?.firstQuartileValue ?? 0}
											max={maxValue}
										/>
									)}
									{showDigitalWorldClass && (
										<Meter
											className="mb-6"
											title="Digital World Class®"
											variant="primary"
											size="lg"
											label={performanceMetric?.worldClass}
											value={performanceMetric?.worldClassValue ?? 0}
											max={maxValue}
										/>
									)}
								</Col>
							</Row>
							<Row>
								<Col className="mb-6" xs={6}>
									<h4 className="mb-1 text-muted">Business Process</h4>
									<p className="mb-0">{performanceMetric?.businessProcess}</p>
								</Col>
								<Col className="mb-6" xs={6}>
									<h4 className="mb-1 text-muted">Driver</h4>
									<p className="mb-0">{performanceMetric?.driver}</p>
								</Col>
								<Col className="mb-6" xs={6}>
									<h4 className="mb-1 text-muted">Category</h4>
									<p className="mb-0">{performanceMetric?.category}</p>
								</Col>
								<Col className="mb-6" xs={6}>
									<h4 className="mb-1 text-muted">Source</h4>
									<p className="mb-0">{performanceMetric?.source}</p>
								</Col>
							</Row>
						</AsyncPage>
					</Col>
					<Col xl={4}>
						<Card className="mb-2">
							<Card.Body>
								<div className="mb-4 d-flex align-items-center justify-content-between">
									<Card.Title className="mb-0">Filters</Card.Title>
								</div>

								<Form.Group>
									<Form.Label>Program</Form.Label>
									<Select value={programId ?? ''} onChange={handleProgramSelectChange}>
										{programOptions.map((po) => (
											<option key={po.id} value={po.id}>
												{po.display}
											</option>
										))}
									</Select>
								</Form.Group>
							</Card.Body>
						</Card>
						<LinkBox
							sections={[
								{
									title: "Can't find what you're looking for?",
									links: [
										{
											title: 'Contact Hackett',
											to: '/contact',
											showIcon: true,
										},
									],
								},
							]}
						/>
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
