import { httpSingleton } from '@/lib/http-client';
import {
	BestPracticeItemDetailModel,
	BestPracticeModel,
	BestPracticeDownloadModel,
	PaginatedResponseModel,
	FormOption,
	ProgramFormOption,
} from '@/lib/models';
import { constructUrl } from '@/lib/utils';

export const bestPracticeService = {
	getBestPractices(queryParameters?: {
		parentBusinessProcessIds?: string;
		businessProcessIds?: string;
		page?: string;
		serviceDeliveryComponentIds?: string;
		size?: string;
		sortDirection?: string;
		sortField?: string;
		themeIds?: string;
		programId: string;
	}) {
		return httpSingleton.orchestrateRequest<PaginatedResponseModel & { bestPractices: BestPracticeModel[] }>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/best-practice', queryParameters),
		});
	},
	getBestPractice(bestPracticeId: string, queryParameters?: { programId?: string }) {
		return httpSingleton.orchestrateRequest<{ bestPractice: BestPracticeItemDetailModel }>({
			method: 'GET',
			url: constructUrl(`/advisory-service/api/v1/best-practice/${bestPracticeId}`, queryParameters),
		});
	},
	getFilters(queryParameters?: { programId?: string }) {
		return httpSingleton.orchestrateRequest<{
			businessProcesses: FormOption[];
			serviceDeliveryComponents: FormOption[];
			themes: FormOption[];
			topics: FormOption[];
			programs: ProgramFormOption[];
		}>({
			method: 'GET',
			url: constructUrl('/advisory-service/api/v1/best-practice/filters', queryParameters),
		});
	},
	getBestPracticeDetailsFilters(bestPracticeId: string) {
		return httpSingleton.orchestrateRequest<{
			programs: ProgramFormOption[];
		}>({
			method: 'GET',
			url: `/advisory-service/api/v1/best-practice/${bestPracticeId}/filters`,
		});
	},
	downloadFile(data: { bestPracticeIds: string[]; selectedColumns?: string[] }) {
		return httpSingleton.orchestrateRequest<BestPracticeDownloadModel>({
			method: 'POST',
			url: '/advisory-service/api/v1/best-practice/download',
			data,
		});
	},
};
