import { httpSingleton } from '@/lib/http-client';
import { ReportListItem } from '@/lib/models';
interface AuthTokenModel {
	jasperToken: string;
}

interface ReportListModel {
	reports: ReportListItem[];
}

export const jasperServices = {
	getReportList() {
		return httpSingleton.orchestrateRequest<ReportListModel>({
			method: 'GET',
			url: '/advisory-service/api/v1/reports',
		});
	},
	getReportDetail(reportId: string) {
		return httpSingleton.orchestrateRequest<ReportListItem>({
			method: 'GET',
			url: `/advisory-service/api/v1/report/${reportId}`,
		});
	},
	getAuthToken() {
		return httpSingleton.orchestrateRequest<AuthTokenModel>({
			method: 'GET',
			url: '/advisory-service/api/v1/report/token',
		});
	},
};
