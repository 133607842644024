export const colors = {
	white: '#FFFFFF',
	gray100: '#F8F9FA',
	gray200: '#E9ECEF',
	gray300: '#DEE2E6',
	gray400: '#CED4DA',
	gray500: '#ADB5BD',
	gray600: '#6D757D',
	gray700: '#495057',
	gray800: '#343A40',
	gray900: '#212529',
	black: '#000000',

	primary: '#006FBA',
	secondary: '#F47920',
	tertiary: '#454595',
	success: '#7FAA3A',
	warning: '#F8AF0A',
	danger: '#CC3333',
	info: '#00A1DE',
	dark: '#002F5F',
};
