import React, { FC } from 'react';
import { createUseThemedStyles } from '@/hooks';

const useTableBodyStyles = createUseThemedStyles({
	tableBody: {},
});

export const TableBody: FC = ({ children }) => {
	const classes = useTableBodyStyles();

	return <tbody className={classes.tableBody}>{children}</tbody>;
};
