import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as Quill from 'quill';
import ReactQuill, { Quill as QuillClass } from 'react-quill';
import Color from 'color';
import 'react-quill/dist/quill.snow.css';

import { createUseThemedStyles } from '@/hooks';
import { ReactComponent as LinkIcon } from '@/assets/icons/icon-link.svg';
import { ReactComponent as AIcon } from '@/assets/icons/icon-a.svg';

// Props from react-quill (Could not import directly from their namespace, as they are not exported correctly)
export interface WysiwygProps {
	id?: string;
	className?: string;
	readOnly?: boolean;
	value?: string | Quill.Delta;
	defaultValue?: string | Quill.Delta;
	placeholder?: string;
	tabIndex?: number;
	bounds?: string | HTMLElement;
	scrollingContainer?: string | HTMLElement;
	onChange?: (content: string, delta: Quill.Delta, source: Quill.Sources) => void;
	onChangeSelection?: (range: Quill.RangeStatic, source: Quill.Sources) => void;
	onFocus?: (range: Quill.RangeStatic, source: Quill.Sources) => void;
	onBlur?: (previousRange: Quill.RangeStatic, source: Quill.Sources) => void;
	onKeyPress?: React.EventHandler<any>;
	onKeyDown?: React.EventHandler<any>;
	onKeyUp?: React.EventHandler<any>;
	preserveWhitespace?: boolean;
}

// Delcare toolbar options
// https://quilljs.com/docs/modules/toolbar/
const modules = {
	toolbar: [['bold', 'italic', 'underline', 'link']],
};

// Declare HTML formats that are allowed to be copy and pasted into the editor (regardless of toolbar availablitiy)
// https://quilljs.com/docs/formats/
const formats = ['bold', 'italic', 'underline', 'link'];

// Delcare custom toolbar icons
// https://github.com/zenoamaro/react-quill/issues/188#issuecomment-445272662
const icons = QuillClass.import('ui/icons');
icons['link'] = ReactDOMServer.renderToString(<LinkIcon />);
icons['bold'] = ReactDOMServer.renderToString(<AIcon />);

const useStyles = createUseThemedStyles((theme) => ({
	wysiwyg: {
		borderRadius: 2,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.gray500}`,
		'& .ql-toolbar': {
			border: 0,
			borderBottomLeftRadius: 2,
			borderBottomRightRadius: 2,
			backgroundColor: '#F2F3F3',
			'& button': {
				borderRadius: 2,
				color: theme.colors.primary,
				'& .ql-stroke': {
					stroke: theme.colors.primary,
				},
				'& .ql-fill': {
					fill: theme.colors.primary,
				},
			},
			'& button:hover, & button:focus, & button.ql-active': {
				color: theme.colors.primary,
				backgroundColor: Color(theme.colors.primary)
					.alpha(0.16)
					.string(),
				'& .ql-stroke': {
					stroke: theme.colors.primary,
				},
				'& .ql-fill': {
					fill: theme.colors.primary,
				},
			},
			'& button:focus-visible': {
				outline: 'none',
				boxShadow: `0 0 0 0.2rem ${Color(theme.colors.primary)
					.alpha(0.32)
					.rgb()}`,
			},
		},
		'& .ql-container': {
			border: 0,
			boxShadow: 'inset 0 1px 4px 0 rgba(0, 0, 0, 0.16)',
			'&.ql-disabled': {
				backgroundColor: theme.colors.gray300,
			},
		},
		'&:hover': {
			borderColor: theme.colors.gray600,
		},
		'&:focus-within': {
			borderColor: theme.colors.primary,
		},
	},
}));

export const Wysiwyg = ({ ...props }: WysiwygProps) => {
	const classes = useStyles();

	return <ReactQuill className={classes.wysiwyg} theme={'snow'} modules={modules} formats={formats} {...props} />;
};
