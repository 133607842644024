import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { EngagementCard, EngagementGrid, LinkBox } from '@/components';

export const EngagementDashboard = () => {
	return (
		<Container className="pt-13">
			<Row className="mb-6">
				<Col>
					<h2 className="mb-0">Engagement Dashboard Page</h2>
				</Col>
			</Row>
			<Row>
				<Col xs={12} xl={8} className="mb-3">
					<EngagementGrid>
						<EngagementCard
							count={9}
							title="Books of Numbers distributed"
							achievements={[
								'51 Advisor inquires',
								'3 Advisor consults',
								'2 Briefings/workshops',
								'1 Peer-to-peer conversation',
							]}
						/>
						<EngagementCard
							count={51}
							title="Virtual Webcasts attended"
							achievements={[
								'23 Lorem ipsum dolor',
								'5 Sit amet consectetur',
								'7 Adipiscing elit sed do',
								'11 Eliusmod tempor incididunt',
								'4 Ut labore et dolore magna aliqua',
							]}
						/>
						<EngagementCard
							count={16}
							title="Strategize with Hackett sessions"
							achievements={[
								'5 Sit amet consectetur',
								'7 Adipiscing elit sed do',
								'11 Eliusmod tempor incididunt',
							]}
						/>
						<EngagementCard
							count={9512}
							title="Marketing pushes from Hackett"
							achievements={[
								'Webcast invitations to all registered portal users',
								'Quick Poll and Performance Study invitations',
								'Monthly newsletter',
							]}
						/>
						<EngagementCard
							count={7}
							title="In-person events attended"
							achievements={[
								'23 Lorem ipsum dolor',
								'5 Sit amet consectetur',
								'11 Eiusmod tempor incididunt',
								'4 Ut labore et dolore magna aliqua.',
							]}
						/>
						<EngagementCard
							count={21}
							title="Performance and diagnostic studies"
							achievements={['11 Eiusmod tempor incididunt', '4 Ut labore et dolore magna aliqua']}
						/>
						<EngagementCard
							count={331}
							title="Personalized outreach touchpoints"
							achievements={[
								'23 Lorem ipsum dolor',
								'5 Sit amet consectetur',
								'7 Adipiscing elit sed do',
							]}
						/>
						<EngagementCard
							count={80}
							title="Assessments completed"
							achievements={[
								'7 Adipiscing elit sed do',
								'11 Eiusmod tempor incididunt',
								'4 Ut labore et dolore magna aliqua',
							]}
						/>
						<EngagementCard
							count={14}
							title="Engagement objectives completed"
							achievements={[
								'23 Lorem ipsum dolor',
								'5 Sit amet consectetur ',
								'7 Adipiscing elit sed do ',
								'11 Eiusmod tempor incididunt ',
								'4 Ut labore et dolore magna aliqua ',
							]}
						/>
					</EngagementGrid>
				</Col>
				<Col xs={12} xl={4}>
					<LinkBox
						className="mb-2"
						sections={[
							{
								title: 'Upcoming Milestones',
								links: [
									{ category: 'January 29, 2022', title: 'Complete Competency Assessment', to: '/#' },
									{ category: 'February 2, 2022', title: 'Do On-Site Presentation', to: '/#' },
									{
										category: 'February 16, 2022',
										title: 'One Day Strategic Sourcing Workshop',
										to: '/#',
									},
								],
							},
						]}
						callToAction={{
							title: 'See Engagement Plan',
							to: '/#',
						}}
					/>
					<LinkBox
						sections={[
							{
								title: "Can't find what you're looking for?",
								links: [{ title: 'Contact Hackett', to: '/contact', showIcon: true }],
							},
						]}
					/>
				</Col>
			</Row>
		</Container>
	);
};
