import { format, parse } from 'date-fns';
import { cloneDeep } from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, ModalProps } from 'react-bootstrap';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';

import { SourceModel, InputControlModel, OptionStateModel } from '@/lib/models';
import { createUseThemedStyles } from '@/hooks';
import { Select, DateInput } from '@/components';

const useStyles = createUseThemedStyles((theme) => ({
	dialog: {
		width: '40%',
		maxWidth: 1240,
		margin: '0 auto',
		'& .hackett-modal__body': {
			backgroundColor: theme.colors.gray100,
		},
	},
}));

interface ReportInputControlsProps extends ModalProps {
	inputControls: InputControlModel[];
	reportResources: SourceModel;
	onApply(reportResources: SourceModel): void;
}

export const ReportInputControlsModal = ({
	inputControls,
	setInputControls,
	reportResources,
	onApply,
	...props
}: ReportInputControlsProps) => {
	const classes = useStyles();
	const [multiSelectInputs, setMultiselectInputs] = useState<Record<string, OptionStateModel[]>>();
	const SELECT_ALL_VALUE = 'all';
	const inputTypes: Record<string, string[]> = {
		checkbox: ['bool'],
		singleText: ['singleValueText', 'singleValueNumber'],
		dateText: ['singleValueDate'],
		singleSelect: ['singleSelect', 'singleSelectRadio'],
		multiSelect: ['multiSelect', 'multiSelectCheckbox'],
	};

	const [inputControlsCopy, setInputControlsCopy] = useState<InputControlModel[]>([]);

	useEffect(() => {
		setMultiselectInputs(
			inputControls
				.filter((value) => value.type === 'multiSelect' || value.type === 'multiSelectCheckbox')
				.reduce((previous, current) => {
					return {
						...previous,
						...{
							[current.id]: current.state.options?.filter((selectedItem) => selectedItem.selected),
						},
					};
				}, {})
		);

		setInputControlsCopy(inputControls);
	}, [inputControls]);

	const getInputControlStructure = (inputControl: InputControlModel, inputIndex: number) => {
		const currentInputTarget = cloneDeep(inputControl);

		for (const type in inputTypes) {
			if (
				inputControl &&
				inputControl.state &&
				inputControl.visible &&
				inputTypes[type].indexOf(inputControl.type) !== -1
			) {
				switch (type) {
					case 'checkbox':
						return (
							<Form.Check
								key={inputControl.id}
								inline
								id={inputControl.id}
								bsPrefix="report-form__check--badge"
							>
								<Form.Check.Input
									type="checkbox"
									className="mr-1"
									value={inputControl.state.value?.[0]}
									required={inputControl.mandatory}
									disabled={inputControl.readOnly}
									onChange={(event) => {
										const inputControlList = cloneDeep(inputControlsCopy);

										currentInputTarget.state.value = [event.target.value];
										inputControlList[inputIndex] = currentInputTarget;
										setInputControlsCopy(inputControlList);
									}}
								/>
								<Form.Check.Label data-title={inputControl.label}>
									{inputControl.label}

									{!inputControl.mandatory && (
										<span className="p text-muted font-italic font-weight-regular ml-1">
											- Optional
										</span>
									)}
								</Form.Check.Label>
							</Form.Check>
						);

					case 'singleText':
						const formType = inputControl.type === 'singleValueText' ? 'text' : 'number';
						return (
							<Form.Group className="mb-6" key={inputControl.id}>
								<Form.Label>{inputControl.label}</Form.Label>
								{!inputControl.mandatory && (
									<span className="p text-muted font-italic font-weight-regular ml-1">
										- Optional
									</span>
								)}
								<Form.Control
									type={formType}
									value={inputControl.state.value?.[0]}
									required={inputControl.mandatory}
									disabled={inputControl.readOnly}
									onChange={(event) => {
										let inputControlList = cloneDeep(inputControlsCopy);

										currentInputTarget.state.value = [event.target.value];
										inputControlList[inputIndex] = currentInputTarget;

										setInputControlsCopy(inputControlList);
									}}
								/>
							</Form.Group>
						);

					case 'dateText':
						return (
							<Form.Group className="mb-6">
								<Row>
									<Col>
										<Form.Label>
											{inputControl.label}{' '}
											{!inputControl.mandatory && (
												<span className="text-muted font-italic font-weight-regular">
													- Optional
												</span>
											)}
										</Form.Label>
										<DateInput
											selected={
												inputControl.state.value
													? Array.isArray(inputControl.state.value)
														? inputControl.state.value.length > 0
															? parse(
																inputControl.state.value[0],
																'yyyy-MM-dd',
																new Date()
															)
															: null
														: parse(inputControl.state.value, 'yyyy-MM-dd', new Date())
													: null
											}
											dateFormat="MM/dd/yyyy"
											onChange={(date) => {
												if (!date) {
													return;
												}

												const formattedDate = format(date, 'yyyy-MM-dd');
												const inputControlList = cloneDeep(inputControlsCopy);

												inputControlList[inputIndex].state.value = [formattedDate];
												setInputControlsCopy(inputControlList);
											}}
											required={inputControl.mandatory}
										/>
									</Col>
								</Row>
							</Form.Group>
						);
					case 'singleSelect':
						return (
							<Form.Group className="mb-6" key={inputControl.id}>
								<Form.Label>{inputControl.label}</Form.Label>
								{!inputControl.mandatory && (
									<span className="p text-muted font-italic font-weight-regular ml-1">
										- Optional
									</span>
								)}

								<Select
									{...{
										required: inputControl.mandatory,
										disabled: inputControl.readOnly,
									}}
									value={
										Array.isArray(inputControlsCopy[inputIndex].state.value)
											? inputControlsCopy[inputIndex].state.value?.[0]
											: inputControlsCopy[inputIndex].state.value
									}
									onChange={(event) => {
										if (inputControl.state.options) {
											const previousSelectedValueIndex = inputControl.state.options.findIndex(
												(value) => value.selected
											);

											const selectedValueIndex = inputControl.state.options.findIndex(
												(value) => value.value === event.target.value
											);

											const inputControlList = cloneDeep(inputControlsCopy);

											if (currentInputTarget.state.options) {
												currentInputTarget.state.options[
													previousSelectedValueIndex
												].selected = false;

												currentInputTarget.state.options[selectedValueIndex].selected = true;

												currentInputTarget.state.value =
													currentInputTarget.state.options[selectedValueIndex].value;
											}

											inputControlList[inputIndex] = currentInputTarget;

											setInputControlsCopy(inputControlList);
										}
									}}
								>
									{inputControl.state.options &&
										inputControl.state.options.map(
											(optionItem: OptionStateModel, index: number) => {
												return (
													<option
														key={`${optionItem.value}-${index}`}
														value={optionItem.value}
													>
														{optionItem.label}
													</option>
												);
											}
										)}
								</Select>
							</Form.Group>
						);

					default:
						//multiSelect case
						return (
							<>
								{inputControl.state.options && multiSelectInputs && multiSelectInputs[inputControl.id] && (
									<Form.Group className="mb-6" key={inputControl.id}>
										<Form.Label>
											{inputControl.label}

											{!inputControl.mandatory && (
												<span className="p text-muted font-italic font-weight-regular ml-1">
													- Optional
												</span>
											)}
										</Form.Label>

										<Typeahead
											id={inputControl.id}
											multiple
											inputProps={{
												required:
													inputControl.mandatory &&
													!multiSelectInputs[inputControl.id].length,
											}}
											paginate={true}
											selected={multiSelectInputs[inputControl.id]}
											onChange={(currentSelection: any) => {
												setMultiselectInputs((prevState: any) => {
													if (currentSelection.length && currentSelection[currentSelection.length - 1].value === SELECT_ALL_VALUE && typeof inputControl.state.options !== 'undefined') {
														return currentSelection.length > inputControl.state.options.length ? { ...prevState, [inputControl.id]: [] } : { ...prevState, [inputControl.id]: inputControl.state.options }
													}

													return { ...prevState, [inputControl.id]: currentSelection };
												});
											}}
											options={(() => {
												return [
													{
														selected: multiSelectInputs[inputControl.id].length === inputControl.state.options.length,
														label: 'Select All',
														value: SELECT_ALL_VALUE
													},
													...inputControl.state.options
												]
											})()}
											placeholder=""
											renderMenu={(results: any, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }) => {
												return (
													<>
														<Menu {...menuProps}>
															{results.map((result: any, index: number) => (
																<React.Fragment key={`${index}-${result.value}`}>
																	<MenuItem option={result} position={index} key={`${index}-${result.value}`}>
																		<input type='checkbox' checked={result.selected} onChange={() => { }} /> {result.value === SELECT_ALL_VALUE ? (
																			<strong>{result.label}</strong>
																		) : result.label === "" ? <strong>Load More</strong> : result.label}
																	</MenuItem>
																</React.Fragment>
															))}
														</Menu>
													</>
												);
											}}
										/>
									</Form.Group>
								)}
							</>
						);
				}
			}
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const rawInputControlValues = inputControlsCopy.map((inputItem) => {
			const inputItemCopy = cloneDeep(inputItem);

			if (
				typeof inputItemCopy.state.options !== 'undefined' &&
				!inputTypes.multiSelect.includes(inputItemCopy.type)
			) {
				const selectedValue = inputItemCopy.state.options.find((item) => item.selected);

				return {
					[inputItemCopy.id]: selectedValue ? [selectedValue.value] : [],
				};
			} else {
				return {
					[inputItemCopy.id]: Array.isArray(inputItemCopy.state.value)
						? inputItemCopy.state.value
						: [inputItemCopy.state.value],
				};
			}
		});

		const inputControlValues = rawInputControlValues.reduce(
			(previous, current) => ({ ...previous, ...current }),
			{}
		);

		let multiSelectInputValues: any = {};

		for (const key in multiSelectInputs) {
			multiSelectInputValues[key as string] = multiSelectInputs[key as string].map((input: any) => input.value);
		}

		let chartParams = {
			...reportResources,
			chartParams: { ...inputControlValues, ...multiSelectInputValues },
		};

		onApply(chartParams);
	};

	return (
		<Modal dialogClassName={classes.dialog} centered {...props}>
			<Form onSubmit={handleSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>Filters</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{inputControlsCopy &&
						inputControlsCopy.map((inputControl: InputControlModel, inputIndex: number) => {
							return (
								<Fragment key={inputControl.id}>
									{getInputControlStructure(inputControl, inputIndex)}
								</Fragment>
							);
						})}
				</Modal.Body>

				<Modal.Footer>
					<div className="text-right">
						<Button className="mr-2" variant="link" onClick={props.onHide}>
							Cancel
						</Button>
						<Button type="submit">Apply</Button>
					</div>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
