import React, { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

import { DocumentModel } from '@/lib/models';
import {
	createUseThemedStyles,
	useAlert,
	useAnalytics,
	useCopyTextToClipboard,
	useHandleError,
	useNavigationConfig,
} from '@/hooks';

import { ReactComponent as ViewIcon } from '@/assets/icons/file.svg';
import { ReactComponent as DownloadIcon } from '@/assets/icons/download.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';
import { ReactComponent as TagIcon } from '@/assets/icons/icon-tags.svg';
import { ReactComponent as ChevronIcon } from '@/assets/icons/icon-chevron-right.svg';
import { ReactComponent as BookmarkIcon } from '@/assets/icons/bookmark.svg';
import { ReactComponent as BookmarkFilledIcon } from '@/assets/icons/bookmark-filled.svg';
import { documentService } from '@/lib/services';
import { downloadFileAtUrl } from '@/lib/utils';

const useStyles = createUseThemedStyles((theme) => ({
	item: {
		padding: '24px 0',
		borderBottom: `1px solid ${theme.colors.gray300}`,
	},
	chevronIcon: {
		marginTop: -3,
		marginLeft: 5,
	},
}));

interface SearchListItemProps {
	id: string;
	tags?: string;
	category: string;
	title: string;
	description: string;
	detailsUrl: string;
	downloadUrl?: string;
	fileType?: string;
	fileName?: string;
	viewUrl?: string;
	canDownload?: boolean;
	type: string;
	isBookmarked: boolean;
	onChange(document: DocumentModel): void;
	isPodcast?: boolean;
}

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const SearchListItem: FC<SearchListItemProps> = ({
	id,
	tags,
	category,
	title,
	description,
	detailsUrl,
	downloadUrl,
	viewUrl,
	canDownload,
	fileType,
	fileName,
	type,
	isBookmarked,
	onChange,
	isPodcast,
}) => {
	const classes = useStyles();
	const { copyTextToClipboard } = useCopyTextToClipboard();
	const { navigationConfig } = useNavigationConfig();
	const handleError = useHandleError();
	const { showAlert } = useAlert();
	const [isBookmarking, setIsBookmarking] = useState(false);
	const analytics = useAnalytics();
	const handleShareButtonClick = () => {
		copyTextToClipboard(`${window.location.origin}/${detailsUrl}`, 'Link copied to clipboard.');
	};

	const handleSaveBookmarkButtonClick = useCallback(async () => {
		try {
			setIsBookmarking(true);

			const response = await documentService.addBookmark(id).fetch();
			if (onChange) {
				onChange(response);
			}

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Bookmark saved.</p>;
				},
			});
		} catch (error) {
			handleError(error);
		} finally {
			setIsBookmarking(false);
		}
	}, [id, handleError, onChange, showAlert]);

	const handleRemoveBookmarkButtonClick = useCallback(async () => {
		try {
			setIsBookmarking(true);

			const response = await documentService.removeBookmark(id).fetch();
			if (onChange) {
				onChange(response);
			}

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Bookmark removed.</p>;
				},
			});
		} catch (error) {
			handleError(error);
		} finally {
			setIsBookmarking(false);
		}
	}, [id, handleError, onChange, showAlert]);

	const handleDownloadClick = useCallback(async () => {
		if (viewUrl) {
			try {
				await downloadFileAtUrl(viewUrl, fileName ?? title);
				documentService.trackDocumentDownload(id).fetch();
				analytics.handleClickEvent(viewUrl);
			} catch (error) {
				handleError(error);
			}
		}
	}, [analytics, fileName, handleError, id, title, viewUrl]);

	return (
		<>
			<div className={classes.item}>
				<Row className="mb-1">
					<Col>
						<div className="mb-2 d-flex align-items-center justify-content-between">
							<h4 className="mb-0 d-flex align-items-center text-muted">{category}</h4>
							<div className="d-flex align-items-center text-primary">
								{viewUrl && (
									<Button
										variant="link"
										className="mr-1"
										href={isPodcast ? detailsUrl : viewUrl}
										target={isPodcast ? '_self' : '_blank'}
										rel={isPodcast ? '' : 'noopener'}
										onClick={() => {
											if (!isPodcast) {
												// Don't track podcast views - podcast urls go to the details page which tracks views
												documentService.trackDocumentView(id).fetch();
											}
											analytics.handleClickEvent(viewUrl);
										}}
									>
										<ViewIcon />
									</Button>
								)}
								{navigationConfig?.allowDownloads && canDownload && viewUrl && (
									<Button className="mr-1" variant="link" onClick={handleDownloadClick}>
										<DownloadIcon />
									</Button>
								)}
								<Button variant="link" className="mx-1" onClick={handleShareButtonClick}>
									<CopyIcon />
								</Button>
								{type === 'document' && (
									<>
										{isBookmarked ? (
											<Button
												variant="link"
												className="ml-1"
												onClick={handleRemoveBookmarkButtonClick}
												disabled={isBookmarking}
											>
												<BookmarkFilledIcon />
											</Button>
										) : (
											<Button
												variant="link"
												className="ml-1"
												onClick={handleSaveBookmarkButtonClick}
												disabled={isBookmarking}
											>
												<BookmarkIcon />
											</Button>
										)}
									</>
								)}
							</div>
						</div>
						{title ? (
							<ResponsiveEllipsis text={title ?? ''} maxLine="2" component="h3" />
						) : (
							<ResponsiveEllipsis text={description ?? ''} maxLine="2" component="p" />
						)}
					</Col>
				</Row>

				{title && description && (
					<Row className="mb-2">
						<Col>
							<ResponsiveEllipsis text={description} maxLine="2" component="p" />
						</Col>
					</Row>
				)}

				<Row>
					<Col>
						<div className="d-flex align-items-start justify-content-between">
							<div>
								{tags && (
									<>
										<TagIcon className="text-primary" />
										<span className="small font-weight-semi-bold text-muted ml-2">{tags}</span>
									</>
								)}
							</div>
							{detailsUrl && (
								<div className="d-flex align-items-start justify-content-between text-primary ml-4">
									<strong>
										<Link to={detailsUrl}>
											View Details <ChevronIcon className={classes.chevronIcon} />
										</Link>
									</strong>
								</div>
							)}
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
};
