import React, { RefObject } from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';
import { ReactComponent as MagnifyingGlassIcon } from '@/assets/icons/icon-magnifying-glass.svg';
import { BsPrefixProps } from 'react-bootstrap/esm/helpers';

interface UseStyleProps {
	hideIcon?: boolean;
}

const useStyles = createUseThemedStyles(
	(theme) => ({
		searchInputOuter: {
			minWidth: 300,
			position: 'relative',
		},
		icon: {
			left: 14,
			top: '50%',
			position: 'absolute',
			pointerEvents: 'none',
			transform: 'translateY(-50%)',
			fill: theme.colors.primary,
		},
		searchInput: {
			width: '100%',
			textIndent: 0,
			paddingLeft: ({ hideIcon }: UseStyleProps) => (hideIcon ? 15 : 40),
			paddingRight: 15,
		},
	}),
	{ index: 2 }
);

// Copy of FormControlProps from react-bootstrap with custom element types
// Allows for use with react-boostrap-typeahead
interface SearchInputProps extends BsPrefixProps, React.HTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
	htmlSize?: number;
	size?: 'sm' | 'lg';
	plaintext?: boolean;
	readOnly?: boolean;
	disabled?: boolean;
	value?: string | string[] | number;
	onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
	type?: string;
	isValid?: boolean;
	isInvalid?: boolean;
	hideIcon?: boolean;
}

export const SearchInput = React.forwardRef(
	(
		{ className, hideIcon, ...props }: SearchInputProps,
		ref: ((instance: HTMLInputElement | null) => void) | RefObject<HTMLInputElement> | null | undefined
	) => {
		const classes = useStyles({
			hideIcon,
		});

		return (
			<div className={classNames(classes.searchInputOuter, className)}>
				{!hideIcon && <MagnifyingGlassIcon className={classes.icon} />}
				<Form.Control
					ref={ref}
					className={classes.searchInput}
					type="search"
					placeholder="Search..."
					{...props}
				/>
			</div>
		);
	}
);
