import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import { createUseThemedStyles, useAlert, useHandleError } from '@/hooks';
import { BundleModel, BundleProgramModel, PROGRAM_ACCESS } from '@/lib/models';
import { bundleService, salesLeadService } from '@/lib/services';
import { AsyncPage } from '@/components';
import { mediaQueries } from '@/jss';

import { ReactComponent as StarIcon } from '@/assets/icons/icon-star.svg';

import { ReactComponent as ArrowsIcon } from '@/assets/icons/icon-arrows.svg';
import { ReactComponent as BarchartIcon } from '@/assets/icons/icon-barchart-up.svg';
import { ReactComponent as RocketIcon } from '@/assets/icons/icon-rocket.svg';
import { ReactComponent as ShieldIcon } from '@/assets/icons/icon-shield.svg';
import { ReactComponent as SignpostIcon } from '@/assets/icons/icon-signpost.svg';

const useStyles = createUseThemedStyles((theme) => ({
	oneProgramColumn: {
		columnGap: 30,
		columnCount: 1,
		[mediaQueries.md]: {
			columnCount: 2,
		},
		[mediaQueries.lg]: {
			columnCount: 4,
		},
	},
	twoProgramColumn: {
		columnGap: 30,
		columnCount: 1,
		[mediaQueries.lg]: {
			columnCount: 2,
		},
	},
	reasonRow: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: '0 -7.5px',
		justifyContent: 'center',
	},
	reasonColumn: {
		width: '100%',
		marginBottom: 35,
		padding: '0 7.5px',
		[mediaQueries.lg]: {
			width: '33.33%',
		},
		'@media(min-width: 1400px)': {
			width: '20%',
		},
	},
	reasonIconOuter: {
		width: 160,
		margin: '0 auto 25px',
	},
	reasonIcon: {
		position: 'relative',
		paddingBottom: '100%',
		backgroundColor: theme.colors.gray300,
		'& svg': {
			top: '50%',
			left: '50%',
			position: 'absolute',
			transform: 'translate(-50%, -50%)',
		},
	},
}));

export const ProgramBundle = () => {
	const classes = useStyles();

	const { bundleId } = useParams<{ bundleId: string }>();
	const handleError = useHandleError();
	const { showAlert } = useAlert();
	const [bundle, setBundle] = useState<BundleModel>();
	const [showFullAccessModal, setShowFullAccessModal] = useState(false);
	const [showUpgradeModal, setShowUpgradeModal] = useState(false);

	const fiveReasons = useMemo(
		() => [
			{
				bgClassName: 'bg-dark',
				title: 'Strategic Insight',
				description:
					'Our independent research and analysis from experienced practitioners help functional leaders navigate the complexities of the evolving business services landscape.',
				Icon: SignpostIcon,
			},
			{
				bgClassName: 'bg-info',
				title: 'Targeted Improvement',
				description:
					'Benchmark metrics and guidance to select the right ones helps leaders to understand performance relative to peer and Digital World Class<sup>&reg;</sup> to determine next steps on the journey.',
				Icon: BarchartIcon,
			},
			{
				bgClassName: 'bg-primary',
				title: 'Accelerate Benefits',
				description:
					'Diagnostic tools help you identify opportunities for improvement, fast. On-demand guidance from experienced advisors helps to convert these to action quickly.',
				Icon: RocketIcon,
			},
			{
				bgClassName: 'bg-warning',
				title: 'Risk Mitigation',
				description:
					'Best practice insight and guidance on how to apply these helps you avoid pitfalls in your journey. An abundance of case material helps you by learning from the best.',
				Icon: ShieldIcon,
			},
			{
				bgClassName: 'bg-success',
				title: 'Manage Change',
				description:
					'Knowledge empowers you and your direct reports to implement change leveraging a wealth of information available on a dedicated online member portal.',
				Icon: ArrowsIcon,
			},
		],
		[]
	);

	const fetchData = useCallback(async () => {
		const response = await bundleService.getBundleByBundleId(bundleId).fetch();
		setBundle(response);
	}, [bundleId]);

	const handleUpdateConfirm = useCallback(async () => {
		try {
			await salesLeadService.sendProgramBundleEmail(bundleId).fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return (
						<p className="mb-0 text-white">
							Thank you for your interest. Your Advisory Team will be in contact with you.
						</p>
					);
				},
			});
			setShowFullAccessModal(false);
			setShowUpgradeModal(false);
		} catch (error) {
			handleError(error);
		}
	}, [bundleId, handleError, showAlert]);

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Program Bundle</title>
			</Helmet>

			<Modal
				show={showFullAccessModal}
				centered
				onHide={() => {
					setShowFullAccessModal(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Get Access Now</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="mb-0">
						To learn more about how you can get access to this program please click the "Confirm" button
						below, and a member of your Advisory Team will get in touch with you.
					</p>
				</Modal.Body>
				<Modal.Footer className="text-right">
					<Button onClick={handleUpdateConfirm}>Confirm</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showUpgradeModal}
				centered
				onHide={() => {
					setShowUpgradeModal(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Upgrade Now</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="mb-0">
						To learn more about upgrading your program to the full program scope please click the "Confirm"
						button below, and a member of your Advisory Team will get in touch with you.
					</p>
				</Modal.Body>
				<Modal.Footer className="text-right">
					<Button onClick={handleUpdateConfirm}>Confirm</Button>
				</Modal.Footer>
			</Modal>

			<AsyncPage fetchData={fetchData}>
				<Container className="bg-white" fluid>
					<Container className="py-11">
						<Row>
							<Col>
								<h1
									className="mb-4 text-primary"
									dangerouslySetInnerHTML={{ __html: bundle?.name ?? '' }}
								/>
								<p
									className={classNames({
										'mb-4': bundle?.programAccess === PROGRAM_ACCESS.NONE,
										'mb-0': bundle?.programAccess !== PROGRAM_ACCESS.NONE,
									})}
								>
									{bundle?.description}
								</p>
								{bundle?.programAccess === PROGRAM_ACCESS.NONE && (
									<div className="text-center">
										<Button
											variant="secondary"
											onClick={() => {
												setShowFullAccessModal(true);
											}}
										>
											Get Access Now
										</Button>
									</div>
								)}
							</Col>
						</Row>
					</Container>
				</Container>

				{bundle?.programDeliverables ? (
					<Container className="bg-gray-100" fluid>
						<Container className="py-11" dangerouslySetInnerHTML={{ __html: bundle.programDeliverables }} />
					</Container>
				) : (
					<Container className="bg-gray-100" fluid>
						<Container className="py-11">
							<Row className="mb-4">
								<Col>
									<h2 className="mb-0 text-primary">Program Scope</h2>
								</Col>
							</Row>
							{(bundle?.programs ?? []).length === 1 && (
								<>
									{(bundle?.programs ?? [])[0].businessProcesses.length <= 4 ? (
										<Row className="mb-4">
											<Col md={6} lg={3}>
												<ProgramColumn program={bundle?.programs[0]} />
											</Col>
										</Row>
									) : (
										<Row className="mb-4">
											<ProgramColumn
												className={classes.oneProgramColumn}
												program={bundle?.programs[0]}
											/>
										</Row>
									)}
								</>
							)}
							{(bundle?.programs ?? []).length === 2 && (
								<Row className="mb-4">
									<Col md={6} className="mb-4">
										<ProgramColumn
											className={classes.twoProgramColumn}
											program={bundle?.programs[0]}
										/>
									</Col>
									<Col md={6}>
										<ProgramColumn
											className={classes.twoProgramColumn}
											program={bundle?.programs[1]}
										/>
									</Col>
								</Row>
							)}
							{(bundle?.programs ?? []).length >= 3 && (
								<Row>
									{bundle?.programs.map((program, programIndex) => (
										<Col key={programIndex} md={6} lg={3} className="mb-4">
											<ProgramColumn program={program} />
										</Col>
									))}
								</Row>
							)}

							{bundle?.programAccess !== PROGRAM_ACCESS.NONE && (
								<Row>
									<Col>
										<p className="d-flex align-items-start h5 small">
											<StarIcon
												width={15}
												height={15}
												className="mr-1 text-warning flex-shrink-0"
												style={{ marginTop: 2 }}
											/>
											= included in your membership
										</p>
									</Col>
								</Row>
							)}
						</Container>
					</Container>
				)}

				{bundle?.programAccess === PROGRAM_ACCESS.PARTIAL && (
					<Container className="bg-gray-100" fluid>
						<Container className="pb-11">
							<Row>
								<Col>
									<div className="text-center">
										<Button
											variant="secondary"
											onClick={() => {
												setShowUpgradeModal(true);
											}}
										>
											Upgrade Now
										</Button>
									</div>
								</Col>
							</Row>
						</Container>
					</Container>
				)}

				<Container className="bg-white" fluid>
					<Container className="pt-7 pb-4">
						<Row className="mb-8">
							<Col>
								{bundle?.fiveReasons ? (
									<>
										<h1 className="mb-2 text-primary">
											Why join our Digital World Class<sup>&reg;</sup> membership
										</h1>
										<h2 className="mb-0 text-muted">Five Reasons</h2>
									</>
								) : (
									<>
										<h1 className="text-center text-primary">
											Why join our market intelligence program?
										</h1>
									</>
								)}
							</Col>
						</Row>
						<div className={classes.reasonRow}>
							{fiveReasons.map((reason, reasonIndex) => (
								<div key={reasonIndex} className={classes.reasonColumn}>
									<div className={classes.reasonIconOuter}>
										<div className={classNames(classes.reasonIcon, reason.bgClassName)}>
											<reason.Icon width={90} height={90} />
										</div>
									</div>
									<h3 className="mb-4 text-center">{reason.title}</h3>
									<hr className="mb-4" />
									<p className="mb-0" dangerouslySetInnerHTML={{ __html: reason.description }} />
								</div>
							))}
						</div>
					</Container>
				</Container>
			</AsyncPage>
		</>
	);
};

const ProgramColumn = ({ className, program }: { className?: string; program?: BundleProgramModel }) => {
	return (
		<>
			<img className="mb-2" style={{ width: '50px' }} src={program?.iconUrl} alt="" />
			<h4 className="mb-2 text-uppercase">
				{program?.hasAccess && (
					<StarIcon
						width={15}
						height={15}
						className="mr-1 text-warning flex-shrink-0"
						style={{ marginTop: -5 }}
					/>
				)}
				{program?.name}
			</h4>
			<div className={className}>
				<ul className="mb-0">
					{program?.businessProcesses.map((process, processIndex) => (
						<li key={processIndex}>{process}</li>
					))}
				</ul>
			</div>
		</>
	);
};
