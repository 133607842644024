import React from 'react';
import { Form } from 'react-bootstrap';

import { ReportExtensionModel } from '@/lib/models';
import { Select } from '@/components';

interface ReportOptionsProps {
	downloadOptions: ReportExtensionModel[] | undefined;
	isDownloadInProgress: boolean;
	currentReportOutput: string;
	handleDownloadReport: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ReportOptions = ({
	downloadOptions,
	isDownloadInProgress,
	currentReportOutput,
	handleDownloadReport,
}: ReportOptionsProps) => {
	return (
		<>
			{downloadOptions && (
				<div className="d-flex align-items-center">
					{isDownloadInProgress ? (
						<Form.Label className="mb-0">Downloading report... </Form.Label>
					) : (
						<>
							<Form.Label className="mb-0">Download format: </Form.Label>
							<Select
								variant="secondary"
								onChange={handleDownloadReport}
								defaultValue={currentReportOutput}
							>
								<option value="">None</option>
								{downloadOptions.map((extension, index) => {
									return (
										<option key={`${index}-${extension.name}`} value={extension.name}>
											{extension.description}
										</option>
									);
								})}
							</Select>
						</>
					)}
				</div>
			)}
		</>
	);
};
