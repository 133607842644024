import React from 'react';
import classNames from 'classnames';
import Color from 'color';

import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles((theme) => ({
	introductionItem: {
		padding: 10,
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.colors.white,
	},
	iconOuter: {
		width: 60,
		height: 60,
		flexShrink: 0,
		display: 'flex',
		marginRight: 10,
		borderRadius: '50%',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.colors.white,
		backgroundColor: Color(theme.colors.primary)
			.desaturate(0.6)
			.lighten(0.7)
			.hex(),
	},
	message: {
		flex: 1,
		margin: 0,
		...theme.fonts.openSansBold,
	},
}));

interface IntroductionItemProps {
	iconElement: JSX.Element;
	message: string;
	className?: string;
}

export const IntroductionItem = ({ iconElement, message, className }: IntroductionItemProps) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.introductionItem, className)}>
			<div className={classes.iconOuter}>{iconElement}</div>
			<p className={classes.message}>{message}</p>
		</div>
	);
};
