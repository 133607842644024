import React from 'react';
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import Color from 'color';

import { createUseThemedStyles } from '@/hooks';
import { focus } from '@/jss';

import { ReactComponent as DownloadIcon } from '@/assets/icons/icon-download.svg';

const useStyles = createUseThemedStyles((theme) => ({
	fileDropzone: {
		padding: 20,
		width: '100%',
		borderRadius: 2,
		cursor: 'pointer',
		border: `1px solid ${theme.colors.primary}`,
		'&:hover': {
			backgroundColor: Color(theme.colors.primary)
				.alpha(0.16)
				.string(),
		},
		'&:focus-within': {
			...focus(theme.colors.primary),
		},
	},
}));

interface FileDropzoneProps {
	accept?: Accept;
	maxSize?: number;
	disabled?: boolean;
	onChange: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
}

export const FileDropzone = ({ accept, maxSize, disabled, onChange }: FileDropzoneProps) => {
	const classes = useStyles();

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: onChange,
		accept,
		maxSize,
		disabled,
	});

	return (
		<div className={classes.fileDropzone} {...getRootProps()}>
			<input {...getInputProps()} />

			{isDragActive ? (
				<div>
					<div className="mb-2 text-center">
						<DownloadIcon className="text-primary" />
					</div>
					<p className="mb-0 text-center">Drop files...</p>
				</div>
			) : (
				<div>
					<div className="mb-2 text-center">
						<DownloadIcon className="text-primary" />
					</div>
					<p className="mb-0 text-center">
						Drag and drop multiple files here, or{' '}
						<span className="text-primary bold">click to select files</span>
					</p>
				</div>
			)}
		</div>
	);
};
