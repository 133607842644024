import React, { FC, useRef } from 'react';
import Masonry from 'react-masonry-css';

import { createUseThemedStyles } from '@/hooks';
import { boxShadow } from '@/jss';

const gutterSize = 16;

const useStyles = createUseThemedStyles((theme) => ({
	engagementGrid: {
		width: 'auto',
		display: 'flex',
		marginLeft: -gutterSize,
		'& .engagement-grid__column': {
			paddingLeft: gutterSize,
			backgroundClip: 'padding-box',
		},
	},
	engagementCard: {
		...boxShadow,
		marginBottom: gutterSize,
		backgroundColor: theme.colors.white,
	},
	count: {
		fontSize: '3.2rem',
		lineHeight: '4.3rem',
		marginBottom: '0.3rem',
		letterSpacing: '0.053rem',
		color: theme.colors.success,
		...theme.fonts.openSansSemiBold,
	},
}));

export const EngagementGrid: FC = ({ children }) => {
	const classes = useStyles();

	const breakPointCols = useRef({
		default: 3,
		991: 2,
		575: 1,
	}).current;

	return (
		<Masonry
			breakpointCols={breakPointCols}
			className={classes.engagementGrid}
			columnClassName="engagement-grid__column"
		>
			{children}
		</Masonry>
	);
};

interface EngagementCardProps {
	count: number;
	title: string;
	achievements: string[];
}

export const EngagementCard = ({ count, title, achievements }: EngagementCardProps) => {
	const classes = useStyles();

	return (
		<div className={classes.engagementCard}>
			<div className="py-3 px-4">
				<h1 className={classes.count}>{count}</h1>
				<p className="mb-0 font-weight-semi-bold">{title}</p>
			</div>
			<hr />
			<div className="py-3 px-4">
				<ul className="m-0 p-0 list-unstyled">
					{achievements.map((achievement, index) => {
						const isLast = index === achievements.length - 1;
						return (
							<li key={index} className={isLast ? 'mb-0' : 'mb-2'}>
								<span className="small">{achievement}</span>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};
