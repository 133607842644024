import React from 'react';
import classNames from 'classnames';

import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles((theme) => ({
	avatar: ({ size }: { size: number }) => ({
		width: size,
		height: size,
		flexShrink: 0,
		borderRadius: '50%',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.colors.gray300,
	}),
}));

interface AvatarProps {
	size: number;
	url: string;
	className?: string;
}

export const Avatar = ({ size, url, className }: AvatarProps) => {
	const classes = useStyles({
		size,
	});

	return <div className={classNames(classes.avatar, className)} style={{ backgroundImage: `url(${url})` }} />;
};
