import React, { FC } from 'react';
import { createUseThemedStyles } from '@/hooks';

const useTableHeadStyles = createUseThemedStyles((theme) => ({
	tableHead: {
		'& tr': {
			borderBottom: `1px solid ${theme.colors.gray200}`,
		},
	},
}));

export const TableHead: FC = (props) => {
	const classes = useTableHeadStyles();

	return <thead className={classes.tableHead}>{props.children}</thead>;
};
