import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { createUseThemedStyles } from '@/hooks';
import { ReactComponent as QuestionIcon } from '@/assets/icons/icon-question.svg';

const useStyles = createUseThemedStyles((theme) => ({
	supportOuter: {
		borderRadius: 4,
		padding: '48px 48px 38px',
		backgroundColor: theme.colors.gray200,
		border: `1px solid ${theme.colors.gray400}`,
	},
}));

export const GetHelp = () => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<div className={classes.supportOuter}>
			<div className="mb-5 d-flex justify-content-center">
				<QuestionIcon className="text-primary" />
			</div>
			<p className="mb-3 text-center">
				<strong>Your Advisory Team is here to help.</strong>
			</p>
			<p className="mb-6 text-center">Reach out to us for knowledge, insight, or assistance.</p>

			<Button
				variant="outline-primary"
				className="w-100 px-0"
				onClick={() => {
					history.push('/advisory-team');
				}}
			>
				Meet Your Team
			</Button>
		</div>
	);
};
