import React, { useRef } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { Inquiries, NoMatch, Profile, Programs } from '@/pages';
import { NavigationSub } from '@/components';
import { useNavigationConfig } from '@/hooks';


export const Account = () => {
	const { navigationConfig } = useNavigationConfig();
	const { path } = useRouteMatch();

	const routes = useRef([
		{
			path: path,
			exact: true,
			main: () => <Redirect to={`${path}/profile`} />,
		},
		{
			path: `${path}/profile`,
			exact: true,
			main: Profile,
		},
		{
			path: `${path}/programs`,
			exact: true,
			main: Programs,
		},
		...(navigationConfig?.allowClientManagement && navigationConfig?.allowAccessToInquiries
			? [{
				path: `${path}/inquiry-settings`,
				exact: true,
				main: Inquiries,
			}] 
			: []),
		{
			path: `${path}/*`,
			exact: false,
			main: NoMatch,
		},
	]).current;

	return (
		<>
			<NavigationSub
				navigationItems={[
					{
						to: `${path}/profile`,
						title: 'Profile',
					},
					{
						to: `${path}/programs`,
						title: 'Programs',
					},
					...(navigationConfig?.allowClientManagement && navigationConfig?.allowAccessToInquiries
						? [{ to: `${path}/inquiry-settings`, title: 'Inquiry Settings' }]
						: []),
				]}
			/>

			<Switch>
				{routes.map((route, index) => {
					return <Route key={index} path={route.path} exact={route.exact} children={<route.main />} />;
				})}
			</Switch>
		</>
	);
};
