import React, { RefObject, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { constructUrl } from '@/lib/utils';
import { createUseThemedStyles } from '@/hooks';
import { SearchInput } from './search-input';

import { ReactComponent as MagnifyingGlassIcon } from '@/assets/icons/icon-magnifying-glass.svg';

const useStyles = createUseThemedStyles((theme) => ({
	globalSearchOuter: {
		position: 'relative',
		alignItems: 'center',
	},
	searchButton: {
		top: 0,
		right: 0,
		bottom: 0,
		padding: '0 12px',
		position: 'absolute',
		borderTopRightRadius: 2,
		borderBottomRightRadius: 2,
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.gray500}`,
	},
}));

interface HeaderSearchProps {
	className?: string;
}

export const HeaderSearch = React.forwardRef(
	(
		{ className }: HeaderSearchProps,
		ref: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined
	) => {
		const classes = useStyles();
		const history = useHistory();
		const [inputValue, setInputValue] = useState('');

		const handleSearchSubmit = useCallback(
			(query: string) => {
				history.push(constructUrl('/search', { query }));
			},
			[history]
		);

		return (
			<div ref={ref} className={classNames(classes.globalSearchOuter, className)}>
				<SearchInput
					hideIcon
					value={inputValue}
					onChange={({ currentTarget }) => {
						setInputValue(currentTarget.value);
					}}
					onKeyDown={({ key }) => {
						if (key === 'Enter') {
							handleSearchSubmit(inputValue);
						}
					}}
				/>
				<button
					className={classes.searchButton}
					onClick={() => {
						handleSearchSubmit(inputValue);
					}}
				>
					<MagnifyingGlassIcon className="text-primary" />
				</button>
			</div>
		);
	}
);
