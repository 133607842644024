import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { Typeahead } from 'react-bootstrap-typeahead';

import config from '@/lib/config';
import { AccountOptionModel, FormOption } from '@/lib/models';
import { accountService } from '@/lib/services';
import { createUseThemedStyles, useAccount, useAlert, useCanManageBookingsUrl, useHandleError } from '@/hooks';
import { AsyncPage, FreshDeskLink, LoadingButton, Select } from '@/components';
import { mediaQueries } from '@/jss';
import {
	constructUrl,
	mutateBpicLocationFormatToInputFormat,
	mutateGooglePlaceResultToBpicLocationFormat,
} from '@/lib/utils';
import { Helmet } from 'react-helmet';

const useStyles = createUseThemedStyles((theme) => ({
	formRow: {
		boxShadow: `${theme.colors.gray300} 0px 2px 8px`,
		margin: '0',
	},
	formCol: {
		background: theme.colors.white,
		padding: '40px 55px',
	},
	firstFormCol: {
		borderBottom: `1px solid ${theme.colors.gray200}`,
		[mediaQueries.lg]: {
			borderRight: `1px solid ${theme.colors.gray200}`,
			borderBottom: '0',
		},
	},
}));

export const Profile = () => {
	const classes = useStyles();
	const handleError = useHandleError();
	const { showAlert } = useAlert();
	const { account, setAccount } = useAccount();
	const canManageBookingsUrl = useCanManageBookingsUrl();

	const [accountLevelOptions, setAccountLevelOptions] = useState<AccountOptionModel[]>([]);
	const [accountFunctionSelectedOptions, setAccountFunctionSelectedOptions] = useState<FormOption[]>([]);
	const [accountFunctionOptions, setAccountFunctionOptions] = useState<FormOption[]>([]);

	const [profileFormEnabled, setProfileFormEnabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [profileForm, setProfileForm] = useState({
		name: '',
		title: '',
		userLevelTagId: '',
		userFunctionTagIds: [] as string[],
		emailAddress: '',
		...(canManageBookingsUrl && { bookingsUrl: '' }),
		mobileNumber: '',
		location: '',
	});
	const locationInputRef = useRef<HTMLInputElement>(null);
	const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
	const [isLocationValid, setIsLocationValid] = useState(true);

	const fetchData = useCallback(async () => {
		const [levelOptionsResponse, functionOptionsResponse, accountResponse] = await Promise.all([
			accountService.getAccountOptions('USER_LEVEL').fetch(),
			accountService.getAccountOptions('USER_FUNCTION').fetch(),
			accountService.getCurrentAccount().fetch(),
		]);
		setAccount(accountResponse.account);
		setAccountLevelOptions(levelOptionsResponse.options);

		const reformattedOptionArray = functionOptionsResponse.options.map(function(obj) {
			const rObj = {
				id: obj.tagId,
				display: obj.name,
			};
			return rObj;
		});
		setAccountFunctionOptions(reformattedOptionArray);
	}, [setAccount]);

	const handleEditProfileButtonClick = () => {
		setProfileFormEnabled(true);
		setProfileForm({
			name: account?.name ?? '',
			title: account?.title ?? '',
			userLevelTagId: account?.userLevelTagId ?? '',
			userFunctionTagIds: account?.userFunctionTagIds ?? [],
			emailAddress: account?.emailAddress ?? '',
			...(canManageBookingsUrl && { bookingsUrl: account?.bookingsUrl ?? '' }),
			mobileNumber: account?.mobileNumber ?? '',
			location: account?.location ?? '',
		});
		if (account?.userFunctionTagIds) {
			const functionSelectedOptionFilter = accountFunctionOptions.filter(({ id }) =>
				account?.userFunctionTagIds?.includes(id)
			);
			setAccountFunctionSelectedOptions(functionSelectedOptionFilter);
		}
	};

	const onChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		setProfileForm((previousValue) => {
			return {
				...previousValue,
				[currentTarget.name]: currentTarget.value,
			};
		});
	};

	const cancelChanges = () => {
		setProfileFormEnabled(false);
	};

	const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		try {
			if (!account) {
				throw new Error('account is undefined.');
			}

			setIsLoading(true);

			console.log('payload logic', locationInputRef.current?.value, isLocationValid, profileForm.location);
			const accountResponse = await accountService
				.updateCurrentAccount(account.accountId, {
					...profileForm,
					...(locationInputRef.current?.value && isLocationValid
						? { location: profileForm.location }
						: { location: '' }),
				})
				.fetch();

			setAccount(accountResponse.account);
			setIsLocationValid(true);

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Your account has been updated.</p>;
				},
			});
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
			setProfileFormEnabled(false);
		}
	};

	useEffect(() => {
		if (!locationInputRef.current) {
			return;
		}

		if (!window.google?.maps?.places?.Autocomplete) {
			return;
		}

		autoCompleteRef.current = new window.google.maps.places.Autocomplete(locationInputRef.current, {
			types: ['(cities)'],
		});

		autoCompleteRef.current.addListener('place_changed', () => {
			if (!autoCompleteRef.current) {
				return;
			}

			const location = mutateGooglePlaceResultToBpicLocationFormat(autoCompleteRef.current.getPlace());

			setProfileForm((previousValue) => {
				return {
					...previousValue,
					location,
				};
			});
			setIsLocationValid(true);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profileFormEnabled]);

	useEffect(() => {
		setProfileForm({
			...profileForm,
			userFunctionTagIds: accountFunctionSelectedOptions.map((value) => value.id),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountFunctionSelectedOptions]);

	return (
		<AsyncPage fetchData={fetchData}>
			<Helmet>
				<title>Hackett Connect - Account - Profile</title>
			</Helmet>
			<Container className="py-7">
				<Row className="mb-11">
					<Col>
						<div className="d-flex align-items-center justify-content-between">
							<h1 className="mb-0 text-primary">Account Settings</h1>
						</div>
					</Col>
				</Row>
				<Form onSubmit={handleFormSubmit}>
					<Row>
						<Col>
							<div className="mb-4 d-flex align-items-center justify-content-between">
								<h3>Profile information</h3>
								{profileFormEnabled ? (
									<div className="d-flex justify-content-between">
										<Button
											onClick={() => cancelChanges()}
											variant="outline-primary"
											className="mr-2"
										>
											Cancel Changes
										</Button>
										<LoadingButton isLoading={isLoading} variant="primary" type="submit">
											Save Changes
										</LoadingButton>
									</div>
								) : (
									<Button onClick={handleEditProfileButtonClick} variant="outline-primary">
										Edit Profile
									</Button>
								)}
							</div>
						</Col>
					</Row>
					<Row className="mb-11">
						<Col>
							{profileFormEnabled ? (
								<Row className={classes.formRow}>
									<Col md={6} className={classNames(classes.firstFormCol, classes.formCol)}>
										<Form.Group className="mb-5">
											<Form.Label>Name</Form.Label>
											<Form.Control
												type="text"
												name="name"
												value={profileForm.name}
												onChange={onChange}
												disabled={isLoading}
												required
											/>
										</Form.Group>
										<Form.Group className="mb-5">
											<Form.Label>Title</Form.Label>
											<Form.Control
												type="text"
												name="title"
												value={profileForm.title}
												onChange={onChange}
												disabled={isLoading}
												required
											/>
										</Form.Group>
										<Form.Group className="mb-5">
											<Form.Label>Level</Form.Label>
											<Select
												value={profileForm.userLevelTagId}
												onChange={(event) =>
													setProfileForm({
														...profileForm,
														userLevelTagId: event.target.value,
													})
												}
												disabled={isLoading}
											>
												{accountLevelOptions.map((option) => (
													<option key={option.tagId} value={option.tagId}>
														{option.name}
													</option>
												))}
											</Select>
										</Form.Group>
										<Form.Group>
											<Form.Label>Job Function</Form.Label>
											<Typeahead
												id="typeahead--jobFunction"
												labelKey="display"
												multiple
												selected={accountFunctionSelectedOptions}
												onChange={(selected) => {
													setAccountFunctionSelectedOptions(selected as FormOption[]);
												}}
												options={accountFunctionOptions}
												placeholder="Select Job Function"
											/>
										</Form.Group>
									</Col>
									<Col md={6} className={classes.formCol}>
										<Form.Group className="mb-5">
											<Form.Label>Email</Form.Label>
											<Form.Control
												type="email"
												value={profileForm.emailAddress}
												className="mb-1"
												disabled
											/>
											<small>
												<FreshDeskLink className="small">Contact support</FreshDeskLink> to
												update your email address.
											</small>
										</Form.Group>
										{canManageBookingsUrl && (
											<Form.Group className="mb-5">
												<Form.Label>
													Bookings Url{' '}
													<span className="text-muted font-italic font-weight-regular">
														- Optional
													</span>
												</Form.Label>
												<Form.Control
													type="text"
													name="bookingsUrl"
													value={profileForm.bookingsUrl}
													onChange={onChange}
													disabled={isLoading}
												/>
											</Form.Group>
										)}
										<Form.Group className="mb-5">
											<Form.Label>
												Office Phone{' '}
												<span className="text-muted font-italic font-weight-regular">
													- Optional
												</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="mobileNumber"
												value={profileForm.mobileNumber}
												onChange={onChange}
												disabled={isLoading}
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label>
												Location{' '}
												<span className="text-muted font-italic font-weight-regular">
													- Optional
												</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="location"
												ref={locationInputRef}
												defaultValue={mutateBpicLocationFormatToInputFormat(
													profileForm.location
												)}
												onChange={(e) => {
													setIsLocationValid(!!e.target.value);
												}}
												disabled={isLoading}
											/>

											{!isLocationValid && (
												<small className="d-block mt-1">
													Location should be selected from the suggestion list, otherwise it
													will be discarded.
												</small>
											)}
										</Form.Group>
									</Col>
								</Row>
							) : (
								<Row className={classes.formRow}>
									<Col md={6} className={classNames(classes.firstFormCol, classes.formCol)}>
										<Form.Group className="mb-5">
											<Form.Label>Name</Form.Label>
											<p className="mb-0">{account?.name}</p>
										</Form.Group>
										<Form.Group className="mb-5">
											<Form.Label>Title</Form.Label>
											<p className="mb-0">{account?.title}</p>
										</Form.Group>
										<Form.Group className="mb-5">
											<Form.Label>Level</Form.Label>
											{account?.userLevelDescription ? (
												<p className="mb-0">{account?.userLevelDescription}</p>
											) : (
												<p className="mb-0 text-muted font-italic">None</p>
											)}
										</Form.Group>
										<Form.Group>
											<Form.Label>Job Function</Form.Label>
											{account?.userFunctionDescription ? (
												<p className="mb-0">{account?.userFunctionDescription}</p>
											) : (
												<p className="mb-0 text-muted font-italic">None</p>
											)}
										</Form.Group>
									</Col>
									<Col md={6} className={classes.formCol}>
										<Form.Group className="mb-5">
											<Form.Label>Email</Form.Label>
											<p className="mb-0">{account?.emailAddress}</p>
										</Form.Group>
										{canManageBookingsUrl && (
											<Form.Group className="mb-5">
												<Form.Label>Bookings URL</Form.Label>
												{account?.bookingsUrl ? (
													<p className="mb-0 text-truncate">{account?.bookingsUrl}</p>
												) : (
													<p className="mb-0 text-muted font-italic">None</p>
												)}
											</Form.Group>
										)}
										<Form.Group className="mb-5">
											<Form.Label>Office Phone</Form.Label>
											{account?.mobileNumber ? (
												<p className="mb-0">{account?.mobileNumber}</p>
											) : (
												<p className="mb-0 text-muted font-italic">None</p>
											)}
										</Form.Group>
										<Form.Group>
											<Form.Label>Location</Form.Label>
											{account?.location ? (
												<p className="mb-0">
													{mutateBpicLocationFormatToInputFormat(account.location)}
												</p>
											) : (
												<p className="mb-0 text-muted font-italic">None</p>
											)}
										</Form.Group>
									</Col>
								</Row>
							)}
						</Col>
					</Row>
				</Form>
				<Row>
					<Col lg={6}>
						<div className="mb-2 d-flex align-items-center justify-content-between">
							<h3>Password</h3>
							<Button
								variant="outline-primary"
								onClick={() => {
									if (config.HACKETT_ADVISORY_USE_REAL_AUTH === 'true') {
										window.location.href = constructUrl(
											`${config.HACKETT_ADVISORY_AUTH_UI_URL}/change-password`,
											{
												redirectUrl: encodeURIComponent(window.location.href),
												platform: 'hackett-connect',
											}
										);
									} else {
										showAlert({
											variant: 'warning',
											children: () => {
												return (
													<p className="mb-0">
														Environment not configured to support this feature.
													</p>
												);
											},
										});
									}
								}}
							>
								Change Password
							</Button>
						</div>
					</Col>
					<Col lg={6}>
						<div className="d-flex align-items-center justify-content-between">
							<h3>Two Factor Authentication Method</h3>
							<Button
								variant="outline-primary"
								onClick={() => {
									if (config.HACKETT_ADVISORY_USE_REAL_AUTH === 'true') {
										window.location.href = constructUrl(
											`${config.HACKETT_ADVISORY_AUTH_UI_URL}/change-mfa-method`,
											{
												redirectUrl: encodeURIComponent(window.location.href),
												platform: 'hackett-connect',
											}
										);
									} else {
										showAlert({
											variant: 'warning',
											children: () => {
												return (
													<p className="mb-0">
														Environment not configured to support this feature.
													</p>
												);
											},
										});
									}
								}}
							>
								Change
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
