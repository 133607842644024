interface BpicLocation {
	city: string;
	state: string;
	country: string;
	countryCode: string;
	placeId: string;
}

export const mutateGooglePlaceResultToBpicLocationFormat = (
	googlePlaceResult: google.maps.places.PlaceResult
): string => {
	const { place_id, address_components, formatted_address } = googlePlaceResult;
	const splitFormattedAddress = formatted_address ? formatted_address.split(', ') : [];

	const location: BpicLocation = {
		city: splitFormattedAddress[0] ?? '',
		state: '',
		country: '',
		countryCode: address_components?.find((ac) => ac.types.includes('country'))?.short_name ?? '',
		placeId: place_id ?? '',
	};

	if (typeof splitFormattedAddress[2] === 'undefined') {
		location.country = splitFormattedAddress[1];
	} else {
		location.state = splitFormattedAddress[1];
		location.country = splitFormattedAddress[2];
	}

	return JSON.stringify(location);
};

export const mutateBpicLocationFormatToInputFormat = (locationString: string): string => {
	try {
		const parsedLocation: BpicLocation = JSON.parse(locationString);
		let locationInputValue = parsedLocation.city + ', ';

		if (parsedLocation.state.length) {
			locationInputValue += parsedLocation.state + ', ';
		}

		locationInputValue += parsedLocation.country;

		return locationInputValue;
	} catch (error) {
		return '';
	}
};
