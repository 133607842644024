import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as TagsIcon } from '@/assets/icons/icon-tags.svg';

export interface TagListItem {
	title: string;
	to: string;
}

interface TagListProps {
	showIcon?: boolean;
	tags: TagListItem[];
}

export const TagList = ({ showIcon = true, tags }: TagListProps) => {
	return (
		<div>
			{showIcon && (
				<span className="text-primary">
					<TagsIcon className="mr-1" />
				</span>
			)}
			{tags.map((tag, index) => {
				const isLastTag = index === tags.length - 1;

				return (
					<span key={index} className="small font-weight-semi-bold">
						<Link key={index} to={tag.to}>
							<u>{tag.title}</u>
						</Link>
						{isLastTag ? '' : ', '}
					</span>
				);
			})}
		</div>
	);
};
