import { TableColumns, TableHeaders } from '@/components/table/models';

export enum PERFORMANCE_METRICS_TABLE_COLUMN_IDS {
	PERFORMANCE_METRICS = 'DESCRIPTION',
	BUSINESS_PROCESS = 'BUSINESS_PROCESS',
	PEER_GROUP = 'PEER_GROUP',
	DIGITAL_WORLD_CLASS = 'DIGITAL_WORLD_CLASS',
	INDUSTRY_PEER = 'INDUSTRY_PEER',
	FIRST_QUARTILE = 'FIRST_QUARTILE',
	DRIVER = 'DRIVER',
	CATEGORY = 'CATEGORY',
	SOURCE = 'SOURCE',
	TOP_PERFORMER = 'TOP_PERFORMER',
}

export const performanceMetricsTableColumns: TableColumns<PERFORMANCE_METRICS_TABLE_COLUMN_IDS> = {
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.PERFORMANCE_METRICS]: {
		columnId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.PERFORMANCE_METRICS,
		columnTitle: 'Benchmark Metrics',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.BUSINESS_PROCESS]: {
		columnId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.BUSINESS_PROCESS,
		columnTitle: 'Business Process',
		isHidable: false,
		isShownByDefault: true,
		isShowing: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.PEER_GROUP]: {
		columnId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.PEER_GROUP,
		columnTitle: 'Peer Group',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.TOP_PERFORMER]: {
		columnId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.TOP_PERFORMER,
		// This is referred to as "First Quartile" in the admin
		columnTitle: 'First Quartile',
		isHidable: false,
		isShownByDefault: false,
		isShowing: false,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.DIGITAL_WORLD_CLASS]: {
		columnId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.DIGITAL_WORLD_CLASS,
		columnTitle: 'Digital World Class®',
		isHidable: false,
		isShownByDefault: false,
		isShowing: false,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.INDUSTRY_PEER]: {
		columnId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.INDUSTRY_PEER,
		columnTitle: 'Industry Peer',
		isHidable: false,
		isShownByDefault: false,
		isShowing: false,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.FIRST_QUARTILE]: {
		columnId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.FIRST_QUARTILE,
		// This is referred to as "Industry First Quartile" in the admin
		columnTitle: 'First Quartile',
		isHidable: false,
		isShownByDefault: false,
		isShowing: false,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.DRIVER]: {
		columnId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.DRIVER,
		columnTitle: 'Driver',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.CATEGORY]: {
		columnId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.CATEGORY,
		columnTitle: 'Category',
		isHidable: true,
		isShownByDefault: false,
		isShowing: false,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.SOURCE]: {
		columnId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.SOURCE,
		columnTitle: 'Source',
		isHidable: true,
		isShownByDefault: true,
		isShowing: true,
	},
};

export const performanceMetricsTableHeaders: TableHeaders<PERFORMANCE_METRICS_TABLE_COLUMN_IDS> = {
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.PERFORMANCE_METRICS]: {
		tableHeaderId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.PERFORMANCE_METRICS,
		title: 'Benchmark Metrics',
		isSortable: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.BUSINESS_PROCESS]: {
		tableHeaderId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.BUSINESS_PROCESS,
		title: 'Business Process',
		isSortable: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.PEER_GROUP]: {
		tableHeaderId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.PEER_GROUP,
		title: 'Peer Group',
		isSortable: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.TOP_PERFORMER]: {
		tableHeaderId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.TOP_PERFORMER,
		// This is referred to as 1stQ internally at Hackett
		title: 'First Quartile',
		isSortable: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.DIGITAL_WORLD_CLASS]: {
		tableHeaderId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.DIGITAL_WORLD_CLASS,
		title: 'Digital World Class®',
		isSortable: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.INDUSTRY_PEER]: {
		tableHeaderId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.INDUSTRY_PEER,
		title: 'Industry Peer',
		isSortable: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.FIRST_QUARTILE]: {
		tableHeaderId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.FIRST_QUARTILE,
		title: 'First Quartile',
		isSortable: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.DRIVER]: {
		tableHeaderId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.DRIVER,
		title: 'Driver',
		isSortable: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.CATEGORY]: {
		tableHeaderId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.CATEGORY,
		title: 'Category',
		isSortable: true,
	},
	[PERFORMANCE_METRICS_TABLE_COLUMN_IDS.SOURCE]: {
		tableHeaderId: PERFORMANCE_METRICS_TABLE_COLUMN_IDS.SOURCE,
		title: 'Source',
		isSortable: true,
	},
};
