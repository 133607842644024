import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { cloneDeep } from 'lodash';
import { constructUrl } from '@/lib/utils';
import { FormOption, InquiryModel, INQUIRY_STATUS_ID, INQUIRY_TYPE_ID } from '@/lib/models';
import { inquiryService } from '@/lib/services';
import { useQuery } from '@/hooks';
import { AsyncPage, ContactRequest, DateInput, MultiSelect, Select } from '@/components';
import { TablePagination } from '@/components/table';
import { Helmet } from 'react-helmet';

export const AdvisorInquiries = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();

	const searchParams = useQuery();
	const clientId = useMemo(() => searchParams.get('clientId'), [searchParams]);
	const programId = useMemo(() => searchParams.get('programId'), [searchParams]);
	const status = useMemo(() => searchParams.get('status'), [searchParams]);
	const advisorId = useMemo(() => searchParams.get('advisorId'), [searchParams]);
	const grcAdvisorId = useMemo(() => searchParams.get('grcAdvisorId'), [searchParams]);
	const accountManagerId = useMemo(() => searchParams.get('accountManagerId'), [searchParams]);
	const type = useMemo(() => searchParams.get('type'), [searchParams]);
	const topicIds = useMemo(() => searchParams.get('topicIds'), [searchParams]);
	const requestNumber = useMemo(() => searchParams.get('requestNumber'), [searchParams]);
	const fromDate = useMemo(() => searchParams.get('fromDate'), [searchParams]);
	const toDate = useMemo(() => searchParams.get('toDate'), [searchParams]);
	const page = useMemo(() => searchParams.get('page'), [searchParams]);
	const size = 10;

	const [inquiries, setInquiries] = useState<InquiryModel[]>([]);
	const [totalInquiryCount, setTotalInquiryCount] = useState(0);

	const isOrConditionAdvisor = useMemo(() => searchParams.get('isOrConditionAdvisor'), [searchParams]);

	const [options, setOptions] = useState({
		clientOptions: [] as FormOption[],
		programOptions: [] as FormOption[],
		statusOptions: [] as FormOption[],
		advisorOptions: [] as FormOption[],
		grcAdvisorOptions: [] as FormOption[],
		accountManagerOptions: [] as FormOption[],
		typeOptions: [] as FormOption[],
		topicsOptions: [] as FormOption[],
		isOrConditionOptions: [] as FormOption[],
	});

	const [filterValues, setFilterValues] = useState({
		clientValue: clientId ?? '',
		programValue: programId ?? '',
		statusValue: status ? (status ?? '').split('|').filter((s) => s) : [],
		advisorValue: advisorId ?? '',
		grcAdvisor: grcAdvisorId ?? '',
		accountManager: accountManagerId ?? '',
		typeValue: type ?? '',
		topicsValue: [] as FormOption[],
		requestNumber: '',
		fromDate: fromDate ? new Date(fromDate) : null,
		toDate: toDate ? new Date(toDate) : null,
		isOrCondition : isOrConditionAdvisor ?? false,
	});

	const fetchIquiries = useCallback(async () => {
		const response = await inquiryService
			.getInquiries({
				...(clientId && { clientId }),
				...(programId && { programId }),
				...(status && { status: [status] as INQUIRY_STATUS_ID[] }),
				...(advisorId && { advisorId }),
				...(grcAdvisorId && { grcAdvisorId }),
				...(accountManagerId && { accountManagerId }),
				...(type && { type: type as INQUIRY_TYPE_ID }),
				...(page && { page }),
				...(size && { size: String(size) }),
				...(topicIds && { topicIds: topicIds.split('|').filter((s) => s) }),
				...(requestNumber && { requestNumber }),
				...(fromDate && { fromDate }),
				...(toDate && { toDate }),
				...(isOrConditionAdvisor && { isOrConditionAdvisor }),
			})
			.fetch();

		setInquiries(response.inquiries);
		setTotalInquiryCount(response.totalCount);
	}, [
		advisorId,
		clientId,
		fromDate,
		grcAdvisorId,
		accountManagerId,
		page,
		programId,
		requestNumber,
		status,
		toDate,
		topicIds,
		type,
		isOrConditionAdvisor,
	]);

	const fetchFilterOptions = useCallback(async () => {
		const response = await inquiryService.getInquiryFilters().fetch();
		setOptions({
			clientOptions: response.clients,
			programOptions: response.programs,
			statusOptions: response.status,
			advisorOptions: response.advisors,
			grcAdvisorOptions: response.grcAdvisors,
			accountManagerOptions: response.accountManagers,
			typeOptions: response.type,
			topicsOptions: response.topics,
			isOrConditionOptions: response.isOrCondition,
		});
	}, []);

	useEffect(() => {
		const topicIdsFromQuery = (topicIds ?? '').split('|').filter((s) => s);
		const selectedTopics: FormOption[] = [];
		topicIdsFromQuery.forEach((tid) => {
			const topic = options.topicsOptions.find((t) => tid === t.id);
			if (topic) {
				selectedTopics.push(topic);
			}
		});

		setFilterValues((previousValue) => ({
			...previousValue,
			topicsValue: selectedTopics,
		}));
	}, [topicIds, options.topicsOptions]);

	const handleClearFiltersButtonClick = () => {
		setFilterValues({
			clientValue: '',
			programValue: '',
			statusValue: [],
			advisorValue: '',
			grcAdvisor: '',
			accountManager: '',
			typeValue: '',
			topicsValue: [],
			requestNumber: '',
			fromDate: null,
			toDate: null,
			isOrCondition: false,
		});

		history.push(pathname);
	};

	const handleFilterFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		history.push(
			constructUrl(
				pathname,
				{
					clientId: filterValues.clientValue,
					programId: filterValues.programValue,
					status: filterValues.statusValue,
					advisorId: filterValues.advisorValue,
					grcAdvisorId: filterValues.grcAdvisor,
					accountManagerId: filterValues.accountManager,
					type: filterValues.typeValue,
					topicIds: filterValues.topicsValue.map((formOption) => formOption.id),
					page: '0',
					size: String(size),
					requestNumber: filterValues.requestNumber,
					fromDate: filterValues.fromDate ? format(filterValues.fromDate, 'yyyy-MM-dd') : '',
					toDate: filterValues.toDate ? format(filterValues.toDate, 'yyyy-MM-dd') : '',
					isOrConditionAdvisor: filterValues.isOrCondition,
				},
				search
			)
		);
	};

	const getActiveFilterLength = () => {
		return Object.entries(filterValues).reduce((filterLengthAccumulator, [key, value]) => {
			if (value) {
				if (Array.isArray(value)) {
					let arrayListTarget: string[] = [];
					let plainFilterValues: string[] = [];
					if (key === 'statusValue') {
						arrayListTarget = options.statusOptions.map((item) => item.id);
						plainFilterValues = cloneDeep(filterValues.statusValue);
					} else {
						arrayListTarget = options.topicsOptions.map((item) => item.id);
						plainFilterValues = filterValues.topicsValue.map((item) => item.id);
					}

					return plainFilterValues.some((item) => arrayListTarget.includes(item))
						? filterLengthAccumulator + 1
						: filterLengthAccumulator;
				}
				if (value instanceof Date) {
					return filterLengthAccumulator;
				}
				if ( typeof value == 'boolean' ) {
					return filterLengthAccumulator;
				}
			const referenceOption: { [key: string]: FormOption[] } = {
					clientValue: options.clientOptions,
					programValue: options.programOptions,
					advisorValue: options.advisorOptions,
					grcAdvisor: options.grcAdvisorOptions,
					accountManager: options.accountManagerOptions,
					typeValue: options.typeOptions,
				};

				filterLengthAccumulator = (referenceOption?.[key] ?? []).map((o) => o.id).includes(value)
					? filterLengthAccumulator + 1
					: filterLengthAccumulator;
			}

			return filterLengthAccumulator;
		}, 0);
	};

	const handlePaginationChange = ({ pageIndex }: { pageIndex: number }) => {
		history.push(constructUrl(pathname, { page: String(pageIndex) }, search));
	};

	return (
		<Container className="py-13">
			<Helmet><title>Hackett Connect - Client Management - Inquiries</title></Helmet>
			<Row className="mb-3">
				<Col>
					<p className="mb-0">
						<Link to="/client-management/inquiries-dashboard">Inquiry Dashboard /</Link>
					</p>
				</Col>
			</Row>
			<Row className="mb-6">
				<Col>
					<h1 className="mb-0">Inquiries</h1>
				</Col>
			</Row>
			<Row>
				<Col xs={12} xl={8} className="mb-3">
					<hr />

					<AsyncPage fetchData={fetchIquiries}>
						{inquiries.length <= 0 && (
							<p className="mt-6 text-muted text-center">
								<strong>No Results</strong>
							</p>
						)}
						{inquiries.map((inquiry) => {
							return (
								<ContactRequest
									key={inquiry.inquiryId}
									name={inquiry.inquiryTitle}
									namePrefix={inquiry.client.name}
									time={`Submitted ${inquiry.createdDescription}`}
									lastMessage={inquiry.listInquiryText}
									href={
										inquiry.inquiryStatus === INQUIRY_STATUS_ID.CLOSED
											? `/client-management/inquiries/${inquiry.inquiryId}`
											: `/client-management/inquiries/${inquiry.inquiryId}/edit`
									}
								>
									<div className="mt-1 d-flex justify-content-between">
										<p className="text-muted">{inquiry.inquiryListDescription}</p>
										<p className="text-muted">
											{inquiry.advisorAccount.name} &bull; {inquiry.grcAdvisorAccount.name}
										</p>
									</div>
								</ContactRequest>
							);
						})}
						{inquiries.length > 0 && (
							<TablePagination
								showPageSize={false}
								pageSize={size}
								pageIndex={parseInt(page ?? '0', 10)}
								total={totalInquiryCount}
								onChange={handlePaginationChange}
							/>
						)}
					</AsyncPage>
				</Col>
				<Col xs={12} xl={4}>
					<Card>
						<Card.Body>
							<div className="mb-4 d-flex align-items-center justify-content-between">
								<Card.Title className="mb-0">Filters</Card.Title>
								<Button variant="link" onClick={handleClearFiltersButtonClick}>
									Clear {getActiveFilterLength() > 0 && `(${getActiveFilterLength()})`}
								</Button>
							</div>
							<AsyncPage fetchData={fetchFilterOptions}>
								<Form onSubmit={handleFilterFormSubmit}>
									<div className="text-center mb-6">
										<Button variant="outline-primary" type="submit">
											Apply Filters
										</Button>
									</div>
									<hr className="mb-6" />
									<Form.Group className="mb-6">
										<Form.Label>Client</Form.Label>
										<Select
											value={filterValues.clientValue}
											onChange={({ currentTarget }) => {
												setFilterValues({
													...filterValues,
													clientValue: currentTarget.value,
												});
											}}
										>
											<option value="">Select client...</option>
											{options.clientOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Program</Form.Label>
										<Select
											value={filterValues.programValue}
											onChange={({ currentTarget }) => {
												setFilterValues({
													...filterValues,
													programValue: currentTarget.value,
												});
											}}
										>
											<option value="">Select program...</option>
											{options.programOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Status</Form.Label>
										<MultiSelect
											options={options.statusOptions.map((option) => ({
												id: option.id,
												title: option.display,
												value: option.id,
											}))}
											selected={
												options.statusOptions
													.filter((o) => filterValues.statusValue.includes(o.id))
													.map((o) => o.id) ?? []
											}
											onChange={(selectedValues) => {
												setFilterValues({
													...filterValues,
													statusValue: selectedValues,
												});
											}}
										>
											Select status...
										</MultiSelect>
									</Form.Group>
									<hr className="mb-6" />
									<Form.Group className="mb-6">
										<Form.Label>Advisor</Form.Label>
										<Select
											value={filterValues.advisorValue}
											onChange={({ currentTarget }) => {
												setFilterValues({
													...filterValues,
													advisorValue: currentTarget.value,
												});
											}}
										>
											<option value="">Select advisor...</option>
											{options.advisorOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>

									<Form.Group className="mb-3">
										<div className="mb-6 d-flex align-items-center">
											<Form.Label className="mb-0">Condition to apply</Form.Label>
											<Select
												className='ml-2'
												value={filterValues.isOrCondition+''}
												onChange={({ currentTarget }) => {
													setFilterValues({
														...filterValues,
														isOrCondition: (currentTarget.value==='true'),
													});
													}}
												>
												<option key="false" value="false">And</option>
												<option key="true" value="true">Or</option>
											</Select>
										</div>
									</Form.Group>

									<Form.Group className="mb-6">
										<Form.Label>Inquiry Advisor</Form.Label>
										<Select
											value={filterValues.grcAdvisor}
											onChange={({ currentTarget }) => {
												setFilterValues({
													...filterValues,
													grcAdvisor: currentTarget.value,
												});
											}}
										>
											<option value="">Select inquiry advisor...</option>
											{options.grcAdvisorOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<hr className="mb-6" />
									<Form.Group className="mb-6">
										<Form.Label>Account Manager</Form.Label>
										<Select
											value={filterValues.accountManager}
											onChange={({ currentTarget }) => {
												setFilterValues({
													...filterValues,
													accountManager: currentTarget.value,
												});
											}}
										>
											<option value="">Select account manager...</option>
											{options.accountManagerOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Type</Form.Label>
										<Select
											value={filterValues.typeValue}
											onChange={({ currentTarget }) => {
												setFilterValues({
													...filterValues,
													typeValue: currentTarget.value,
												});
											}}
										>
											<option value="">Select type...</option>
											{options.typeOptions.map((option) => {
												return (
													<option key={option.id} value={option.id}>
														{option.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Topics</Form.Label>
										<Typeahead
											id="typeahead--topics"
											labelKey="display"
											multiple
											selected={filterValues.topicsValue}
											onChange={(selected) => {
												setFilterValues({
													...filterValues,
													topicsValue: selected as FormOption[],
												});
											}}
											options={options.topicsOptions}
											placeholder="Select topics..."
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Inquiry #</Form.Label>
										<Form.Control
											type="number"
											value={filterValues.requestNumber}
											onChange={({ currentTarget }) => {
												setFilterValues({
													...filterValues,
													requestNumber: currentTarget.value,
												});
											}}
											placeholder="Enter Inquiry #..."
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>From Date</Form.Label>
										<DateInput
											isClearable
											maxDate={filterValues.toDate}
											selected={filterValues.fromDate}
											onChange={(date) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													fromDate: date,
												}));
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>To Date</Form.Label>
										<DateInput
											isClearable
											minDate={filterValues.fromDate}
											selected={filterValues.toDate}
											onChange={(date) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													toDate: date,
												}));
											}}
										/>
									</Form.Group>
									<hr className="mb-6" />
									<div className="text-center">
										<Button variant="outline-primary" type="submit">
											Apply Filters
										</Button>
									</div>
								</Form>
							</AsyncPage>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};
