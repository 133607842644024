import { v4 as uuid } from 'uuid';
import React, { FC, useMemo } from 'react';
import { Col, Row, Card, Form } from 'react-bootstrap';

export interface PropgramToggleItemProps {
	title: string;
	isChecked: boolean;
	onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const ProgramToggleItem: FC<PropgramToggleItemProps> = ({ title, isChecked, onChange }) => {
	const switchId = useMemo(uuid, []);

	return (
		<Row>
			<Col>
				<Card>
					<Card.Body>
						<Row>
							<Col className="d-flex align-items-center" xs={12} md={6} lg={8}>
								<p className="mb-0 bold">{title}</p>
							</Col>
							<Col className="d-flex align-items-center" xs={12} md={6} lg={4}>
								<Form.Switch
									id={`switch--${switchId}`}
									checked={isChecked}
									onChange={onChange}
									label={isChecked ? 'Enabled' : 'Disabled'}
								/>
							</Col>
						</Row>
					</Card.Body>
					<hr />
				</Card>
			</Col>
		</Row>
	);
};
