import { TimeZoneModel } from '@/lib/models';

export enum ROLE_IDS {
	ADVISOR = 'ADVISOR',
	SUPER_ADMIN = 'SUPER_ADMIN',
	USER = 'USER',
	ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
	GRC_ADVISOR = 'GRC_ADVISOR',
}

export interface AccountModel {
	accountId: string;
	analytics: object;
	clientName: string;
	deliveryDate?: string;
	deliveryDateDescription?: string;
	emailAddress: string;
	hasAdvisoryProfile: boolean;
	location?: string;
	mobileNumber?: string;
	name: string;
	roles: ROLE_IDS[];
	timeZone: TimeZoneModel;
	title: string;
	userFunctionDescription?: string;
	userFunctionTagIds?: string[];
	userLevelDescription?: string;
	userLevelTagId?: string;
	bookingsUrl?: string;
	deleted: boolean;
}

export interface UnmatchedAccountModel {
	company: string;
	email: string;
	phone: string;
	title: string;
	userName: string;
}

export interface AccountOptionModel {
	tagId: string;
	name: string;
}

export interface BannerMessage {
	bannerMessageId: string;
	title: string;
	linkDescription: string;
	linkUrl: string;
}
