import Cookies from 'js-cookie';
import React, { useCallback, useEffect } from 'react';
import { COOKIES } from '@/lib/models';
import { ErrorDisplay } from '@/components';
import { httpSingleton } from '@/lib/http-client/';
import { Helmet } from 'react-helmet';

interface MaintenanceProps {
	className?: string;
}

export const Maintenance = ({ className }: MaintenanceProps) => {
	useEffect(() => {
		const getSystemStatus = async () => {
			try {
				const response = await httpSingleton.getSystemStatus();

				if (response === 503) {
					// If system is down, stay on this page.
					return;
				}

				let targetUrl = Cookies.get(COOKIES.MAINTENANCE_TARGET_URL) ?? '/';
				if (targetUrl === '/maintenance') {
					targetUrl = '/';
				}

				window.location.replace(targetUrl);
			} catch (error) {
				// Do not handle, assume system is down.
			}
		};

		getSystemStatus();

		return () => {
			Cookies.remove(COOKIES.MAINTENANCE_TARGET_URL);
		};
	}, []);

	const fetchPageData = useCallback(async () => {
		let targetUrl = Cookies.get(COOKIES.MAINTENANCE_TARGET_URL) ?? '/';
		if (targetUrl === '/maintenance') {
			targetUrl = '/';
		}
		window.location.replace(targetUrl);
	}, []);

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Maintenance</title>
			</Helmet>
			<ErrorDisplay
				className={''}
				error={{ message: 'Sorry, we encountered an error.' }}
				showBackButton={true}
				showRetryButton={false}
				onRetryButtonClick={fetchPageData}
			/>
		</>
	);
};
