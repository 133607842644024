import { MultiSelectOption } from '@/components';
import { FormOption } from '@/lib/models';

export const formOptionToMultiSelectOption = (formOptions: FormOption[]): MultiSelectOption[] => {
	return formOptions.map((fo) => ({
		id: fo.id,
		title: fo.display,
		value: fo.id,
		...(fo.filterItems && { options: formOptionToMultiSelectOption(fo.filterItems) }),
	}));
};
