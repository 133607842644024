import { AccountModel, DocumentModel, EventModel } from '@/lib/models';

export interface DeliverableModel {
	contacts: AccountModel[];
	deliverableId: string;
	deliverableTypeDescription: string;
	deliverableTypeId: string;
	hours: number;
	deliveryDate: string;
	deliverableDateDescription: string;
	description: string;
	documents: DocumentModel[];
	documentsUploaded: DeliverableDocumentUploaded[];
	events: EventModel[];
	programsDescription?: string;
	programIds: string[];
	title: string;
	topicId: string;
}

export interface DeliverableDocumentUploaded {
	deliverableDocumentUploadedId: string;
	documentViewUrl: string;
	title: string;
	deliverableId: string;
}

export const deliverableUploadedAttachment = {
	validExtensions: ['.pdf', '.zip', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'],
	maxFileSize_bytes: 104857600, // 100MB
	accept: {
		'application/pdf': ['.pdf'],
		'application/zip': ['.zip'],
		'application/msword': ['.doc'],
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		'application/vnd.ms-excel': ['.xls'],
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
		'application/vnd.ms-powerpoint': ['.ppt'],
		'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
	},
};
