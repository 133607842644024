import React, { useCallback, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import classNames from 'classnames';

import { ACTIVITY_TYPE_ID, BannerMessage, EventModel, RecentActivityModel, Recommendation } from '@/lib/models';
import { accountService, activityService, eventsService, recommendationsService } from '@/lib/services';
import { createUseThemedStyles } from '@/hooks';
import {
	AsyncPage,
	Carousel,
	ColorBlockContainer,
	DashboardRecentlyViewed,
	DashboardRecommendation,
	GetHelp,
	UpcomingEventLink,
	WelcomeBanner,
} from '@/components';
import { mediaQueries } from '@/jss';
import { Helmet } from 'react-helmet';

const useStyles = createUseThemedStyles((theme) => ({
	eventsColumn: {
		marginBottom: 30,
		[mediaQueries.xl]: {
			paddingRight: '10%',
			borderRight: `1px solid ${theme.colors.gray400}`,
		},
	},
	supportColumn: {
		[mediaQueries.xl]: {
			paddingLeft: '10%',
		},
	},
}));

export const Dashboard = () => {
	const classes = useStyles();

	const [bannerMessages, setBannerMessages] = useState<BannerMessage[]>([]);
	const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
	const [recentlyViewedContent, setRecentlyViewedContent] = useState<RecentActivityModel[]>([]);
	const [upcomingEvents, setUpcomingEvents] = useState<EventModel[]>([]);

	const fetchData = useCallback(async () => {
		const [bannerMessagesResponse, recommendationsResponse, activityResponse, eventsResponse] = await Promise.all([
			accountService.getBannerMessages().fetch(),
			recommendationsService.getRecommendations().fetch(),
			activityService.getRecentlyViewed({ limit: 6 }).fetch(),
			eventsService.getUpcomingEvents({ size: 4, page: '0' }).fetch(),
		]);

		setBannerMessages(bannerMessagesResponse.bannerMessages);
		setRecommendations(recommendationsResponse.recommendedContent);
		setRecentlyViewedContent(activityResponse.recentActivities);
		setUpcomingEvents(eventsResponse.events);
	}, []);

	return (
		<AsyncPage fetchData={fetchData}>
			<Helmet><title>Hackett Connect</title></Helmet>
			{bannerMessages.length > 0 && (
				<WelcomeBanner bannerMessages={bannerMessages} onBannerMessageRead={setBannerMessages} />
			)}

			<ColorBlockContainer className="py-11">
				<Row className="mb-10">
					<Col>
						<h1 className="mb-0 text-white text-center">Our recommendations for you</h1>
					</Col>
				</Row>
				<Row>
					<Col>
						{recommendations.length <= 0 && (
							<p className="mb-0 text-white text-center">There is no recommended content.</p>
						)}
						{recommendations.length > 0 && (
							<Carousel>
								{recommendations.map((recommendation, index) => {
									return (
										<DashboardRecommendation
											key={index}
											imageSrc={recommendation.imageUrl ?? ''}
											type={recommendation.contentTypeDescription}
											to={recommendation.detailsUrl}
											title={recommendation.title}
											// recentlyPublished
											recommendedByAdvisor
										/>
									);
								})}
							</Carousel>
						)}
					</Col>
				</Row>
			</ColorBlockContainer>
			{recentlyViewedContent.length > 0 && (
				<Container fluid className="bg-gray-200">
					<Container className="pt-11 pb-6">
						<Row className="mb-8">
							<Col>
								<h3 className="mb-0">Your previously viewed content</h3>
							</Col>
						</Row>
						<Row>
							{recentlyViewedContent.map((content) => {
								return (
									<Col xs={12} lg={6} xl={4} className="mb-8" key={content.recentActivityId}>
										<DashboardRecentlyViewed
											type={content.ipTypeDescription ?? ''}
											title={content.ipTitle ?? ''}
											to={
												content.activityTypeId === ACTIVITY_TYPE_ID.BP
													? `/best-practices/${content.ipId}`
													: `/research/${content.ipId}`
											}
										/>
									</Col>
								);
							})}
						</Row>
					</Container>
				</Container>
			)}
			<Container className="pt-11 pb-15">
				<Row>
					<Col xs={12} lg={6} className={classes.eventsColumn}>
						<h2 className="mb-7">Upcoming Events</h2>
						{upcomingEvents.length <= 0 && <p className="mb-0 text-muted">There are no upcoming events.</p>}
						{upcomingEvents.map((event, eventIndex) => {
							const isLast = upcomingEvents.length - 1 === eventIndex;

							return (
								<UpcomingEventLink
									key={event.eventId}
									className={classNames({
										'mb-8': !isLast,
									})}
									imageSrc={event.imageUrl ?? ''}
									date={event.durationDescription ?? ''}
									title={event.name}
									to={`/events/browse-events/${event.eventId}`}
								/>
							);
						})}
					</Col>
					<Col xs={12} lg={6} className={classes.supportColumn}>
						<h2 className="mb-7">Support</h2>
						<Row>
							<Col>
								<GetHelp />
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
