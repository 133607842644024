import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, ModalProps, Row } from 'react-bootstrap';

import { contactsTableColumns, contactsTableHeaders, CONTACTS_TABLE_COLUMN_IDS } from '@/lib/__mocks__';
import { AccountModel } from '@/lib/models';
import { deliverablesService } from '@/lib/services';
import { createUseThemedStyles, useDebouncedState, useHandleError } from '@/hooks';
import { SearchInput } from '@/components';
import { TableRowConfig } from '@/components/table/models';
import { SORT_DIRECTION, TableCell, TableHeader, TableRenderer, TableRow } from '@/components/table';

const useStyles = createUseThemedStyles((theme) => ({
	dialog: {
		width: '90%',
		height: '100%',
		maxWidth: 1240,
		margin: '0 auto',
		'& .modal-content': {
			maxHeight: '90vh',
		},
		'& .hackett-modal__body': {
			overflowY: 'auto',
			backgroundColor: theme.colors.gray100,
		},
	},
}));

interface DeliverablesExistingModalProps extends ModalProps {
	createClientUrl: string;
	value: AccountModel[];
	onSave(selectedContacts: AccountModel[]): void;
}

export const DeliverablesExistingModal = ({
	createClientUrl,
	value,
	onSave,
	...props
}: DeliverablesExistingModalProps) => {
	const handleError = useHandleError();
	const classes = useStyles();
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearchValue = useDebouncedState(searchValue);

	const [tableIsLoading, setTableIsLoading] = useState(false);
	const [tableSelectAll, setTableSelectAll] = useState(false);
	const [tableColumnsData, setTableColumnsData] = useState(contactsTableColumns);
	const [tableHeadersData] = useState(contactsTableHeaders);
	const [tableSortKey, setTableSortKey] = useState<string | undefined>(undefined);
	const [tableSortDirection, setTableSortDirection] = useState<SORT_DIRECTION | undefined>(undefined);
	const [tableState, setTableState] = useState({ size: 10, page: 0 });

	const [contacts, setContacts] = useState<TableRowConfig<AccountModel>[]>([]);
	const [totalContacts, setTotalContacts] = useState(0);
	const [previouslySelectedContacts, setPreviouslySelectedContacts] = useState<AccountModel[]>([]);
	const [newelySelectedContacts, setNewelySelectedContacts] = useState<AccountModel[]>([]);

	const fetchContacts = useCallback(async () => {
		if (!props.show) {
			return;
		}

		try {
			setTableIsLoading(true);

			const response = await deliverablesService
				.getAccounts({
					...(debouncedSearchValue && { query: debouncedSearchValue }),
					...(tableSortDirection && { sortDirection: tableSortDirection }),
					...(tableSortKey && { sortBy: tableSortKey }),
					...(previouslySelectedContacts.length > 0 && {
						accountsToIgnore: previouslySelectedContacts.map((a) => a.accountId),
					}),
					page: String(tableState.page),
					size: String(tableState.size),
				})
				.fetch();
			const formattedContacts = response.accounts.map((account) => {
				return {
					rowId: account.accountId,
					checked: false,
					expanded: false,
					columnData: account,
				};
			});

			setContacts(formattedContacts);
			setTotalContacts(response.totalCount);
		} catch (error) {
			handleError(error);
		} finally {
			setTableIsLoading(false);
		}
	}, [
		debouncedSearchValue,
		handleError,
		previouslySelectedContacts,
		props.show,
		tableSortDirection,
		tableSortKey,
		tableState.page,
		tableState.size,
	]);

	const handleEnter = useCallback(() => {
		setSearchValue('');

		setTableIsLoading(false);
		setTableSelectAll(false);
		setTableSortKey(undefined);
		setTableSortDirection(undefined);
		setTableState({
			size: 10,
			page: 0,
		});

		setContacts([]);
		setTotalContacts(0);
		setPreviouslySelectedContacts(value);
		setNewelySelectedContacts([]);
	}, [value]);

	useEffect(() => {
		fetchContacts();
	}, [fetchContacts]);

	const handleTableSort = useCallback((sortKey: string, sortDirection: SORT_DIRECTION) => {
		setTableSortKey(sortKey);
		setTableSortDirection(sortDirection);
	}, []);

	const handleTableRowCheckboxChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		const newelySelectedContactsClone = cloneDeep(newelySelectedContacts);
		const indexToRemove = newelySelectedContactsClone.findIndex((c) => c.accountId === currentTarget.value);

		if (indexToRemove > -1) {
			newelySelectedContactsClone.splice(indexToRemove, 1);
		} else {
			const contactToAdd = contacts.find((c) => c.columnData.accountId === currentTarget.value);
			if (contactToAdd) {
				newelySelectedContactsClone.push(contactToAdd.columnData);
			}
		}

		setNewelySelectedContacts(newelySelectedContactsClone);
	};

	const handleAddSelectedButtonClick = () => {
		onSave(previouslySelectedContacts.concat(newelySelectedContacts));
	};

	return (
		<Modal dialogClassName={classes.dialog} centered onEnter={handleEnter} {...props}>
			<Modal.Header closeButton>
				<Modal.Title>Add Contacts</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col>
						<Row>
							<Col xl={6}>
								<SearchInput
									className="mb-6"
									value={searchValue}
									onChange={({ currentTarget }) => {
										setSearchValue(currentTarget.value);
										setTableState({
												size: 10,
												page: 0,
											});
									}}
								/>
							</Col>
						</Row>

						<TableRenderer
							isLoading={tableIsLoading}
							tableSelectAll={tableSelectAll}
							onTableSelectAllChange={setTableSelectAll}
							tableColumnData={tableColumnsData}
							onTableColumnDataChange={setTableColumnsData}
							tableRowData={contacts}
							onTableRowDataChange={setContacts}
							tableRowDataTotal={totalContacts}
							pageSize={tableState.size}
							pageIndex={tableState.page}
							onPaginationChange={({ pageSize, pageIndex }) => {
								setTableState({
									size: pageSize,
									page: pageIndex,
								});
							}}
							hidePagination={contacts.length <= 0}
							hideColumnControls={true}
							tableHeaderRowRenderer={() => {
								return (
									<TableRow>
										<TableHeader />
										{Object.values(tableColumnsData).map((columnConfig) => {
											if (!columnConfig.isShowing) {
												return null;
											}

											const tableHeader = tableHeadersData[columnConfig.columnId];
											const tableHeaderProps = {
												key: tableHeader.tableHeaderId,
												sortable: tableHeader.isSortable,
												sortKey: tableHeader.tableHeaderId,
												onSort: handleTableSort,
												sortDirection:
													tableSortKey === tableHeader.tableHeaderId
														? tableSortDirection
														: undefined,
											};

											switch (columnConfig.columnId) {
												case CONTACTS_TABLE_COLUMN_IDS.DELIVERED:
												case CONTACTS_TABLE_COLUMN_IDS.ACTIONS:
													return null;
												default:
													return (
														<TableHeader {...tableHeaderProps}>
															{tableHeader.title}
														</TableHeader>
													);
											}
										})}
									</TableRow>
								);
							}}
							tableBodyRowRenderer={(data) => {
								return (
									<TableRow key={data.rowId}>
										<TableCell fixed width={55} className="pr-0">
											<Form.Check id={`table-row__checkbox--${data.rowId}`}>
												<Form.Check.Input
													value={data.rowId}
													checked={newelySelectedContacts.some(
														(c) => c.accountId === data.columnData.accountId
													)}
													onClick={(event) => {
														event.stopPropagation();
													}}
													onChange={handleTableRowCheckboxChange}
												/>
												<Form.Check.Label
													onClick={(event) => {
														event.stopPropagation();
													}}
												/>
											</Form.Check>
										</TableCell>
										{Object.values(tableColumnsData).map((columnConfig) => {
											if (!columnConfig.isShowing) {
												return null;
											}

											const { columnData } = data;

											switch (columnConfig.columnId) {
												case CONTACTS_TABLE_COLUMN_IDS.COMPANY:
													return (
														<TableCell key={columnConfig.columnId} className="text-nowrap">
															{columnData.clientName ?? '-'}
														</TableCell>
													);
												case CONTACTS_TABLE_COLUMN_IDS.NAME:
													return (
														<TableCell key={columnConfig.columnId} className="text-nowrap">
															{columnData.name ?? '-'}
														</TableCell>
													);
												case CONTACTS_TABLE_COLUMN_IDS.TITLE:
													return (
														<TableCell key={columnConfig.columnId} className="text-nowrap">
															{columnData.title ?? '-'}
														</TableCell>
													);
												case CONTACTS_TABLE_COLUMN_IDS.EMAIL:
													return (
														<TableCell key={columnConfig.columnId} className="text-nowrap">
															{columnData.emailAddress ?? '-'}
														</TableCell>
													);
												case CONTACTS_TABLE_COLUMN_IDS.PHONE:
													return (
														<TableCell key={columnConfig.columnId} className="text-nowrap">
															{columnData.mobileNumber ?? '-'}
														</TableCell>
													);
												case CONTACTS_TABLE_COLUMN_IDS.DELIVERED:
												case CONTACTS_TABLE_COLUMN_IDS.ACTIONS:
													return null;
												default:
													return <TableCell key={columnConfig.columnId}>-</TableCell>;
											}
										})}
									</TableRow>
								);
							}}
						>
							<div className="d-flex align-items-center">
								<h3>Existing contacts ({totalContacts})</h3>
							</div>
						</TableRenderer>
						{contacts.length <= 0 && (
							<p className="mt-11 mb-6 text-muted text-center">
								<strong>We can't find "{searchValue}".</strong>
								<br />
								Please refine your search, or{' '}
								<a href={createClientUrl} target="_blank" rel="noreferrer">
									create a new user in DTP
								</a>
								.
							</p>
						)}
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<div className="text-right">
					<Button className="mr-2" variant="link" onClick={props.onHide}>
						Cancel
					</Button>
					<Button onClick={handleAddSelectedButtonClick}>Update Selected</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
