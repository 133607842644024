export interface ResultItemModel {
	data: {
		category: string;
		id: string;
	};
	value: string;
}

export interface SearchResultDocumentModel {
	id: string;
	title: string;
	description: string;
	downloadUrl?: string;
	viewUrl?: string;
	podcastUrl?: string;
	canDownload?: boolean;
	fileType: string;
	fileName?: string;
	detailsUrl: string;
	bookmarkFlag?: boolean;
	type: string;
	typeDescription: string;
	contentTypes: string;
	topicTags: string;
}

export interface SearchResultModel {
	count: number;
	documents: SearchResultDocumentModel[];
	hasNext: boolean;
	lastSortValue: string;
}

export enum SEARCH_SORT {
	RELEVANCE = 'RELEVANCE',
	NAME_ASC = 'NAME_ASC',
	NAME_DESC = 'NAME_DESC',
	DATE_ASC = 'DATE_ASC',
	DATE_DESC = 'DATE_DESC',
}

export const searchSortOptions = [
	{
		searchSort: SEARCH_SORT.RELEVANCE,
		title: 'Relevance',
	},
	{
		searchSort: SEARCH_SORT.NAME_ASC,
		title: 'Name (A-Z)',
	},
	{
		searchSort: SEARCH_SORT.NAME_DESC,
		title: 'Name (Z-A)',
	},
	{
		searchSort: SEARCH_SORT.DATE_DESC,
		title: 'Most Recent',
	},
	{
		searchSort: SEARCH_SORT.DATE_ASC,
		title: 'Least Recent',
	},
];
