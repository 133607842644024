import { ThemeConfig } from '@/contexts';

export const badge = (theme: ThemeConfig) => ({
	'.hackett-badge': {
		...theme.fonts.xxs,
		display: 'inline-flex',
		padding: '0.4rem 1.0rem',
		backgroundColor: theme.colors.white,
		'&-pill': {
			borderRadius: 500,
		},
		'&.bg-primary': {
			color: theme.colors.white,
			backgroundColor: `${theme.colors.primary} !important`,
		},
		'&.bg-light': {
			color: theme.colors.gray700,
			backgroundColor: `${theme.colors.gray200} !important`,
		},
	},
});
