import React from 'react';
import { Button } from 'react-bootstrap';

import { Avatar } from '@/components';

interface AdvisorProps {
	avatarUrl: string;
	jobTitle?: string;
	name: string;
	specialties: string;
	bio?: string;
	bookingsUrl?: string;
	className?: string;
}

export const Advisor = ({ avatarUrl, jobTitle, name, specialties, bio, bookingsUrl, className }: AdvisorProps) => {
	return (
		<div className={className}>
			<div className="d-flex align-items-center">
				<Avatar className="mr-6" size={86} url={avatarUrl} />
				<div>
					{jobTitle && <h3 className="small text-muted text-uppercase">{jobTitle}</h3>}
					<h2 className="h3">{name}</h2>
					<p className="mb-0">{specialties}</p>
				</div>
			</div>
			{bio && <p className="mt-4 mb-0">{bio}</p>}
			{bookingsUrl && (
				<Button
					variant="outline-primary"
					className="mt-6"
					href={bookingsUrl}
					target="_blank"
					rel="noopener"
				>
					Schedule a Meeting
				</Button>
			)}
		</div>
	);
};
