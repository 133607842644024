import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { DocumentModel } from '@/lib/models';
import { constructUrl, formOptionToMultiSelectOption, getParsedQuery, getValidPaginatorSize } from '@/lib/utils';
import { documentService } from '@/lib/services';
import { useHandleError, useQuery } from '@/hooks';
import {
	flattenParentAndChildIdsForSelection,
	getTopLevelParentAndChildrenIds,
	LinkBox,
	Loader,
	LoadingButton,
	MultiSelect,
	MultiSelectOption,
	RecentlyViewed,
	ResearchListItem,
	Select,
} from '@/components';

export const Research = () => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const handleError = useHandleError();

	const query = useQuery();
	const hasQuery = useMemo(() => {
		const paramsToIgnore = ['size'];

		return (
			Object.entries(getParsedQuery(query)).filter(
				([key, value]) => !paramsToIgnore.includes(key) && value !== ''
			).length > 0
		);
	}, [query]);
	const topicsQuery = useMemo(
		() =>
			query
				.get('topics')
				?.split('|')
				.filter((s) => s),
		[query]
	);
	const parentBusinessProcessesQuery = useMemo(
		() =>
			query
				.get('parentBusinessProcesses')
				?.split('|')
				.filter((s) => s),
		[query]
	);
	const businessProcessesQuery = useMemo(
		() =>
			query
				.get('businessProcesses')
				?.split('|')
				.filter((s) => s),
		[query]
	);
	const contentTypesQuery = useMemo(
		() =>
			query
				.get('contentTypes')
				?.split('|')
				.filter((s) => s),
		[query]
	);
	const size = useMemo(() => getValidPaginatorSize(query), [query]);
	const pageRef = useRef(0);

	const [isLoadingFilters, setIsLoadingFilters] = useState(false);

	const [topicFilters, setTopicFilters] = useState<MultiSelectOption[]>([]);
	const [businessProcessFilters, setBusinessProcessFilters] = useState<MultiSelectOption[]>([]);
	const [contentTypeFilters, setContentTypeFilters] = useState<MultiSelectOption[]>([]);

	const [documents, setDocuments] = useState<DocumentModel[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [loadingDocuments, setLoadingDocuments] = useState(false);

	const fetchFilters = useCallback(async () => {
		try {
			setIsLoadingFilters(true);

			const response = await documentService
				.getDocumentFilters({
					...(topicsQuery && { topicIds: topicsQuery }),
					...(parentBusinessProcessesQuery && { parentBusinessProcessIds: parentBusinessProcessesQuery }),
					...(businessProcessesQuery && { businessProcessIds: businessProcessesQuery }),
				})
				.fetch();

			setTopicFilters(formOptionToMultiSelectOption(response.topics));
			setBusinessProcessFilters(formOptionToMultiSelectOption(response.businessProcesses));
			setContentTypeFilters(formOptionToMultiSelectOption(response.contentTypes));
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoadingFilters(false);
		}
	}, [businessProcessesQuery, handleError, parentBusinessProcessesQuery, topicsQuery]);

	const allBpSelections = useMemo(() => {
		return flattenParentAndChildIdsForSelection(
			businessProcessFilters,
			parentBusinessProcessesQuery,
			businessProcessesQuery
		);
	}, [businessProcessFilters, businessProcessesQuery, parentBusinessProcessesQuery]);

	useEffect(() => {
		fetchFilters();
	}, [fetchFilters]);

	const fetchDocuments = useCallback(async () => {
		try {
			setLoadingDocuments(true);

			const response = await documentService
				.getDocuments({
					...(topicsQuery && { topicIds: topicsQuery }),
					...(parentBusinessProcessesQuery && { parentBusinessProcessIds: parentBusinessProcessesQuery }),
					...(businessProcessesQuery && { businessProcessIds: businessProcessesQuery }),
					...(contentTypesQuery && { documentTypes: contentTypesQuery }),
					size,
					page: String(pageRef.current),
				})
				.fetch();

			setTotalCount(response.totalCount);
			setDocuments((previousValue) => {
				if (pageRef.current === 0) {
					return response.documents;
				}

				return previousValue.concat(response.documents);
			});
		} catch (error) {
			handleError(error);
		} finally {
			setLoadingDocuments(false);
		}
	}, [businessProcessesQuery, contentTypesQuery, handleError, parentBusinessProcessesQuery, size, topicsQuery]);

	useEffect(() => {
		pageRef.current = 0;
	}, [businessProcessesQuery, contentTypesQuery, parentBusinessProcessesQuery, topicsQuery]);

	useEffect(() => {
		fetchDocuments();
	}, [fetchDocuments]);

	const handleClearButtonClick = () => {
		pageRef.current = 0;
		history.replace(pathname);
	};

	const handleTopicsChange = (selectedTopicIds: string[]) => {
		pageRef.current = 0;
		history.replace(constructUrl(pathname, { topics: selectedTopicIds }, search));
	};

	const handleBusinessProcessesChange = useCallback(
		(selectedBpIds: string[]) => {
			const { parentIds, remainingChildrenIds } = getTopLevelParentAndChildrenIds(
				businessProcessFilters,
				selectedBpIds
			);

			pageRef.current = 0;
			history.replace(
				constructUrl(
					pathname,
					{
						parentBusinessProcesses: parentIds,
						businessProcesses: remainingChildrenIds,
					},
					search
				)
			);
		},
		[businessProcessFilters, history, pathname, search]
	);

	const handleContentTypesChange = (selectedContentTypeIds: string[]) => {
		pageRef.current = 0;
		history.replace(constructUrl(pathname, { contentTypes: selectedContentTypeIds }, search));
	};

	const handleShowMoreButtonClick = () => {
		pageRef.current += 1;
		fetchDocuments();
	};

	const handlePageSizeChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		pageRef.current = 0;
		history.replace(constructUrl(pathname, { size: currentTarget.value }, search));
	};

	const handleResearchListItemChange = (document: DocumentModel) => {
		const documentsClone = cloneDeep(documents);
		const indexToUpdate = documentsClone.findIndex((d) => d.documentId === document.documentId);

		documentsClone[indexToUpdate] = {
			...documentsClone[indexToUpdate],
			bookmarkedFlag: document.bookmarkedFlag,
		};

		setDocuments(documentsClone);
	};

	return (
		<>
			<Helmet>
				<title>Hackett Connect - Research</title>
			</Helmet>

			<Container className="pt-7">
				<Row>
					<Col>
						<div className="d-flex align-items-center justify-content-between">
							<h1 className="mb-0 text-primary">
								{loadingDocuments ? (
									<>Loading...</>
								) : (
									<>{hasQuery ? `Filtered Research` : 'All Research'}</>
								)}
							</h1>
						</div>
					</Col>
				</Row>
			</Container>

			<Container className="pt-7 pb-16">
				<Row>
					<Col xs={12} xl={8} className="mb-6">
						<div className="mb-6 d-flex align-items-center justify-content-end">
							<div className="d-flex align-items-center">
								<Form.Label className="mb-0">Page Size</Form.Label>
								<Select variant="secondary" value={size} onChange={handlePageSizeChange}>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="50">50</option>
								</Select>
							</div>
						</div>
						{!loadingDocuments && documents.length === 0 && (
							<p className="mb-2 text-muted text-center">
								<strong>No Results</strong>
							</p>
						)}
						{documents.map((document) => (
							<ResearchListItem
								key={document.documentId}
								documentId={document.documentId}
								category={document.documentTypeName}
								fileType={document.fileType}
								fileName={document.fileName}
								viewUrl={document.viewUrl}
								canDownload={document.canDownload}
								isBookmarked={document.bookmarkedFlag}
								to={`/research/${document.documentId}`}
								title={document.name}
								description={document.listDescription ?? ''}
								imageSrc={document.imageUrl ?? ''}
								date={document.publicationDateDescription}
								tags={document.topicTags.map((tag) => tag.name)}
								onChange={handleResearchListItemChange}
								isPodcast={!!document.podcastUrl}
							/>
						))}
						{loadingDocuments && documents.length <= 0 && (
							<div className="mb-8 d-flex justify-content-center">
								<Loader />
							</div>
						)}
						<hr className="mb-8" />
						<div className="d-flex align-items-center justify-content-end">
							<span className="text-muted mr-4">
								Showing 1-{documents.length}
								{hasQuery && <>&nbsp;of {totalCount}</>}
							</span>
							{documents.length < totalCount && (
								<LoadingButton
									isLoading={loadingDocuments}
									variant="outline-info"
									onClick={handleShowMoreButtonClick}
								>
									Show {size} More
								</LoadingButton>
							)}
						</div>
					</Col>
					<Col xs={12} xl={4} className="mb-6">
						<Card className="mb-2">
							<Card.Body>
								<div className="mb-4 d-flex align-items-end justify-content-between">
									<h3 className="mb-0">Filters</h3>
									<Button variant="link" onClick={handleClearButtonClick}>
										Clear
									</Button>
								</div>
								<Form.Group className="mb-6">
									<Form.Label>Topics</Form.Label>
									<MultiSelect
										options={topicFilters}
										selected={topicsQuery ?? []}
										onChange={handleTopicsChange}
										disabled={isLoadingFilters}
									>
										Select
									</MultiSelect>
								</Form.Group>
								<Form.Group className="mb-6">
									<Form.Label>Business Processes</Form.Label>
									<MultiSelect
										options={businessProcessFilters}
										selected={allBpSelections}
										onChange={handleBusinessProcessesChange}
										disabled={isLoadingFilters}
									>
										Select
									</MultiSelect>
								</Form.Group>
								<Form.Group>
									<Form.Label>Content Type</Form.Label>
									<MultiSelect
										options={contentTypeFilters}
										selected={contentTypesQuery ?? []}
										onChange={handleContentTypesChange}
										disabled={isLoadingFilters}
									>
										Select
									</MultiSelect>
								</Form.Group>
							</Card.Body>
						</Card>
						<RecentlyViewed className="mb-2" />
						<LinkBox
							sections={[
								{
									title: "Can't find what you're looking for?",
									links: [{ title: 'Contact Hackett', to: '/contact', showIcon: true }],
								},
							]}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};
