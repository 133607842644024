import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Modal, ModalProps, Row } from 'react-bootstrap';

import { DocumentModel, FormOption } from '@/lib/models';
import { documentService } from '@/lib/services';
import { createUseThemedStyles, useDebouncedState, useHandleError } from '@/hooks';
import { DateInput, DeliverablesDocumentListItem, Loader, SearchInput, Select } from '@/components';
import { TablePagination } from './table';

const useStyles = createUseThemedStyles((theme) => ({
	dialog: {
		width: '90%',
		height: '100%',
		maxWidth: 1240,
		margin: '0 auto',
		'& .modal-content': {
			maxHeight: '90vh',
		},
		'& .hackett-modal__body': {
			overflowY: 'auto',
			backgroundColor: theme.colors.gray100,
		},
	},
}));

const initialFilterValues = {
	link: '',
	fromDate: null as null | Date,
	toDate: null as null | Date,
	documentType: '',
};

interface DeliverablesDocumentModalProps extends ModalProps {
	value: DocumentModel[];
	onSave(selectedDocuments: DocumentModel[]): void;
}

export const DeliverablesDocumentModal = ({ value, onSave, ...props }: DeliverablesDocumentModalProps) => {
	const handleError = useHandleError();
	const classes = useStyles();
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearchValue = useDebouncedState(searchValue);
	const [sortOptions, setSortOptions] = useState<FormOption[]>([]);
	const [documentTypes, setDocumentTypes] = useState<FormOption[]>([]);
	const [filterValues, setFilterValues] = useState(initialFilterValues);
	const [appliedFilters, setAppliedFilters] = useState(initialFilterValues);

	const pageSize = useRef(4);
	const page = useRef(0);
	const [sortField, setSortField] = useState('');
	const [documents, setDocuments] = useState<DocumentModel[]>([]);
	const [totalDocuments, setTotalDocuments] = useState(0);
	const [selectedDocuments, setSelectedDocuments] = useState<DocumentModel[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchDocumentTypes = useCallback(async () => {
		try {
			const filtersResponse = await documentService.getDocumentFilters().fetch();

			setSortOptions(filtersResponse.sortFields);
			setDocumentTypes(filtersResponse.contentTypes);
		} catch (error) {
			handleError(error);
		}
	}, [handleError]);

	const fetchDocuments = useCallback(async () => {
		try {
			setIsLoading(true);

			const response = await documentService
				.getDocuments({
					page: String(page.current),
					size: String(pageSize.current),
					...(debouncedSearchValue && { searchQuery: debouncedSearchValue }),
					...(sortField && { sortField }),
					...(appliedFilters.link && { link: appliedFilters.link }),
					...(appliedFilters.fromDate && {
						fromDate: format(appliedFilters.fromDate, 'yyyy-MM-dd'),
					}),
					...(appliedFilters.toDate && {
						toDate: format(appliedFilters.toDate, 'yyyy-MM-dd'),
					}),
					...(appliedFilters.documentType && { documentTypes: [appliedFilters.documentType] }),
				})
				.fetch();
			setDocuments(response.documents);
			setTotalDocuments(response.totalCount);
		} catch (error) {
			handleError(error);
		} finally {
			setIsLoading(false);
		}
	}, [
		appliedFilters.documentType,
		appliedFilters.fromDate,
		appliedFilters.link,
		appliedFilters.toDate,
		debouncedSearchValue,
		handleError,
		sortField,
	]);

	useEffect(() => {
		page.current = 0;
	}, [debouncedSearchValue]);

	const handleEnter = useCallback(() => {
		setSearchValue('');
		setSortField('');
		page.current = 0;
		setFilterValues(initialFilterValues);
		setAppliedFilters(initialFilterValues);

		fetchDocumentTypes();
		setSelectedDocuments(value);
	}, [fetchDocumentTypes, value]);

	useEffect(() => {
		if (props.show) {
			fetchDocuments();
		}
	}, [fetchDocuments, props.show]);

	const handleDocumentListItemChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		const selectedDocumentsClone = cloneDeep(selectedDocuments);
		const indexToRemove = selectedDocumentsClone.findIndex((d) => d.documentId === currentTarget.value);

		if (indexToRemove > -1) {
			selectedDocumentsClone.splice(indexToRemove, 1);
		} else {
			const documentToAdd = documents.find((d) => d.documentId === currentTarget.value);
			if (documentToAdd) {
				selectedDocumentsClone.push(documentToAdd);
			}
		}

		setSelectedDocuments(selectedDocumentsClone);
	};

	const handlePaginationChange = ({ pageIndex }: { pageIndex: number }) => {
		page.current = pageIndex;
		fetchDocuments();
	};

	const handleClearButtonClick = () => {
		page.current = 0;
		setFilterValues(initialFilterValues);
		setAppliedFilters(initialFilterValues);
	};

	const handleApplyFiltersButtonClick = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		page.current = 0;
		setAppliedFilters(filterValues);
	};

	const handleAddSelectedButtonClick = () => {
		onSave(selectedDocuments);
	};

	return (
		<Modal dialogClassName={classes.dialog} centered {...props} onEntering={handleEnter}>
			<Modal.Header closeButton>
				<Modal.Title>Add Documents</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xl={8} className="mb-3">
						<Row>
							<Col xl={6}>
								<SearchInput
									className="mb-6"
									value={searchValue}
									onChange={({ currentTarget }) => {
										setSearchValue(currentTarget.value);
									}}
								/>
							</Col>
						</Row>
						<div className="mb-6 d-flex justify-content-between align-items-center">
							<div className="d-flex align-items-center">
								<h3 className="mb-0">
									{selectedDocuments.length > 0 ? (
										<>
											{selectedDocuments.length} Selected{' '}
											<Button
												className="pl-2"
												variant="link"
												onClick={() => {
													setSelectedDocuments([]);
												}}
											>
												Clear Selections
											</Button>
										</>
									) : (
										`Documents (${totalDocuments})`
									)}
								</h3>
								{isLoading && <Loader className="ml-2" size={30} />}
							</div>
							<div className="d-flex align-items-center">
								<Form.Label className="mb-0 text-muted">Sort By</Form.Label>
								<Select
									variant="secondary"
									value={sortField}
									onChange={({ currentTarget }) => {
										page.current = 0;
										setSortField(currentTarget.value);
									}}
								>
									{sortOptions.map((o) => {
										return (
											<option key={o.id} value={o.id}>
												{o.display}
											</option>
										);
									})}
								</Select>
							</div>
						</div>
						<hr />
						{!isLoading && documents.length <= 0 && (
							<p className="mt-6 text-muted text-center">
								<strong>No Results</strong>
							</p>
						)}
						<div style={{ opacity: isLoading ? 0.5 : 1 }}>
							{documents.map((document) => {
								return (
									<DeliverablesDocumentListItem
										key={document.documentId}
										showCheck
										title={document.name}
										submissionDate={document.publicationDateDescription}
										fileType={document.fileType}
										fileName={document.fileName ?? 'file name unknown'}
										checkboxProps={{
											id: document.documentId,
											value: document.documentId,
											checked: selectedDocuments.some(
												(d) => d.documentId === document.documentId
											),
											onChange: handleDocumentListItemChange,
										}}
									/>
								);
							})}
						</div>
						{documents.length > 0 && (
							<TablePagination
								showPageSize={false}
								pageSize={pageSize.current}
								pageIndex={page.current}
								total={totalDocuments}
								onChange={handlePaginationChange}
							/>
						)}
					</Col>
					<Col xl={4}>
						<Card>
							<Card.Body>
								<div className="mb-4 d-flex align-items-center justify-content-between">
									<Card.Title className="mb-0">Filters</Card.Title>
									<Button variant="link" onClick={handleClearButtonClick}>
										Clear ({Object.values(appliedFilters).filter((v) => v).length})
									</Button>
								</div>
								<Form onSubmit={handleApplyFiltersButtonClick}>
									<Form.Group className="mb-6">
										<Form.Label>Link</Form.Label>
										<Form.Control
											type="text"
											value={filterValues.link}
											onChange={({ currentTarget }) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													link: currentTarget.value,
												}));
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>From Date</Form.Label>
										<DateInput
											isClearable
											maxDate={filterValues.toDate}
											selected={filterValues.fromDate}
											onChange={(date) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													fromDate: date,
												}));
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>To Date</Form.Label>
										<DateInput
											isClearable
											minDate={filterValues.fromDate}
											selected={filterValues.toDate}
											onChange={(date) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													toDate: date,
												}));
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Document Type</Form.Label>
										<Select
											value={filterValues.documentType}
											onChange={({ currentTarget }) => {
												setFilterValues((previousValue) => ({
													...previousValue,
													documentType: currentTarget.value,
												}));
											}}
										>
											<option value="">Select document type...</option>
											{documentTypes.map((dt) => {
												return (
													<option key={dt.id} value={dt.id}>
														{dt.display}
													</option>
												);
											})}
										</Select>
									</Form.Group>
									<hr className="mb-6" />
									<div className="text-center">
										<Button variant="outline-primary" type="submit">
											Apply Filters
										</Button>
									</div>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<div className="text-right">
					<Button className="mr-2" variant="link" onClick={props.onHide}>
						Cancel
					</Button>
					<Button onClick={handleAddSelectedButtonClick}>
						Add Selected {selectedDocuments.length > 0 && `(${selectedDocuments.length})`}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
