import config from '@/lib/config';

export enum COOKIES {
	ACCESS_TOKEN = 'X-Hackett-Access-Token',
	TARGET_URL = 'TARGET_URL',
	MAINTENANCE_TARGET_URL = 'MAINTENANCE_TARGET_URL',
}

export const accessTokenCookieAttributes = {
	sameSite: 'lax' as 'lax',
	domain: config.HACKETT_ADVISORY_COOKIE_DOMAIN,
};
