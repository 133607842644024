import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import React, { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Color from 'color';

import { COOKIES } from '@/lib/models';
import { TableColumnConfig, TableColumns, TableRowConfig, DownloadParametersModel } from './models';

import { createUseThemedStyles } from '@/hooks';
import { FadeTransition, Loader } from '@/components';

import { Table } from './table';
import { TableBody } from './table-body';
import { TableHead } from './table-head';
import { TablePagination } from './table-pagination';
import { TableColumnModal } from './table-column-modal';

import { ReactComponent as GearIcon } from '@/assets/icons/icon-gear.svg';
import { ReactComponent as DownloadIcon } from '@/assets/icons/icon-download.svg';

const useStyles = createUseThemedStyles((theme) => ({
	selectAllAlertOuter: {
		overflow: 'hidden',
	},
	selectAllAlert: {
		padding: 16,
		borderRadius: 4,
		display: 'flex',
		marginBottom: 16,
		alignItems: 'center',
		justifyContent: 'center',
		border: `1px solid ${theme.colors.primary}`,
		backgroundColor: Color(theme.colors.primary)
			.alpha(0.16)
			.string(),
	},
	tableOuter: {
		position: 'relative',
	},
	loader: {
		top: '50%',
		left: '50%',
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
	},
}));

interface TableCheckboxProps {
	id: string;
	value: string;
	checked: boolean;
	label: string;
	onChange(event: React.ChangeEvent<HTMLInputElement | HTMLLabelElement>): void;
	onClick(event: React.MouseEvent<HTMLInputElement | HTMLLabelElement>): void;
}

interface TableRendererProps<ColumnId extends string, TableRowColumnConfig extends object> {
	tableColumnData: TableColumns<ColumnId>;
	onTableColumnDataChange(tableColumns: TableColumns<ColumnId>): void;
	tableRowData: TableRowConfig<TableRowColumnConfig>[];
	onTableRowDataChange(tableRowData: TableRowConfig<TableRowColumnConfig>[]): void;
	tableSelectAll?: boolean;
	onTableSelectAllChange?(value: boolean): void;
	downloadUrl?: string;
	setUncheckedRowsIds?(value: string[]): void;
	uncheckedRowsIds?: string[];
	setCheckedRowsIds?(value: string[]): void;
	checkedRowsIds?: string[];
	downloadData?: DownloadParametersModel;
	tableHeaderRowRenderer(checkboxProps: TableCheckboxProps, clearSelections: () => void): JSX.Element | null;
	tableBodyRowRenderer(
		data: TableRowConfig<TableRowColumnConfig>,
		checkboxProps: TableCheckboxProps
	): JSX.Element | null;
	isLoading?: boolean;
	pageSize: number;
	pageIndex: number;
	tableRowDataTotal: number;
	onPaginationChange(values: { pageSize: number; pageIndex: number }): void;
	hidePagination?: boolean;
	hideColumnControls?: boolean;
	className?: string;
}

export function TableRenderer<ColumnId extends string, TableRowColumnConfig extends object>({
	tableColumnData,
	onTableColumnDataChange,
	tableRowData,
	onTableRowDataChange,
	tableSelectAll,
	onTableSelectAllChange,
	downloadUrl,
	downloadData,
	setUncheckedRowsIds,
	uncheckedRowsIds,
	setCheckedRowsIds,
	checkedRowsIds,
	tableHeaderRowRenderer,
	tableBodyRowRenderer,
	isLoading,
	pageSize,
	pageIndex,
	tableRowDataTotal,
	onPaginationChange,
	hidePagination = false,
	hideColumnControls = false,
	className,
	children,
}: PropsWithChildren<TableRendererProps<ColumnId, TableRowColumnConfig>>) {
	const classes = useStyles();
	const tableId = useRef(uuid());
	const [showTableColumnModal, setShowTableColumnModal] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [downloadLength, setDownloadLength] = useState(0);
	const [pageChanges, setPageChanges] = useState(false);

	const clearSelections = useCallback(() => {

		const tableRowsDataClone = tableRowData.map((row) => {
			row.checked = false;
			return row;
		});
		setDownloadLength(0);
		setUncheckedRowsIds?.([]);
		setCheckedRowsIds?.([]);
		onTableRowDataChange(tableRowsDataClone);
		setSelectAll(false);
		if (onTableSelectAllChange) {
			onTableSelectAllChange(false);
		}
	}, [onTableRowDataChange, onTableSelectAllChange, tableRowData, setUncheckedRowsIds, setCheckedRowsIds]);

	const selectAllOnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onTableRowDataChange(
				tableRowData.map((row) => {
					row.checked = event.currentTarget.checked;
					return row;
				})
			);
			setPageChanges(false);
			const selectAllCurrentPage = tableRowData.map(a => a.rowId);
			onTableSelectAllChange?.(false);
			setSelectAll(event.currentTarget.checked);
			setCheckedRowsIds?.(selectAllCurrentPage);
			setUncheckedRowsIds?.([]);
			setDownloadLength(tableRowData.length);
			if (!event.currentTarget.checked) {
				clearSelections();
			}
		},
		[clearSelections, onTableRowDataChange, tableRowData, onTableSelectAllChange, setUncheckedRowsIds, setCheckedRowsIds]
	);

	const rowSelectOnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { value, checked } = event.currentTarget;
			if (downloadLength > tableRowData.length + 1 && downloadLength === tableRowDataTotal) {
				onTableSelectAllChange?.(true);
			}

			else if (downloadLength !== tableRowDataTotal) {
				onTableSelectAllChange?.(false);
			}
			const tableRowsDataClone = tableRowData.map((row) => {
				if (row.rowId === value) {
					if (row.checked && downloadLength >= 0) {
						if (!(uncheckedRowsIds?.includes(value)) && tableSelectAll) {
							setUncheckedRowsIds?.([...uncheckedRowsIds!, value]);
							onTableSelectAllChange?.(true);
						}
						setCheckedRowsIds?.(checkedRowsIds?.filter(element => element !== value)!);
						setDownloadLength(downloadLength - 1);
					}
					else {
						if (uncheckedRowsIds?.includes(value) && tableSelectAll) {
							setUncheckedRowsIds?.(uncheckedRowsIds?.filter(element => element !== value)!);
						}
						setCheckedRowsIds?.([...checkedRowsIds!, value]);
						setDownloadLength(downloadLength + 1);
					}
					row.checked = checked;

				}


				return row;
			});
			const allAreChecked = tableRowsDataClone.every((row) => {
				return row.checked;
			});

			if (allAreChecked) {
				setSelectAll(true);
			} else {
				setSelectAll(false);
			}
			onTableRowDataChange(tableRowsDataClone);
		},
		[tableRowData, onTableRowDataChange, setDownloadLength, downloadLength, tableSelectAll, onTableSelectAllChange, setUncheckedRowsIds, uncheckedRowsIds, checkedRowsIds, setCheckedRowsIds, tableRowDataTotal]
	);

	const selectAllCheckboxProps = useMemo((): TableCheckboxProps => {
		return {
			id: `table-head__checkbox--${tableId.current}`,
			value: 'SELECT_ALL',
			checked: selectAll || false,
			label: '',
			onChange: selectAllOnChange,
			onClick: (event) => {
				event.stopPropagation();
			},
		};
	}, [selectAll, selectAllOnChange]);

	const tableRowCheckboxProps = useMemo(() => {
		return (data: TableRowConfig<TableRowColumnConfig>): TableCheckboxProps => ({
			id: `table-row__checkbox--${data.rowId}`,
			value: data.rowId,
			checked: data.checked,
			label: '',
			onChange: rowSelectOnChange,
			onClick: (event) => {
				event.stopPropagation();
			},
		});
	}, [rowSelectOnChange]);

	const hideableColumnLength = useMemo(() => {
		return Object.values<TableColumnConfig<ColumnId>>(tableColumnData).filter((tableColumn) => {
			return tableColumn.isHidable;
		}).length;
	}, [tableColumnData]);


	return (
		<div className={className}>
			<TableColumnModal
				tableColumnData={tableColumnData}
				show={showTableColumnModal}
				onChange={(updatedTableColumnData) => {
					setShowTableColumnModal(false);
					onTableColumnDataChange(updatedTableColumnData);
				}}
				onHide={() => {
					setShowTableColumnModal(false);
				}}
			/>

			<FadeTransition in={tableSelectAll && downloadLength === tableRowDataTotal}>
				<div className={classes.selectAllAlertOuter}>
					<div className={classes.selectAllAlert}>
						<p className="mb-0 mr-2">
							All <strong>{tableRowDataTotal}</strong> items are selected.
						</p>
						<Button variant="link" onClick={clearSelections}>
							Clear selection
						</Button>
					</div>
				</div>
			</FadeTransition>

			<FadeTransition
				in={tableRowData.length > 0 && downloadLength === tableRowData.length && !tableSelectAll && !pageChanges}
			>
				<div className={classes.selectAllAlertOuter}>
					<div className={classes.selectAllAlert}>
						<p className="mb-0 mr-2">
							All <strong>{downloadLength}</strong> items on this page are selected.
						</p>
						<Button
							variant="link"
							onClick={() => {
								if (onTableSelectAllChange) {
									onTableSelectAllChange(true);
									setDownloadLength(tableRowDataTotal)
								}
							}}
						>
							Select all {tableRowDataTotal} items
						</Button>
					</div>
				</div>
			</FadeTransition>

			{(downloadUrl || hideableColumnLength > 0) && (
				<div className="mb-3 d-flex align-items-center justify-content-between">
					<div className="flex-grow-1">{children}</div>
					<div className="d-flex align-items-center justify-content-between flex-shrink-0">
						{downloadUrl && (
							<Form className="d-inline-block mr-2" action={downloadUrl} method="post">
								<Form.Control
									className="d-none"
									type="text"
									name="access_token"
									value={Cookies.get(COOKIES.ACCESS_TOKEN)}
									readOnly
								/>

								{downloadData &&
									Object.entries(downloadData).map(([key, value], index) => {
										switch (typeof value) {
											case 'boolean':
												return (
													<Form.Control
														key={`${key}-${index}`}
														className="d-none"
														type="checkbox"
														name={key}
														value={String(value)}
														checked={value}
														readOnly
													/>
												);
											case 'string':
											default:
												return (
													<Form.Control
														key={`${key}-${index}`}
														className="d-none"
														type="text"
														name={key}
														value={value}
														readOnly
													/>
												);
										}
									})}

								<Button
									type="submit"
									formTarget="_blank"
									className="d-flex align-items-center font-weight-semi-bold mr-3"
									variant="link"
									disabled={downloadLength <= 0}
								>
									<DownloadIcon className="mr-1" />
									Download Selected (
									{<>{downloadLength}</>})
								</Button>
							</Form>
						)}
						{hideableColumnLength > 0 && !hideColumnControls && (
							<Button
								className="d-flex align-items-center font-weight-semi-bold"
								variant="link"
								onClick={() => {
									setShowTableColumnModal(true);
								}}
							>
								<GearIcon className="mr-1" />
								Edit Table Columns
							</Button>
						)}
					</div>
				</div>
			)}

			<div className={classes.tableOuter}>
				<Table isLoading={isLoading}>
					<TableHead>{tableHeaderRowRenderer(selectAllCheckboxProps, clearSelections)}</TableHead>
					<TableBody>
						{tableRowData.map((trd) => {
							return tableBodyRowRenderer(trd, tableRowCheckboxProps(trd));
						})}
					</TableBody>
				</Table>
				{isLoading && <Loader className={classes.loader} />}
			</div>

			{!hidePagination && (
				<TablePagination
					pageSize={pageSize}
					pageIndex={pageIndex}
					total={tableRowDataTotal}
					onChange={(values) => {
						onPaginationChange(values);
						setPageChanges(true);
						downloadLength === tableRowData.length && setSelectAll?.(false);
					}}
				/>
			)}
		</div>
	);

}
