import React, { useMemo } from 'react';

import config from '@/lib/config';
import { createUseThemedStyles } from '@/hooks';

const useStyles = createUseThemedStyles((theme) => ({
	renderJson: {
		padding: 16,
		marginTop: 24,
		borderRadius: 4,
		color: 'rgb(205,205,205)',
		...theme.fonts.xs,
		backgroundColor: 'rgb(29,29,29)',
		border: `1px solid ${theme.colors.gray300}`,
		whiteSpace: 'pre-wrap',
		'& .string': {
			color: 'rgb(190, 136, 112)',
		},
		'& .number': {
			color: 'rgb(177, 198, 161)',
		},
		'& .boolean, & .null': {
			color: 'rgb(84, 132, 186)',
		},
		'& .key': {
			color: 'rgb(158, 213, 250)',
		},
	},
}));

interface RenderJsonProps {
	json: any;
}

export const RenderJson = ({ json }: RenderJsonProps) => {
	const classes = useStyles();

	const syntaxHighlightedJson = useMemo(() => {
		if (!json) {
			return '';
		}

		const jsonWithoutHtmlTags = JSON.stringify(json, null, 4)
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;');

		return jsonWithoutHtmlTags.replace(
			/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
			(match) => {
				var className = 'number';

				if (/^"/.test(match)) {
					if (/:$/.test(match)) {
						className = 'key';
					} else {
						className = 'string';
					}
				} else if (/true|false/.test(match)) {
					className = 'boolean';
				} else if (/null/.test(match)) {
					className = 'null';
				}

				return `<span class="${className}">${match}</span>`;
			}
		);
	}, [json]);

	if (config.HACKETT_ADVISORY_SHOW_DEBUG === 'true') {
		return <pre className={classes.renderJson} dangerouslySetInnerHTML={{ __html: syntaxHighlightedJson }} />;
	}

	return null;
};
